export var priceNumber = function priceNumber(number) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    decimals: 4
  },
      decimals = _ref.decimals;

  var abs = Math.abs(number);
  if (isNaN(abs)) return '0';

  if (abs >= 1000 * 1000 * 1000) {
    return new Intl.NumberFormat('en', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: 0
    }).format(number / (1000 * 1000 * 1000)) + 'B';
  } else if (abs >= 1000 * 1000) {
    return new Intl.NumberFormat('en', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: 0
    }).format(number / (1000 * 1000)) + 'M';
  } // else if (abs < 10) {
  //   return new Intl.NumberFormat('en', {
  //     maximumFractionDigits: 6,
  //     minimumFractionDigits: 6
  //   }).format(number)
  // }


  return new Intl.NumberFormat('en', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: 0
  }).format(number);
};