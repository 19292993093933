import { ProPlan } from '@jdiyield/interfaces'

export const proPlans = [
  {
    id: 2,
    title: 'Stake',
    body: '2,000 JDI',
    pricing: 2000,
    description: 'Stake and forget. Pro feature is enabled.'
  },
  {
    id: 1,
    title: 'Annual',
    body: 'Pay 500 JDI',
    pricing: 500,
    description: 'Prepaid for a year usage.'
  },
  {
    id: 0,
    title: 'Monthly',
    body: 'Pay 50 JDI',
    pricing: 50,
    description: 'Prepaid for a month usage.'
  }
] as ProPlan[]

export const benefits = {
  basic: [
    `Stake, unstake, withdraw,
    or remove liquidity`,
    'Harvest rewards',
    '1 slot on Watch list',
    'Revoke allowances',
    'Price graph',
    'Wallet values'
  ],
  pro: [
    '3 slots on Watch list',
    '5-min auto refresh',
    'stake/unstake or remove LP',
    'Harvest all farm in 1 click',
    // 'Manage your farm',
    // 'Custom farm',
    // '50% get distributed to JDI locked staking pool (Tiers)',
    'Holdings Overview (coming soon)',
    'Telegram/Line chatbot (coming soon)',
    'Code Change alert'
  ]
}
