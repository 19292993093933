import { nodes } from '@jdiyield/constants';
import random from 'lodash/random'; // # 10+ nodes balanced, US/EU
// const REACT_APP_NODE_1 = 'https://bsc-dataseed1.ninicoin.io'
// // # 10+ nodes balanced, US/EU
// const REACT_APP_NODE_2 = 'https://bsc-dataseed1.defibit.io'
// // # 10+ nodes balanced in each region, global
// const REACT_APP_NODE_3 = 'https://bsc-dataseed.binance.org'

var getNodeUrl = function getNodeUrl(chainID) {
  var _nodes$chainID;

  var rpc = (_nodes$chainID = nodes[chainID]) !== null && _nodes$chainID !== void 0 ? _nodes$chainID : [];
  var randomIndex = random(0, rpc.length - 1);
  return rpc[randomIndex];
};

export default getNodeUrl;