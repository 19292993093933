import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable unused-imports/no-unused-imports */
import Layout from '@frontend/components/Layout';
import { NotiProvider } from '@frontend/components/Notification';
import { useGA } from '@frontend/hooks/ga';
import { AccountProvider } from '@frontend/providers/AccountProvider';
import { ChoosingProProvider } from '@frontend/providers/ChoosingProProvider';
import { CoinPriceProvider } from '@frontend/providers/CoinPriceProvider';
import { CurrencyProvider } from '@frontend/providers/CurrencyProvider';
import { PlatformProvider } from '@frontend/providers/PlatformProvider';
import { WalletConfigProvider } from '@frontend/providers/WalletConfigProvider';
import { colors } from '@frontend/styles/colors';
import { StyledProvider, useThemeContext } from '@frontend/styles/Provider';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import * as React from 'react';

var App = function App(_ref) {
  var _pageProps$platforms, _pageProps$tokens, _pageProps$baseLpToke;

  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  useGA();
  return _jsx(PlatformProvider, {
    platforms: (_pageProps$platforms = pageProps === null || pageProps === void 0 ? void 0 : pageProps.platforms) !== null && _pageProps$platforms !== void 0 ? _pageProps$platforms : [],
    tokens: (_pageProps$tokens = pageProps === null || pageProps === void 0 ? void 0 : pageProps.tokens) !== null && _pageProps$tokens !== void 0 ? _pageProps$tokens : [],
    baseLpTokens: (_pageProps$baseLpToke = pageProps === null || pageProps === void 0 ? void 0 : pageProps.baseLpTokens) !== null && _pageProps$baseLpToke !== void 0 ? _pageProps$baseLpToke : [],
    children: _jsxs(StyledProvider, {
      children: [_jsx(HeadSEO, {}), _jsx(NotiProvider, {
        children: _jsx(CurrencyProvider, {
          children: _jsx(CoinPriceProvider, {
            children: _jsx(AccountProvider, {
              children: _jsx(WalletConfigProvider, {
                children: _jsx(ChoosingProProvider, {
                  children: _jsx(Layout, {
                    children: _jsx(Component, _objectSpread({}, pageProps))
                  })
                })
              })
            })
          })
        })
      })]
    })
  });
};

var HeadSEO = function HeadSEO() {
  var _useThemeContext = useThemeContext(),
      theme = _useThemeContext.theme;

  return _jsx(DefaultSeo, {
    title: "JDIYIELD | One stop SAFU service for yield farmers",
    description: "One stop SAFU service for yield farmers",
    openGraph: {
      url: 'https://www.jdiyield.com/',
      title: 'JDIYIELD | One stop SAFU service for yield farmers',
      description: 'One stop SAFU service for yield farmers',
      images: [{
        url: 'https://www.jdiyield.com/images/preview.png',
        width: 2400,
        height: 1260,
        alt: 'JDIYIELD'
      }],
      site_name: 'JDIYIELD'
    },
    additionalMetaTags: [{
      name: 'theme-color',
      content: colors.accentLight400
    }],
    additionalLinkTags: [{
      rel: 'icon',
      href: "/images/logo/jdi-solid-".concat(theme, ".svg")
    }, {
      rel: 'manifest',
      href: '/manifest.json'
    }, {
      rel: 'apple-touch-icon',
      href: '/icons/192.png'
    }]
  });
};

export default appWithTranslation(App);