import { Chain, Platform, PlatformName } from '@jdiyield/interfaces'

// export const oldplatforms: Platform[] = [

// ]

export const oldplatforms: Platform[] = [
  {
    title: 'DODO NFT',
    name: 'DODO NFT BSC',
    link: 'https://dodonft.io/',
    logo: 'https://assets.coingecko.com/coins/images/12651/large/dodo_logo.png?1601433025',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x7081685ff3ff16fb6c0df08ac93bdb7299eeefc9' }],
    nftConfigs: [
      {
        //DODOMysteryBoxYUIHatano
        contractAddress: '0xc25286ef3bae3f6fe2d6d0a6e2acad0301af97b8'
      },
      {
        //DODOMysteryBoxKAKA
        contractAddress: '0xdf7e00cd0bb91d1502a1a14575e58b5d8f20c8d4'
      }
    ]
  },
  {
    title: 'ENTER ART',
    name: 'ENTER ART BSC',
    link: 'https://www.enter.art/',
    logo: '/images/logo/enterart.svg',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xa593576f213d1dfe9fc4eefa3dc34cb390a645de' }],
    nftConfigs: [
      {
        contractAddress: '0x8F943AA9df1f6BF333497EE8A01D06314EA3Af55'
      }
    ]
  },
  {
    title: 'treasureland',
    name: 'treasureland BSC',
    link: 'https://treasureland.market/',
    logo: '/images/logo/treasureland.svg',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x4b9ee53b17bca029fe2bb2a9af7e1332bbd021ba' }],
    nftConfigs: [
      {
        contractAddress: '0xf7a21ffb762ef2c14d8713b18f5596b4b0b0490a'
      }
    ]
  },
  {
    title: 'OpenSea',
    name: 'OpenSea ETH',
    link: 'https://opensea.io/',
    logo: '/images/logo/opensea.svg',
    isDollar: true,
    new: false,
    chain: Chain.ETHEREUM,
    contracts: [],
    nftConfigs: [
      {
        contractAddress: 'we use Opensea API'
      }
    ]
  },
  {
    title: 'Axie Infinity',
    name: PlatformName.AXIE_INFINITY,
    link: 'https://axieinfinity.com/',
    logo: '/images/coin/AXS.png',
    isDollar: true,
    new: false,
    chain: Chain.RONIN,
    contracts: [
      {
        contractAddress: '1',
        routerAddress: '',
        pendingReward: 'pending',
        getBonusMultiplier: 'none',
        showReward: true,
        rewardAddress: '0xa8754b9fa15fc18bb59458815510e40a12cd2014',
        stake: false,
        unstake: false,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: false
      }
    ],
    nftConfigs: [
      {
        contractAddress: '0x32950db2a7164ae833121501c797d79e7b79d74c'
      }
    ]
  },
  {
    name: 'Mirror',
    title: 'Mirror',
    chain: Chain.TERRA,
    link: 'https://terra.mirror.finance/',
    logo: 'https://i.postimg.cc/pLJK7LpK/M1o7x-Ab-T-o.png',
    symbol: 'MIR',
    isDollar: true,
    contracts: [
      {
        contractAddress: 'terra17f7zu97865jmknk7p2glqvxzhduk78772ezac5',
        routerAddress: '',
        pendingReward: 'pending',
        functionRewardPerBlockName: 'rewardPerBlock',
        getBonusMultiplier: 'none',
        showReward: true,
        rewardAddress: 'terra15gwkyepfc6xgca5t5zefzwy42uts8l2m4g40k6',
        stake: false,
        unstake: false,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: false
      }
    ]
  },
  // {
  //   title: 'Foundation',
  //   name: 'Foundation ETH',
  //   link: 'https://foundation.app/',
  //   logo: '/images/logo/forest.svg',
  //   isDollar: true,
  //   new: false,
  //   chain: Chain.ETHEREUM,
  //   contracts: [],
  //   nftConfigs: [
  //     {
  //       contractAddress: '0x3b3ee1931dc30c1957379fac9aba94d1c48a5405'
  //     }
  //   ]
  // },
  {
    title: 'Mai Finance',
    name: 'Mai Finance Polygon',
    logo: '/images/logo/mai.svg',
    link: 'https://mai.finance',
    new: false,
    isDollar: true,
    chain: Chain.POLYGON,
    deployer: [{ contractAddress: '0x86fe8d6d4c8a007353617587988552b6921514cb' }],
    timeLock: [{ contractAddress: '0x3feacf904b152b1880bde8bf04ac9eb636fee4d8' }],
    // lendContracts: [
    //   {
    //     id: 'Mai Finance Vault',
    //     liquidity: 'getCash',
    //     totalSupply: 'totalSupply',
    //     underlying: 'underlying',
    //     borrowed: 'borrowBalanceStored',
    //     lendTokens: ['0xa3fa99a148fa48d14ed51d610c367c61876997f1']
    //   }
    // ],
    contracts: [
      {
        contractAddress: '0x574fe4e8120c4da1741b5fd45584de7a5b521f0f',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        pendingReward: 'pending',
        functionRewardPerBlockName: 'rewardPerBlock',
        getBonusMultiplier: 'none',
        showReward: true,
        rewardAddress: '0x580a84c73811e1839f75d86d75d88cca0c241ff4',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: false
      }
    ]
  },
  {
    title: 'Alpaca Finance',
    symbol: 'ALPACA',
    logo: 'https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/logo512.png',
    link: 'https://app.alpacafinance.org/',
    isDollar: true,
    new: false,
    name: PlatformName.ALPACA_FINANCE,
    chain: Chain.BINANCE_SMART_CHAIN,
    nftConfigs: [
      {
        contractAddress: '0xe85d7b8f4c0c13806e158a1c9d7dcb33140cdc46'
      }
    ],
    lendContracts: [
      {
        id: 'Alpaca Lending',
        liquidity: 'getCash',
        totalSupply: 'totalSupply',
        underlying: 'underlying',
        borrowed: 'borrowBalanceStored',
        lendTokens: [
          /*ibALPACA*/ '0xf1be8ecc990cbcb90e166b71e368299f0116d421',
          /*ibBNB*/ '0xd7d069493685a581d27824fc46eda46b7efc0063',
          /*ibBUSD*/ '0x7c9e73d4c71dae564d41f78d56439bb4ba87592f',
          /*ibUSDT*/ '0x158da805682bdc8ee32d52833ad41e74bb951e59',
          /*ibTUSD*/ '0x3282d2a151ca00bfe7ed17aa16e42880248cd3cd',
          /*ibBTCB*/ '0x08fc9ba2cac74742177e0afc3dc8aed6961c24e7',
          /*ibETH*/ '0xbff4a34a4644a113e8200d7f1d79b3555f723afe'
        ]
      }
    ],
    deployer: [{ contractAddress: '0xc44f82b07ab3e691f826951a6e335e1bc1bb0b51' }],
    timeLock: [{ contractAddress: '0x2d5408f2287bf9f9b05404794459a846651d0a59' }],
    contracts: [
      {
        contractAddress: '0xa625ab01b08ce023b2a342dbb12a16f2c8489a8f',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingAlpaca',
        functionRewardPerBlockName: 'alpacaPerBlock',
        getBonusMultiplier: 'none',
        showReward: true,
        rewardAddress: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
        stake: false,
        unstake: false,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: true
      }
    ]
  },
  {
    title: 'Dopple',
    name: PlatformName.DOPPLE,
    chain: Chain.BINANCE_SMART_CHAIN,
    link: 'https://dopple.finance/Stake',
    symbol: 'DOP',
    logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Flogo.svg&w=128&q=75',
    new: false,
    isDollar: true,
    specialCaseTokens: [
      {
        id: null,
        symbol: 'DOP-LP',
        name: 'Dopple',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://firebasestorage.googleapis.com/v0/b/ape-board-prod.appspot.com/o/logo%2FvPool.png?alt=media&token=8a6fe8c5-d2c9-411d-b71e-9ecdc1e4764e',
        decimals: 18,
        address: '0x9116f04092828390799514bac9986529d70c3791'
      },
      {
        id: null,
        symbol: 'DOP-UST-LP',
        name: 'Dopple',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fust-pool.png&w=128&q=75',
        decimals: 18,
        address: '0x7edcdc8cd062948ce9a9bc38c477e6aa244dd545'
      },
      {
        id: null,
        symbol: 'DOP-2P-LP',
        name: 'Dopple 2pool LP Token',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fbusd-usdt.svg&w=128&q=75',
        decimals: 18,
        address: '0x124166103814e5a033869c88e0f40c61700fca17'
      },
      {
        id: null,
        symbol: 'DOP-3P-LP',
        name: 'Dopple BUSD-USDT-DOLLY',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fdolly-pool.svg&w=64&q=75',
        decimals: 18,
        address: '0xaa5509ce0ecea324bff504a46fc61eb75cb68b0c'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-USDN-LP',
        name: 'Dopple KUSD-BUSD-USDC-USDN',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-usdn.svg&w=64&q=75',
        decimals: 18,
        address: '0x367189b09340a6e157e5fd31cb0924e91b71710c'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-TUSD-LP',
        name: 'Dopple KUSD-BUSD-USDC-TUSD',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-tusd.svg&w=64&q=75',
        decimals: 18,
        address: '0x07fe67a1cfc285e14e72fb5bb03a6ccfa6224aa8'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-LP',
        name: 'Dopple KUSD-BUSD-USDC',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc.svg&w=64&q=75',
        decimals: 18,
        address: '0x2bf718e5fa2106dc1998d3f964c1baea8bda36e1'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDT-LP',
        name: 'Dopple KUSD-BUSD-USDT',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdt.svg&w=64&q=75',
        decimals: 18,
        address: '0x7755816b41f04ab62cec2334263987e7cd78a4fe'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-DOLLY-LP',
        name: 'Dopple KUSD-BUSD-DOLLY',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-dolly.svg&w=64&q=75',
        decimals: 18,
        address: '0x01222c8b6cb44e47c19e61c6ddac769e6d9fe629'
      }
    ],
    deployer: [{ contractAddress: '0x5f188439575f7bf21c29e8b7894d9916abeb306d' }],
    timeLock: [{ contractAddress: '0x6eaf575e471cff9cc3b798d72807563bd87885f7' }],
    contracts: [
      {
        contractAddress: '0xda0a175960007b0919dbf11a38e6ec52896bddbe',
        routerAddress: '0x6B011d0d53b0Da6ace2a3F436Fd197A4E35f47EF',
        pendingReward: 'pendingDopple',
        showReward: true,
        rewardAddress: '0x844fa82f1e54824655470970f7004dd90546bb28',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        getBonusMultiplier: 'bonusMultiplier',
        supportIL: true,
        functionRewardPerBlockName: 'dopplePerBlock',
        supportROI: true
      },
      {
        contractAddress: '0x45069E9632d1b29d16C5E06dc9Ed8F33B6aBe6DB',
        routerAddress: '0x6B011d0d53b0Da6ace2a3F436Fd197A4E35f47EF',
        pendingReward: 'pendingDoppleX',
        showReward: true,
        rewardAddress: '0x802a183ac9f6b082716dfee55432ed0c04acb49a',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        getBonusMultiplier: 'BONUS_MULTIPLIER',
        supportIL: true,
        functionRewardPerBlockName: 'doppleXPerBlock',
        supportROI: true
      }
    ]
  },
  {
    title: 'Venus',
    name: 'Venus',
    link: 'https://app.venus.io/dashboard',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'XVS',
    logo: '/images/coin/xvs.png',
    isDollar: true,
    new: false,
    lendContracts: [
      {
        id: 'Venus Lending',
        liquidity: 'getCash',
        totalSupply: 'totalSupply',
        underlying: 'underlying',
        borrowed: 'borrowBalanceStored',
        lendTokens: [
          /*vSXP*/ '0x2fF3d0F6990a40261c66E1ff2017aCBc282EB6d0',
          /*vUSDC*/ '0xecA88125a5ADbe82614ffC12D0DB554E2e2867C8',
          /*vUSDT*/ '0xfD5840Cd36d94D7229439859C0112a4185BC0255',
          /*vBUSD*/ '0x95c78222B3D6e262426483D42CfA53685A67Ab9D',
          /*vBNB*/ '0xA07c5b74C9B40447a954e1466938b865b6BBea36',
          /*vXVS*/ '0x151B1e2635A717bcDc836ECd6FbB62B674FE3E1D',
          /*vBTC*/ '0x882C173bC7Ff3b7786CA16dfeD3DFFfb9Ee7847B',
          /*vETH*/ '0xf508fCD89b8bd15579dc79A6827cB4686A3592c8',
          /*vLTC*/ '0x57A5297F2cB2c0AaC9D554660acd6D385Ab50c6B',
          /*vXRP*/ '0xB248a295732e0225acd3337607cc01068e3b9c10',
          /*vBCH*/ '0x5F0388EBc2B94FA8E123F404b79cCF5f40b29176',
          /*vDOT*/ '0x1610bc33319e9398de5f57B33a5b184c806aD217',
          /*vLINK*/ '0x650b940a1033B8A1b1873f78730FcFC73ec11f1f',
          /*vBETH*/ '0x972207A639CC1B374B893cc33Fa251b55CEB7c07',
          /*vDAI*/ '0x334b3eCB4DCa3593BCCC3c7EBD1A1C1d1780FBF1',
          /*vFIL*/ '0xf91d58b5aE142DAcC749f58A49FCBac340Cb0343',
          /*vADA*/ '0x9A0AF7FDb2065Ce470D72664DE73cAE409dA28Ec'
        ]
      }
    ]
  },
  {
    title: 'Balancer',
    name: PlatformName.BALANCER_ETH,
    logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
    chain: Chain.ETHEREUM,
    isDollar: true,
    new: false,
    link: 'https://app.balancer.fi',
    specialCaseTokens: [
      {
        id: null,
        symbol: 'Balancer 50 WBTC 50 WETH',
        name: 'WBTC+WETH',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xa6f548df93de924d73be7d25dc02554c6bd66db5'
      },
      {
        id: null,
        symbol: 'Balancer 80 BAL 20 WETH',
        name: 'BAL+WETH',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x5c6ee304399dbdb9c8ef030ab642b10820db8f56'
      },

      {
        id: null,
        symbol: 'Balancer 60 WETH 40 DAI',
        name: 'WETH+DAI',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x0b09dea16768f0799065c475be02919503cb2a35'
      },
      {
        id: null,
        symbol: 'Balancer 50 USDC 50 WETH',
        name: 'WETH+USDC',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x96646936b91d6b9d7d0c47c496afbf3d6ec7b6f8'
      },

      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x8339e311265a025fd5792db800daa8eda4264e2c'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x45910faff3cbf990fdb204682e93055506682d17'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xfa22ec1c02f121083bf04fbbcaad019f490d7a30'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xa660ba113f9aabaeb4bcd28a4a1705f4997d5432'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xea8886a24b6e01fba88a9e98d794e8d1f29ed863'
      },

      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xde148e6cc3f6047eed6e97238d341a2b8589e19e'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x5d563ca1e2daaae3402c36097b934630ab53702c'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x9f1f16b025f703ee985b58ced48daf93dad2f7ef'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x3ebf48cd7586d7a4521ce59e53d9a907ebf1480f'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xefaa1604e82e1b3af8430b90192c1b9e8197e377'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x9c08c7a7a89cfd671c79eacdc6f07c1996277ed5'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xe99481dc77691d8e2456e5f3f61c1810adfc1503'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xec60a5fef79a92c741cb74fdd6bfc340c0279b01'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x231e687c9961d3a27e6e266ac5c433ce4f8253e4'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x80be0c303d8ad2a280878b50a39b1ee8e54dbd22'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x5aa90c7362ea46b3cbfbd7f01ea5ca69c98fef1c'
      },

      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x186084ff790c65088ba694df11758fae4943ee9e'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x4626d81b3a1711beb79f4cecff2413886d461677'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x072f14b85add63488ddad88f855fda4a99d6ac9b'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xaac98ee71d4f8a156b6abaa6844cdb7789d086ce'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x7eb878107af0440f9e776f999ce053d277c8aca8'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x16faf9f73748013155b7bc116a3008b57332d1e6'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x6ae82385f76e3742f89cb46343b169f6ee49de1b'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x8a92c3afabab59101b4e2426c82a7ddbb66b5450'
      },

      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xf4c0dd9b82da36c07605df83c8a416f11724d88b'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x3e5fa9518ea95c3e533eb377c001702a9aacaa32'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xf8a0623ab66f985effc1c69d05f1af4badb01b00'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x021c343c6180f03ce9e48fae3ff432309b9af199'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x14462305d211c12a736986f4e8216e28c5ea7ab4'
      },
      {
        id: null,
        symbol: 'Balancer',
        name: 'Balancer',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xf5aaf7ee8c39b651cebf5f1f50c10631e78e0ef9'
      },

      {
        id: null,
        symbol: 'Balancer USDT USDC DAI',
        name: 'USDT+USDC+DAI',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x06df3b2bbb68adc8b0e302443692037ed9f91b42'
      },
      {
        id: null,
        symbol: 'Balancer sBTC renBTC WBTC',
        name: 'sBTC+renBTC+WBTC',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56'
      }
    ],
    contracts: [
      {
        contractAddress: 'balancer reward',
        routerAddress: '',
        pendingReward: '',
        rewardAddress: '',
        showReward: true,
        stake: false,
        unstake: false,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: false
      }
    ]
  },
  {
    title: 'Balancer',
    name: PlatformName.BALANCER_POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
    chain: Chain.POLYGON,
    isDollar: true,
    new: false,
    link: 'https://polygon.balancer.fi',
    specialCaseTokens: [
      {
        id: '',
        symbol: 'BPSP',
        name: 'USDC+DAI+miMATIC+USDT',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x06df3b2bbb68adc8b0e302443692037ed9f91b42'
      },
      {
        id: '',
        symbol: 'BPTC',
        name: 'WBTC+USDC+WETH',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x03cd191f589d12b0582a99808cf19851e468e6b5'
      },
      {
        id: '',
        symbol: 'B-POLYBASE',
        name: 'WMATIC+USDC+WETH+BAL',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x0297e37f1873d2dab4487aa67cd56b58e2f27875'
      },
      {
        id: '',
        symbol: 'BP-BTC-SP',
        name: 'WBTC+renBTC',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56'
      },
      {
        id: '',
        symbol: '80BAL-20WETH',
        name: 'BAL+WETH',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x7eb878107af0440f9e776f999ce053d277c8aca8'
      },
      {
        id: '',
        symbol: 'B_POLYDEFI',
        name: 'LINK+WETH+BAL+AAVE',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xce66904b68f1f070332cbc631de7ee98b650b499'
      },
      {
        id: '',
        symbol: 'B-QIPOOL',
        name: 'WMATIC+USDC+QI+BAL+miMATIC',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0xf461f2240b66d55dcf9059e26c022160c06863bf'
      },
      {
        id: '',
        symbol: 'BPSG',
        name: 'SNX-GRT-USDC-WETH',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x4e7f40cd37cee710f5e87ad72959d30ef8a01a5d'
      },
      {
        id: '',
        symbol: 'P-MTA',
        name: ' WMATIC+MTA+WETH',
        chain: Chain.POLYGON,
        logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
        decimals: 18,
        address: '0x614b5038611729ed49e0ded154d8a5d3af9d1d9e'
      }
    ],
    contracts: [
      {
        contractAddress: 'balancer reward',
        routerAddress: '',
        pendingReward: '',
        rewardAddress: '',
        showReward: true,
        stake: false,
        unstake: false,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: false
      }
    ]
  },
  {
    title: 'Aqua Finance',
    name: 'Aqua Finance BSC',
    link: 'https://farm.aquafinance.live/',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/logo/AFI.png',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0xa543c5aa849919772991deda60ef5edab7228bad' }],
    timeLock: [{ contractAddress: '0x87d5797e592adb94e542bc95939046b7e95e3f54' }],
    contracts: [
      {
        contractAddress: '0xf8f88A0F2f6C758dE77511B0Bba674E63e95F165',
        routerAddress: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
        pendingReward: 'pendingCea',
        rewardAddress: '0x402bf56d4500f7bc86771390925ff8d059d29842',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'ceaPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: 'Foodcourt',
    name: 'Foodcourt',
    link: 'https://foodcourt.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'COUPON',
    logo: '/images/coin/coupon.svg',
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0xc801c7980c8c7900bc898b1f38392b235ff64097',
        initCodeHash: '0xf1d77ba4cb681341af63066d44297a59a8962ec4fe422a20eca5077dca88cef9'
      }
    ],
    deployer: [{ contractAddress: '0xf00d60f98e9e2c0de57ef7aab62a087aab69b9df' }],
    contracts: [
      {
        contractAddress: '0xe43b7c5c4c2df51306cceb7cbc4b2fcc038874f1',
        routerAddress: '0x0f4610ab02920a99f639f675085a5d3e24b0d7ae',
        pendingReward: 'pendingCoupon',
        rewardAddress: '0x084bb94e93891d74579b54ab63ed24c4ef9cd5ef',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'couponPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: 'KillSwitch',
    name: PlatformName.KILL_SWITCH,
    link: 'https://app.killswitch.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'KSW',
    logo: '/images/logo/killswitch.png',
    isDollar: true,
    new: false,
    nftConfigs: [{ contractAddress: '0x186aeb1449f48f477a78c24ae7d6aa5941e9a80c' }],
    deployer: [{ contractAddress: '0xe395605bd95b3a641b9d1266cdb256176419161a' }],
    contracts: [
      {
        contractAddress: '0xd02ad47cc8d52b66f245a09df3bec188ed26cff3',
        routerAddress: '0x0f4610ab02920a99f639f675085a5d3e24b0d7ae',
        pendingReward: 'pendingKSW',
        rewardAddress: '',
        showReward: false,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'kswPerBlock',
        getBonusMultiplier: 'none'
      }
    ]
  },
  {
    title: 'Neverswap',
    name: 'Neverswap',
    link: 'https://neverswap.com/#/farm',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'NEVER',
    logo: '/images/coin/never.svg',
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0x6d29ae56e3dce38531c100b3a5e7ff61ca30a534',
        initCodeHash: '0x7a155969b652184b876400e8c2e1b0282d27db71e8ac0f3569bfe24f0df0f2c3'
      }
    ],
    deployer: [{ contractAddress: '0x0d7c27dcf16757cd42afd658603e66e9331da084' }],
    contracts: [
      {
        contractAddress: '0x8fb60dd3557c491e04d00a06fdc0618423a3c618',
        routerAddress: '0x29a3ea9fe2fc3cf8fd27d42de4d12f022a25b326',
        pendingReward: 'pendingNever',
        rewardAddress: '0x1137d5836ef0e0ed9acc74aef8ffe2eaf81120b5',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'neverPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: 'Wardenswap',
    name: 'wardenswap',
    chain: Chain.BINANCE_SMART_CHAIN,
    link: 'https://farm.wardenswap.finance/?t=1&s=0/#/farm',
    factories: [
      {
        factoryAddress: '0x3657952d7bA5A0A4799809b5B6fdfF9ec5B46293',
        initCodeHash: '0xfbbcb3ce9944dd53a7ef447076d0e4e4811911365339bab292e4733d5b37a120'
      }
    ],
    deployer: [{ contractAddress: '0x5e12ae8e436cd25f0041d931f8e4c7a3bb42cc1f' }],
    timeLock: [{ contractAddress: '0x7a6981d4dbfa6b8fe4a75b8947363084719a3d86' }],
    contracts: [
      {
        contractAddress: '0xde866dD77b6DF6772e320dC92BFF0eDDC626C674',
        routerAddress: '0x71ac17934b60a4610dc58b715b61e45dcbde4054',
        pendingReward: 'pendingWarden',
        rewardAddress: '0x0feadcc3824e7f3c12f40e324a60c23ca51627fc',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'wardenPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ],
    symbol: 'WAD',
    logo: '/images/coin/wad.png',
    isDollar: true,
    new: false
  },
  {
    title: 'Autofarm',
    name: 'autofarm',
    link: 'https://autofarm.network/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'AUTO',
    logo: 'https://i.postimg.cc/VshPhwHX/autofarm-icon-200x200.png',
    new: false,
    isDollar: true,
    deployer: [{ contractAddress: '0xf482404f0ee4bbc780199b2995a43882a8595ada' }],
    timeLock: [{ contractAddress: '0x28579eca0a326e53340edd93e18698d379876a04' }],
    contracts: [
      {
        //AUTO
        contractAddress: '0x0895196562C7868C5Be92459FaE7f877ED450452',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingAUTO',
        functionRewardPerBlockName: 'AUTOPerBlock',
        showReward: true,
        rewardAddress: '0xa184088a740c695e156f91f5cc086a06bb78b827',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true
      }
      // {
      //   // AUTOFARM CROSSCHAIN
      //   contractAddress: '0x763a05bdb9f8946d8c3fa72d1e0d3f5e68647e5c',
      //   routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
      //   pendingReward: 'pendingAUTO',
      //   showReward: true,
      //   rewardAddress: '0xa184088a740c695e156f91f5cc086a06bb78b827',
      //   stake: true,
      //   unstake: true,
      //   removeLiquidity: 'NORMAL',
      //   harvest: true,
      //   supportIL: true,
      //   supportROI: true
      // }
    ]
  },
  {
    title: 'Pancakeswap',
    name: PlatformName.PANCAKE_SWAP,
    chain: Chain.BINANCE_SMART_CHAIN,
    link: 'https://pancakeswap.finance/',
    symbol: 'CAKE',
    logo: '/images/logo/pancake-symbol.png',
    factories: [
      {
        factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
        initCodeHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5'
      }
    ],
    deployer: [{ contractAddress: '0x0f9399fc81dac77908a2dde54bb87ee2d17a3373' }],
    contracts: [
      {
        contractAddress: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
        routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        functionRewardPerBlockName: 'cakePerBlock',
        pendingReward: 'pendingCake',
        rewardAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        showReward: true,
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      },
      {
        //title: 'Auto Compound Cake Pool',
        contractAddress: '0xa80240eb5d7e05d3f250cf000eec0891d00b51cc',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingCake',
        showReward: false,
        rewardAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: false // TODO PLS FIX HERE
      },
      {
        //? Stake CAKE EARN KALM
        contractAddress: '0xb9ff4da0954b300542e722097671ead8cf337c17',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingReward',
        showReward: true,
        getBonusMultiplier: 'none',
        functionRewardPerBlockName: 'rewardPerBlock',
        rewardAddress: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: false //TODO PLS FIX HERE
      }
    ],
    isDollar: true,
    new: false
  },
  {
    title: 'Apeswap',
    name: PlatformName.APE_SWAP,
    chain: Chain.BINANCE_SMART_CHAIN,
    link: 'https://apeswap.finance/',
    symbol: 'Banana',
    logo: '/images/logo/apeswap.png',
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6',
        initCodeHash: '0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b'
      }
    ],
    deployer: [{ contractAddress: '0x876688c3df57953259d69de76b9f209bb3645e12' }],
    contracts: [
      {
        contractAddress: '0x5c8d727b265dbafaba67e050f2f739caeeb4a6f9',
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        functionRewardPerBlockName: 'cakePerBlock',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingCake',
        supportIL: true,
        supportROI: true,
        harvest: true,
        stake: true,
        unstake: true,
        showReward: true,
        removeLiquidity: 'NORMAL',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      },
      {
        //JDI
        contractAddress: '0xa3283c497710cbbe31d35a5cf56c315c621d6e10',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0x0491648c910ad2c1afaab733faf71d30313df7fc',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        getBonusMultiplier: 'none',
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //JDI-BNB
        contractAddress: '0x7336b60ac64a5bf04eec760bd135ea105994387c',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0x0491648c910ad2c1afaab733faf71d30313df7fc',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //JDI
        contractAddress: '0x72c8750af0744282e065f0df3f73460a3764c624',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0x0491648c910ad2c1afaab733faf71d30313df7fc',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //BFT
        contractAddress: '0x49bbb345c432b9b66b407f1525a81a84f4509b8b',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0xa4f93159ce0a4b533b443c74b89967c60a5969f8',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - BISON
        contractAddress: '0x5a10311f7d03a6e7ab464da54bdd535f3d4ce512',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - CYT
        contractAddress: '0x64fc4bf63c4315156f56b953a42bf2459b227a68',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0xd9025e25bb6cf39f8c926a704039d2dd51088063',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - STARS
        contractAddress: '0x2555190c9f515877ac88defc2242a4e91aa49021',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0xbd83010eb60f12112908774998f65761cf9f6f9a',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - BIRD
        contractAddress: '0xaffd843b548f70cc586cdecb2dd0e85be5349e96',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x82a479264b36104be4fdb91618a59a4fc0f50650',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - DINOP
        contractAddress: '0x82ee644a04573aebbfbf3d7fa98f20922a5c606a',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0xea90dc6f64d18771ca1dac8098526a9082265b42',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - MARU
        contractAddress: '0x51c58fb9a61c00e3e4cd09b499726ba1cd3dd261',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x215f51990a0ca5aa360a177181f014e2da376e5e',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - PERA
        contractAddress: '0xe6c5dafc5baeb927bc56b3a4154edecfdbb937d6',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0xb9d8592e16a9c1a3ae6021cddb324eac1cbc70d6',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - LAND
        contractAddress: '0x44b7e5593dfc65208a2b68c3f6c1cffd881ef6fb',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x9d986a3f147212327dd658f712d5264a73a1fdb0',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - SACT
        contractAddress: '0xade70b187429e3cf4a8dd476ecba8ce9002621ec',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x1ba8c21c623c843cd4c60438d70e7ad50f363fbb',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - BHC
        contractAddress: '0x189ae88b8df159bdb28260e86e87d770f2fc384d',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - BANANA
        contractAddress: '0x8f97b2e6559084cfaba140e2ab4da9aaf23fe7f8',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - CRUSH
        contractAddress: '0xac4409cc7709783c7cceb6c6e69fe42fad192623',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x0ef0626736c2d484a792508e99949736d0af807e',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        //GNANA - GMR
        contractAddress: '0xfd9c606d3ff6ac1b044c4f5c0a9fa934634bd2e8',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x0523215dcafbf4e4aa92117d13c6985a3bef27d7',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      }
    ]
  },
  {
    title: 'Definix',
    name: PlatformName.DEFINIX,
    link: 'https://bsc.definix.com/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'FINIX',
    logo: 'https://bsc.definix.com/images/coins/finix.png',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0xb8da64f509caa0ace0ec926435c64cd0ca6dea74' }],
    contracts: [
      {
        contractAddress: '0x6b51e8fdc32ead0b837deb334fcb79e24f3b105a',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingFinix',
        showReward: true,
        rewardAddress: '0x0f02b1f5af54e04fb6dd6550f009ac2429c4e30d',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportROI: true,
        supportIL: true,
        functionRewardPerBlockName: 'finixPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: 'Mdex',
    name: PlatformName.MDEX,
    link: 'https://bsc.mdex.com',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'MDX',
    logo: 'https://i.postimg.cc/0jwXgp4J/tdmmZdko.png',
    factories: [
      {
        factoryAddress: '0x3CD1C46068dAEa5Ebb0d3f55F6915B10648062B8',
        initCodeHash: '0x0d994d996174b05cfc7bed897dc1b20b4c458fc8d64fe98bc78b3c64a6b4d093'
      }
    ],
    deployer: [{ contractAddress: '0xc6f3b19075f20ad4e9efc7662ac2631d90aa7226' }],
    contracts: [
      {
        contractAddress: '0xc48fe252aa631017df253578b1405ea399728a50',
        routerAddress: '0x7dae51bd3e3376b8c7c4900e9107f12be3af1ba8',
        pendingReward: 'pending',
        functionRewardPerBlockName: 'mdxPerBlock',
        getBonusMultiplier: 'none',
        showReward: true,
        rewardAddress: '0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true
      },
      {
        contractAddress: '0x6aEE12e5Eb987B3bE1BA8e621BE7C4804925bA68',
        routerAddress: '0x7dae51bd3e3376b8c7c4900e9107f12be3af1ba8',
        pendingReward: 'pending',
        functionRewardPerBlockName: 'mdxPerBlock',
        getBonusMultiplier: 'none',
        showReward: true,
        rewardAddress: '0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true
      },
      {
        contractAddress: '0xDF484250C063C46F2E1F228954F82266CB987D78',
        routerAddress: '0x7dae51bd3e3376b8c7c4900e9107f12be3af1ba8',
        pendingReward: 'pending',
        functionRewardPerBlockName: 'wbnbPerBlock',
        getBonusMultiplier: 'none',
        showReward: true,
        rewardAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true
      },
      {
        contractAddress: '0x71e9b620d7873cB9583c69650162Bd6DDfa38cb2',
        routerAddress: '0x7dae51bd3e3376b8c7c4900e9107f12be3af1ba8',
        pendingReward: 'pending',
        functionRewardPerBlockName: 'coinPerBlock',
        getBonusMultiplier: 'none',
        showReward: true,
        rewardAddress: '0x95a1199eba84ac5f19546519e287d43d2f0e1b41',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true
      }
    ],
    isDollar: true,
    new: false
  },
  {
    title: 'Swamp Finance',
    name: PlatformName.SWAMP,
    link: 'https://swamp.finance/app/',
    chain: Chain.BINANCE_SMART_CHAIN,
    new: false,
    isDollar: true,
    symbol: 'SWAMP',
    logo: 'https://swamp.finance/static/frontend/img/logo.svg',
    deployer: [{ contractAddress: '0xa9eb7ad908107e13757ca837435ec713fb55589b' }],
    timeLock: [{ contractAddress: '0xc0dda6d4dd7b38e99452fa99b6090637353e4064' }],
    contracts: [
      {
        contractAddress: '0x33adbf5f1ec364a4ea3a5ca8f310b597b8afdee3',
        routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        pendingReward: 'pendingNATIVE',
        showReward: true,
        rewardAddress: '0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        functionRewardPerBlockName: 'NATIVEPerBlock',
        supportROI: false
      }
    ]
  },
  {
    //TODO PLS UPDATE
    title: 'PancakeBunny',
    name: 'PancakeBunny',
    link: 'https://pancakebunny.finance/pool',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'BUNNY',
    logo: '/images/coin/bunny.svg',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0xe87f02606911223c2cf200398ffaf353f60801f7' }],
    timeLock: [{ contractAddress: '0x1f443c166c4bc17180bc9902afe91464f2e1da89' }],
    contracts: [
      {
        pendingReward: 'pending',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        contractAddress: '0x1b6e3d394f1d809769407dea84711cf57e507b99',
        showReward: true,
        rewardAddress: '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Saltswap',
    name: PlatformName.SALT_SWAP,
    link: 'https://www.saltswap.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'SALT',
    logo: 'https://i.postimg.cc/Qt0hCWbz/salt-bae.png',
    new: false,
    isDollar: true,
    deployer: [{ contractAddress: '0x023080cdd8485ba54161117689d9bee0228569dc' }],
    contracts: [
      {
        contractAddress: '0xb4405445ffacf2b86bc2bd7d1c874ac739265658',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingSalt',
        supportROI: false,
        showReward: true,
        rewardAddress: '0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'Kebabfinance',
    name: PlatformName.KEBAB_FINANCE,
    link: 'https://kebabfinance.com/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'KEBAB',
    logo: '/images/logo/kebab-symbol.png',
    new: false,
    isDollar: true,
    deployer: [{ contractAddress: '0xd3be342407e6624b549d06275823a62e92bfb4d7' }],
    timeLock: [{ contractAddress: '0xdfdcc19078831d0d8348876f11e309833cb78a59' }],
    contracts: [
      {
        contractAddress: '0x76fcefffcf5325c6156ca89639b17464ea833ecd',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingCake',
        supportROI: true,
        functionRewardPerBlockName: 'cakePerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER',
        showReward: true,
        rewardAddress: '0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'Koala Defi',
    name: PlatformName.KOALA_DEFI,
    link: 'https://app.koaladefi.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    new: false,
    symbol: 'LYPTUS',
    logo: '/images/logo/koaladefi-symbol.svg',
    isDollar: true,
    deployer: [{ contractAddress: '0x9ddfda1dfac07e5ff9e9f2a5260ed3446e342982' }],
    contracts: [
      {
        contractAddress: '0x68c616dcea206055b85830e641fbf7a20648548d',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingLyptus',
        supportROI: true,
        functionRewardPerBlockName: 'lyptusPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER',
        showReward: true,
        rewardAddress: '0xba26397cdff25f0d26e815d218ef3c77609ae7f1',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      },
      {
        contractAddress: '0x7b3cA828e189739660310B47fC89B3a3e8A0E564',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingNalis',
        rewardAddress: '0xb2ebaa0ad65e9c888008bf10646016f7fcdd73c3',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'nalisPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: 'Thunderswap',
    name: PlatformName.THUNDER_SWAP,
    link: 'https://thunderswap.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'TNDR',
    logo: 'https://thunderswap.finance/images/thunder.svg',
    new: false,
    isDollar: true,
    deployer: [{ contractAddress: '0x1737a624e20c3474a4ade8cd5d304138e507fd27' }],
    contracts: [
      {
        contractAddress: '0xa7bfbefbe923dcf6eeff355c22520395670b684e',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingThunder',
        showReward: true,
        rewardAddress: '0x7cc46141ab1057b1928de5ad5ee78bb37efc4868',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Manyswap',
    name: PlatformName.MANY_SWAP,
    link: 'https://manyswap.io/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'MANY',
    logo: 'https://manyswap.io/images/logo.png',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0x2b0609102b827ca51a1c3826452c4737bebbc12d' }],
    contracts: [
      {
        contractAddress: '0x1c515985c6318550afb5bc590f4f0843b977250a',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingMany',
        showReward: true,
        rewardAddress: '0x2dd6c9c5bcd16816226542688788932c6e79a600',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Goosedefi',
    link: 'https://www.goosedefi.com/',
    name: PlatformName.GOOSE_DEFI,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x9e40abb0b3d27b285e8c6c383888285762a649e2' }],
    contracts: [
      {
        contractAddress: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingEgg',
        showReward: true,
        rewardAddress: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ],
    symbol: 'EGG',
    logo: 'https://i.postimg.cc/nrSFRQ9m/goosefinance-dapp-high-risk-bsc-logo-166x166-20c8eb624582d0a8ff9bdb950f7c6e54.png',
    isDollar: true,
    new: false
  },
  {
    title: 'Jaguarswap',
    name: PlatformName.JAGUAR_SWAP,
    link: 'https://jaguarswap.com/',
    chain: Chain.BINANCE_SMART_CHAIN,
    new: false,
    symbol: 'JAGUAR',
    logo: 'https://i.postimg.cc/BZ266FHq/spaces-MZXtwp-Jr-NJLof0-Abl-Fv-avatar-1619788300301.png',
    isDollar: true,
    deployer: [{ contractAddress: '0x3b94ef11edc19e7546711a7038a73ca9d16c416a' }],
    contracts: [
      {
        contractAddress: '0x8e4301509a484c6fc211c8902013e90cd416f58d',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingJaguar',
        showReward: true,
        rewardAddress: '0x4a3524936Db5C310d852266033589D3f6F30BA5d',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportROI: false,
        supportIL: true
      }
    ]
  },
  {
    title: 'WaultSwap',
    name: PlatformName.WAULT,
    link: 'https://app.wault.finance/bsc/index.html',
    chain: Chain.BINANCE_SMART_CHAIN,
    isDollar: true,
    new: false,
    symbol: 'WEX',
    logo: '/images/coin/WAULTx.svg',
    factories: [
      {
        factoryAddress: '0xb42e3fe71b7e0673335b3331b3e1053bd9822570',
        initCodeHash: '0x9599db1eaa37ab366bf260f51beefce9296eb6197de387c533d905e9b82debe9'
      }
    ],
    deployer: [{ contractAddress: '0x25932efc092400f7bc62ecbb2c3eab67f9e8ba55' }],
    contracts: [
      {
        contractAddress: '0x22fB2663C7ca71Adc2cc99481C77Aaf21E152e2D',
        routerAddress: '0xd48745e39bbed146eec15b79cbf964884f9877c2',
        pendingReward: 'pendingWex',
        showReward: true,
        rewardAddress: '0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Valuedefi',
    name: PlatformName.VALUE_DEFI,
    link: 'https://valuedefi.io/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'vBSWAP',
    logo: 'https://i.postimg.cc/SsW0gZq0/download.png',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0x7be4d5a99c903c437ec77a20cb6d0688cbb73c7f' }],
    contracts: [
      {
        contractAddress: '0xd56339F80586c08B7a4E3a68678d16D37237Bd96',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0x4f0ed527e8a95ecaa132af214dfd41f30b361600',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Treedefi',
    name: PlatformName.TREE_DEFI,
    link: 'https://app.treedefi.com/#/',
    symbol: 'SEED',
    logo: '/images/logo/treedefi-symbol.png',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xaa287adeff00c1b3e54cc1304af345f11920a835' }],
    contracts: [
      {
        contractAddress: '0xa9a438b8b2e41b3bf322dba139af9490dc226953',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingTree',
        showReward: true,
        rewardAddress: '0x40b34cc972908060d6d527276e17c105d224559d',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Blizzard',
    symbol: 'xBLZD',
    link: 'https://www.blizzard.money/',
    logo: 'https://i.postimg.cc/s2NMgSmF/2.png',
    isDollar: true,
    new: false,
    name: PlatformName.BLIZZARD,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xcbb1f855b0b2e8cc2fb64974f72558b0839de9f4' }],
    timeLock: [{ contractAddress: '0x92ba5f1fd427e7eac944fde984007f3b06e9bb10' }],
    contracts: [
      {
        contractAddress: '0x367cdda266ada588d380c7b970244434e4dde790',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingxBLZD',
        showReward: true,
        rewardAddress: '0x9a946c3cb16c08334b69ae249690c236ebd5583e',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Blueswap',
    symbol: 'BLUE',
    link: 'https://app.blueswap.finance/',
    logo: 'https://i.postimg.cc/qRrNjRdq/2.png',
    isDollar: true,
    new: false,
    name: PlatformName.BLUE_SWAP,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x6dc664eb84e2931939f9b4ec311a43ec30a8c527' }],
    contracts: [
      {
        contractAddress: '0xb04381026f5d4aaf0487ac4336f16e133fa5fb0a',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingBlue',
        showReward: true,
        rewardAddress: '0x36c0556c2b15aed79f842675ff030782738ef9e8',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Cafeswap',
    symbol: 'BREW',
    link: 'https://app.cafeswap.finance/',
    logo: 'https://assets.coingecko.com/coins/images/13966/large/coingecko_listing.png?1613377423',
    isDollar: true,
    new: false,
    name: PlatformName.CAFE_SWAP,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xde9b6e1b97531b25fd06d4e9ff5263e49f49c4fc' }],
    timeLock: [{ contractAddress: '0x60f064e42d89f765f6d01de3ce7097c65df4105b' }],
    contracts: [
      {
        contractAddress: '0xc772955c33088a97d56d0bbf473d05267bc4febb',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingCake',
        showReward: true,
        rewardAddress: '0x790be81c3ca0e53974be2688cdb954732c9862e1',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'PantherSwap',
    symbol: 'PANTHER',
    link: 'https://pantherswap.com/',
    logo: 'https://i.postimg.cc/rpq3KWDG/panther.png',
    isDollar: true,
    new: false,
    name: PlatformName.PANTHER_SWAP,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xfb58d737942e1c39f713203c323e95afb12361ab' }],
    contracts: [
      {
        contractAddress: '0x058451c62b96c594ad984370eda8b6fd7197bbd4',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingPanther',
        showReward: true,
        rewardAddress: '0x1f546ad641b56b86fd9dceac473d1c7a357276b7',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Beefy',
    symbol: 'BIFI',
    link: 'https://app.beefy.finance/',
    logo: 'https://i.postimg.cc/7Y7VQG2r/F-3dn1-LR-400x400-removebg-preview-2.png',
    isDollar: true,
    new: false,
    name: PlatformName.BEEFY,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x565eb5e5b21f97ae9200d121e77d2760fff106cb' }],
    timeLock: [{ contractAddress: '0x4a32de8c248533c28904b24b4cfcfe18e9f2ad01' }],
    contracts: [
      {
        contractAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'Pending',
        showReward: false,
        rewardAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Beefy',
    symbol: 'BIFI',
    link: 'https://polygon.beefy.finance/',
    logo: 'https://i.postimg.cc/7Y7VQG2r/F-3dn1-LR-400x400-removebg-preview-2.png',
    isDollar: true,
    new: false,
    name: PlatformName.BEEFY_POLYGON,
    chain: Chain.POLYGON,
    deployer: [{ contractAddress: '0x565eb5e5b21f97ae9200d121e77d2760fff106cb' }],
    timeLock: [{ contractAddress: '0x4a32de8c248533c28904b24b4cfcfe18e9f2ad01' }],
    contracts: [
      {
        contractAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
        routerAddress: '',
        pendingReward: 'Pending',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  // {
  //   symbol: 'ELE',
  //   logo: 'https://assets.coingecko.com/coins/images/14541/large/eleven_finance_logo.png?1616895791',
  //   isDollar: true,
  //   new: false
  //   title: 'Eleven Finance',
  //   name: 'Eleven Finance',
  //   contractAddress: '0x1ac6c0b955b6d7acb61c9bdf3ee98e0689e07b8a',
  //   routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
  //   pendingReward: 'pendingEleven',
  //   showReward: true,
  //   rewardAddress: '0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0',
  //   stake: true,
  //   unstake: true,
  //   removeLiquidity: 'NORMAL',
  //   harvest: true,
  // },

  // // {
  // //   title: 'Horizon Protocol',
  // //   symbol: 'HZN',
  // //   logo: 'https://assets.coingecko.com/coins/images/14795/small/horizon-logo-200-cg.png?1618475792',
  // //   isDollar: true,
  // //   new: false,
  // //   name: PlatformName.HORIZON_PROTOCOL,
  // //   chain: Chain.BINANCE_SMART_CHAIN,
  // //   contracts: [
  // //     {
  // //       contractAddress: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
  // //       routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
  // //       pendingReward: 'pending',
  // //       showReward: true,
  // //       rewardAddress: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
  // //       stake: true,
  // //       unstake: true,
  // //       removeLiquidity: 'NORMAL',
  // //       harvest: true,
  // //       supportIL: true
  // //     }
  // //   ]
  // // },
  {
    title: 'Ellipsis',
    symbol: 'EPS',
    link: 'https://ellipsis.finance/',
    logo: 'https://i.postimg.cc/wMYPCQ8p/download.jpg',
    isDollar: true,
    new: false,
    name: PlatformName.ELLIPSIS,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xabc00210a691ce0f3d7d0602d7d84aea4d91cdfd' }],
    contracts: [
      {
        contractAddress: '0xcce949de564fe60e7f96c85e55177f8b9e4cf61b',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'claimableReward',
        showReward: true,
        rewardAddress: '0xa4f93159ce0a4b533b443c74b89967c60a5969f8',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Ten Finance',
    symbol: 'TENFI',
    link: 'https://ten.finance/',
    logo: 'https://i.postimg.cc/Mpw0gvsw/download-1.png',
    isDollar: true,
    new: false,
    name: PlatformName.TEN_FINANCE,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x13fa1d58706f4e2482c67f22b98cb3f832efdf09' }],
    timeLock: [{ contractAddress: '0x5a50b8eda11de347f5ed9925dae59eacc4504d68' }],
    contracts: [
      {
        contractAddress: '0x264a1b3f6db28de4d3dd4ed23ab31a468b0c1a96',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingTENFI',
        showReward: true,
        rewardAddress: '0xd15c444f1199ae72795eba15e8c1db44e47abf62',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false, //TODO FIX THIS
        getBonusMultiplier: 'none',
        functionRewardPerBlockName: 'TENFIPerBlock'
      }
    ]
  },
  {
    title: 'Infinitee Finance',
    symbol: 'INFTEE',
    link: 'https://infinitee.finance/',
    logo: 'https://infinitee.finance/img/icon-inftee-black.77ef5c94.svg',
    isDollar: true,
    new: false,
    name: PlatformName.INFINITEE_FINANCE,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x289dfd63dc0afdb98657bec31857eb0c922ee40e' }],
    contracts: [
      {
        contractAddress: '0x3e532b4266a779de401297336d8de6dd099745a9',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingInfinitee',
        showReward: true,
        rewardAddress: '0xc350caa89eb963d5d6b964324a0a7736d8d65533',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Aperocket',
    symbol: 'SPACE',
    link: 'https://aperocket.finance/',
    logo: 'https://aperocket.finance/images/vaults/space.svg',
    isDollar: true,
    new: false,
    name: PlatformName.APE_ROCKET,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x6b70f0136a7e2bd1fa945566b82b208760632b2e' }],
    contracts: [
      // SPACE
      // {
      //   //TODO PLS FIX HERE
      //   contractAddress: '0xd79dc49Ed716832658ec28FE93dd733e0DFB8d58',
      //   routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
      //   pendingReward: 'pending',
      //   showReward: true,
      //   rewardAddress: '0xd0f82498051067e154d1dcd3d88fa95063949d7e',
      //   stake: true,
      //   unstake: true,
      //   removeLiquidity: 'NORMAL',
      //   harvest: true,
      //   supportIL: true,
      //   supportROI: false
      // },
      // BANANA
      {
        contractAddress: '0xB8FDa49A709E9D00274053D9Ed34CCa1B4BB21f8',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // // BANANA-BNB Flip Deprecate
      {
        contractAddress: '0x2A3980860C3B2caeadE5272DF66782297032fa5F',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BANANA-BUSD Flip Deprecate
      {
        contractAddress: '0xEe6826Eb76f25994984687F2F38B1787eA3567C1',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BUSD-BNB Flip Deprecate
      {
        contractAddress: '0xC9E5b25d37cBcb30FC7aA11cE44E4652A319DdB2',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BUSD-USDC Flip
      {
        contractAddress: '0xdc8b4ABB0053e8443b964fb4c0680b55E21ca87d',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // ETH-BNB Flip
      {
        contractAddress: '0x542618f90F2CA668D12d53b50B5008C2b3Acece7',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BANANA-BUSD
      {
        contractAddress: '0x9d82Bb1983409bf79E599019F948EC36A3Bb05aD',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x7bd46f6da97312ac2dbd1749f82e202764c0b914',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BANANA-BNB
      {
        contractAddress: '0xAd1E51D935D6d25B6483F6846b5bbBc1e20df3ef',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0xf65c1c0478efde3c19b49ecbe7acc57bb6b1d713',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BUSD-BNB
      {
        contractAddress: '0xe927c82E2Ec5C2e045D58145854B8Fad48a3283A',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x51e6d27fa57373d8d4c256231241053a70cb1d93',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BUSD-USDC
      {
        contractAddress: '0xEAbbE6225F82655032ff049dfA461785a1965543',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0xc087c78abac4a0e900a327444193dbf9ba69058e',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // ETH-BNB
      {
        contractAddress: '0x9a9d0374E05589C3F0b5C27B32119800a2e295cA',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0xa0c3ef24414ed9c9b456740128d8e63d016a9e11',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BANANA-BNB Flip
      {
        contractAddress: '0x5f7faeFC9606FfE8B519DDC2Acf3A12e5767a7EE',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BANANA-BUSD Flip
      {
        contractAddress: '0x858CD232B2236da873f8F72518a6deD3eBa7F1aF',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // BUSD-BNB Flip
      {
        contractAddress: '0xd0177E5Bde32765d1493D80f9b343a4E52010F5D',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // ADA-BNB Flip
      {
        contractAddress: '0x6708B15E3B845768712Bc78a182c76fdc30b8421',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      // Auto cake
      {
        contractAddress: '0x274B5B7868c848Ac690DC9b4011e9e7e29133700',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pending',
        showReward: true,
        rewardAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'ZFarm',
    name: PlatformName.ZFARM,
    symbol: 'ZFARM',
    link: 'https://app.zfarm.finance/',
    logo: 'https://app.zfarm.finance/images/tokens/ZFARM.png',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x7abbe8babb7e1d872ed8190954a26bfffc8f42d5' }],
    timeLock: [{ contractAddress: '0x96eb78894c1d5fa440a87cc35b4db6cd9956e099' }],
    contracts: [
      {
        contractAddress: '0x66c901febc771a8e0bbe0a9f8a2487c60ba07bf4',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingCake',
        showReward: true,
        rewardAddress: '0x42d1b21eabe04d308148ea9ab90be674b64b4eef',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        getBonusMultiplier: 'BONUS_MULTIPLIER',
        functionRewardPerBlockName: 'cakePerBlock'
      },
      {
        contractAddress: '0x0080e040020892e87677148cbc28a09b2d3bf86e',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingRewards',
        showReward: true,
        rewardAddress: '0x0491648c910ad2c1afaab733faf71d30313df7fc',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false,
        getBonusMultiplier: 'none',
        functionRewardPerBlockName: 'rewardsPerBlock'
      }
    ]
  },
  {
    title: 'Dinopark',
    symbol: 'DINOP',
    link: 'https://www.dinopark.fun/',
    logo: '/images/coin/dinop.svg',
    isDollar: true,
    new: false,
    name: PlatformName.DINOP,
    chain: Chain.BINANCE_SMART_CHAIN,
    timeLock: [],
    nftConfigs: [
      //NFT ART
      {
        contractAddress: '0x761D07173a769bAcF92165fcC80Aa6981CeF0420'
      },
      {
        contractAddress: '0xd456C3B4d7f81E9D4db51af5561c7112345256f9'
      },
      {
        contractAddress: '0xf0EB97889f62C691Eb58a5254a5a1a142f0b163d'
      },
      //series 2
      {
        contractAddress: '0x66a6631d326B3108BE66c9C2a59fcfE8821D7cB9'
      },
      {
        contractAddress: '0x2F233DA332DE91ed77e878545081a1dfA4214398'
      },
      {
        contractAddress: '0x506e7af143bc3BdAEcF428688146e1335e789204'
      },
      {
        contractAddress: '0xDDd59259B20374907f18AFF0c15592281DDAF069'
      },
      {
        contractAddress: '0xC173d8b14ED1f595A157d0dc09C0965dDBF360fF'
      },
      {
        contractAddress: '0xE84f994536DD59c70F4266113ad931Abe65fF6c2'
      },
      {
        contractAddress: '0xF3653AEd31675088Ac0db505BE07EfA91ad3373a'
      },
      {
        contractAddress: '0xc8ac5a69ac9C6A1FE3AEfa50f2A14cFa23018f91'
      },
      {
        contractAddress: '0xda9c43c1bc12a0f76c59141ff5bd876c399dccd7'
      },
      {
        contractAddress: '0xA08f9C42C595047BB97176a259487cC4Fb9DF94c'
      }
    ],
    contracts: [
      //?lvl 1=>2
      {
        //PoolDiop
        contractAddress: '0x731227C73E92979F66179A0aac4C1965e7959805',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      },
      {
        //PoolBanana
        contractAddress: '0xC74ecAE8de7230e27A72b39aA5F36310a21a53a4',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      },
      {
        //PoolJDI
        contractAddress: '0x45B8c84984Ce58879adF6a65f1a8D3614E5DEE1f',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      },
      {
        //PoolCake
        contractAddress: '0xFF4Cae6fc9D0335Ebf5e580a4b92d4F5e597b815',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      },

      //?lvl 0=>1
      {
        //PoolJDI
        contractAddress: '0x176d4f739e531Fa5C61351308EFE6BAdB674B312',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      },
      {
        // PoolDINOP
        contractAddress: '0xb9609793C1f41eC1386B034629195E40b7885822',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      },
      {
        //PoolDBM
        contractAddress: '0x36EcBE8100F704Edc78e6C91Ae75C00641645ed9',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      },
      {
        //PoolZfarm
        contractAddress: '0xd152Df76D4D2aB5E7A1b4CD0984743b9487F3bBB',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: true,
        supportROI: false
      },
      //finnish
      {
        //PoolJDI
        contractAddress: '0x5261729982b07176853cC399bf7fBfD6055511f7',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0xea90dc6f64d18771ca1dac8098526a9082265b42',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      {
        // PoolDINOP
        contractAddress: '0x9c20f9a419dCa69059AAc7C4b944f9fB4a52Bc0e',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0xea90dc6f64d18771ca1dac8098526a9082265b42',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      {
        //PoolBANANA
        contractAddress: '0x9CEADda8Dd25E6427Ed3619bf9ebEaCA0D851cbb',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0xea90dc6f64d18771ca1dac8098526a9082265b42',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      },
      {
        //PoolDBM
        contractAddress: '0x47Bf068069bE9e233b91447eeDdD9A6263e7618F',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0x0314e5a39806c30d67b869ee1bcdabee7e08de74',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false
      }
    ]
  },
  {
    title: 'Twindex',
    symbol: 'TWIN',
    link: 'https://twindex.com/#/',
    logo: '/images/coin/twindex.svg',
    isDollar: true,
    new: false,
    name: PlatformName.TWINDEX,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x5f188439575f7bf21c29e8b7894d9916abeb306d' }],
    timeLock: [{ contractAddress: '0xcad9146102d29175fd7908eb6820a48e4fc78cea' }],
    factories: [
      {
        factoryAddress: '0x4E66Fda7820c53C1a2F601F84918C375205Eac3E',
        initCodeHash: '0x652b5a4fa6b1efd55c9821e56279c871f9999588a5472d22ab78b8ec61e2521e'
      }
    ],
    contracts: [
      {
        contractAddress: '0xe6be78800f25ffae4d1db7ca6d3485629bd200ed',
        routerAddress: '0x6b011d0d53b0da6ace2a3f436fd197a4e35f47ef',
        pendingReward: 'pendingTwin',
        showReward: true,
        rewardAddress: '0x3806aae953a3a873d02595f76c7698a57d4c7a57',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'twinPerBlock',
        getBonusMultiplier: 'bonusMultipler'
      }
    ]
  },
  {
    title: 'DoubleMoon',
    symbol: 'DMC',
    link: 'https://exchange.doublemoon.finance/#/swap',
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0x0314e5a39806C30D67B869EE1bCDABee7e08dE74/logo.png',
    name: 'doublemoon',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0x9130fc4334921f418fbbaf87d37a57e786ee75d2' }],
    contracts: [
      {
        contractAddress: '0xbe739a112ef6278ceb374bcad977252bc3918ca9',
        routerAddress: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0xA7bD090bC58e663388Be63d5E8a7b683f5A3d4FC',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: false
      }
    ]
  },
  {
    title: 'Tapswap',
    symbol: 'TAPS',
    link: 'https://dapp.tapswap.finance/#/',
    logo: 'https://bscscan.com/token/images/tapswapmoney_32.png',
    isDollar: true,
    new: false,
    name: 'Tapswap',
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xd3fa0d0ce1d28a1b84e40a14005071c4e7064cbf' }],
    contracts: [
      {
        contractAddress: '0xEDdadBd5C67fE8CBD16b058757778381e228bD8D',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingTaps',
        showReward: true,
        rewardAddress: '0x56eab07247e3e6404ac90140f20bba61375d5c3c',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        functionRewardPerBlockName: 'tapsPerBlock',
        supportROI: true,
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: 'AleSwap',
    name: 'AleSwap',
    symbol: 'ALE',
    link: 'https://aleswap.finance/#/farm',
    logo: '/images/coin/ale.svg',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    factories: [
      {
        factoryAddress: '0x731d91dd835330cb2beeafb9a1672e3177b7168f',
        initCodeHash: '0x916f996a539c1f8442fdb123b996861179bd5951e7448b61240086f71cd79f29'
      }
    ],
    deployer: [{ contractAddress: '0x7056088c4879f0fa1133f1135fc2144f94d35a12' }],
    contracts: [
      {
        contractAddress: '0x0646EDAde9c7e8Ae361E51721118333b2978F333',
        routerAddress: '0xbfbcc27fc5ea4c1d7538e3e076c79a631eb2bea6',
        pendingReward: 'pendingAle',
        functionRewardPerBlockName: 'alePerBlock',
        showReward: true,
        rewardAddress: '0x99ca242f20424a6565cc17a409e557fa95e25bd7',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: 'BiSwap',
    symbol: 'BSW',
    link: 'https://biswap.org/',
    logo: '/images/coin/bsw.svg',
    isDollar: true,
    new: false,
    name: 'BiSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    factories: [
      {
        factoryAddress: '0x858E3312ed3A876947EA49d572A7C42DE08af7EE',
        initCodeHash: '0xfea293c909d87cd4153593f077b76bb7e94340200f4ee84211ae8e4f9bd7ffdf'
      }
    ],
    deployer: [{ contractAddress: '0xc6af770101da859d680e0829380748cccd8f7984' }],
    contracts: [
      {
        contractAddress: '0xDbc1A13490deeF9c3C12b44FE77b503c1B061739',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: 'pendingBSW',
        showReward: true,
        rewardAddress: '0x965f527d9159dce6288a2219db51fc6eef120dd1',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        functionRewardPerBlockName: 'BSWPerBlock',
        supportROI: true,
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: 'CoinSwap',
    name: 'CoinSwap',
    link: 'https://master.coinswap.space/',
    symbol: 'CSS',
    logo: '/images/coin/css.png',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xa1f4d6f76c9ae5b21455a3367b79cfa360015f7a' }],
    contracts: [
      {
        contractAddress: '0x3A0a988D680dBBB02DECBfd35F9E0676B4bEc292',
        rewardAddress: '0x3bc5798416c1122bcfd7cb0e055d50061f23850d',
        routerAddress: '0x34dbe8e5faefabf5018c16822e4d86f02d57ec27',
        pendingReward: 'pendingReward',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        functionRewardPerBlockName: 'cssPerBlock',
        supportROI: true,
        getBonusMultiplier: 'bonusMultiplier'
      },
      {
        contractAddress: '0xf1cF07Fe21b7dC6cf8b196Fe978ec94ed0c106a5',
        rewardAddress: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        routerAddress: '0x34dbe8e5faefabf5018c16822e4d86f02d57ec27',
        pendingReward: 'pendingReward',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        functionRewardPerBlockName: 'rewardPerBlock',
        supportROI: false,
        getBonusMultiplier: 'none'
      }
    ]
  },
  // {
  //   symbol: 'ALPHA',
  //   logo: 'https://i.postimg.cc/5trwj9sf/image.png',
  //   isDollar: true,
  //   new: false
  //   title: 'Alpha Finance',
  //   name: 'alpha',
  //   contractAddress: '0x3bb5f6285c312fc7e1877244103036ebbeda193d',
  //   routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
  //   pendingReward: 'pendingAlpha',
  //   showReward: true,
  //   rewardAddress: '0x36c0556c2b15aed79f842675ff030782738ef9e8',
  //   stake: false,
  //   unstake: false,
  //   removeLiquidity: 'NORMAL',
  //   harvest: false,
  // }
  {
    title: 'Seeder finance',
    symbol: 'Leaf',
    link: 'https://seeder.finance/farms',
    logo: '/images/coin/leaf.svg',
    isDollar: true,
    new: false,
    name: 'Seeder finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    nftConfigs: [{ contractAddress: '0x1d14f49ea29928958005df9e7c75afe4df42fa87' }],
    deployer: [{ contractAddress: '0x21f94a5217ecaedd9092ce88de598c7d526638ca' }],
    contracts: [
      {
        contractAddress: '0x1aF28E7b1A03fA107961897a28449F4F9768ac75',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: 'pendingReward',
        showReward: true,
        rewardAddress: '0x1cbddf83de068464eba3a4e319bd3197a7eea12c',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: false
      }
    ]
  },
  // {
  //   title: 'VIVA Insure',
  //   name: PlatformName.VIVA_INSURE,
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   link: 'https://viva.insure/',
  //   symbol: 'VIVA',
  //   logo: 'https://i.postimg.cc/02hQF84m/vivainsure-dapp-defi-bsc-logo-166x166-432a71011782d5067f489195a33d4c51.png',
  //   contracts: [
  //     {
  //       contractAddress: '0xd9d4467f115effaa0dd3af9749768ebb5d9f06e1',
  //       routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  //       functionRewardPerBlockName: 'VIVAPerBlock',
  //       pendingReward: 'pendingVIVA',
  //       rewardAddress: '0xec87e4ffc54da4dab82abb50595cd44306d20e03',
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: true,
  //       supportROI: false,
  //       showReward: true,
  //       getBonusMultiplier: 'BONUS_MULTIPLIER'
  //     }
  //   ],
  //   isDollar: true,
  //   new: false
  // },
  //? ###################### POLYGON ################################
  {
    title: 'SushiSwap',
    name: PlatformName.SUSHISWAP_POLYGON,
    link: 'https://app.sushi.com/farm',
    chain: Chain.POLYGON,
    symbol: 'SUSHI',
    logo: '/images/coin/sushi.png',
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        initCodeHash: '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303'
      }
    ],
    deployer: [{ contractAddress: '0xf87bc5535602077d340806d71f805ea9907a843d' }],
    contracts: [
      {
        contractAddress: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
        routerAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        pendingReward: 'pendingSushi',
        rewardAddress: '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
        stake: true,
        unstake: true,
        harvest: true,
        supportIL: false,
        showReward: true,
        removeLiquidity: 'NORMAL',
        supportROI: true,
        functionRewardPerBlockName: 'sushiPerSecond',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ]
  },
  {
    title: '50Cent Network',
    name: '50Cent Network Polygon',
    link: 'https://50cent.network/',
    chain: Chain.POLYGON,
    symbol: '50C',
    logo: '/images/coin/50c.svg',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0x627a1e3fc70426b74beecf2964bea37d414bf9ec' }],
    contracts: [
      {
        contractAddress: '0x3F8868c5A75Ae03498100774F73B10F80eBF44d4',
        routerAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        pendingReward: 'pendingToken',
        rewardAddress: '0x0102bbfDdFFBd8d28d3a1b9C47017F62F42768f2',
        stake: true,
        unstake: true,
        harvest: true,
        supportIL: false,
        showReward: true,
        removeLiquidity: 'NORMAL',
        supportROI: false,
        functionRewardPerBlockName: 'arrRewardPerBlock'
      },
      {
        contractAddress: '0x975601b1f21d3688EC853f25821Ce9DAd62c5E1d',
        routerAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        pendingReward: 'pendingToken',
        rewardAddress: '0xa656DC2b1061f80F4E847bbA2D9Bd52db4889836',
        stake: true,
        unstake: true,
        harvest: true,
        supportIL: false,
        showReward: true,
        removeLiquidity: 'NORMAL',
        supportROI: false,
        functionRewardPerBlockName: 'arrRewardPerBlock'
      }
    ]
  },
  {
    title: 'QuickSwap',
    name: PlatformName.QUICKSWAP_POLYGON,
    chain: Chain.POLYGON,
    link: 'https://quickswap.exchange/',
    symbol: 'Quick',
    logo: '/images/coin/quick.png',
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32',
        initCodeHash: ''
      }
    ],
    deployer: [{ contractAddress: '0x476307dac3fd170166e007fcaa14f0a129721463' }],
    timeLock: [{ contractAddress: '0x5eec262b05a57da9beb5fe96a34aa4ed0c5e029f' }],
    contracts: [
      {
        //quickSwap wmatic-quick
        contractAddress: '0x7Ca29F0DB5Db8b88B332Aa1d67a2e89DfeC85E7E',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'rewardPerBlock',
        pendingReward: 'pendingQuick',
        showReward: true,
        rewardAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        //quickSwap usdc-usdt
        contractAddress: '0x251d9837a13F38F3Fe629ce2304fa00710176222',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'rewardPerBlock',
        pendingReward: 'pendingQuick',
        showReward: true,
        rewardAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        //quickSwap eth-usdc
        contractAddress: '0x4A73218eF2e820987c59F838906A82455F42D98b',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'rewardPerBlock',
        pendingReward: 'pendingQuick',
        showReward: true,
        rewardAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        //quickSwap wbtc-eth
        contractAddress: '0x070D182EB7E9C3972664C959CE58C5fC6219A7ad',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'rewardPerBlock',
        pendingReward: 'pendingQuick',
        showReward: true,
        rewardAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        //quickSwap eth-weth
        contractAddress: '0xB26bfcD52D997211C13aE4C35E82ced65AF32A02',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'rewardPerBlock',
        pendingReward: 'pendingQuick',
        showReward: true,
        rewardAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        //quickSwap dai-weth
        contractAddress: '0x785AaCd49c1Aa3ca573F2a32Bb90030A205b8147',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'rewardPerBlock',
        pendingReward: 'pendingQuick',
        showReward: true,
        rewardAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        //quickSwap aave-weth
        contractAddress: '0x573bb5CCC26222d8108EdaCFcC7F7cb9e388Af10',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'rewardPerBlock',
        pendingReward: 'pendingQuick',
        showReward: true,
        rewardAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        //quickSwap link-weth
        contractAddress: '0x97D69E23DF7BBB01F9eA78b5651cb6ad125D6d9a',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'rewardPerBlock',
        pendingReward: 'pendingQuick',
        showReward: true,
        rewardAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      }
    ]
  },
  {
    title: 'Dfyn',
    name: PlatformName.DFYN_POLYGIN,
    chain: Chain.POLYGON,
    link: 'https://exchange.dfyn.network/#/',
    symbol: 'DFYN',
    logo: '/images/coin/dfyn.png',
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0xE7Fb3e833eFE5F9c441105EB65Ef8b261266423B',
        initCodeHash: '0xf187ed688403aa4f7acfada758d8d53698753b998a3071b06f1b777f4330eaf3'
      }
    ],
    deployer: [{ contractAddress: '0x8b2ab2aea6ce8d51d75bee6a735f443112683dd9' }],
    timeLock: [{ contractAddress: '0xd1152629fd0a2654b197e16428b01a69fe1fb925' }],
    contracts: [
      //? Ecosystem
      {
        contractAddress: '0x250fF0EE7a02d0DaeC193A0Ea76f879B26818732',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0x6719BD4E5Aed0e25b4cE5FB01d763353103e7258',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xa3d7c12a474a6806df5e0260019eD613bF970c54',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xfBCE866aF59bEa3b3880330F7DE3b08d7bc26676',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xf4822f5e1B01Dc101914C888d88E6d295c6A7FCA',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0x4cAE3C18b058eBF1EC439f01457348a9dD9CcC53',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      //? Popular
      {
        contractAddress: '0xeee84F55F493c6ea89b655FFF09F2a2f9C2D1Dd8',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0x17e8732E2f0f6c35a794e9db4e63AeaDa9ce927C',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xA51aF13F630489eE77B4489844041031e4e36824',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xa55D1729eF64755D44640C67feA6D18A44EE9326',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xf01adB0eF728D4544E9a1E3543FC98F0C1CAE7FD',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xbd7BD38EC111A1789158b25240B5DaAE043113aE',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0x12286A4a13FCAFB77f08c48A00e6963A0ca6d917',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0x39a7733e5F418a5F4c11A2212f085F0a776Ac7D3',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xCf32aF39BC10BAd0E193630E4E49b0Fa44867E7B',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xC79FC48EC33038e80531B14b1efE0C8cAb50747A',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xbE6D6BA111E459610646FD4234005331af49179F',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xC9091079b96fc51df933720b5071c0B0d18EF785',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xf8f7F41BC59f37cfC19794CB41Ec37073fc98E5f',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0x9b0E341661E8A993BBe3dd4b1d2484f100A55BB4',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xE504196B11dE48Da00872697f4683F5596dc8E8E',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0xF4B0Dfe49aa35463D40d2FFAe47006990Ae10465',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      },
      {
        contractAddress: '0x6aa7f7cD7185905948951ab10E5FAE65d4Ab883D',
        routerAddress: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429',
        pendingReward: 'pendingDFYN',
        rewardAddress: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerBlock'
      }
    ]
  },
  {
    title: 'ApeSwap',
    link: 'https://app.apeswap.finance/yield',
    name: PlatformName.APE_SWAP_POLYGON,
    chain: Chain.POLYGON,
    symbol: 'BANANA',
    logo: '/images/logo/apeswap.png',
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0xcf083be4164828f00cae704ec15a36d711491284',
        initCodeHash: '0x511f0f358fe530cda0859ec20becf391718fdf5a329be02f4c95361f3d6a42d8'
      }
    ],
    deployer: [{ contractAddress: '0x876688c3df57953259d69de76b9f209bb3645e12' }],
    contracts: [
      {
        contractAddress: '0x54aff400858Dcac39797a81894D9920f16972D1D',
        rewardAddress: '0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        functionRewardPerBlockName: 'bananaPerSecond',
        pendingReward: 'pendingBanana',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true,
        getBonusMultiplier: 'none'
      }
    ]
  },
  {
    title: 'PolyCrystal',
    name: 'PolyCrystal Polygon',
    link: 'https://polycrystal.finance/',
    chain: Chain.POLYGON,
    symbol: 'CRYSTL',
    logo: '/images/coin/crystal.png',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0x6c4242cbe5b658da0a9440f90bb3afd31975418d' }],
    contracts: [
      //? farm
      {
        contractAddress: '0xeBCC84D2A73f0c9E23066089C6C24F4629Ef1e6d',
        rewardAddress: '0x76bf0c28e604cc3fe9967c83b3c3f31c213cfe64',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingCrystal',
        functionRewardPerBlockName: 'crystalPerBlock',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      //? auto pool
      {
        contractAddress: '0x5BaDd6C71fFD0Da6E4C7D425797f130684D057dd',
        rewardAddress: '',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: '',
        showReward: false,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: false,
        removeLiquidity: 'NORMAL',
        supportROI: false
      },
      //? partner pool
      {
        contractAddress: '0x4e9e19b2943c74a2a6f801be0421ed3c563b83e9',
        rewardAddress: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        functionRewardPerBlockName: 'rewardPerBlock',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        contractAddress: '0x80163ae2ed3f0f572f580e9749a57a4d999e3f5b',
        rewardAddress: '0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        functionRewardPerBlockName: 'rewardPerBlock',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        contractAddress: '0xb51620eabfb567ce383272b922e6ec663e86bdb0',
        rewardAddress: '0x5d47baba0d66083c52009271faf3f50dcc01023c',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        functionRewardPerBlockName: 'rewardPerBlock',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        contractAddress: '0xde771a3445d60ab734e14f2e4aa1aaac6d724403',
        rewardAddress: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        functionRewardPerBlockName: 'rewardPerBlock',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      },
      {
        contractAddress: '0x03a6c95be4d1cefd51511c0a7ce6be66f0aa4957',
        rewardAddress: '0x3a3df212b7aa91aa0402b9035b098891d276572b',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        functionRewardPerBlockName: 'rewardPerBlock',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      }
    ]
  },
  {
    title: 'PolyYeld',
    name: 'PolyYeld Polygon',
    link: 'https://polyyeld.finance/',
    chain: Chain.POLYGON,
    symbol: 'YELD',
    logo: '/images/coin/yeld.svg',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0x49854708a8c42eeb837a97dd97d597890ceb1334' }],
    contracts: [
      {
        //farm
        contractAddress: '0x2dc11b394bd0f1cc6ac0a269cfe3cc0b333601b4',
        rewardAddress: '0xd0f3121a190d85de0ab6131f2bcecdbfcfb38891',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'YeldPerBlock',
        pendingReward: 'pendingYeld',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true,
        getBonusMultiplier: 'none'
      },
      {
        //auto
        contractAddress: '0xc49bc7118a73ca6cb36bfa454fd40ecae079a463',
        rewardAddress: '',
        routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        functionRewardPerBlockName: 'YeldPerBlock',
        pendingReward: 'pendingYeld',
        showReward: false,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: false,
        removeLiquidity: 'NORMAL',
        supportROI: false,
        getBonusMultiplier: 'none'
      }
    ]
  },
  {
    title: 'Polycat',
    name: 'Polycat Polygon',
    link: 'https://polycat.finance/',
    chain: Chain.POLYGON,
    symbol: 'FISH',
    logo: '/images/logo/polycat.svg',
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0x1cb757f1eb92f25a917ce9a92ed88c1ac0734334' }],
    contracts: [
      {
        contractAddress: '0xBdA1f897E851c7EF22CD490D2Cf2DAce4645A904',
        rewardAddress: '',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: '',
        showReward: false,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: false,
        removeLiquidity: 'NORMAL',
        supportROI: false
      },
      {
        contractAddress: '0x8CFD1B9B7478E7B0422916B72d1DB6A9D513D734',
        rewardAddress: '0x3a3df212b7aa91aa0402b9035b098891d276572b',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingFish',
        functionRewardPerBlockName: 'fishPerBlock',
        showReward: true,
        stake: true,
        unstake: true,
        supportIL: false,
        harvest: true,
        removeLiquidity: 'NORMAL',
        supportROI: true
      }
    ]
  },
  {
    title: 'JDI Vaults',
    symbol: 'JDI',
    link: 'https://jdiyield.com/vault',
    logo: '/images/coin/JDI.svg',
    isDollar: true,
    new: false,
    name: PlatformName.JDI,
    chain: Chain.BINANCE_SMART_CHAIN,
    timeLock: [],
    contracts: [
      {
        contractAddress: '0x859f4e52fe4076d483A2EBCf53Ae69A35eBa7Aa5',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      }
    ]
  },
  {
    title: 'JDI Vaults',
    symbol: 'JDI',
    link: 'https://jdiyield.com/vault',
    logo: '/images/coin/JDI.svg',
    isDollar: true,
    new: false,
    name: PlatformName.JDI_POLYGON,
    chain: Chain.POLYGON,
    contracts: [
      {
        contractAddress: '0x7398cF51AF6Ca168295a6352F268E5d35FEDF770',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingReward',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      }
    ]
  },
  {
    title: 'TakoDefi',
    name: 'takdefi BSC',
    link: 'https://takodefi.com/',
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xe43c2e3381be1c815d493d74a4535f0ccbfb5a04' }],
    contracts: [
      {
        contractAddress: '0x4448336ba564bd620be90d55078e397c26492a43',
        routerAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        pendingReward: 'pendingTako',
        rewardAddress: '0x2f3391aebe27393aba0a790aa5e1577fea0361c2',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'takoPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      },
      {
        contractAddress: '0x8399b18a8f951a84e98366013ece47f9bcb6d1f5',
        routerAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        pendingReward: 'pendingInku',
        rewardAddress: '0xb37cad62441ef8b866f3e36f12fd42062b6c0f33',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'InkuPerBlock',
        getBonusMultiplier: 'none'
      }
    ],
    symbol: 'TAKO',
    logo: '/images/coin/TAKO.png',
    isDollar: true,
    new: false
  },
  {
    title: 'TakoDefi',
    name: 'takdefi Polygon',
    link: 'https://takodefi.com/',
    chain: Chain.POLYGON,
    deployer: [{ contractAddress: '0xe43c2e3381be1c815d493d74a4535f0ccbfb5a04' }],
    contracts: [
      {
        contractAddress: '0xb19300246e19929a617c4260189f7b759597b8d8',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        pendingReward: 'pendingTako',
        rewardAddress: '0x6d2a71f4edf10ab1e821b9b373363e1e24e5df6b',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: false,
        supportROI: true,
        functionRewardPerBlockName: 'takoPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER'
      }
    ],
    symbol: 'TAKO',
    logo: '/images/coin/TAKO.png',
    isDollar: true,
    new: false
  },
  // {
  //   title: 'Aperocket',
  //   symbol: 'pSPACE',
  //   link: 'https://polygon.aperocket.finance/',
  //   logo: 'https://aperocket.finance/images/vaults/space.svg',
  //   name: PlatformName.APE_ROCKET_POLYGON,
  //   chain: Chain.POLYGON,
  //   isDollar: true,
  //   new: false,
  //   contracts: [
  //     // pSPACE
  //     {
  //       contractAddress: '0xBa56A30ec2Ee2B4C3c7EE75e0CFEbcD1b22dE8cd',
  //       routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
  //       pendingReward: '',
  //       rewardAddress: '0xd016caae879c42cb0d74bb1a265021bf980a7e96',
  //       showReward: true,
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: false,
  //       supportROI: false
  //     },
  //     // pSPACE Maximizer
  //     {
  //       contractAddress: '0x0F3D1A56ad59ba1be2f290e91ae2b8E70cd20d42',
  //       routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
  //       pendingReward: '',
  //       rewardAddress: '0xd016caae879c42cb0d74bb1a265021bf980a7e96',
  //       showReward: true,
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: false,
  //       supportROI: false
  //     },
  //     // pSPACE-WETH
  //     {
  //       contractAddress: '0x0be6e19528B77bAAc180dFc72dc1Afa2b09dD860',
  //       routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
  //       pendingReward: '',
  //       rewardAddress: '0xd016caae879c42cb0d74bb1a265021bf980a7e96',
  //       showReward: true,
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: false,
  //       supportROI: false
  //     },
  //     // MATIC-BANANA
  //     {
  //       contractAddress: '0xCAb27557316B4CF1950872d54Cf853646e70B19D',
  //       routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
  //       pendingReward: '',
  //       rewardAddress: '0x034293f21f1cce5908bc605ce5850df2b1059ac0',
  //       showReward: true,
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: false,
  //       supportROI: false
  //     },
  //     // MATIC-WETH
  //     {
  //       contractAddress: '0xCF1AC4Aac55DA77854479c4069F7Fb25F290A93f',
  //       routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
  //       pendingReward: '',
  //       rewardAddress: '0x6cf8654e85ab489ca7e70189046d507eba233613',
  //       showReward: true,
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: false,
  //       supportROI: false
  //     },
  //     // MATIC-DAI
  //     {
  //       contractAddress: '0xb2ce974aF77A8EcDF8f9E8BACF1A43f733D040FF',
  //       routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
  //       pendingReward: '',
  //       rewardAddress: '0xd32f3139a214034a0f9777c87ee0a064c1ff6ae2',
  //       showReward: true,
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: false,
  //       supportROI: false
  //     },
  //     // MATIC-BNB
  //     {
  //       contractAddress: '0x6F402Ce7Fd7d86946B6bc682341b9E4E225d3991',
  //       routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
  //       pendingReward: '',
  //       rewardAddress: '0x0359001070cf696d5993e0697335157a6f7db289',
  //       showReward: true,
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: false,
  //       supportROI: false
  //     }
  //   ]
  // },
  // {
  //   title: 'Iron finance',
  //   symbol: 'STEEL',
  //   logo: '/images/coin/titan.png',
  //   link: 'https://legacy-bsc.iron.finance/',
  //   isDollar: true,
  //   new: false,
  //   name: 'Iron finance',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   contracts: [
  //     {
  //       contractAddress: '0xC5a992dD7ba108e3349D2Fd8e8E126753Ca8Ce34',
  //       routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
  //       pendingReward: 'pendingReward',
  //       showReward: true,
  //       rewardAddress: '0x9001ee054f1692fef3a48330cb543b6fec6287eb',
  //       stake: true,
  //       unstake: true,
  //       removeLiquidity: 'NORMAL',
  //       harvest: true,
  //       supportIL: false,
  //       functionRewardPerBlockName: 'rewardPerBlock',
  //       supportROI: true,
  //       getBonusMultiplier: 'BONUS_MULTIPLIER'
  //     }
  //   ]
  // },
  {
    title: 'Iron finance',
    link: 'https://app.iron.finance/',
    name: PlatformName.IRON_POLYGON,
    chain: Chain.POLYGON,
    symbol: 'TITAN',
    logo: '/images/coin/titan.png',
    isDollar: true,
    new: false,
    lendContracts: [
      {
        id: 'Iron Lending',
        liquidity: 'getCash',
        totalSupply: 'totalSupply',
        underlying: 'underlying',
        borrowed: 'borrowBalanceStored',
        lendTokens: [
          /*rMATIC*/ '0xCa0F37f73174a28a64552D426590d3eD601ecCa1',
          /*rIRON*/ '0x09ca5d827712dd7b2570fd534305b663ae788c17',
          /*rWETH*/ '0x186C4137136970739b472A8192D3D2AFc5543B61',
          /*rBTC*/ '0xEe1eb5fEBeF78A1eb1a23E79930D9c587F954E05',
          /*rICE*/ '0xf535b089453dfd8ae698af6d7d5bc9f804781b81',
          /*rUSDT*/ '0xad6ad29d6b8b74b4302dd829c945ca3274035c16',
          /*rUSDC*/ '0xbEbAD52f3A50806b25911051BabDe6615C8e21ef'
        ]
      }
    ],
    specialCaseTokens: [
      {
        id: 'is3usd',
        symbol: 'IS3USD',
        name: 'IS3USD',
        chain: Chain.POLYGON,
        logo: '/images/coin/IS3USD.png',
        decimals: 18,
        address: '0xb4d09ff3da7f9e9a2ba029cb0a81a989fd7b8f17'
      },
      {
        id: null,
        symbol: 'IRON-IS3USD',
        name: 'IRON-IS3USD',
        chain: Chain.POLYGON,
        logo: '/images/coin/IRON-IS3USD.png',
        decimals: 18,
        address: '0x985d40fedaa3208dabacdfdca00cbeaac9543949'
      }
    ],
    deployer: [{ contractAddress: '0x5aebde597752d689132dc64d093ff4b09067e9e6' }],
    contracts: [
      //ICE
      {
        contractAddress: '0x1fd1259fa8cdc60c6e8c86cfa592ca1b8403dfad',
        routerAddress: '0x837503e8a8753ae17fb8c8151b8e6f586defcb57',
        pendingReward: 'pendingReward',
        rewardAddress: '0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef',
        functionRewardPerBlockName: 'rewardPerSecond',
        stake: true,
        unstake: true,
        harvest: true,
        supportIL: false,
        showReward: true,
        removeLiquidity: 'NORMAL',
        supportROI: true,
        getBonusMultiplier: 'none'
      }
      // //? OLD
      // {
      //   contractAddress: '0x65430393358e55A658BcdE6FF69AB28cF1CbB77a',
      //   routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
      //   pendingReward: 'pendingReward',
      //   rewardAddress: '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a',
      //   stake: true,
      //   unstake: true,
      //   harvest: true,
      //   supportIL: false,
      //   showReward: true,
      //   removeLiquidity: 'NORMAL',
      //   supportROI: false,
      //   getBonusMultiplier: 'BONUS_MULTIPLIER'
      // },
      // {
      //   contractAddress: '0xa37DD1f62661EB18c338f18Cf797cff8b5102d8e',
      //   routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
      //   rewardAddress: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      //   functionRewardPerBlockName: 'rewardPerBlock',
      //   pendingReward: 'pendingReward',
      //   showReward: true,
      //   stake: true,
      //   unstake: true,
      //   supportIL: false,
      //   harvest: true,
      //   removeLiquidity: 'NORMAL',
      //   supportROI: false,
      //   getBonusMultiplier: 'BONUS_MULTIPLIER'
      // }
    ]
  },
  {
    title: 'Autofarm',
    name: PlatformName.AUTO_FARM_POLYGON,
    link: 'https://autofarm.network/polygon/',
    chain: Chain.POLYGON,
    symbol: 'AUTO',
    logo: 'https://i.postimg.cc/VshPhwHX/autofarm-icon-200x200.png',
    new: false,
    isDollar: true,
    deployer: [{ contractAddress: '0xf482404f0ee4bbc780199b2995a43882a8595ada' }],
    timeLock: [{ contractAddress: '0x40edf10ef6b1f1c1cb4911c3b5eb73ae7043a160' }],
    contracts: [
      {
        //CROSS-CHAIN
        contractAddress: '0x89d065572136814230A55DdEeDDEC9DF34EB0B76',
        routerAddress: '',
        pendingReward: 'pendingpAUTO',
        functionRewardPerBlockName: 'AUTOPerBlock',
        showReward: false,
        rewardAddress: '0x7f426f6dc648e50464a0392e60e1bb465a67e9cf',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true
      }
    ]
  },
  {
    title: 'Space TukTuk',
    name: 'PlatformName.SPACE_TUKTUK',
    link: 'https://space.tuktuk.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'AUTO',
    logo: '/images/coin/tuk.png',
    new: false,
    isDollar: true,
    factories: [
      {
        factoryAddress: '0xd6FaA863BE0795d5aAb2bA7eF44E3e7521fa62Ae',
        initCodeHash: '0x54d4b1e4b842bc29e62344879fa140236148005d30118e3fd1672b3dcab304f4'
      }
    ],
    deployer: [{ contractAddress: '0xc291e451c0b71ccc82fce2f7ed2ea1237d559747' }],
    contracts: [
      {
        contractAddress: '0x30D010a7815443E01ba641Ba4Ddab36ac50847c9',
        routerAddress: '0x8CA43fC055Ae47E11959e079F37051831F850cA7',
        pendingReward: 'pendingTuk',
        functionRewardPerBlockName: 'tukPerBlock',
        getBonusMultiplier: 'BONUS_MULTIPLIER',
        showReward: true,
        rewardAddress: '0xe363096e780fb8675966b3ffca88bedd62b838fa',
        supportROI: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'WaultSwap',
    name: 'WaultSwap Polygon',
    logo: '/images/coin/wexpoly.png',
    link: 'https://app.wault.finance/polygon/index.html',
    chain: Chain.POLYGON,
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0xa98ea6356a316b44bf710d5f9b6b4ea0081409ef',
        initCodeHash: '0x1cdc2246d318ab84d8bc7ae2a3d81c235f3db4e113f4c6fdc1e2211a9291be47'
      }
    ],
    contracts: [
      {
        contractAddress: '0xc8bd86e5a132ac0bf10134e270de06a8ba317bfe',
        routerAddress: '0x3a1d87f206d12415f5b0a33e786967680aab4f6d',
        rewardAddress: '0x4c4bf319237d98a30a929a96112effa8da3510eb',
        pendingReward: 'pendingWex',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'wexPerBlock',
        getBonusMultiplier: 'none'
      }
    ]
  },
  {
    title: 'BakerySwap',
    name: 'BakerySwap',
    logo: '/images/coin/bakery.svg',
    link: 'https://bakeryswap.org/',
    chain: Chain.BINANCE_SMART_CHAIN,
    isDollar: true,
    new: false,
    nftConfigs: [
      {
        contractAddress: '0x43db8ea81074b31cf2665b600a4086cf36b59445'
      }
    ],
    // factories: [
    //   {
    //     factoryAddress: '0xa98ea6356a316b44bf710d5f9b6b4ea0081409ef',
    //     initCodeHash: '0x1cdc2246d318ab84d8bc7ae2a3d81c235f3db4e113f4c6fdc1e2211a9291be47'
    //   }
    // ],
    deployer: [{ contractAddress: '0xfea7747216ff0212cb21ae35578c17b60428e376' }],
    contracts: [
      {
        contractAddress: '0x61890bd5AF37A44f15a697f9db9BfcCeAbF4e322',
        routerAddress: '',
        rewardAddress: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
        pendingReward: '',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: false,
        functionRewardPerBlockName: 'tokenPerBlock'
      }
    ]
  },
  {
    title: 'firebird',
    name: 'Firebird',
    logo: '/images/coin/firebird.svg',
    link: 'https://app.firebird.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0x5de74546d3b86c8df7feec30253865e1149818c8'
      }
    ],
    specialCaseTokens: [
      {
        id: null,
        symbol: '4Stables',
        name: '4Stables',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://firebasestorage.googleapis.com/v0/b/ape-board-prod.appspot.com/o/logo%2FvPool.png?alt=media&token=8a6fe8c5-d2c9-411d-b71e-9ecdc1e4764e',
        decimals: 18,
        address: '0x4a592de6899ff00fbc2c99d7af260b5e7f88d1b4'
      }
    ],
    deployer: [{ contractAddress: '0xa20ca7c6705fb88847cbf50549d7a38f4e99d32c' }],
    contracts: [
      {
        contractAddress: '0xd67caed2cedd964dfc7e91543099960db044a6fe',
        routerAddress: '0x92e4f29be975c1b1eb72e77de24dccf11432a5bd',
        rewardAddress: '0x645695d8b5693d806af33dfc82eb15d4cc0a4a59',
        pendingReward: 'pendingReward',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'rewardPerSecond',
        getBonusMultiplier: 'none'
      }
    ]
  },
  {
    title: 'firebird Polygon',
    name: 'Firebird',
    logo: '/images/coin/firebird.svg',
    link: 'https://app.firebird.finance/',
    chain: Chain.POLYGON,
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0x5de74546d3b86c8df7feec30253865e1149818c8'
      }
    ],
    specialCaseTokens: [
      {
        id: null,
        symbol: '3FBird',
        name: '3FBird',
        chain: Chain.POLYGON,
        logo: '/images/coin/IS3USD.png',
        decimals: 18,
        address: '0x4a592de6899ff00fbc2c99d7af260b5e7f88d1b4'
      }
    ],
    deployer: [{ contractAddress: '0xa20ca7c6705fb88847cbf50549d7a38f4e99d32c' }],
    contracts: [
      {
        contractAddress: '0xe9a8b6ea3e7431e6befca51258cb472df2dd21d4',
        routerAddress: '0x6466cdc2615f111dc6dbd0ef6fdd209f5ff8d184',
        rewardAddress: '0xd78c475133731cd54dadcb430f7aae4f03c1e660',
        pendingReward: 'pendingReward',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        functionRewardPerBlockName: 'rewardPerSecond',
        getBonusMultiplier: 'none',
        supportROI: false
      }
    ]
  },
  {
    title: 'Augury',
    name: 'Augury Polygon',
    logo: '/images/coin/omen.png',
    link: 'https://augury.finance',
    chain: Chain.POLYGON,
    isDollar: true,
    new: false,
    factories: [
      {
        factoryAddress: '0xc35dadb65012ec5796536bd9864ed8773abc74c4',
        initCodeHash: '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303'
      }
    ],
    deployer: [{ contractAddress: '0x1eadf5cac8e5751d8420775875d30985d9390312' }],
    contracts: [
      {
        contractAddress: '0x6ad70613d14c34aa69e1604af91c39e0591a132e',
        routerAddress: '0x1b02da8cb0d097eb8d57a175b88c7d8b47997506',
        rewardAddress: '0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e',
        pendingReward: 'pendingOmen',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        functionRewardPerBlockName: 'omenPerBlock',
        getBonusMultiplier: 'none'
      }
    ]
  },
  {
    title: 'Moonpot',
    name: 'Moonpot',
    logo: 'https://assets.coingecko.com/coins/images/17517/small/moonpot.PNG?1628067384',
    link: 'https://play.moonpot.com/',
    chain: Chain.BINANCE_SMART_CHAIN,
    isDollar: true,
    new: false,
    deployer: [{ contractAddress: '0x654ac60246c9b7e35f0f51f116d67ebc0a956d09' }],
    contracts: [
      {
        contractAddress: '0x35ebb629b6e65cb7705b5e0695982d206898f195',
        routerAddress: '0x11111112542d85b3ef69ae05771c2dccff4faa26',
        rewardAddress: '0x3fcca8648651e5b974dd6d3e50f61567779772a8',
        pendingReward: 'earned',
        showReward: true,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true,
        getBonusMultiplier: 'none'
      },
      {
        contractAddress: '0x30d55ce74e2dcd1b0ff37214a6978fcfc06aa499',
        routerAddress: '0x11111112542d85b3ef69ae05771c2dccff4faa26',
        rewardAddress: '0x3fcca8648651e5b974dd6d3e50f61567779772a8',
        pendingReward: 'earned',
        showReward: false,
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true,
        supportROI: true
      }
    ]
  },
  {
    title: 'Copycat',
    name: PlatformName.COPYCAT,
    link: 'https://www.copycat.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'COPYCAT',
    logo: 'https://assets.coingecko.com/coins/images/18276/small/copycat.PNG?1631233811',
    new: false,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0x9a1b69f216ea3b9d7c4f938edaea0dae7e758c17',
        routerAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        pendingReward: 'pendingCPC',
        supportROI: false,
        showReward: true,
        rewardAddress: '0xd635b32688f36ee4a7fe117b4c91dd811277acb6',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'AleSwap Vault',
    name: 'AleSwap Vault',
    symbol: 'ALE',
    link: 'https://aleswap.finance/#/farm',
    logo: '/images/coin/ale.svg',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    deployer: [{ contractAddress: '0xd64ff9Ad55afE90BAf1E76A720C55081a07cea0d' }],
    specialCaseTokens: [
      {
        id: null,
        symbol: 'DOP-LP',
        name: 'Dopple',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://firebasestorage.googleapis.com/v0/b/ape-board-prod.appspot.com/o/logo%2FvPool.png?alt=media&token=8a6fe8c5-d2c9-411d-b71e-9ecdc1e4764e',
        decimals: 18,
        address: '0x9116f04092828390799514bac9986529d70c3791'
      },
      {
        id: null,
        symbol: 'DOP-UST-LP',
        name: 'Dopple',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fust-pool.png&w=128&q=75',
        decimals: 18,
        address: '0x7edcdc8cd062948ce9a9bc38c477e6aa244dd545'
      },
      {
        id: null,
        symbol: 'DOP-2P-LP',
        name: 'Dopple 2pool LP Token',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fbusd-usdt.svg&w=128&q=75',
        decimals: 18,
        address: '0x124166103814e5a033869c88e0f40c61700fca17'
      },
      {
        id: null,
        symbol: 'DOP-3P-LP',
        name: 'Dopple BUSD-USDT-DOLLY',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fdolly-pool.svg&w=64&q=75',
        decimals: 18,
        address: '0xaa5509ce0ecea324bff504a46fc61eb75cb68b0c'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-USDN-LP',
        name: 'Dopple KUSD-BUSD-USDC-USDN',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-usdn.svg&w=64&q=75',
        decimals: 18,
        address: '0x367189b09340a6e157e5fd31cb0924e91b71710c'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-TUSD-LP',
        name: 'Dopple KUSD-BUSD-USDC-TUSD',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-tusd.svg&w=64&q=75',
        decimals: 18,
        address: '0x07fe67a1cfc285e14e72fb5bb03a6ccfa6224aa8'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-LP',
        name: 'Dopple KUSD-BUSD-USDC',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc.svg&w=64&q=75',
        decimals: 18,
        address: '0x2bf718e5fa2106dc1998d3f964c1baea8bda36e1'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDT-LP',
        name: 'Dopple KUSD-BUSD-USDT',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdt.svg&w=64&q=75',
        decimals: 18,
        address: '0x7755816b41f04ab62cec2334263987e7cd78a4fe'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-DOLLY-LP',
        name: 'Dopple KUSD-BUSD-DOLLY',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-dolly.svg&w=64&q=75',
        decimals: 18,
        address: '0x01222c8b6cb44e47c19e61c6ddac769e6d9fe629'
      }
    ],
    contracts: [
      // definix
      {
        contractAddress: '0x66b3ec4497e5aa64fded66fa8f7dbc4085c73c8b',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xF7923dfa2aB2e67EEE67630A4161DA8794A76c3e',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x40afa1238253E52C32FA26D8c22D39F7377AaB55',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x994a9d49a50670838A6f1937d884DE3e1d094e15',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x44782F1f4F30C04C9951b3dd72a76744b2bda12b',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x92290f5c30d45E46cAe1d53c16fBF52C1FC3A1fF',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x9E40ABAd4d251105c9641f7faAdd0DeaC41f7373',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x26E85E4f420eC7b7219132b108309112FFd7965B',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // biswap
      {
        contractAddress: '0xaabC7b3dA2823126EF81F3142C34E46b8d0Eb7EC',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xB2bBa912D9CaB53AB82D91Bd754E61D884c3c505',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xB82CfEdFE4056Da45A0508c168a69260941F5FAA',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // apeswap
      {
        contractAddress: '0x84FC45745f4232C2aEF5315ad8d6a03fcD95a4a7',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x97f7B0918C6c08A69b71AC5DD7317Dc7E1951E86',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // Twindex2.0
      {
        contractAddress: '0x50C3229640181d8A3b34595Ba90E015183b0c480',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xd309191987f3662e9DBe39DA88358E4FfB296c43',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x085b7cE8c30f4aF095948673BA5B2f31CfD1E690',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xdbBEc9E83f012cD5AE9cf455954b30D34E2C1dBd',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x5357E431feD0f390Ad467E35215F87AFFcC0dA59',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x776Dfec84D3924f824bb9EDdC79f4C8c2bb7e986',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // dopple
      {
        contractAddress: '0xbC8e0c6b630d4BD6fe662ca5A9D313D5182bd397',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xE39DaEc240881CB07570f1a24F9ca8A1E39c2B4c',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xa295DDBF6404f868d3D311F66708C6CEC42846b8',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xBa9BDB60aea0a4F9B479F904566a933C55644bb1',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xf664C16d63694713a7C941Fcd36c5640F78BfBEa',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x9141E6fc458E02687c448957ecbFc9686f5B3d00',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xFa15C31dCB0b9f74A7C67459D03BE1A424c86a01',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x95c40489009Ca9d084B7B1434fcCDFB9DccF330f',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x3373cFA5426727031DBbA3410Ed2717C3D174371',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      }
    ]
  },
  {
    title: 'LatteSwap',
    name: PlatformName.LATTE_SWAP,
    link: 'https://app.latteswap.com/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'LATTE',
    logo: 'https://assets.coingecko.com/coins/images/15642/small/5842b803-3905-46ae-9dc3-df35bb12da4e.png?1621411379',
    new: false,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0xbcee0d15a4402c9cc894d52cc5e9982f60c463d6',
        routerAddress: '0x4ec82ecd19529ff50a467f522029f69459bf071d',
        pendingReward: 'pendingLatte',
        getBonusMultiplier: 'bonusMultiplier',
        functionRewardPerBlockName: 'lattePerBlock',
        supportROI: true,
        showReward: true,
        rewardAddress: '0x8d78c2ff1fb4fba08c7691dfeac7bb425a91c81a',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'Copycat',
    name: PlatformName.COPYCAT,
    link: 'https://www.copycat.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'COPYCAT',
    logo: 'https://assets.coingecko.com/coins/images/18276/small/copycat.PNG?1631233811',
    new: false,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0x9a1b69f216ea3b9d7c4f938edaea0dae7e758c17',
        routerAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        pendingReward: 'pendingCPC',
        supportROI: false,
        showReward: true,
        rewardAddress: '0xd635b32688f36ee4a7fe117b4c91dd811277acb6',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'AleSwap Vault',
    name: 'AleSwap Vault',
    symbol: 'ALE',
    link: 'https://aleswap.finance/#/farm',
    logo: '/images/coin/ale.svg',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    specialCaseTokens: [
      {
        id: null,
        symbol: 'DOP-LP',
        name: 'Dopple',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://firebasestorage.googleapis.com/v0/b/ape-board-prod.appspot.com/o/logo%2FvPool.png?alt=media&token=8a6fe8c5-d2c9-411d-b71e-9ecdc1e4764e',
        decimals: 18,
        address: '0x9116f04092828390799514bac9986529d70c3791'
      },
      {
        id: null,
        symbol: 'DOP-UST-LP',
        name: 'Dopple',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fust-pool.png&w=128&q=75',
        decimals: 18,
        address: '0x7edcdc8cd062948ce9a9bc38c477e6aa244dd545'
      },
      {
        id: null,
        symbol: 'DOP-2P-LP',
        name: 'Dopple 2pool LP Token',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fbusd-usdt.svg&w=128&q=75',
        decimals: 18,
        address: '0x124166103814e5a033869c88e0f40c61700fca17'
      },
      {
        id: null,
        symbol: 'DOP-3P-LP',
        name: 'Dopple BUSD-USDT-DOLLY',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fdolly-pool.svg&w=64&q=75',
        decimals: 18,
        address: '0xaa5509ce0ecea324bff504a46fc61eb75cb68b0c'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-USDN-LP',
        name: 'Dopple KUSD-BUSD-USDC-USDN',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-usdn.svg&w=64&q=75',
        decimals: 18,
        address: '0x367189b09340a6e157e5fd31cb0924e91b71710c'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-TUSD-LP',
        name: 'Dopple KUSD-BUSD-USDC-TUSD',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-tusd.svg&w=64&q=75',
        decimals: 18,
        address: '0x07fe67a1cfc285e14e72fb5bb03a6ccfa6224aa8'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-LP',
        name: 'Dopple KUSD-BUSD-USDC',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc.svg&w=64&q=75',
        decimals: 18,
        address: '0x2bf718e5fa2106dc1998d3f964c1baea8bda36e1'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDT-LP',
        name: 'Dopple KUSD-BUSD-USDT',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdt.svg&w=64&q=75',
        decimals: 18,
        address: '0x7755816b41f04ab62cec2334263987e7cd78a4fe'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-DOLLY-LP',
        name: 'Dopple KUSD-BUSD-DOLLY',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-dolly.svg&w=64&q=75',
        decimals: 18,
        address: '0x01222c8b6cb44e47c19e61c6ddac769e6d9fe629'
      }
    ],
    contracts: [
      // definix
      {
        contractAddress: '0x66b3ec4497e5aa64fded66fa8f7dbc4085c73c8b',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xF7923dfa2aB2e67EEE67630A4161DA8794A76c3e',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x40afa1238253E52C32FA26D8c22D39F7377AaB55',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x994a9d49a50670838A6f1937d884DE3e1d094e15',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x44782F1f4F30C04C9951b3dd72a76744b2bda12b',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x92290f5c30d45E46cAe1d53c16fBF52C1FC3A1fF',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x9E40ABAd4d251105c9641f7faAdd0DeaC41f7373',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x26E85E4f420eC7b7219132b108309112FFd7965B',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // biswap
      {
        contractAddress: '0xaabC7b3dA2823126EF81F3142C34E46b8d0Eb7EC',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xB2bBa912D9CaB53AB82D91Bd754E61D884c3c505',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xB82CfEdFE4056Da45A0508c168a69260941F5FAA',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // apeswap
      {
        contractAddress: '0x84FC45745f4232C2aEF5315ad8d6a03fcD95a4a7',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x97f7B0918C6c08A69b71AC5DD7317Dc7E1951E86',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // Twindex2.0
      {
        contractAddress: '0x50C3229640181d8A3b34595Ba90E015183b0c480',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xd309191987f3662e9DBe39DA88358E4FfB296c43',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x085b7cE8c30f4aF095948673BA5B2f31CfD1E690',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xdbBEc9E83f012cD5AE9cf455954b30D34E2C1dBd',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x5357E431feD0f390Ad467E35215F87AFFcC0dA59',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x776Dfec84D3924f824bb9EDdC79f4C8c2bb7e986',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // dopple
      {
        contractAddress: '0xbC8e0c6b630d4BD6fe662ca5A9D313D5182bd397',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xE39DaEc240881CB07570f1a24F9ca8A1E39c2B4c',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xa295DDBF6404f868d3D311F66708C6CEC42846b8',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xBa9BDB60aea0a4F9B479F904566a933C55644bb1',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xf664C16d63694713a7C941Fcd36c5640F78BfBEa',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x9141E6fc458E02687c448957ecbFc9686f5B3d00',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xFa15C31dCB0b9f74A7C67459D03BE1A424c86a01',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x95c40489009Ca9d084B7B1434fcCDFB9DccF330f',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x3373cFA5426727031DBbA3410Ed2717C3D174371',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      }
    ]
  },
  {
    title: 'LatteSwap',
    name: PlatformName.LATTE_SWAP,
    link: 'https://app.latteswap.com/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'LATTE',
    logo: 'https://assets.coingecko.com/coins/images/15642/small/5842b803-3905-46ae-9dc3-df35bb12da4e.png?1621411379',
    new: false,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0xbcee0d15a4402c9cc894d52cc5e9982f60c463d6',
        routerAddress: '0x4ec82ecd19529ff50a467f522029f69459bf071d',
        pendingReward: 'pendingLatte',
        supportROI: true,
        showReward: true,
        rewardAddress: '0x8d78c2ff1fb4fba08c7691dfeac7bb425a91c81a',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'Copycat',
    name: PlatformName.COPYCAT,
    link: 'https://www.copycat.finance/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'COPYCAT',
    logo: 'https://assets.coingecko.com/coins/images/18276/small/copycat.PNG?1631233811',
    new: false,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0x9a1b69f216ea3b9d7c4f938edaea0dae7e758c17',
        routerAddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        pendingReward: 'pendingCPC',
        supportROI: false,
        showReward: true,
        rewardAddress: '0xd635b32688f36ee4a7fe117b4c91dd811277acb6',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'AleSwap Vault',
    name: 'AleSwap Vault',
    symbol: 'ALE',
    link: 'https://aleswap.finance/#/farm',
    logo: '/images/coin/ale.svg',
    isDollar: true,
    new: false,
    chain: Chain.BINANCE_SMART_CHAIN,
    specialCaseTokens: [
      {
        id: null,
        symbol: 'DOP-LP',
        name: 'Dopple',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://firebasestorage.googleapis.com/v0/b/ape-board-prod.appspot.com/o/logo%2FvPool.png?alt=media&token=8a6fe8c5-d2c9-411d-b71e-9ecdc1e4764e',
        decimals: 18,
        address: '0x9116f04092828390799514bac9986529d70c3791'
      },
      {
        id: null,
        symbol: 'DOP-UST-LP',
        name: 'Dopple',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fust-pool.png&w=128&q=75',
        decimals: 18,
        address: '0x7edcdc8cd062948ce9a9bc38c477e6aa244dd545'
      },
      {
        id: null,
        symbol: 'DOP-2P-LP',
        name: 'Dopple 2pool LP Token',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fbusd-usdt.svg&w=128&q=75',
        decimals: 18,
        address: '0x124166103814e5a033869c88e0f40c61700fca17'
      },
      {
        id: null,
        symbol: 'DOP-3P-LP',
        name: 'Dopple BUSD-USDT-DOLLY',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Fdolly-pool.svg&w=64&q=75',
        decimals: 18,
        address: '0xaa5509ce0ecea324bff504a46fc61eb75cb68b0c'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-USDN-LP',
        name: 'Dopple KUSD-BUSD-USDC-USDN',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-usdn.svg&w=64&q=75',
        decimals: 18,
        address: '0x367189b09340a6e157e5fd31cb0924e91b71710c'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-TUSD-LP',
        name: 'Dopple KUSD-BUSD-USDC-TUSD',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc-tusd.svg&w=64&q=75',
        decimals: 18,
        address: '0x07fe67a1cfc285e14e72fb5bb03a6ccfa6224aa8'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDC-LP',
        name: 'Dopple KUSD-BUSD-USDC',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdc.svg&w=64&q=75',
        decimals: 18,
        address: '0x2bf718e5fa2106dc1998d3f964c1baea8bda36e1'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-USDT-LP',
        name: 'Dopple KUSD-BUSD-USDT',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-usdt.svg&w=64&q=75',
        decimals: 18,
        address: '0x7755816b41f04ab62cec2334263987e7cd78a4fe'
      },
      {
        id: null,
        symbol: 'KUSD-BUSD-DOLLY-LP',
        name: 'Dopple KUSD-BUSD-DOLLY',
        chain: Chain.BINANCE_SMART_CHAIN,
        logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-lp-kelly-busd-dolly.svg&w=64&q=75',
        decimals: 18,
        address: '0x01222c8b6cb44e47c19e61c6ddac769e6d9fe629'
      }
    ],
    contracts: [
      // definix
      {
        contractAddress: '0x66b3ec4497e5aa64fded66fa8f7dbc4085c73c8b',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xF7923dfa2aB2e67EEE67630A4161DA8794A76c3e',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x40afa1238253E52C32FA26D8c22D39F7377AaB55',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x994a9d49a50670838A6f1937d884DE3e1d094e15',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x44782F1f4F30C04C9951b3dd72a76744b2bda12b',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x92290f5c30d45E46cAe1d53c16fBF52C1FC3A1fF',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x9E40ABAd4d251105c9641f7faAdd0DeaC41f7373',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x26E85E4f420eC7b7219132b108309112FFd7965B',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // biswap
      {
        contractAddress: '0xaabC7b3dA2823126EF81F3142C34E46b8d0Eb7EC',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xB2bBa912D9CaB53AB82D91Bd754E61D884c3c505',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xB82CfEdFE4056Da45A0508c168a69260941F5FAA',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // apeswap
      {
        contractAddress: '0x84FC45745f4232C2aEF5315ad8d6a03fcD95a4a7',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x97f7B0918C6c08A69b71AC5DD7317Dc7E1951E86',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // Twindex2.0
      {
        contractAddress: '0x50C3229640181d8A3b34595Ba90E015183b0c480',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xd309191987f3662e9DBe39DA88358E4FfB296c43',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x085b7cE8c30f4aF095948673BA5B2f31CfD1E690',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xdbBEc9E83f012cD5AE9cf455954b30D34E2C1dBd',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x5357E431feD0f390Ad467E35215F87AFFcC0dA59',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x776Dfec84D3924f824bb9EDdC79f4C8c2bb7e986',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      // dopple
      {
        contractAddress: '0xbC8e0c6b630d4BD6fe662ca5A9D313D5182bd397',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xE39DaEc240881CB07570f1a24F9ca8A1E39c2B4c',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xa295DDBF6404f868d3D311F66708C6CEC42846b8',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xBa9BDB60aea0a4F9B479F904566a933C55644bb1',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xf664C16d63694713a7C941Fcd36c5640F78BfBEa',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x9141E6fc458E02687c448957ecbFc9686f5B3d00',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0xFa15C31dCB0b9f74A7C67459D03BE1A424c86a01',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x95c40489009Ca9d084B7B1434fcCDFB9DccF330f',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      },
      {
        contractAddress: '0x3373cFA5426727031DBbA3410Ed2717C3D174371',
        routerAddress: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        pendingReward: '',
        showReward: false,
        rewardAddress: '',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: false,
        supportIL: false,
        supportROI: true
      }
    ]
  },
  {
    title: 'LatteSwap',
    name: PlatformName.LATTE_SWAP,
    link: 'https://app.latteswap.com/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'LATTE',
    logo: 'https://assets.coingecko.com/coins/images/15642/small/5842b803-3905-46ae-9dc3-df35bb12da4e.png?1621411379',
    new: false,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0xbcee0d15a4402c9cc894d52cc5e9982f60c463d6',
        routerAddress: '0x4ec82ecd19529ff50a467f522029f69459bf071d',
        pendingReward: 'pendingLatte',
        supportROI: true,
        showReward: true,
        rewardAddress: '0x8d78c2ff1fb4fba08c7691dfeac7bb425a91c81a',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'LuckyLion',
    name: PlatformName.LUCKYLION,
    link: 'https://www.luckylion.io/',
    chain: Chain.BINANCE_SMART_CHAIN,
    symbol: 'LUCKY',
    logo: 'https://assets.coingecko.com/coins/images/18712/small/lucky-token-200px.png?1633079262',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0xB6FE67c8a28d50c50f65fDb5847Ee4477c550568',
        routerAddress: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
        getBonusMultiplier: 'BONUS_MULTIPLIER',
        functionRewardPerBlockName: 'luckyPerBlock',
        pendingReward: 'pendingLucky',
        supportROI: true,
        showReward: true,
        rewardAddress: '0xc3d912863152e1afc935ad0d42d469e7c6b05b77',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'Penguin Finance',
    name: PlatformName.PENGUIN_FINANCE,
    link: 'https://penguinfinance.org/',
    chain: Chain.AVAX,
    symbol: 'PEFI',
    logo: 'https://assets.coingecko.com/coins/images/14643/small/TcxLiQr.png?1617486828',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0x256040dc7b3CECF73a759634fc68aA60EA0D68CB',
        routerAddress: '0x60aE616a2155Ee3d9A68541Ba4544862310933d4',
        getBonusMultiplier: 'BONUS_MULTIPLIER',
        functionRewardPerBlockName: 'luckyPerBlock',
        pendingReward: 'pendingPEFI',
        supportROI: false,
        showReward: true,
        rewardAddress: '0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'Lydia Finance',
    name: PlatformName.LYDIA_FINANCE,
    link: 'https://www.lydia.finance/',
    chain: Chain.AVAX,
    symbol: 'PEFI',
    logo: 'https://assets.coingecko.com/coins/images/15195/small/512_pure_logo.png?1620083279',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0xFb26525B14048B7BB1F3794F6129176195Db7766',
        routerAddress: '0xA52aBE4676dbfd04Df42eF7755F01A3c41f28D27',
        getBonusMultiplier: 'BONUS_MULTIPLIER',
        functionRewardPerBlockName: 'lydPerSec',
        pendingReward: 'pendingLyd',
        supportROI: false,
        showReward: true,
        rewardAddress: '0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'JOE',
    name: PlatformName.JOE,
    link: 'https://traderjoexyz.com/',
    chain: Chain.AVAX,
    symbol: 'JOE',
    logo: 'https://assets.coingecko.com/coins/images/17569/small/joe_200x200.png?1628497750',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0x188bED1968b795d5c9022F6a0bb5931Ac4c18F00',
        routerAddress: '0x2C7B8e971c704371772eDaf16e0dB381A8D02027',
        getBonusMultiplier: '',
        functionRewardPerBlockName: 'joePerSec',
        pendingReward: 'pendingTokens',
        supportROI: false,
        showReward: true,
        rewardAddress: '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'Pangolin',
    name: PlatformName.PANGOLIN,
    link: 'https://pangolin.exchange/',
    chain: Chain.AVAX,
    symbol: 'PNG',
    logo: 'https://assets.coingecko.com/coins/images/14023/small/pangolin.jpg?1613743598',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0x574d3245e36Cf8C9dc86430EaDb0fDB2F385F829',
        routerAddress: '0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106',
        getBonusMultiplier: '',
        functionRewardPerBlockName: 'rewardPerToken',
        pendingReward: 'pendingTokens',
        supportROI: false,
        showReward: true,
        rewardAddress: '0x60781c2586d68229fde47564546784ab3faca982',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'spiritswap',
    name: PlatformName.SPIRITSWAP,
    link: 'https://app.spiritswap.finance',
    chain: Chain.FTM,
    symbol: 'SPIRIT',
    logo: 'https://assets.coingecko.com/coins/images/15118/small/4mY2Biy9_400x400.jpg?1619753382',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0x9083EA3756BDE6Ee6f27a6e996806FBD37F6F093',
        routerAddress: '0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52',
        getBonusMultiplier: '',
        functionRewardPerBlockName: 'spiritPerBlock',
        pendingReward: 'pendingSpirit',
        supportROI: false,
        showReward: true,
        rewardAddress: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'SpookySwap',
    name: PlatformName.SPOOKYSWAP,
    link: 'https://spookyswap.finance/',
    chain: Chain.FTM,
    symbol: 'BOO',
    logo: 'https://assets.coingecko.com/coins/images/15223/small/logo_200x200.png?1621992076',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0x2b2929E785374c651a81A63878Ab22742656DcDd',
        routerAddress: '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
        getBonusMultiplier: '',
        functionRewardPerBlockName: 'booPerSecond',
        pendingReward: 'pendingBOO',
        supportROI: false,
        showReward: true,
        rewardAddress: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ]
  },
  {
    title: 'vedao',
    name: PlatformName.VEDAO,
    link: 'https://www.vedao.io/',
    chain: Chain.FTM,
    symbol: 'WEVE',
    logo: 'https://assets.coingecko.com/coins/images/22741/small/S0aENDh0_400x400.jpg?1642544703',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0xE04C26444d37fE103B9cc8033c99b09D47056f51',
        routerAddress: '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
        getBonusMultiplier: '',
        functionRewardPerBlockName: 'rewardsPerBlock',
        pendingReward: 'pendingRewards',
        supportROI: false,
        showReward: true,
        rewardAddress: '0x911da02C1232A3c3E1418B834A311921143B04d7',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ],
    specialCaseTokens: [
      {
        id: null,
        symbol: 'USDC',
        name: 'USDC',
        chain: Chain.FTM,
        logo: '/images/coin/usdc.png',
        decimals: 6,
        address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75'
      }
    ]
  },
  {
    title: 'OXD',
    name: PlatformName.OXDAO,
    link: 'https://www.oxdao.fi/',
    chain: Chain.FTM,
    symbol: '0XD',
    logo: 'https://assets.coingecko.com/coins/images/22888/small/rjks-OoT_400x400.jpg?1642827011',
    new: true,
    isDollar: true,
    contracts: [
      {
        contractAddress: '0xa7821C3e9fC1bF961e280510c471031120716c3d',
        routerAddress: '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
        getBonusMultiplier: '',
        functionRewardPerBlockName: 'oxdPerSecond',
        pendingReward: 'pendingOXD',
        supportROI: false,
        showReward: true,
        rewardAddress: '0xc165d941481e68696f43ee6e99bfb2b23e0e3114',
        stake: true,
        unstake: true,
        removeLiquidity: 'NORMAL',
        harvest: true,
        supportIL: true
      }
    ],
    specialCaseTokens: [
      {
        id: null,
        symbol: 'USDC',
        name: 'USDC',
        chain: Chain.FTM,
        logo: '/images/coin/usdc.png',
        decimals: 6,
        address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75'
      }
    ]
  }
]

export default oldplatforms
