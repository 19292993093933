import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useThemeContext } from '@frontend/styles/Provider';
import Lottie from 'lottie-react';
import { useMemo } from 'react';
import * as React from 'react';
import loadingDarkAnimation from './assets/jdi_loading_dark.json';
import loadingLightAnimation from './assets/jdi_loading_light.json';
import loadingWhiteAnimation from './assets/jdi_loading_white.json';
export var CircleSpinner = function CircleSpinner(_ref) {
  var type = _ref.type,
      style = _ref.style,
      className = _ref.className,
      width = _ref.width;

  var _useThemeContext = useThemeContext(),
      theme = _useThemeContext.theme;

  var selectedAnimation = useMemo(function () {
    var selectedTheme = theme;
    if (type) selectedTheme = type;

    switch (selectedTheme) {
      case 'light':
        return loadingLightAnimation;

      case 'dark':
        return loadingDarkAnimation;

      default:
        return loadingWhiteAnimation;
    }
  }, [theme, type]);
  return _jsx(Lottie, {
    animationData: selectedAnimation,
    style: _objectSpread({
      transform: 'scale(2.5)',
      width: '1em',
      height: '1em',
      fontSize: width
    }, style),
    className: className
  });
};