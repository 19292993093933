import { Chain, Token } from '@jdiyield/interfaces'

export const bscBaseTokens: Record<string, Token> = {
  '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': {
    id: 'binancecoin',
    symbol: 'WBNB',
    name: 'Binance Coin',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png?1547034615',
    decimals: 18,
    address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
  },
  '0xe9e7cea3dedca5984780bafc599bd69add087d56': {
    id: 'binance-usd',
    symbol: 'BUSD',
    name: 'Binance USD',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/9576/large/BUSD.png?1568947766',
    decimals: 18,
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56'
  },
  '0x55d398326f99059ff775485246999027b3197955': {
    id: 'tether',
    symbol: 'USDT',
    name: 'Binance-Peg BUSD-T',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707',
    decimals: 18,
    address: '0x55d398326f99059ff775485246999027b3197955'
  },
  '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3': {
    id: 'dai',
    symbol: 'DAI',
    name: 'Binance-Peg Dai',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png?1574218774',
    decimals: 18,
    address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3'
  },
  '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5': {
    id: 'dolly-bsc',
    symbol: 'DOLLY',
    name: 'Dolly Stable Coin',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/dolly.svg',
    decimals: 18,
    address: '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5'
  }
}

export const polygonBaseTokens: Record<string, Token> = {
  '0x0000000000000000000000000000000000001010': {
    id: 'wmatic',
    symbol: 'MATIC',
    name: 'MATIC',
    chain: Chain.POLYGON,
    logo: '/images/coin/polygon.svg',
    decimals: 18,
    address: '0x0000000000000000000000000000000000001010'
  },
  '0x2791bca1f2de4661ed88a30c99a7a9449aa84174': {
    id: 'usd-coin',
    symbol: 'USDC',
    name: 'USDC',
    chain: Chain.POLYGON,
    logo: '/images/coin/usdc.png',
    decimals: 6,
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  },
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': {
    id: 'tether',
    symbol: 'USDT',
    name: 'USDT',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707',
    decimals: 6,
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
  },
  '0x087175607ed8231db7a678f0e0e3fa74bb24767a': {
    id: 'dolly-polygon',
    symbol: 'DOLLY',
    name: 'Dolly Stable Coin',
    chain: Chain.POLYGON,
    logo: '/images/coin/dolly.svg',
    decimals: 18,
    address: '0x087175607ed8231db7a678f0e0e3fa74bb24767a'
  }
}

//TODO will deprecated
export const oldtokens: Record<string, Token> = {
  '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee': {
    id: 'ethereum',
    symbol: 'ETH',
    name: 'ethereum',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    decimals: 18,
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
  },
  '0xc99a6a985ed2cac1ef41640596c5a5f9f4e19ef5': {
    id: 'ethereum',
    symbol: 'ETH',
    name: 'ethereum',
    chain: Chain.RONIN,
    logo: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    decimals: 18,
    address: '0xc99a6a985ed2cac1ef41640596c5a5f9f4e19ef5'
  },
  '0xbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb': {
    id: 'binancecoin',
    symbol: 'BNB',
    name: 'Binance Coin',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png?1547034615',
    decimals: 18,
    address: '0xbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb'
  },
  '0x0000000000000000000000000000000000000000': {
    id: 'ethereum',
    symbol: 'ETH',
    name: 'ethereum',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    decimals: 18,
    address: '0x0000000000000000000000000000000000000000'
  },
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8': {
    id: 'ethereum',
    symbol: 'ETH',
    name: 'Binance-Peg Ethereum',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    decimals: 18,
    address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8'
  },
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
    id: 'weth',
    symbol: 'WETH',
    name: 'weth',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2518/large/weth.png?1547036627',
    decimals: 18,
    address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
  },
  '0xe1237aa7f535b0cc33fd973d66cbf830354d16c7': {
    id: 'weth',
    symbol: 'YWETH',
    name: 'yweth',
    chain: Chain.ETHEREUM,
    logo: 'https://etherscan.io/token/images/cyWETH_32.png',
    decimals: 18,
    address: '0xe1237aa7f535b0cc33fd973d66cbf830354d16c7'
  },
  '0xb9ef770b6a5e12e45983c5d80545258aa38f3b78': {
    id: '0chain',
    symbol: 'zcn',
    name: '0chain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4934/large/0_Black-svg.png?1600757954',
    decimals: 10,
    address: '0xb9ef770b6a5e12e45983c5d80545258aa38f3b78'
  },
  '0xe41d2489571d322189246dafa5ebde1f4699f498': {
    id: '0x',
    symbol: 'zrx',
    name: '0x',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/863/large/0x.png?1547034672',
    decimals: 18,
    address: '0xe41d2489571d322189246dafa5ebde1f4699f498'
  },
  '0x111111111117dc0aa78b770fa6a738034120c302': {
    id: '1inch',
    symbol: '1INCH',
    name: '1inch',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13469/large/1inch-token.png?1608803028',
    decimals: 18,
    address: '0x111111111117dc0aa78b770fa6a738034120c302'
  },
  '0x8888801af4d980682e47f1a9036e589479e835c5': {
    id: '88mph',
    symbol: 'mph',
    name: '88mph',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13137/large/yfU-_Tcj_400x400.png?1605581509',
    decimals: 18,
    address: '0x8888801af4d980682e47f1a9036e589479e835c5'
  },
  '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': {
    id: 'aave',
    symbol: 'AAVE',
    name: 'Aave',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12645/large/AAVE.png?1601374110',
    decimals: 18,
    address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9'
  },
  '0x3f382dbd960e3a9bbceae22651e88158d2791550': {
    id: 'aavegotchi',
    symbol: 'ghst',
    name: 'Aavegotchi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12467/large/ghst_200.png?1600750321',
    decimals: 18,
    address: '0x3f382dbd960e3a9bbceae22651e88158d2791550'
  },
  '0xade00c28244d5ce17d72e40330b1c318cd12b7c3': {
    id: 'adex',
    symbol: 'adx',
    name: 'AdEx',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/847/large/adex.png?1547034643',
    decimals: 18,
    address: '0xade00c28244d5ce17d72e40330b1c318cd12b7c3'
  },
  '0xbf2179859fc6d5bee9bf9158632dc51678a4100e': {
    id: 'aelf',
    symbol: 'elf',
    name: 'elf',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1371/large/aelf-logo.png?1547035397',
    decimals: 18,
    address: '0xbf2179859fc6d5bee9bf9158632dc51678a4100e'
  },
  '0x91af0fbb28aba7e31403cb457106ce79397fd4e6': {
    id: 'aergo',
    symbol: 'aergo',
    name: 'Aergo',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4490/large/Aergo.png?1558084282',
    decimals: 18,
    address: '0x91af0fbb28aba7e31403cb457106ce79397fd4e6'
  },
  '0x5ca9a71b1d01849c0a95490cc00559717fcf0d1d': {
    id: 'aeternity',
    symbol: 'ae',
    name: 'Aeternity',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1091/large/aeternity.png?1547035060',
    decimals: 18,
    address: '0x5ca9a71b1d01849c0a95490cc00559717fcf0d1d'
  },
  '0x2d80f5f5328fdcb6eceb7cacf5dd8aedaec94e20': {
    id: 'aga-token',
    symbol: 'aga',
    name: 'AGA Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12180/large/aga-logo.png?1597937396',
    decimals: 4,
    address: '0x2d80f5f5328fdcb6eceb7cacf5dd8aedaec94e20'
  },
  '0x07e3c70653548b04f0a75970c1f81b4cbbfb606f': {
    id: 'agrello',
    symbol: 'dlt',
    name: 'Agrello',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/900/large/Agrello.png?1558084369',
    decimals: 18,
    address: '0x07e3c70653548b04f0a75970c1f81b4cbbfb606f'
  },
  '0x27054b13b1b798b345b591a4d22e6562d47ea75a': {
    id: 'airswap',
    symbol: 'ast',
    name: 'AirSwap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1019/large/AST.png?1547034939',
    decimals: 4,
    address: '0x27054b13b1b798b345b591a4d22e6562d47ea75a'
  },
  '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7': {
    id: 'akropolis',
    symbol: 'akro',
    name: 'Akropolis',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3328/large/Akropolis.png?1547037929',
    decimals: 18,
    address: '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7'
  },
  '0xed04915c23f00a313a544955524eb7dbd823143d': {
    id: 'alchemy-pay',
    symbol: 'ach',
    name: 'Alchemy Pay',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12390/large/ACH_%281%29.png?1599691266',
    decimals: 8,
    address: '0xed04915c23f00a313a544955524eb7dbd823143d'
  },
  '0x27702a26126e0b3702af63ee09ac4d1a084ef628': {
    id: 'aleph',
    symbol: 'aleph',
    name: 'Aleph.im',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11676/large/Monochram-aleph.png?1608483725',
    decimals: 18,
    address: '0x27702a26126e0b3702af63ee09ac4d1a084ef628'
  },
  '0x2d0e95bd4795d7ace0da3c0ff7b706a5970eb9d3': {
    id: 'all-sports',
    symbol: 'soc',
    name: 'All Sports',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2697/large/all-sports.png?1547036904',
    decimals: 18,
    address: '0x2d0e95bd4795d7ace0da3c0ff7b706a5970eb9d3'
  },
  '0x00a8b738e453ffd858a7edf03bccfe20412f0eb0': {
    id: 'allianceblock',
    symbol: 'albt',
    name: 'AllianceBlock',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12392/large/alliance_block_logo.jpg?1599546617',
    decimals: 18,
    address: '0x00a8b738e453ffd858a7edf03bccfe20412f0eb0'
  },
  '0xa1faa113cbe53436df28ff0aee54275c13b40975': {
    id: 'alpha-finance',
    symbol: 'alpha',
    name: 'Alpha Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12738/large/ec7316b1-8bd9-4a76-8a1e-2d5c0b287d2f.jpg?1602306985',
    decimals: 18,
    address: '0xa1faa113cbe53436df28ff0aee54275c13b40975'
  },
  '0x2a9bdcff37ab68b95a53435adfd8892e86084f93': {
    id: 'alpha-quark-token',
    symbol: 'aqt',
    name: 'Alpha Quark Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12872/large/alpha_quark_logo.png?1605169527',
    decimals: 18,
    address: '0x2a9bdcff37ab68b95a53435adfd8892e86084f93'
  },
  '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95': {
    id: 'apeswap-finance',
    symbol: 'BANANA',
    name: 'BANANA',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/banana.svg',
    decimals: 18,
    address: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95'
  },
  '0xddb3bd8645775f59496c821e4f55a7ea6a6dc299': {
    id: 'gnana',
    symbol: 'GNANA',
    name: 'Golden Banana',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://apeswap.finance/images/golden-banana.svg',
    decimals: 18,
    address: '0xddb3bd8645775f59496c821e4f55a7ea6a6dc299'
  },
  '0x4dc3643dbc642b72c158e7f3d2ff232df61cb6ce': {
    id: 'amber',
    symbol: 'amb',
    name: 'Ambrosus',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1041/large/_nSEjidJ_400x400.jpg?1593158410',
    decimals: 18,
    address: '0x4dc3643dbc642b72c158e7f3d2ff232df61cb6ce'
  },
  '0xff20817765cb7f73d4bde2e66e067e58d11095c2': {
    id: 'amp-token',
    symbol: 'amp',
    name: 'Amp',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12409/large/amp-200x200.png?1599625397',
    decimals: 18,
    address: '0xff20817765cb7f73d4bde2e66e067e58d11095c2'
  },
  '0xd46ba6d942050d489dbd938a2c909a5d5039a161': {
    id: 'ampleforth',
    symbol: 'ampl',
    name: 'Ampleforth',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4708/large/Ampleforth.png?1561684250',
    decimals: 9,
    address: '0xd46ba6d942050d489dbd938a2c909a5d5039a161'
  },
  '0xcd62b1c403fa761baadfc74c525ce2b51780b184': {
    id: 'anj',
    symbol: 'anj',
    name: 'Aragon Court',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10765/large/ANJ.png?1588956187',
    decimals: 18,
    address: '0xcd62b1c403fa761baadfc74c525ce2b51780b184'
  },
  '0x8290333cef9e6d528dd5618fb97a76f268f3edd4': {
    id: 'ankr',
    symbol: 'ankr',
    name: 'Ankr',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4324/large/U85xTl2.png?1608111978',
    decimals: 18,
    address: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4'
  },
  '0xe95a203b1a91a908f9b9ce46459d101078c2c3cb': {
    id: 'ankreth',
    symbol: 'aeth',
    name: 'ankrETH',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13403/large/ankr.png?1608764406',
    decimals: 18,
    address: '0xe95a203b1a91a908f9b9ce46459d101078c2c3cb'
  },
  '0xf99d58e463a2e07e5692127302c20a191861b4d6': {
    id: 'anyswap',
    symbol: 'any',
    name: 'Anyswap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12242/large/anyswap.jpg?1598443880',
    decimals: 18,
    address: '0xf99d58e463a2e07e5692127302c20a191861b4d6'
  },
  '0x0b38210ea11411557c13457d4da7dc6ea731b88a': {
    id: 'api3',
    symbol: 'api3',
    name: 'API3',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13256/large/api3.jpg?1606751424',
    decimals: 18,
    address: '0x0b38210ea11411557c13457d4da7dc6ea731b88a'
  },
  '0xc8c424b91d8ce0137bab4b832b7f7d154156ba6c': {
    id: 'apm-coin',
    symbol: 'APM',
    name: 'apM Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10069/large/PvDCvh5h_400x400.png?1575460334',
    decimals: 18,
    address: '0xc8c424b91d8ce0137bab4b832b7f7d154156ba6c'
  },
  '0x1a7a8bd9106f2b8d977e08582dc7d24c723ab0db': {
    id: 'appcoins',
    symbol: 'appc',
    name: 'AppCoins',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1876/large/appcoins.png?1547036186',
    decimals: 18,
    address: '0x1a7a8bd9106f2b8d977e08582dc7d24c723ab0db'
  },
  '0x95a4492f028aa1fd432ea71146b433e7b4446611': {
    id: 'apy-finance',
    symbol: 'apy',
    name: 'APY.Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13041/large/1*AvkD-OLocausbxqUzezZ0A.png?1604577922',
    decimals: 18,
    address: '0x95a4492f028aa1fd432ea71146b433e7b4446611'
  },
  '0xa117000000f279d81a1d3cc75430faa017fa5a2e': {
    id: 'aragon',
    symbol: 'ant',
    name: 'Aragon',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/681/large/JelZ58cv_400x400.png?1601449653',
    decimals: 18,
    address: '0xa117000000f279d81a1d3cc75430faa017fa5a2e'
  },
  '0xb98d4c97425d9908e66e53a6fdf673acca0be986': {
    id: 'arcblock',
    symbol: 'abt',
    name: 'Arcblock',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2341/large/arcblock.png?1547036543',
    decimals: 18,
    address: '0xb98d4c97425d9908e66e53a6fdf673acca0be986'
  },
  '0x1f3f9d3068568f8040775be2e8c03c103c61f3af': {
    id: 'archer-dao-governance-token',
    symbol: 'arch',
    name: 'Archer DAO Governance Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13188/large/archer_logo.png?1606097487',
    decimals: 18,
    address: '0x1f3f9d3068568f8040775be2e8c03c103c61f3af'
  },
  '0x1337def16f9b486faed0293eb623dc8395dfe46a': {
    id: 'armor',
    symbol: 'armor',
    name: 'ARMOR',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13748/large/armor.png?1611425846',
    decimals: 18,
    address: '0x1337def16f9b486faed0293eb623dc8395dfe46a'
  },
  '0xba50933c268f567bdc86e1ac131be072c6b0b71a': {
    id: 'arpa-chain',
    symbol: 'arpa',
    name: 'ARPA Chain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8506/large/9u0a23XY_400x400.jpg?1559027357',
    decimals: 18,
    address: '0xba50933c268f567bdc86e1ac131be072c6b0b71a'
  },
  '0xeeee2a622330e6d2036691e983dee87330588603': {
    id: 'askobar-network',
    symbol: 'asko',
    name: 'Asko',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11773/large/Asko_Logo_%28200x200%29.png?1604706371',
    decimals: 18,
    address: '0xeeee2a622330e6d2036691e983dee87330588603'
  },
  '0xdacd69347de42babfaecd09dc88958378780fb62': {
    id: 'atari',
    symbol: 'atri',
    name: 'Atari',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12992/large/atari.png?1604212345',
    decimals: 0,
    address: '0xdacd69347de42babfaecd09dc88958378780fb62'
  },
  '0xc12d099be31567add4e4e4d0d45691c3f58f5663': {
    id: 'auctus',
    symbol: 'auc',
    name: 'Auctus',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2165/large/Auctus.png?1593479271',
    decimals: 18,
    address: '0xc12d099be31567add4e4e4d0d45691c3f58f5663'
  },
  '0x18aaa7115705e8be94bffebde57af9bfc265b998': {
    id: 'audius',
    symbol: 'audio',
    name: 'Audius',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12913/large/AudiusCoinLogo_2x.png?1603425727',
    decimals: 18,
    address: '0x18aaa7115705e8be94bffebde57af9bfc265b998'
  },
  '0x221657776846890989a759ba2973e427dff5c9bb': {
    id: 'augur',
    symbol: 'rep',
    name: 'Augur',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/309/large/REP.png?1596339859',
    decimals: 18,
    address: '0x221657776846890989a759ba2973e427dff5c9bb'
  },
  '0x9ab165d795019b6d8b3e971dda91071421305e5a': {
    id: 'aurora',
    symbol: 'aoa',
    name: 'Aurora',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4755/large/aurora-logo.png?1547040076',
    decimals: 18,
    address: '0x9ab165d795019b6d8b3e971dda91071421305e5a'
  },
  '0xb705268213d593b8fd88d3fdeff93aff5cbdcfae': {
    id: 'aurora-dao',
    symbol: 'idex',
    name: 'IDEX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2565/large/49046004.png?1557813562',
    decimals: 18,
    address: '0xb705268213d593b8fd88d3fdeff93aff5cbdcfae'
  },
  '0xa184088a740c695e156f91f5cc086a06bb78b827': {
    id: 'auto',
    symbol: 'AUTO',
    name: 'Auto',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13751/large/autofarm_icon_200x200.png?1611494288',
    decimals: 18,
    address: '0xa184088a740c695e156f91f5cc086a06bb78b827'
  },
  '0x0f02b1f5af54e04fb6dd6550f009ac2429c4e30d': {
    id: 'finix',
    symbol: 'FINIX',
    name: 'finix',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://bsc.definix.com/images/coins/finix.png',
    decimals: 18,
    address: '0x0f02b1f5af54e04fb6dd6550f009ac2429c4e30d'
  },
  '0x070a9867ea49ce7afc4505817204860e823489fe': {
    id: 'six-network',
    symbol: 'SIX',
    name: 'SIX Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://bsc.definix.com/images/coins/six.png',
    decimals: 18,
    address: '0x070a9867ea49ce7afc4505817204860e823489fe'
  },
  '0x9c65ab58d8d978db963e63f2bfb7121627e3a739': {
    id: 'mdex',
    symbol: 'MDX',
    name: 'Mdex',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13775/large/mdex.png?1611739676',
    decimals: 18,
    address: '0x9c65ab58d8d978db963e63f2bfb7121627e3a739'
  },
  '0xc813ea5e3b48bebeedb796ab42a30c5599b01740': {
    id: 'autonio',
    symbol: 'niox',
    name: 'Autonio',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1122/large/NewLogo.png?1597298450',
    decimals: 4,
    address: '0xc813ea5e3b48bebeedb796ab42a30c5599b01740'
  },
  '0x0d88ed6e74bbfd96b831231638b66c05571e824f': {
    id: 'aventus',
    symbol: 'avt',
    name: 'Aventus',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/901/large/Aventus_Vertical_Logo_Dark_1500px.png?1612494414',
    decimals: 18,
    address: '0x0d88ed6e74bbfd96b831231638b66c05571e824f'
  },
  '0xf5d669627376ebd411e34b98f19c868c8aba5ada': {
    id: 'axie-infinity',
    symbol: 'AXS',
    name: 'Axie Infinity',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png?1604471082',
    decimals: 18,
    address: '0xf5d669627376ebd411e34b98f19c868c8aba5ada'
  },
  '0x97a9107c1793bc407d6f527b77e7fff4d812bece': {
    id: 'axie-infinity',
    symbol: 'AXS',
    name: 'Axie Infinity',
    chain: Chain.RONIN,
    logo: 'https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png?1604471082',
    decimals: 18,
    address: '0x97a9107c1793bc407d6f527b77e7fff4d812bece'
  },
  '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0': {
    id: 'axie-infinity',
    symbol: 'AXS',
    name: 'Axie Infinity',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png?1604471082',
    decimals: 18,
    address: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0'
  },
  '0x71f85b2e46976bd21302b64329868fd15eb0d127': {
    id: 'axion',
    symbol: 'axn',
    name: 'Axion',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13110/large/AXION_Logo.png?1609309261',
    decimals: 18,
    address: '0x71f85b2e46976bd21302b64329868fd15eb0d127'
  },
  '0x3472a5a71965499acd81997a54bba8d852c6e53d': {
    id: 'badger-dao',
    symbol: 'badger',
    name: 'Badger DAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13287/large/badger_dao_logo.jpg?1607054976',
    decimals: 18,
    address: '0x3472a5a71965499acd81997a54bba8d852c6e53d'
  },
  '0x1f7216fdb338247512ec99715587bb97bbf96eae': {
    id: 'badger-sett-badger',
    symbol: 'bbadger',
    name: 'Badger Sett Badger',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14502/small/BADGER_DAO.png?1616559959',
    decimals: 18,
    address: '0x1f7216fdb338247512ec99715587bb97bbf96eae'
  },
  '0xba100000625a3754423978a60c9317c58a424e3d': {
    id: 'balancer',
    symbol: 'BAL',
    name: 'Balancer',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
    decimals: 18,
    address: '0xba100000625a3754423978a60c9317c58a424e3d'
  },
  '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c': {
    id: 'bancor',
    symbol: 'bnt',
    name: 'Bancor Network Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/736/large/bancor.png?1547034477',
    decimals: 18,
    address: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c'
  },
  '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55': {
    id: 'band-protocol',
    symbol: 'band',
    name: 'Band Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9545/large/band-protocol.png?1568730326',
    decimals: 18,
    address: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55'
  },
  '0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18': {
    id: 'band-protocol',
    symbol: 'BAND',
    name: 'Binance-Peg Band Protocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/9545/large/band-protocol.png?1568730326',
    decimals: 18,
    address: '0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18'
  },
  '0x374cb8c27130e2c9e04f44303f3c8351b9de61c1': {
    id: 'bao-finance',
    symbol: 'BAO',
    name: 'Bao Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13547/large/bao.PNG?1609713518',
    decimals: 18,
    address: '0x374cb8c27130e2c9e04f44303f3c8351b9de61c1'
  },
  '0x0391d2021f89dc339f60fff84546ea23e337750f': {
    id: 'barnbridge',
    symbol: 'bond',
    name: 'BarnBridge',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12811/large/barnbridge.jpg?1602728853',
    decimals: 18,
    address: '0x0391d2021f89dc339f60fff84546ea23e337750f'
  },
  '0x07150e919b4de5fd6a63de1f9384828396f25fdc': {
    id: 'base-protocol',
    symbol: 'base',
    name: 'Base Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13265/large/200x200green.png?1607650121',
    decimals: 9,
    address: '0x07150e919b4de5fd6a63de1f9384828396f25fdc'
  },
  '0x68a118ef45063051eac49c7e647ce5ace48a68a5': {
    id: 'based-money',
    symbol: '$based',
    name: 'Based Money',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12115/large/Based.png?1597261198',
    decimals: 18,
    address: '0x68a118ef45063051eac49c7e647ce5ace48a68a5'
  },
  '0x0d8775f648430679a709e98d2b0cb6250d2887ef': {
    id: 'basic-attention-token',
    symbol: 'bat',
    name: 'Basic Attention Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/677/large/basic-attention-token.png?1547034427',
    decimals: 18,
    address: '0x0d8775f648430679a709e98d2b0cb6250d2887ef'
  },
  '0x101d82428437127bf1608f699cd651e6abf9766e': {
    id: 'basic-attention-token',
    symbol: 'bat',
    name: 'Binance-Peg Basic Attention',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/677/large/basic-attention-token.png?1547034427',
    decimals: 18,
    address: '0x101d82428437127bf1608f699cd651e6abf9766e'
  },
  '0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a': {
    id: 'basis-cash',
    symbol: 'bac',
    name: 'Basis Cash',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13246/large/BAC.jpg?1606708629',
    decimals: 18,
    address: '0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a'
  },
  '0xa7ed29b253d8b4e3109ce07c80fc570f81b63696': {
    id: 'basis-share',
    symbol: 'bas',
    name: 'Basis Share',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13251/large/BAS.png?1606718334',
    decimals: 18,
    address: '0xa7ed29b253d8b4e3109ce07c80fc570f81b63696'
  },
  '0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5': {
    id: 'blockchain-certified-data-token',
    symbol: 'BCDT',
    name: 'BCdiploma-EvidenZ',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2014/large/evidenz-512.png?1594871754',
    decimals: 18,
    address: '0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5'
  },
  '0xca3f508b8e4dd382ee878a314789373d80a5190a': {
    id: 'beefy-finance',
    symbol: 'BIFI',
    name: 'Beefy.Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12704/large/token.png?1601876182',
    decimals: 18,
    address: '0xca3f508b8e4dd382ee878a314789373d80a5190a'
  },
  '0xfbdd194376de19a88118e84e279b977f165d01b8': {
    id: 'beefy-finance',
    symbol: 'BIFI',
    name: 'Beefy.Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12704/large/token.png?1601876182',
    decimals: 18,
    address: '0xfbdd194376de19a88118e84e279b977f165d01b8'
  },
  '0xa91ac63d040deb1b7a5e4d4134ad23eb0ba07e14': {
    id: 'bella-protocol',
    symbol: 'bel',
    name: 'Bella Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12478/large/Bella.png?1602230054',
    decimals: 18,
    address: '0xa91ac63d040deb1b7a5e4d4134ad23eb0ba07e14'
  },
  '0x67c597624b17b16fb77959217360b7cd18284253': {
    id: 'benchmark-protocol',
    symbol: 'mark',
    name: 'Benchmark Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13212/large/benchmark_protocol.jpg?1606267583',
    decimals: 9,
    address: '0x67c597624b17b16fb77959217360b7cd18284253'
  },
  '0xf7e04d8a32229b4ca63aa51eea9979c7287fea48': {
    id: 'beowulf',
    symbol: 'bwf',
    name: 'Beowulf',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12586/large/BWF.png?1600932145',
    decimals: 5,
    address: '0xf7e04d8a32229b4ca63aa51eea9979c7287fea48'
  },
  '0xb3104b4b9da82025e8b9f8fb28b3553ce2f67069': {
    id: 'bibox-token',
    symbol: 'bix',
    name: 'Bibox Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1441/large/bibox-token.png?1547035519',
    decimals: 18,
    address: '0xb3104b4b9da82025e8b9f8fb28b3553ce2f67069'
  },
  '0x2791bfd60d232150bff86b39b7146c0eaaa2ba81': {
    id: 'bifi',
    symbol: 'bifi',
    name: 'BiFi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13671/large/ysYIu7Q.png?1610679337',
    decimals: 18,
    address: '0x2791bfd60d232150bff86b39b7146c0eaaa2ba81'
  },
  '0x0c7d5ae016f806603cb1782bea29ac69471cab9c': {
    id: 'bifrost',
    symbol: 'bfc',
    name: 'Bifrost',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4639/large/bifrost_32.png?1608520677',
    decimals: 18,
    address: '0x0c7d5ae016f806603cb1782bea29ac69471cab9c'
  },
  '0x2c537e5624e4af88a7ae4060c022609376c8d0eb': {
    id: 'bilira',
    symbol: 'tryb',
    name: 'BiLira',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10119/large/v1bIhvRr_400x400.png?1576359242',
    decimals: 6,
    address: '0x2c537e5624e4af88a7ae4060c022609376c8d0eb'
  },
  '0x4fabb145d64652a948d72533023f6e7a623c7c53': {
    id: 'binance-usd',
    symbol: 'BUSD',
    name: 'Binance USD',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9576/large/BUSD.png?1568947766',
    decimals: 18,
    address: '0x4fabb145d64652a948d72533023f6e7a623c7c53'
  },
  '0xe9e7cea3dedca5984780bafc599bd69add087d56': {
    id: 'binance-usd',
    symbol: 'BUSD',
    name: 'Binance USD',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/9576/large/BUSD.png?1568947766',
    decimals: 18,
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56'
  },

  '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': {
    id: 'binancecoin',
    symbol: 'WBNB',
    name: 'Binance Coin',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png?1547034615',
    decimals: 18,
    address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
  },
  '0x70401dfd142a16dc7031c56e862fc88cb9537ce0': {
    id: 'bird-money',
    symbol: 'bird',
    name: 'Bird.Money',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13260/large/favicon-180x180.png?1611546646',
    decimals: 18,
    address: '0x70401dfd142a16dc7031c56e862fc88cb9537ce0'
  },
  '0x4375e7ad8a01b8ec3ed041399f62d9cd120e0063': {
    id: 'bit-z-token',
    symbol: 'bz',
    name: 'Bit-Z Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4774/large/bit-z-logo.png?1547274387',
    decimals: 18,
    address: '0x4375e7ad8a01b8ec3ed041399f62d9cd120e0063'
  },
  '0x06e0feb0d74106c7ada8497754074d222ec6bcdf': {
    id: 'bitball',
    symbol: 'btb',
    name: 'Bitball',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7039/large/BitBall_png_.png?1588640849',
    decimals: 18,
    address: '0x06e0feb0d74106c7ada8497754074d222ec6bcdf'
  },
  '0x986ee2b944c42d017f52af21c4c69b84dbea35d8': {
    id: 'bitmart-token',
    symbol: 'bmx',
    name: 'BitMart Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5236/large/bitmart-token.png?1548835017',
    decimals: 18,
    address: '0x986ee2b944c42d017f52af21c4c69b84dbea35d8'
  },
  '0x4efe8665e564bf454ccf5c90ee16817f7485d5cf': {
    id: 'blackdragon-token',
    symbol: 'BDT',
    name: 'BlackDragon Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13426/large/Black-Dragon-Black.png?1608515220',
    decimals: 18,
    address: '0x4efe8665e564bf454ccf5c90ee16817f7485d5cf'
  },
  '0x211ffbe424b90e25a15531ca322adf1559779e45': {
    id: 'blockport',
    symbol: 'bux',
    name: 'BUX Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/2316/large/BUXC.png?1600760731',
    decimals: 18,
    address: '0x211ffbe424b90e25a15531ca322adf1559779e45'
  },
  '0x340d2bde5eb28c1eed91b2f790723e3b160613b7': {
    id: 'blockv',
    symbol: 'vee',
    name: 'BLOCKv',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1266/large/blockv.png?1547351380',
    decimals: 18,
    address: '0x340d2bde5eb28c1eed91b2f790723e3b160613b7'
  },
  '0x107c4504cd79c5d2696ea0030a8dd4e92601b82e': {
    id: 'bloom',
    symbol: 'blt',
    name: 'Bloom',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2662/large/bloom.png?1547036854',
    decimals: 18,
    address: '0x107c4504cd79c5d2696ea0030a8dd4e92601b82e'
  },
  '0x177d39ac676ed1c67a2b268ad7f1e58826e5b0af': {
    id: 'blox',
    symbol: 'cdt',
    name: 'Blox',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1231/large/Blox_Staking_Logo_2.png?1609117544',
    decimals: 18,
    address: '0x177d39ac676ed1c67a2b268ad7f1e58826e5b0af'
  },
  '0x5732046a883704404f284ce41ffadd5b007fd668': {
    id: 'bluzelle',
    symbol: 'blz',
    name: 'Bluzelle',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2848/large/bluzelle.png?1547351698',
    decimals: 18,
    address: '0x5732046a883704404f284ce41ffadd5b007fd668'
  },
  '0xcca0c9c383076649604ee31b20248bc04fdf61ca': {
    id: 'bmax',
    symbol: 'btmx',
    name: 'Bitmax Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5003/large/BTMX.jpg?1547040429',
    decimals: 18,
    address: '0xcca0c9c383076649604ee31b20248bc04fdf61ca'
  },
  '0x01ff50f8b7f74e4f00580d9596cd3d0d6d6e326f': {
    id: 'bnktothefuture',
    symbol: 'bft',
    name: 'BnkToTheFuture',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3468/large/bnktothefuture.png?1547351865',
    decimals: 18,
    address: '0x01ff50f8b7f74e4f00580d9596cd3d0d6d6e326f'
  },
  '0x5dc02ea99285e17656b8350722694c35154db1e8': {
    id: 'bonded-finance',
    symbol: 'bond',
    name: 'Bonded Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13222/large/bonded_finance_logo.png?1606318433',
    decimals: 8,
    address: '0x5dc02ea99285e17656b8350722694c35154db1e8'
  },
  '0xd2dda223b2617cb616c1580db421e4cfae6a8a85': {
    id: 'bondly',
    symbol: 'bondly',
    name: 'Bondly',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13322/large/logomark.png?1607474416',
    decimals: 18,
    address: '0xd2dda223b2617cb616c1580db421e4cfae6a8a85'
  },
  '0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9': {
    id: 'boringdao',
    symbol: 'bor',
    name: 'BoringDAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12917/large/bor_logo.png?1603607502',
    decimals: 18,
    address: '0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9'
  },
  '0x746dda2ea243400d5a63e0700f190ab79f06489e': {
    id: 'bosagora',
    symbol: 'boa',
    name: 'BOSAGORA',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9202/large/boaLogo.png?1565231346',
    decimals: 7,
    address: '0x746dda2ea243400d5a63e0700f190ab79f06489e'
  },
  '0x5beabaebb3146685dd74176f68a0721f91297d37': {
    id: 'bounce-token',
    symbol: 'bot',
    name: 'Bounce',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11984/large/photo_2020-10-19_09.17.57.jpeg?1603070366',
    decimals: 18,
    address: '0x5beabaebb3146685dd74176f68a0721f91297d37'
  },
  '0xd945d2031b4c63c0e363304fb771f709b502dc0a': {
    id: 'bountymarketcap',
    symbol: 'bmc',
    name: 'BountyMarketCap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13472/large/bmc-logo-200.png?1608852915',
    decimals: 18,
    address: '0xd945d2031b4c63c0e363304fb771f709b502dc0a'
  },
  '0x558ec3152e2eb2174905cd19aea4e34a23de9ad6': {
    id: 'bread',
    symbol: 'brd',
    name: 'Bread',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1440/large/bread.png?1547563238',
    decimals: 18,
    address: '0x558ec3152e2eb2174905cd19aea4e34a23de9ad6'
  },
  '0x725c263e32c72ddc3a19bea12c5a0479a81ee688': {
    id: 'bridge-mutual',
    symbol: 'bmi',
    name: 'Bridge Mutual',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13808/large/bmi_logo.png?1612009598',
    decimals: 18,
    address: '0x725c263e32c72ddc3a19bea12c5a0479a81ee688'
  },
  '0x78650b139471520656b9e7aa7a5e9276814a38e9': {
    id: 'btc-standard-hashrate-token',
    symbol: 'btcst',
    name: 'BTC Standard Hashrate Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13636/large/btcst-coin.png?1610501705',
    decimals: 18,
    address: '0x78650b139471520656b9e7aa7a5e9276814a38e9'
  },
  '0x666d875c600aa06ac1cf15641361dec3b00432ef': {
    id: 'btse-token',
    symbol: 'btse',
    name: 'BTSE Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10807/large/BTSE_logo_Square.jpeg?1583965964',
    decimals: 8,
    address: '0x666d875c600aa06ac1cf15641361dec3b00432ef'
  },
  '0xb683d83a532e2cb7dfa5275eed3698436371cc9f': {
    id: 'btu-protocol',
    symbol: 'btu',
    name: 'BTU Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3697/large/btuprotocol.jpeg?1549597021',
    decimals: 18,
    address: '0xb683d83a532e2cb7dfa5275eed3698436371cc9f'
  },
  '0x6e36556b3ee5aa28def2a8ec3dae30ec2b208739': {
    id: 'build-finance',
    symbol: 'build',
    name: 'BUILD Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12380/large/build.PNG?1599463828',
    decimals: 18,
    address: '0x6e36556b3ee5aa28def2a8ec3dae30ec2b208739'
  },
  '0x56d811088235f11c8920698a204a5010a788f4b3': {
    id: 'bzx-protocol',
    symbol: 'bzrx',
    name: 'bZx Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11811/large/bzrx.png?1594563172',
    decimals: 18,
    address: '0x56d811088235f11c8920698a204a5010a788f4b3'
  },
  '0x43044f861ec040db59a7e324c40507addb673142': {
    id: 'cap',
    symbol: 'cap',
    name: 'Cap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11775/large/CAP.png?1594083244',
    decimals: 18,
    address: '0x43044f861ec040db59a7e324c40507addb673142'
  },
  '0xcdeee767bed58c5325f68500115d4b722b3724ee': {
    id: 'carbon',
    symbol: 'crbn',
    name: 'Carbon',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13262/large/CRBN_Icon_200.png?1606803750',
    decimals: 18,
    address: '0xcdeee767bed58c5325f68500115d4b722b3724ee'
  },
  '0x954b890704693af242613edef1b603825afcd708': {
    id: 'cardstack',
    symbol: 'card',
    name: 'Cardstack',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3247/large/cardstack.png?1547037769',
    decimals: 18,
    address: '0x954b890704693af242613edef1b603825afcd708'
  },
  '0xb6ee9668771a79be7967ee29a63d4184f8097143': {
    id: 'cargox',
    symbol: 'cxo',
    name: 'CargoX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2580/large/cargox.png?1547738832',
    decimals: 18,
    address: '0xb6ee9668771a79be7967ee29a63d4184f8097143'
  },
  '0x115ec79f1de567ec68b7ae7eda501b406626478e': {
    id: 'carry',
    symbol: 'cre',
    name: 'Carry',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3994/large/Carry.png?1592402610',
    decimals: 18,
    address: '0x115ec79f1de567ec68b7ae7eda501b406626478e'
  },
  '0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d': {
    id: 'cartesi',
    symbol: 'ctsi',
    name: 'Cartesi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11038/large/cartesi.png?1592288021',
    decimals: 18,
    address: '0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d'
  },
  '0x50bc2ecc0bfdf5666640048038c1aba7b7525683': {
    id: 'carvertical',
    symbol: 'cv',
    name: 'carVertical',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2098/large/carvertical.png?1547738903',
    decimals: 18,
    address: '0x50bc2ecc0bfdf5666640048038c1aba7b7525683'
  },
  '0x26db5439f651caf491a87d48799da81f191bdb6b': {
    id: 'cashbet-coin',
    symbol: 'cbc',
    name: 'Casino Betting Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2477/large/Elj3BV73_400x400.jpg?1605753220',
    decimals: 8,
    address: '0x26db5439f651caf491a87d48799da81f191bdb6b'
  },
  '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643': {
    id: 'cdai',
    symbol: 'cdai',
    name: 'cDAI',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9281/large/cDAI.png?1576467585',
    decimals: 8,
    address: '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643'
  },
  '0x4f9254c83eb525f9fcf346490bbb3ed28a81c667': {
    id: 'celer-network',
    symbol: 'celr',
    name: 'Celer Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4379/large/Celr.png?1554705437',
    decimals: 18,
    address: '0x4f9254c83eb525f9fcf346490bbb3ed28a81c667'
  },
  '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d': {
    id: 'celsius-degree-token',
    symbol: 'cel',
    name: 'Celsius Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3263/large/CEL_logo.png?1609598753',
    decimals: 4,
    address: '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d'
  },
  '0x03042482d64577a7bdb282260e2ea4c8a89c064b': {
    id: 'centaur',
    symbol: 'cntr',
    name: 'Centaur',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12743/large/logo_%2898%29.png?1602630445',
    decimals: 18,
    address: '0x03042482d64577a7bdb282260e2ea4c8a89c064b'
  },
  '0xc4c2614e694cf534d407ee49f8e44d125e4681c4': {
    id: 'chain-games',
    symbol: 'chain',
    name: 'Chain Games',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12257/large/chain-logo-centered-500x500.png?1599617244',
    decimals: 18,
    address: '0xc4c2614e694cf534d407ee49f8e44d125e4681c4'
  },
  '0x514910771af9ca656af840dff83e8264ecf986ca': {
    id: 'chainlink',
    symbol: 'LINK',
    name: 'Chainlink',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700',
    decimals: 18,
    address: '0x514910771af9ca656af840dff83e8264ecf986ca'
  },
  // '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd': {
  //   id: 'chainlink',
  //   symbol: 'link',
  //   name: 'Chainlink',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700',
  //   decimals: 18,
  //   address: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd'
  // },
  '0x0000000000004946c0e9f43f4dee607b0ef1fa1c': {
    id: 'chi-gastoken',
    symbol: 'chi',
    name: 'Chi Gastoken',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11583/large/chi.png?1591331659',
    decimals: 0,
    address: '0x0000000000004946c0e9f43f4dee607b0ef1fa1c'
  },
  '0x3506424f91fd33084466f402d5d97f05f8e3b4af': {
    id: 'chiliz',
    symbol: 'chz',
    name: 'Chiliz',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8834/large/Chiliz.png?1561970540',
    decimals: 18,
    address: '0x3506424f91fd33084466f402d5d97f05f8e3b4af'
  },
  '0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2': {
    id: 'chromaway',
    symbol: 'chr',
    name: 'Chromia',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5000/large/Chromia.png?1559038018',
    decimals: 6,
    address: '0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2'
  },
  '0xd4c435f5b09f855c3317c8524cb1f586e42795fa': {
    id: 'cindicator',
    symbol: 'cnd',
    name: 'Cindicator',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1006/large/cindicator.png?1547034913',
    decimals: 18,
    address: '0xd4c435f5b09f855c3317c8524cb1f586e42795fa'
  },
  '0x41e5560054824ea6b0732e656e3ad64e20e94e45': {
    id: 'civic',
    symbol: 'cvc',
    name: 'Civic',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/788/large/civic.png?1547034556',
    decimals: 8,
    address: '0x41e5560054824ea6b0732e656e3ad64e20e94e45'
  },
  '0x6c3be406174349cfa4501654313d97e6a31072e1': {
    id: 'cnns',
    symbol: 'cnns',
    name: 'CNNS',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8362/large/cnns.png?1557992686',
    decimals: 18,
    address: '0x6c3be406174349cfa4501654313d97e6a31072e1'
  },
  '0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c': {
    id: 'cocos-bcx',
    symbol: 'cocos',
    name: 'COCOS BCX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4932/large/_QPpjoUi_400x400.jpg?1566430520',
    decimals: 18,
    address: '0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c'
  },
  '0x1a23a6bfbadb59fa563008c0fb7cf96dfcf34ea1': {
    id: 'cofix',
    symbol: 'cofi',
    name: 'CoFiX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12781/large/dnPnSkfa_400x400.png?1602885321',
    decimals: 18,
    address: '0x1a23a6bfbadb59fa563008c0fb7cf96dfcf34ea1'
  },
  '0x2c949199cff14aeaf1b33d64db01f48fb57f592f': {
    id: 'coinbene-token',
    symbol: 'coni',
    name: 'Coinbene Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4772/large/CONI_LOGO.png?1612690472',
    decimals: 8,
    address: '0x2c949199cff14aeaf1b33d64db01f48fb57f592f'
  },
  '0x3277dd536471a3cbeb0c9486acad494c95a31e73': {
    id: 'coinhe-token',
    symbol: 'cht',
    name: 'CoinHe Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9239/large/coinhe.png?1565769425',
    decimals: 18,
    address: '0x3277dd536471a3cbeb0c9486acad494c95a31e73'
  },
  '0x36ac219f90f5a6a3c77f2a7b660e3cc701f68e25': {
    id: 'coinmetro',
    symbol: 'xcm',
    name: 'CoinMetro',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3125/large/xcm.png?1547037518',
    decimals: 18,
    address: '0x36ac219f90f5a6a3c77f2a7b660e3cc701f68e25'
  },
  '0xe61fdaf474fac07063f2234fb9e60c1163cfa850': {
    id: 'coinvest',
    symbol: 'coin',
    name: 'Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2394/large/coin.png?1606626874',
    decimals: 18,
    address: '0xe61fdaf474fac07063f2234fb9e60c1163cfa850'
  },
  '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1': {
    id: 'concentrated-voting-power',
    symbol: 'cvp',
    name: 'PowerPool Concentrated Voting Power',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12266/large/Powerpool.jpg?1598621373',
    decimals: 18,
    address: '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1'
  },
  '0xeabb8996ea1662cad2f7fb715127852cd3262ae9': {
    id: 'connect-financial',
    symbol: 'cnfi',
    name: 'Connect Financial',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13592/large/Connect_Financial.png?1609993203',
    decimals: 18,
    address: '0xeabb8996ea1662cad2f7fb715127852cd3262ae9'
  },
  '0x6400b5522f8d448c0803e6245436dd1c81df09ce': {
    id: 'content-value-network',
    symbol: 'cvnt',
    name: 'Content Value Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5158/large/cvn.jpg?1547394846',
    decimals: 8,
    address: '0x6400b5522f8d448c0803e6245436dd1c81df09ce'
  },
  '0x5c872500c00565505f3624ab435c222e558e9ff8': {
    id: 'cotrader',
    symbol: 'cot',
    name: 'CoTrader',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4205/large/logo_black.png?1547039508',
    decimals: 18,
    address: '0x5c872500c00565505f3624ab435c222e558e9ff8'
  },
  '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713': {
    id: 'cover-protocol',
    symbol: 'cover',
    name: 'Cover Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13563/large/1_eWBbDaqpxXqt7WYPSP4qSw.jpeg?1609822578',
    decimals: 18,
    address: '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713'
  },
  '0xada86b1b313d1d5267e3fc0bb303f0a2b66d0ea7': {
    id: 'covesting',
    symbol: 'cov',
    name: 'Covesting',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1950/large/covesting.png?1547036237',
    decimals: 18,
    address: '0xada86b1b313d1d5267e3fc0bb303f0a2b66d0ea7'
  },
  '0xdaab5e695bb0e8ce8384ee56ba38fa8290618e52': {
    id: 'crdt',
    symbol: 'CRDT',
    name: 'CRDT',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11859/large/image_%281%29.png?1600937373',
    decimals: 18,
    address: '0xdaab5e695bb0e8ce8384ee56ba38fa8290618e52'
  },
  '0x0eb3a705fc54725037cc9e008bdede697f62f33a': {
    id: 'cosmos',
    symbol: 'atom',
    name: 'Cosmos',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/1481/small/cosmos_hub.png?1555657960',
    decimals: 18,
    address: '0x0eb3a705fc54725037cc9e008bdede697f62f335'
  },
  '0x2ba592f78db6436527729929aaf6c908497cb200': {
    id: 'cream-2',
    symbol: 'cream',
    name: 'Cream',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11976/large/Cream.png?1596593418',
    decimals: 18,
    address: '0x2ba592f78db6436527729929aaf6c908497cb200'
  },
  '0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888': {
    id: 'cream',
    symbol: 'cream',
    name: 'Cream',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11976/large/Cream.png?1596593418',
    decimals: 18,
    address: '0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888'
  },
  '0xcbc1065255cbc3ab41a6868c22d1f1c573ab89fd': {
    id: 'cream-eth2',
    symbol: 'CRETH2',
    name: 'Cream ETH 2',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13568/small/creth2.png?1609837099',
    decimals: 18,
    address: '0xcbc1065255cbc3ab41a6868c22d1f1c573ab89fd'
  },
  '0x46b9ad944d1059450da1163511069c718f699d31': {
    id: 'credits',
    symbol: 'cs',
    name: 'CREDITS',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1566/large/cs.png?1547035736',
    decimals: 6,
    address: '0x46b9ad944d1059450da1163511069c718f699d31'
  },
  '0x32a7c02e79c4ea1008dd6564b35f131428673c41': {
    id: 'crust-network',
    symbol: 'cru',
    name: 'Crust Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12549/large/sAB3KVzD_400x400.jpg?1600680411',
    decimals: 18,
    address: '0x32a7c02e79c4ea1008dd6564b35f131428673c41'
  },
  '0x08389495d7456e1951ddf7c3a1314a4bfb646d8b': {
    id: 'crypterium',
    symbol: 'crpt',
    name: 'Crypterium',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1901/large/crypt.png?1547036205',
    decimals: 18,
    address: '0x08389495d7456e1951ddf7c3a1314a4bfb646d8b'
  },
  '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b': {
    id: 'crypto-com-chain',
    symbol: 'cro',
    name: 'Crypto.com Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7310/large/cypto.png?1547043960',
    decimals: 8,
    address: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b'
  },
  '0x26e75307fc0c021472feb8f727839531f112f317': {
    id: 'crypto20',
    symbol: 'c20',
    name: 'CRYPTO20',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2097/large/c20.png?1547036413',
    decimals: 18,
    address: '0x26e75307fc0c021472feb8f727839531f112f317'
  },
  '0x17ac188e09a7890a1844e5e65471fe8b0ccfadf3': {
    id: 'cryptocurrency-top-10-tokens-index',
    symbol: 'cc10',
    name: 'Cryptocurrency Top 10 Tokens Index',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13692/large/BRfNDy1.png?1610960481',
    decimals: 18,
    address: '0x17ac188e09a7890a1844e5e65471fe8b0ccfadf3'
  },
  '0x7f0c8b125040f707441cad9e5ed8a8408673b455': {
    id: 'csp-dao-network',
    symbol: 'nebo',
    name: 'CSP DAO Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13436/large/csp_dao.png?1608535699',
    decimals: 18,
    address: '0x7f0c8b125040f707441cad9e5ed8a8408673b455'
  },
  '0x817bbdbc3e8a1204f3691d14bb44992841e3db35': {
    id: 'cudos',
    symbol: 'cudos',
    name: 'Cudos',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13651/large/CudosIconTransparent.png?1610631426',
    decimals: 18,
    address: '0x817bbdbc3e8a1204f3691d14bb44992841e3db35'
  },
  '0xe1c7e30c42c24582888c758984f6e382096786bd': {
    id: 'curate',
    symbol: 'xcur',
    name: 'Curate',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13327/large/XCUR.png?1611628267',
    decimals: 8,
    address: '0xe1c7e30c42c24582888c758984f6e382096786bd'
  },
  '0xd533a949740bb3306d119cc777fa900ba034cd52': {
    id: 'curve-dao-token',
    symbol: 'crv',
    name: 'Curve DAO Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12124/large/Curve.png?1597369484',
    decimals: 18,
    address: '0xd533a949740bb3306d119cc777fa900ba034cd52'
  },
  '0x62359ed7505efc61ff1d56fef82158ccaffa23d7': {
    id: 'cvault-finance',
    symbol: 'core',
    name: 'cVault.finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12635/large/cvault.finance_logo.png?1601353499',
    decimals: 18,
    address: '0x62359ed7505efc61ff1d56fef82158ccaffa23d7'
  },
  '0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4': {
    id: 'cyberfi',
    symbol: 'cfi',
    name: 'CyberFi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13112/large/cyberfi_logo.jpeg?1605283367',
    decimals: 18,
    address: '0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4'
  },
  '0xf85feea2fdd81d51177f6b8f35f0e6734ce45f5f': {
    id: 'cybermiles',
    symbol: 'cmt',
    name: 'CyberMiles',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1300/large/cybermiles.png?1547035327',
    decimals: 18,
    address: '0xf85feea2fdd81d51177f6b8f35f0e6734ce45f5f'
  },
  '0x6b175474e89094c44da98b954eedeac495271d0f': {
    id: 'dai',
    symbol: 'DAI',
    name: 'Dai',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png?1574218774',
    decimals: 18,
    address: '0x6b175474e89094c44da98b954eedeac495271d0f'
  },
  '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3': {
    id: 'dai',
    symbol: 'DAI',
    name: 'Binance-Peg Dai',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png?1574218774',
    decimals: 18,
    address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3'
  },
  '0x0f51bb10119727a7e5ea3538074fb341f56b09ad': {
    id: 'dao-maker',
    symbol: 'dao',
    name: 'DAO Maker',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13915/large/4.png?1612838831',
    decimals: 18,
    address: '0x0f51bb10119727a7e5ea3538074fb341f56b09ad'
  },
  '0x543ff227f64aa17ea132bf9886cab5db55dcaddf': {
    id: 'daostack',
    symbol: 'gen',
    name: 'DAOstack',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3479/large/gen.png?1547038215',
    decimals: 18,
    address: '0x543ff227f64aa17ea132bf9886cab5db55dcaddf'
  },
  '0x9469d013805bffb7d3debe5e7839237e535ec483': {
    id: 'darwinia-network-native-token',
    symbol: 'ring',
    name: 'Darwinia Network Native Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9443/large/iHFgzyCK_400x400.png?1567463393',
    decimals: 18,
    address: '0x9469d013805bffb7d3debe5e7839237e535ec483'
  },
  '0x69b148395ce0015c13e36bffbad63f49ef874e03': {
    id: 'data',
    symbol: 'dta',
    name: 'DATA',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2604/large/data.png?1547036749',
    decimals: 18,
    address: '0x69b148395ce0015c13e36bffbad63f49ef874e03'
  },
  '0x765f0c16d1ddc279295c1a7c24b0883f62d33f75': {
    id: 'databroker-dao',
    symbol: 'dtx',
    name: 'DaTa eXchange Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2920/large/dtx.png?1578973961',
    decimals: 18,
    address: '0x765f0c16d1ddc279295c1a7c24b0883f62d33f75'
  },
  '0x80ab141f324c3d6f2b18b030f1c4e95d4d658778': {
    id: 'dea',
    symbol: 'dea',
    name: 'DEA',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12825/large/dea_logo.jpg?1611136739',
    decimals: 18,
    address: '0x80ab141f324c3d6f2b18b030f1c4e95d4d658778'
  },
  '0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3': {
    id: 'decentr',
    symbol: 'dec',
    name: 'Decentr',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11816/large/Decentr.png?1594637985',
    decimals: 18,
    address: '0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3'
  },
  '0xee06a81a695750e71a662b51066f2c74cf4478a0': {
    id: 'decentral-games',
    symbol: 'dg',
    name: 'Decentral Games',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13267/large/Copy_of_DG_Coin_logo.png?1606834850',
    decimals: 18,
    address: '0xee06a81a695750e71a662b51066f2c74cf4478a0'
  },
  '0x0f5d2fb29fb7d3cfee444a200298f468908cc942': {
    id: 'decentraland',
    symbol: 'mana',
    name: 'Decentraland',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png?1550108745',
    decimals: 18,
    address: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942'
  },
  '0xfa6de2697d59e88ed7fc4dfe5a33dac43565ea41': {
    id: 'defi-top-5-tokens-index',
    symbol: 'defi5',
    name: 'DEFI Top 5 Tokens Index',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13691/large/thGDKHo.png?1610959947',
    decimals: 18,
    address: '0xfa6de2697d59e88ed7fc4dfe5a33dac43565ea41'
  },
  '0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17': {
    id: 'defi-yield-protocol',
    symbol: 'dyp',
    name: 'DeFi Yield Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13480/large/dyp_logo.png?1608971751',
    decimals: 18,
    address: '0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17'
  },
  '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831': {
    id: 'defidollar',
    symbol: 'dusd',
    name: 'DefiDollar',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12333/large/defidollar_logo.png?1599116360',
    decimals: 18,
    address: '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831'
  },
  '0x20c36f062a31865bed8a5b1e512d9a1a20aa333a': {
    id: 'defidollar-dao',
    symbol: 'dfd',
    name: 'DefiDollar DAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12959/large/DFD.jpg?1604415975',
    decimals: 18,
    address: '0x20c36f062a31865bed8a5b1e512d9a1a20aa333a'
  },
  '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b': {
    id: 'defipulse-index',
    symbol: 'dpi',
    name: 'DeFiPulse Index',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12465/large/defi_pulse_index_set.png?1600051053',
    decimals: 18,
    address: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b'
  },
  '0xd5525d397898e5502075ea5e830d8914f6f0affe': {
    id: 'degenerator',
    symbol: 'meme',
    name: 'Meme',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12140/large/meme.jpg?1597476037',
    decimals: 8,
    address: '0xd5525d397898e5502075ea5e830d8914f6f0affe'
  },
  '0x88ef27e69108b2633f8e1c184cc37940a075cc02': {
    id: 'dego-finance',
    symbol: 'dego',
    name: 'Dego Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12503/large/c185FKx.png?1600298167',
    decimals: 18,
    address: '0x88ef27e69108b2633f8e1c184cc37940a075cc02'
  },
  '0x152687bc4a7fcc89049cf119f9ac3e5acf2ee7ef': {
    id: 'deltahub-community',
    symbol: 'DHC',
    name: 'DeltaHub Community',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12656/large/DHC_Transparent_200x200.png?1601440150',
    decimals: 18,
    address: '0x152687bc4a7fcc89049cf119f9ac3e5acf2ee7ef'
  },
  '0x3597bfd533a99c9aa083587b074434e61eb0a258': {
    id: 'dent',
    symbol: 'dent',
    name: 'Dent',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1152/large/gLCEA2G.png?1604543239',
    decimals: 8,
    address: '0x3597bfd533a99c9aa083587b074434e61eb0a258'
  },
  '0x08d32b0da63e2c3bcf8019c9c5d849d7a9d791e6': {
    id: 'dentacoin',
    symbol: 'dcn',
    name: 'Dentacoin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/850/large/dentacoin.png?1547034647',
    decimals: 0,
    address: '0x08d32b0da63e2c3bcf8019c9c5d849d7a9d791e6'
  },
  '0x3a880652f47bfaa771908c07dd8673a787daed3a': {
    id: 'derivadao',
    symbol: 'ddx',
    name: 'DerivaDAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13453/large/ddx_logo.png?1608741641',
    decimals: 18,
    address: '0x3a880652f47bfaa771908c07dd8673a787daed3a'
  },
  '0x3b62f3820e0b035cc4ad602dece6d796bc325325': {
    id: 'deus-finance',
    symbol: 'deus',
    name: 'DEUS Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12539/large/deus_logo.jpg?1611136731',
    decimals: 18,
    address: '0x3b62f3820e0b035cc4ad602dece6d796bc325325'
  },
  '0x5caf454ba92e6f2c929df14667ee360ed9fd5b26': {
    id: 'dev-protocol',
    symbol: 'dev',
    name: 'Dev Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11560/large/Dev_Protocol__CoinGecko_Logo___Jan.18.2021_.png?1611021474',
    decimals: 18,
    address: '0x5caf454ba92e6f2c929df14667ee360ed9fd5b26'
  },
  '0xde4ee8057785a7e8e800db58f9784845a5c2cbd6': {
    id: 'dexe',
    symbol: 'dexe',
    name: 'DeXe',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12713/large/logo_%2814%29.png?1601952779',
    decimals: 18,
    address: '0xde4ee8057785a7e8e800db58f9784845a5c2cbd6'
  },
  '0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4': {
    id: 'dexkit',
    symbol: 'kit',
    name: 'DexKit',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13187/large/dexkit_logo.png?1606093850',
    decimals: 18,
    address: '0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4'
  },
  '0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0': {
    id: 'dextf',
    symbol: 'dextf',
    name: 'DEXTF',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12634/large/DEXTFiconNEGATIVE-page-001.jpg?1601349042',
    decimals: 18,
    address: '0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0'
  },
  '0xb81d70802a816b5dacba06d708b5acf19dcd436d': {
    id: 'dextoken-governance',
    symbol: 'dexg',
    name: 'Dextoken Governance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12223/large/dextoken-logo-v2_200.png?1598408669',
    decimals: 18,
    address: '0xb81d70802a816b5dacba06d708b5acf19dcd436d'
  },
  '0x7b123f53421b1bf8533339bfbdc7c98aa94163db': {
    id: 'dfohub',
    symbol: 'buidl',
    name: 'dfohub',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11131/large/buidllogo.png?1599577041',
    decimals: 18,
    address: '0x7b123f53421b1bf8533339bfbdc7c98aa94163db'
  },
  '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0': {
    id: 'dforce-token',
    symbol: 'df',
    name: 'dForce Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9709/large/xlGxxIjI_400x400.jpg?1571006794',
    decimals: 18,
    address: '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0'
  },
  '0xca1207647ff814039530d7d35df0e1dd2e91fa84': {
    id: 'dhedge-dao',
    symbol: 'dht',
    name: 'dHEDGE DAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12508/large/dht.png?1600752201',
    decimals: 18,
    address: '0xca1207647ff814039530d7d35df0e1dd2e91fa84'
  },
  '0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419': {
    id: 'dia-data',
    symbol: 'DIA',
    name: 'DIA',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11955/large/DIA-icon-colour_%281%29.png?1596423488',
    decimals: 18,
    address: '0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419'
  },
  '0x798d1be841a82a273720ce31c822c61a67a601c3': {
    id: 'digg',
    symbol: 'digg',
    name: 'DIGG',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13737/large/digg.PNG?1611292196',
    decimals: 9,
    address: '0x798d1be841a82a273720ce31c822c61a67a601c3'
  },
  '0x5986d5c77c65e5801a5caa4fae80089f870a71da': {
    id: 'badger-sett-digg',
    symbol: 'bdigg',
    name: 'Badger Sett Digg',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14503/small/DIGG.png?1616560407',
    decimals: 18,
    address: '0x5986d5c77c65e5801a5caa4fae80089f870a71da'
  },
  '0xb9eefc4b0d472a44be93970254df4f4016569d27': {
    id: 'digitalbits',
    symbol: 'xdb',
    name: 'DigitalBits',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8089/large/digitalbits-logo.jpg?1554454902',
    decimals: 7,
    address: '0xb9eefc4b0d472a44be93970254df4f4016569d27'
  },
  '0xc666081073e8dff8d3d1c2292a29ae1a2153ec09': {
    id: 'digitex-futures-exchange',
    symbol: 'dgtx',
    name: 'Digitex Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2188/large/DGTX_coin_icon.png?1606869511',
    decimals: 18,
    address: '0xc666081073e8dff8d3d1c2292a29ae1a2153ec09'
  },
  '0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf': {
    id: 'digix-gold',
    symbol: 'dgx',
    name: 'Digix Gold',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4171/large/DGX_Token.png?1547039436',
    decimals: 9,
    address: '0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf'
  },
  '0xe0b7927c4af23765cb51314a0e0521a9645f0e2a': {
    id: 'digixdao',
    symbol: 'dgd',
    name: 'DigixDAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/397/large/dgd.png?1547034124',
    decimals: 18,
    address: '0xe0b7927c4af23765cb51314a0e0521a9645f0e2a'
  },
  '0x0abdace70d3790235af448c88547603b945604ea': {
    id: 'district0x',
    symbol: 'dnt',
    name: 'district0x',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/849/large/district0x.png?1547223762',
    decimals: 18,
    address: '0x0abdace70d3790235af448c88547603b945604ea'
  },
  '0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f': {
    id: 'dlp-duck-token',
    symbol: 'duck',
    name: 'DLP Duck Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13440/large/DLP_Duck_Token.png?1612840740',
    decimals: 18,
    address: '0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f'
  },
  '0x2ccbff3a042c68716ed2a2cb0c544a9f1d1935e1': {
    id: 'dmarket',
    symbol: 'dmt',
    name: 'DMarket',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1602/large/dmarket.png?1547223830',
    decimals: 8,
    address: '0x2ccbff3a042c68716ed2a2cb0c544a9f1d1935e1'
  },
  '0xe5dada80aa6477e85d09747f2842f7993d0df71c': {
    id: 'dock',
    symbol: 'dock',
    name: 'Dock',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3978/large/2675.png?1547039034',
    decimals: 18,
    address: '0xe5dada80aa6477e85d09747f2842f7993d0df71c'
  },
  '0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd': {
    id: 'dodo',
    symbol: 'DODO',
    name: 'DODO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12651/large/dodo_logo.png?1601433025',
    decimals: 18,
    address: '0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd'
  },
  '0x9c405acf8688afb61b3197421cdeec1a266c6839': {
    id: 'dogeyield',
    symbol: 'dogy',
    name: 'DogeYield',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13734/large/dogy200px.png?1611266985',
    decimals: 18,
    address: '0x9c405acf8688afb61b3197421cdeec1a266c6839'
  },
  '0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544': {
    id: 'doki-doki-finance',
    symbol: 'doki',
    name: 'Doki Doki Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12759/large/doki_logo.png?1602338064',
    decimals: 18,
    address: '0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544'
  },
  '0x0a913bead80f321e7ac35285ee10d9d922659cb7': {
    id: 'dos-network',
    symbol: 'dos',
    name: 'DOS Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7991/large/DOS.png?1552900889',
    decimals: 18,
    address: '0x0a913bead80f321e7ac35285ee10d9d922659cb7'
  },
  '0x419c4db4b9e25d6db2ad9691ccb832c8d9fda05e': {
    id: 'dragonchain',
    symbol: 'drgn',
    name: 'Dragonchain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1289/large/dragonchain.png?1547957761',
    decimals: 18,
    address: '0x419c4db4b9e25d6db2ad9691ccb832c8d9fda05e'
  },
  '0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20': {
    id: 'duckdaodime',
    symbol: 'ddim',
    name: 'DuckDaoDime',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12146/large/token_DDIM-01.png?1606982032',
    decimals: 18,
    address: '0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20'
  },
  '0x940a2db1b7008b6c776d4faaca729d6d4a4aa551': {
    id: 'dusk-network',
    symbol: 'dusk',
    name: 'DUSK Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5217/large/D_ticker_purple_on_circle_%282%29.png?1563781659',
    decimals: 18,
    address: '0x940a2db1b7008b6c776d4faaca729d6d4a4aa551'
  },
  '0xbca3c97837a39099ec3082df97e28ce91be14472': {
    id: 'dust-token',
    symbol: 'dust',
    name: 'DUST Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11162/large/DUST.png?1589280496',
    decimals: 8,
    address: '0xbca3c97837a39099ec3082df97e28ce91be14472'
  },
  '0xa1d65e8fb6e87b60feccbc582f7f97804b725521': {
    id: 'dxdao',
    symbol: 'dxd',
    name: 'DXdao',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11148/large/dxdao.png?1607999331',
    decimals: 18,
    address: '0xa1d65e8fb6e87b60feccbc582f7f97804b725521'
  },
  '0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3': {
    id: 'dynamic-set-dollar',
    symbol: 'dsd',
    name: 'Dynamic Set Dollar',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13249/large/DSD.jpg?1606713628',
    decimals: 18,
    address: '0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3'
  },
  '0x6468e79a80c0eab0f9a2b574c8d5bc374af59414': {
    id: 'e-radix',
    symbol: 'exrd',
    name: 'e-Radix',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13145/large/exrd_logo.png?1605662677',
    decimals: 18,
    address: '0x6468e79a80c0eab0f9a2b574c8d5bc374af59414'
  },
  '0x913d8adf7ce6986a8cbfee5a54725d9eea4f0729': {
    id: 'easyfi',
    symbol: 'easy',
    name: 'EasyFi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12742/large/skiXdJLe_400x400.png?1602630380',
    decimals: 18,
    address: '0x913d8adf7ce6986a8cbfee5a54725d9eea4f0729'
  },
  '0x2781246fe707bb15cee3e5ea354e2154a2877b16': {
    id: 'elysia',
    symbol: 'el',
    name: 'ELYSIA',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10887/large/CeyRVXPY_400x400.jpg?1585559128',
    decimals: 18,
    address: '0x2781246fe707bb15cee3e5ea354e2154a2877b16'
  },
  '0x36f3fd68e7325a35eb768f1aedaae9ea0689d723': {
    id: 'empty-set-dollar',
    symbol: 'esd',
    name: 'Empty Set Dollar',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12440/large/esd_logo_circle.png?1603676421',
    decimals: 18,
    address: '0x36f3fd68e7325a35eb768f1aedaae9ea0689d723'
  },
  '0xc528c28fec0a90c083328bc45f587ee215760a0f': {
    id: 'endor',
    symbol: 'edr',
    name: 'Endor Protocol Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3683/large/0b805574-ef0d-41dc-b518-8d6148bf4716.png?1547038680',
    decimals: 18,
    address: '0xc528c28fec0a90c083328bc45f587ee215760a0f'
  },
  '0xf0ee6b27b759c9893ce4f094b49ad28fd15a23e4': {
    id: 'enigma',
    symbol: 'eng',
    name: 'Enigma',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1007/large/enigma-logo.png?1547034914',
    decimals: 8,
    address: '0xf0ee6b27b759c9893ce4f094b49ad28fd15a23e4'
  },
  '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c': {
    id: 'enjincoin',
    symbol: 'enj',
    name: 'Enjin Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1102/large/enjin-coin-logo.png?1547035078',
    decimals: 18,
    address: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c'
  },
  '0xa2085073878152ac3090ea13d1e41bd69e60dc99': {
    id: 'escoin-token',
    symbol: 'elg',
    name: 'Escoin Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13566/large/escoin-200.png?1609833886',
    decimals: 18,
    address: '0xa2085073878152ac3090ea13d1e41bd69e60dc99'
  },
  '0x5bdc00b6676579b301b276198db1ea9affb94329': {
    id: 'eterbase',
    symbol: 'xbase',
    name: 'Eterbase Utility Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5059/large/image.png?1597301514',
    decimals: 18,
    address: '0x5bdc00b6676579b301b276198db1ea9affb94329'
  },
  '0xc719d010b63e5bbf2c0551872cd5316ed26acd83': {
    id: 'etherisc',
    symbol: 'dip',
    name: 'Etherisc DIP Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4586/large/dip.png?1547039863',
    decimals: 18,
    address: '0xc719d010b63e5bbf2c0551872cd5316ed26acd83'
  },
  '0x80fb784b7ed66730e8b1dbd9820afd29931aab03': {
    id: 'ethlend',
    symbol: 'lend',
    name: 'Aave [OLD]',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1365/large/ethlend.png?1547394586',
    decimals: 18,
    address: '0x80fb784b7ed66730e8b1dbd9820afd29931aab03'
  },
  '0x5af2be193a6abca9c8817001f45744777db30756': {
    id: 'ethos',
    symbol: 'vgx',
    name: 'Voyager Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/794/large/Voyager-vgx.png?1575693595',
    decimals: 8,
    address: '0x5af2be193a6abca9c8817001f45744777db30756'
  },
  '0xf3db5fa2c66b7af3eb0c0b782510816cbe4813b8': {
    id: 'everex',
    symbol: 'evx',
    name: 'Everex',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/997/large/everex.png?1548125695',
    decimals: 4,
    address: '0xf3db5fa2c66b7af3eb0c0b782510816cbe4813b8'
  },
  '0xee573a945b01b788b9287ce062a0cfc15be9fd86': {
    id: 'exeedme',
    symbol: 'xed',
    name: 'Exeedme',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13518/large/exeedme.png?1610669597',
    decimals: 18,
    address: '0xee573a945b01b788b9287ce062a0cfc15be9fd86'
  },
  '0xd6c67b93a7b248df608a653d82a100556144c5da': {
    id: 'exnetwork-token',
    symbol: 'exnt',
    name: 'ExNetwork Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12328/large/exnt_logo.png?1599102916',
    decimals: 16,
    address: '0xd6c67b93a7b248df608a653d82a100556144c5da'
  },
  '0x4e15361fd6b4bb609fa63c81a2be19d873717870': {
    id: 'fantom',
    symbol: 'ftm',
    name: 'Fantom',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4001/large/Fantom.png?1558015016',
    decimals: 18,
    address: '0x4e15361fd6b4bb609fa63c81a2be19d873717870'
  },
  '0xe5caef4af8780e59df925470b050fb23c43ca68c': {
    id: 'ferrum-network',
    symbol: 'frm',
    name: 'Ferrum Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8251/large/frm.png?1563777564',
    decimals: 6,
    address: '0xe5caef4af8780e59df925470b050fb23c43ca68c'
  },
  '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85': {
    id: 'fetch-ai',
    symbol: 'fet',
    name: 'Fetch.ai',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5681/large/Fetch.jpg?1572098136',
    decimals: 18,
    address: '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85'
  },
  '0xef9cd7882c067686691b6ff49e650b43afbbcc6b': {
    id: 'finnexus',
    symbol: 'fnx',
    name: 'FinNexus',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11488/large/finnexus_gecko.png?1591260971',
    decimals: 18,
    address: '0xef9cd7882c067686691b6ff49e650b43afbbcc6b'
  },
  '0xb4467e8d621105312a914f1d42f10770c0ffe3c8': {
    id: 'flash-stake',
    symbol: 'flash',
    name: 'Flashstake',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13533/large/Flashstake.png?1609730156',
    decimals: 18,
    address: '0xb4467e8d621105312a914f1d42f10770c0ffe3c8'
  },
  '0x4946fcea7c692606e8908002e55a582af44ac121': {
    id: 'foam-protocol',
    symbol: 'foam',
    name: 'FOAM',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3345/large/K51lJQc.png?1547037959',
    decimals: 18,
    address: '0x4946fcea7c692606e8908002e55a582af44ac121'
  },
  '0x1fcdce58959f536621d76f5b7ffb955baa5a672f': {
    id: 'force-protocol',
    symbol: 'for',
    name: 'ForTube',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8242/large/for.png?1606195375',
    decimals: 18,
    address: '0x1fcdce58959f536621d76f5b7ffb955baa5a672f'
  },
  '0x853d955acef822db058eb8505911ed77f175b99e': {
    id: 'frax',
    symbol: 'frax',
    name: 'Frax',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13422/large/frax_logo.png?1608476506',
    decimals: 18,
    address: '0x853d955acef822db058eb8505911ed77f175b99e'
  },
  '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0': {
    id: 'frax-share',
    symbol: 'fxs',
    name: 'Frax Share',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13423/large/frax_share.png?1608478989',
    decimals: 18,
    address: '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0'
  },
  '0x2f141ce366a2462f02cea3d12cf93e4dca49e4fd': {
    id: 'free-coin',
    symbol: 'free',
    name: 'FREE coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5585/large/free-coin.png?1548126559',
    decimals: 18,
    address: '0x2f141ce366a2462f02cea3d12cf93e4dca49e4fd'
  },
  '0xf151980e7a781481709e8195744bf2399fb3cba4': {
    id: 'freeway-token',
    symbol: 'fwt',
    name: 'Freeway Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13012/large/A-200px.png?1604381296',
    decimals: 18,
    address: '0xf151980e7a781481709e8195744bf2399fb3cba4'
  },
  '0x7d91e637589ec3bb54d8213a9e92dc6e8d12da91': {
    id: 'friends-with-benefits',
    symbol: 'fwb',
    name: 'Friends With Benefits',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12873/large/fwb_logo.png?1603182111',
    decimals: 4,
    address: '0x7d91e637589ec3bb54d8213a9e92dc6e8d12da91'
  },
  '0xf8c3527cc04340b208c854e985240c02f7b7793f': {
    id: 'frontier-token',
    symbol: 'front',
    name: 'Frontier',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12479/large/frontier_logo.png?1600145472',
    decimals: 18,
    address: '0xf8c3527cc04340b208c854e985240c02f7b7793f'
  },
  '0x393b312c01048b3ed2720bf1b090084c09e408a1': {
    id: 'fryworld',
    symbol: 'fries',
    name: 'fry.world',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12741/large/fries_logo.png?1602147631',
    decimals: 18,
    address: '0x393b312c01048b3ed2720bf1b090084c09e408a1'
  },
  '0xfffffffff15abf397da76f1dcc1a1604f45126db': {
    id: 'fsw-token',
    symbol: 'fsw',
    name: 'Falconswap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12256/large/falconswap.png?1598534184',
    decimals: 18,
    address: '0xfffffffff15abf397da76f1dcc1a1604f45126db'
  },
  '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9': {
    id: 'ftx-token',
    symbol: 'ftt',
    name: 'FTX Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9026/large/F.png?1609051564',
    decimals: 18,
    address: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9'
  },
  '0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a': {
    id: 'fuel-token',
    symbol: 'fuel',
    name: 'Fuel Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13305/large/bW_WB5Rz_400x400.jpg?1607325041',
    decimals: 18,
    address: '0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a'
  },
  '0x419d0d8bdd9af5e606ae2232ed285aff190e711b': {
    id: 'funfair',
    symbol: 'fun',
    name: 'FunFair',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/761/large/funfair.png?1592404368',
    decimals: 8,
    address: '0x419d0d8bdd9af5e606ae2232ed285aff190e711b'
  },
  '0xffffffff2ba8f66d4e51811c5190992176930278': {
    id: 'furucombo',
    symbol: 'combo',
    name: 'Furucombo',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13629/large/COMBO_token_ol.png?1610701537',
    decimals: 18,
    address: '0xffffffff2ba8f66d4e51811c5190992176930278'
  },
  '0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d': {
    id: 'fuse-network-token',
    symbol: 'fuse',
    name: 'Fuse Network Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10347/large/vUXKHEe.png?1601523640',
    decimals: 18,
    address: '0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d'
  },
  '0x8c15ef5b4b21951d50e53e4fbda8298ffad25057': {
    id: 'fx-coin',
    symbol: 'fx',
    name: 'f(x) Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8186/large/47271330_590071468072434_707260356350705664_n.jpg?1556096683',
    decimals: 18,
    address: '0x8c15ef5b4b21951d50e53e4fbda8298ffad25057'
  },
  '0x15d4c048f83bd7e37d49ea4c83a07267ec4203da': {
    id: 'gala',
    symbol: 'gala',
    name: 'Gala',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12493/large/GALA-COINGECKO.png?1600233435',
    decimals: 8,
    address: '0x15d4c048f83bd7e37d49ea4c83a07267ec4203da'
  },
  '0x63f88a2298a5c4aee3c216aa6d926b184a4b2437': {
    id: 'gamecredits',
    symbol: 'game',
    name: 'GameCredits',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/193/large/XlQmXoU.png?1595304945',
    decimals: 18,
    address: '0x63f88a2298a5c4aee3c216aa6d926b184a4b2437'
  },
  '0x0000000000b3f879cb30fe243b4dfee438691c04': {
    id: 'gastoken',
    symbol: 'gst2',
    name: 'GasToken',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10779/large/gas.png?1583466756',
    decimals: 2,
    address: '0x0000000000b3f879cb30fe243b4dfee438691c04'
  },
  '0xe66747a101bff2dba3697199dcce5b743b454759': {
    id: 'gatechain-token',
    symbol: 'gt',
    name: 'GateToken',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8183/large/gt.png?1556085624',
    decimals: 18,
    address: '0xe66747a101bff2dba3697199dcce5b743b454759'
  },
  '0xc3771d47e2ab5a519e2917e61e23078d0c05ed7f': {
    id: 'gather',
    symbol: 'gth',
    name: 'Gather',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12458/large/Gather-Logo-Working-File.png?1599981686',
    decimals: 18,
    address: '0xc3771d47e2ab5a519e2917e61e23078d0c05ed7f'
  },
  '0x6b9f031d718dded0d681c20cb754f97b3bb81b78': {
    id: 'geeq',
    symbol: 'GEEQ',
    name: 'GEEQ',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11953/large/GeeqLogoPNGTransparent-1.png?1596421769',
    decimals: 18,
    address: '0x6b9f031d718dded0d681c20cb754f97b3bb81b78'
  },
  '0x28d3e409bb9bc58f1ca6e009f8fc78a1db85e6b7': {
    id: 'gem-exchange-and-trading',
    symbol: 'gxt',
    name: 'Gem Exchange And Trading',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12226/large/gxt_icon_256x256_gradient.png?1598306924',
    decimals: 18,
    address: '0x28d3e409bb9bc58f1ca6e009f8fc78a1db85e6b7'
  },
  '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd': {
    id: 'gemini-dollar',
    symbol: 'gusd',
    name: 'Gemini Dollar',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5992/large/gemini-dollar-gusd.png?1536745278',
    decimals: 2,
    address: '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd'
  },
  '0x6ec8a24cabdc339a06a172f8223ea557055adaa5': {
    id: 'genaro-network',
    symbol: 'gnx',
    name: 'Genaro Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1361/large/genaro-network.png?1547035387',
    decimals: 9,
    address: '0x6ec8a24cabdc339a06a172f8223ea557055adaa5'
  },
  '0x103c3a209da59d3e7c4a89307e66521e081cfdf0': {
    id: 'genesis-vision',
    symbol: 'gvt',
    name: 'Genesis Vision',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1173/large/Genesis-vision.png?1558045005',
    decimals: 18,
    address: '0x103c3a209da59d3e7c4a89307e66521e081cfdf0'
  },
  '0x147faf8de9d8d8daae129b187f0d02d819126750': {
    id: 'geodb',
    symbol: 'geo',
    name: 'GeoDB',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11130/large/geodb.png?1588941704',
    decimals: 18,
    address: '0x147faf8de9d8d8daae129b187f0d02d819126750'
  },
  '0x8a854288a5976036a725879164ca3e91d30c6a1b': {
    id: 'get-token',
    symbol: 'get',
    name: 'GET Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1927/large/GET_Protocol.png?1552893230',
    decimals: 18,
    address: '0x8a854288a5976036a725879164ca3e91d30c6a1b'
  },
  '0xc5bbae50781be1669306b9e001eff57a2957b09d': {
    id: 'gifto',
    symbol: 'gto',
    name: 'Gifto',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1359/large/gifto.png?1547742697',
    decimals: 5,
    address: '0xc5bbae50781be1669306b9e001eff57a2957b09d'
  },
  '0x9cec686ba6f07d6135b2091140c795166ef5b761': {
    id: 'givingtoservices',
    symbol: 'svcs',
    name: 'GivingToServices',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8763/large/SVCS_Ticker_logo_.png?1611590501',
    decimals: 18,
    address: '0x9cec686ba6f07d6135b2091140c795166ef5b761'
  },
  '0x6810e776880c02933d47db1b9fc05908e5386b96': {
    id: 'gnosis',
    symbol: 'gno',
    name: 'Gnosis',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/662/large/logo_square_simple_300px.png?1609402668',
    decimals: 18,
    address: '0x6810e776880c02933d47db1b9fc05908e5386b96'
  },
  '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429': {
    id: 'golem',
    symbol: 'glm',
    name: 'Golem',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/542/large/Golem_Submark_Positive_RGB.png?1606392013',
    decimals: 18,
    address: '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429'
  },
  '0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107': {
    id: 'govi',
    symbol: 'govi',
    name: 'Govi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13875/large/GOVI.png?1612451531',
    decimals: 18,
    address: '0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107'
  },
  '0x12b19d3e2ccc14da04fae33e63652ce469b3f2fd': {
    id: 'grid',
    symbol: 'grid',
    name: 'GridPlus',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1106/large/grid.png?1547743150',
    decimals: 12,
    address: '0x12b19d3e2ccc14da04fae33e63652ce469b3f2fd'
  },
  '0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0': {
    id: 'growth-defi',
    symbol: 'gro',
    name: 'GROWTH DeFi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12239/large/growthdefi_logo.png?1598438196',
    decimals: 18,
    address: '0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0'
  },
  '0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd': {
    id: 'hakka-finance',
    symbol: 'hakka',
    name: 'Hakka Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12163/large/Hakka-icon.png?1597746776',
    decimals: 18,
    address: '0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd'
  },
  '0xa0246c9032bc3a600820415ae600c6388619a14d': {
    id: 'harvest-finance',
    symbol: 'farm',
    name: 'Harvest Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12304/large/Harvest.png?1599007988',
    decimals: 18,
    address: '0xa0246c9032bc3a600820415ae600c6388619a14d'
  },
  '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f': {
    id: 'havven',
    symbol: 'SNX',
    name: 'Synthetix Network Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3406/large/SNX.png?1598631139',
    decimals: 18,
    address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f'
  },
  '0x50b728d8d964fd00c2d0aad81718b71311fef68a': {
    id: 'havven',
    symbol: 'SNX',
    name: 'Synthetix Network Token',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/3406/large/SNX.png?1598631139',
    decimals: 18,
    address: '0x50b728d8d964fd00c2d0aad81718b71311fef68a'
  },
  '0x9ac983826058b8a9c7aa1c9171441191232e8404': {
    id: 'havven',
    symbol: 'SNX',
    name: 'Binance-Peg Synthetix Network Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/3406/large/SNX.png?1598631139',
    decimals: 18,
    address: '0x9ac983826058b8a9c7aa1c9171441191232e8404'
  },
  '0x7968bc6a03017ea2de509aaa816f163db0f35148': {
    id: 'hedget',
    symbol: 'hget',
    name: 'Hedget',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12453/large/Hedget.png?1599944809',
    decimals: 6,
    address: '0x7968bc6a03017ea2de509aaa816f163db0f35148'
  },
  '0x584bc13c7d411c00c01a62e8019472de68768430': {
    id: 'hegic',
    symbol: 'hegic',
    name: 'Hegic',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12454/large/Hegic.png?1599938210',
    decimals: 18,
    address: '0x584bc13c7d411c00c01a62e8019472de68768430'
  },
  '0x38c6a68304cdefb9bec48bbfaaba5c5b47818bb2': {
    id: 'high-performance-blockchain',
    symbol: 'hpb',
    name: 'High Performance Blockchain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2363/large/HPB_Logo_2020_-_Colour.jpg?1596097580',
    decimals: 18,
    address: '0x38c6a68304cdefb9bec48bbfaaba5c5b47818bb2'
  },
  '0x6c6ee5e31d828de241282b9606c8e98ea48526e2': {
    id: 'holotoken',
    symbol: 'hot',
    name: 'Holo',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3348/large/Holologo_Profile.png?1547037966',
    decimals: 18,
    address: '0x6c6ee5e31d828de241282b9606c8e98ea48526e2'
  },
  '0x12f649a9e821f90bb143089a6e56846945892ffb': {
    id: 'howdoo',
    symbol: 'udoo',
    name: 'Hyprr (Howdoo)',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3078/large/howdoo.png?1547744057',
    decimals: 18,
    address: '0x12f649a9e821f90bb143089a6e56846945892ffb'
  },
  '0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5': {
    id: 'hunt-token',
    symbol: 'hunt',
    name: 'HUNT',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7989/large/HUNT.png?1571024256',
    decimals: 18,
    address: '0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5'
  },
  '0x0316eb71485b0ab14103307bf65a021042c6d380': {
    id: 'huobi-btc',
    symbol: 'hbtc',
    name: 'Huobi BTC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12407/large/Unknown-5.png?1599624896',
    decimals: 18,
    address: '0x0316eb71485b0ab14103307bf65a021042c6d380'
  },
  '0x6f259637dcd74c767781e37bc6133cd6a68aa161': {
    id: 'huobi-token',
    symbol: 'ht',
    name: 'Huobi Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2822/large/huobi-token-logo.png?1547036992',
    decimals: 18,
    address: '0x6f259637dcd74c767781e37bc6133cd6a68aa161'
  },
  '0xdf574c24545e5ffecb9a659c229253d4111d87e1': {
    id: 'husd',
    symbol: 'husd',
    name: 'HUSD',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9567/large/HUSD.jpg?1568889385',
    decimals: 8,
    address: '0xdf574c24545e5ffecb9a659c229253d4111d87e1'
  },
  '0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3': {
    id: 'hxro',
    symbol: 'hxro',
    name: 'Hxro',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7805/large/hxro-squarelogo-1585089594129.png?1586221980',
    decimals: 18,
    address: '0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3'
  },
  '0xe99a894a69d7c2e3c92e61b64c505a6a57d2bc07': {
    id: 'hyperion',
    symbol: 'hyn',
    name: 'Hyperion',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7171/large/Hyperion.png?1558069078',
    decimals: 18,
    address: '0xe99a894a69d7c2e3c92e61b64c505a6a57d2bc07'
  },
  '0xd6014ea05bde904448b743833ddf07c3c7837481': {
    id: 'ibtc',
    symbol: 'iBTC',
    name: 'iBTC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8853/large/SynthiBTC_32.png?1585640330',
    decimals: 18,
    address: '0xd6014ea05bde904448b743833ddf07c3c7837481'
  },
  '0x903bef1736cddf2a537176cf3c64579c3867a881': {
    id: 'ichi-farm',
    symbol: 'ichi',
    name: 'ichi.farm',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13119/large/ichifarm.jpg?1605664946',
    decimals: 9,
    address: '0x903bef1736cddf2a537176cf3c64579c3867a881'
  },
  '0x26ce25148832c04f3d7f26f32478a9fe55197166': {
    id: 'idextools',
    symbol: 'dext',
    name: 'DexTools',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11603/large/dext.png?1605790188',
    decimals: 18,
    address: '0x26ce25148832c04f3d7f26f32478a9fe55197166'
  },
  '0x875773784af8135ea0ef43b5a374aad105c5d39e': {
    id: 'idle',
    symbol: 'idle',
    name: 'IDLE',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13286/large/token-logo.png?1607004948',
    decimals: 18,
    address: '0x875773784af8135ea0ef43b5a374aad105c5d39e'
  },
  '0xa9859874e1743a32409f75bb11549892138bba1e': {
    id: 'ieth',
    symbol: 'ieth',
    name: 'iETH',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8846/large/iETH.png?1562212479',
    decimals: 18,
    address: '0xa9859874e1743a32409f75bb11549892138bba1e'
  },
  '0x607f4c5bb672230e8672085532f7e901544a7375': {
    id: 'iexec-rlc',
    symbol: 'rlc',
    name: 'iExec RLC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/646/large/pL1VuXm.png?1604543202',
    decimals: 9,
    address: '0x607f4c5bb672230e8672085532f7e901544a7375'
  },
  '0x0954906da0bf32d5479e25f46056d22f08464cab': {
    id: 'index-cooperative',
    symbol: 'index',
    name: 'Index Cooperative',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12729/large/c7w3TmXs_400x400.png?1602630788',
    decimals: 18,
    address: '0x0954906da0bf32d5479e25f46056d22f08464cab'
  },
  '0x86772b1409b61c639eaac9ba0acfbb6e238e5f83': {
    id: 'indexed-finance',
    symbol: 'ndx',
    name: 'Indexed Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13546/large/indexed-light.74bb5471.png?1609712728',
    decimals: 18,
    address: '0x86772b1409b61c639eaac9ba0acfbb6e238e5f83'
  },
  '0xe28b3b32b6c345a34ff64674606124dd5aceca30': {
    id: 'injective-protocol',
    symbol: 'inj',
    name: 'Injective Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12882/large/injective_logo.jpg?1603255762',
    decimals: 18,
    address: '0xe28b3b32b6c345a34ff64674606124dd5aceca30'
  },
  '0xa2b726b1145a4773f68593cf171187d8ebe4d495': {
    id: 'injective-protocol',
    symbol: 'inj',
    name: 'Binance-Peg Injective Protocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12882/large/injective_logo.jpg?1603255762',
    decimals: 18,
    address: '0xa2b726b1145a4773f68593cf171187d8ebe4d495'
  },
  '0xb5a4ac5b04e777230ba3381195eff6a60c3934f2': {
    id: 'insure',
    symbol: 'sure',
    name: 'inSure DeFi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10354/large/logo200.png?1578435990',
    decimals: 18,
    address: '0xb5a4ac5b04e777230ba3381195eff6a60c3934f2'
  },
  '0x5e6b6d9abad9093fdc861ea1600eba1b355cd940': {
    id: 'iot-chain',
    symbol: 'itc',
    name: 'IoT Chain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1304/large/iot-chain-logo.png?1547035331',
    decimals: 18,
    address: '0x5e6b6d9abad9093fdc861ea1600eba1b355cd940'
  },
  '0x0db8d8b76bc361bacbb72e2c491e06085a97ab31': {
    id: 'iqeon',
    symbol: 'iqn',
    name: 'IQeon',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1736/large/IQN_logo_64x64.png?1547036010',
    decimals: 18,
    address: '0x0db8d8b76bc361bacbb72e2c491e06085a97ab31'
  },
  '0x8a9c67fee641579deba04928c4bc45f66e26343a': {
    id: 'jarvis-reward-token',
    symbol: 'jrt',
    name: 'Jarvis Reward Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10390/large/cfeii0y.png?1578868949',
    decimals: 18,
    address: '0x8a9c67fee641579deba04928c4bc45f66e26343a'
  },
  '0xa5fd1a791c4dfcaacc963d4f73c6ae5824149ea7': {
    id: 'jibrel',
    symbol: 'jnt',
    name: 'Jibrel Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2016/large/Capture.PNG?1547036293',
    decimals: 18,
    address: '0xa5fd1a791c4dfcaacc963d4f73c6ae5824149ea7'
  },
  '0x5580ab97f226c324c671746a1787524aef42e415': {
    id: 'jul',
    symbol: 'jul',
    name: 'JustLiquidity',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12378/large/JUL256_256.png?1599445678',
    decimals: 18,
    address: '0x5580ab97f226c324c671746a1787524aef42e415'
  },
  '0xe6710e0cda178f3d921f456902707b0d4c4a332b': {
    id: 'julien',
    symbol: 'julien',
    name: 'JULIEN',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12954/large/julien_logo.jpg?1603792446',
    decimals: 4,
    address: '0xe6710e0cda178f3d921f456902707b0d4c4a332b'
  },
  '0x1410434b0346f5be678d0fb554e5c7ab620f8f4a': {
    id: 'kan',
    symbol: 'kan',
    name: 'BitKan',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4651/large/kan-token.png?1547039917',
    decimals: 18,
    address: '0x1410434b0346f5be678d0fb554e5c7ab620f8f4a'
  },
  '0xd9ec3ff1f8be459bb9369b4e79e9ebcf7141c093': {
    id: 'kardiachain',
    symbol: 'kai',
    name: 'KardiaChain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7942/large/KardiaChain.png?1591631223',
    decimals: 18,
    address: '0xd9ec3ff1f8be459bb9369b4e79e9ebcf7141c093'
  },
  '0xdfe691f37b6264a90ff507eb359c45d55037951c': {
    id: 'karma-dao',
    symbol: 'karma',
    name: 'Karma DAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11884/large/Karma.png?1597042574',
    decimals: 4,
    address: '0xdfe691f37b6264a90ff507eb359c45d55037951c'
  },
  '0x7979f6c54eba05e18ded44c4f986f49a5de551c2': {
    id: 'kebab-token',
    symbol: 'kebab',
    name: 'Kebab Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13716/large/Dvwfg1Y.png?1611111552',
    decimals: 18,
    address: '0x7979f6c54eba05e18ded44c4f986f49a5de551c2'
  },
  '0x85eee30c52b0b379b046fb0f85f4f3dc3009afec': {
    id: 'keep-network',
    symbol: 'keep',
    name: 'Keep Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3373/large/IuNzUb5b_400x400.jpg?1589526336',
    decimals: 18,
    address: '0x85eee30c52b0b379b046fb0f85f4f3dc3009afec'
  },
  '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44': {
    id: 'keep3rv1',
    symbol: 'kp3r',
    name: 'Keep3rV1',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12966/large/kp3r_logo.jpg?1607057458',
    decimals: 18,
    address: '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44'
  },
  '0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8': {
    id: 'kira-network',
    symbol: 'kex',
    name: 'KIRA Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13232/large/kira_logo.jpg?1606570162',
    decimals: 6,
    address: '0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8'
  },
  '0x177ba0cac51bfc7ea24bad39d81dcefd59d74faa': {
    id: 'kittenfinance',
    symbol: 'kif',
    name: 'KittenFinance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12340/large/SnQPkABT_400x400.png?1599173267',
    decimals: 18,
    address: '0x177ba0cac51bfc7ea24bad39d81dcefd59d74faa'
  },
  '0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d': {
    id: 'kleros',
    symbol: 'pnk',
    name: 'Kleros',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3833/large/kleros.png?1547975322',
    decimals: 18,
    address: '0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d'
  },
  '0xf34960d9d60be18cc1d5afc1a6f012a723a28811': {
    id: 'kucoin-shares',
    symbol: 'kcs',
    name: 'KuCoin Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1047/large/sa9z79.png?1610678720',
    decimals: 6,
    address: '0xf34960d9d60be18cc1d5afc1a6f012a723a28811'
  },
  '0xdd974d5c2e2928dea5f71b9825b8b646686bd200': {
    id: 'kyber-network',
    symbol: 'knc',
    name: 'Kyber Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/947/large/kyber-logo.png?1547034806',
    decimals: 18,
    address: '0xdd974d5c2e2928dea5f71b9825b8b646686bd200'
  },
  '0x8971f9fd7196e5cee2c1032b50f656855af7dd26': {
    id: 'lambda',
    symbol: 'lamb',
    name: 'Lambda',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4703/large/Lambda-Logo.png?1547219112',
    decimals: 18,
    address: '0x8971f9fd7196e5cee2c1032b50f656855af7dd26'
  },
  '0xc27a2f05fa577a83ba0fdb4c38443c0718356501': {
    id: 'lamden',
    symbol: 'tau',
    name: 'Lamden',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2155/large/Lamden_Logo_2020_-_White_w_Black_Borders.png?1597732013',
    decimals: 18,
    address: '0xc27a2f05fa577a83ba0fdb4c38443c0718356501'
  },
  '0x037a54aab062628c9bbae1fdb1583c195585fe41': {
    id: 'lcx',
    symbol: 'lcx',
    name: 'LCX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9985/large/zRPSu_0o_400x400.jpg?1574327008',
    decimals: 18,
    address: '0x037a54aab062628c9bbae1fdb1583c195585fe41'
  },
  '0x0a50c93c762fdd6e56d86215c24aaad43ab629aa': {
    id: 'legolas-exchange',
    symbol: 'lgo',
    name: 'LGO Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2353/large/2_JNnfVRPMBuA1hwnRubH72A.png?1595311622',
    decimals: 8,
    address: '0x0a50c93c762fdd6e56d86215c24aaad43ab629aa'
  },
  '0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3': {
    id: 'leo-token',
    symbol: 'leo',
    name: 'LEO Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8418/large/leo-token.png?1558326215',
    decimals: 18,
    address: '0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3'
  },
  '0xbbff34e47e559ef680067a6b1c980639eeb64d24': {
    id: 'leverj-gluon',
    symbol: 'l2',
    name: 'Leverj Gluon',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12950/large/Gluon256x256.png?1604048379',
    decimals: 18,
    address: '0xbbff34e47e559ef680067a6b1c980639eeb64d24'
  },
  '0xae697f994fc5ebc000f8e22ebffee04612f98a0d': {
    id: 'lgcy-network',
    symbol: 'lgcy',
    name: 'LGCY Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12181/large/LGCY_network.jpg?1597926587',
    decimals: 18,
    address: '0xae697f994fc5ebc000f8e22ebffee04612f98a0d'
  },
  '0x5a98fcbea516cf06857215779fd812ca3bef1b32': {
    id: 'lido-dao',
    symbol: 'ldo',
    name: 'Lido DAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13573/large/Lido_DAO.png?1609873644',
    decimals: 18,
    address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32'
  },
  '0xab37e1358b639fd877f015027bb62d3ddaa7557e': {
    id: 'lien',
    symbol: 'lien',
    name: 'Lien',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12224/large/Lien.png?1598262819',
    decimals: 8,
    address: '0xab37e1358b639fd877f015027bb62d3ddaa7557e'
  },
  '0x3e9bc21c9b189c09df3ef1b824798658d5011937': {
    id: 'linear',
    symbol: 'lina',
    name: 'Linear',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12509/large/linear.jpg?1606884470',
    decimals: 18,
    address: '0x3e9bc21c9b189c09df3ef1b824798658d5011937'
  },
  '0x578b49c45961f98d8df92854b53f1641af0a5036': {
    id: 'linka',
    symbol: 'linka',
    name: 'LINKA',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9487/large/C2RRuVH6eVbXIDbew-0VJoGpPb_bMPjRjM9qlYud7tqtGgb1GskioTkIntKuugvXnX-Y3O54L-4zWeWWm_Fffo1kJiqJG5vE4Iu1FSvZ-N750y_tlB7yAGeYztAD-MDrwEPaF3Y9j6E94mPVyKuidqbxf9vBeR5r4GAya3friWZjMzpnJ_q9v0jiIBxEC5YAy1C0Zx6yqVoHJjiTW.jpg?1568013160',
    decimals: 18,
    address: '0x578b49c45961f98d8df92854b53f1641af0a5036'
  },
  '0xfa3118b34522580c35ae27f6cf52da1dbb756288': {
    id: 'linkeye',
    symbol: 'let',
    name: 'Linkeye',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1814/large/linkeye.png?1547975964',
    decimals: 6,
    address: '0xfa3118b34522580c35ae27f6cf52da1dbb756288'
  },
  '0xb59490ab09a0f526cc7305822ac65f2ab12f9723': {
    id: 'litentry',
    symbol: 'lit',
    name: 'Litentry',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13825/large/logo_200x200.png?1612153317',
    decimals: 18,
    address: '0xb59490ab09a0f526cc7305822ac65f2ab12f9723'
  },
  '0x58b6a8a3302369daec383334672404ee733ab239': {
    id: 'livepeer',
    symbol: 'lpt',
    name: 'Livepeer',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7137/large/logo-circle-white.png?1611492552',
    decimals: 18,
    address: '0x58b6a8a3302369daec383334672404ee733ab239'
  },
  '0x5e3346444010135322268a4630d2ed5f8d09446c': {
    id: 'lockchain',
    symbol: 'loc',
    name: 'LockTrip',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1357/large/LOC_Transparent_New.png?1579491094',
    decimals: 18,
    address: '0x5e3346444010135322268a4630d2ed5f8d09446c'
  },
  '0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0': {
    id: 'loom-network',
    symbol: 'loom',
    name: 'Loom Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3387/large/1_K76UVoLq-FOL7l-_Fag-Qw_2x.png?1547038040',
    decimals: 18,
    address: '0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0'
  },
  '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd': {
    id: 'loopring',
    symbol: 'lrc',
    name: 'Loopring',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/913/large/LRC.png?1572852344',
    decimals: 18,
    address: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd'
  },
  '0x3db6ba6ab6f95efed1a6e794cad492faaabf294d': {
    id: 'lto-network',
    symbol: 'lto',
    name: 'LTO Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6068/large/LTO_Network.png?1567443682',
    decimals: 8,
    address: '0x3db6ba6ab6f95efed1a6e794cad492faaabf294d'
  },
  '0xb4d930279552397bba2ee473229f89ec245bc365': {
    id: 'mahadao',
    symbol: 'maha',
    name: 'MahaDAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13404/large/mahadao.jpg?1608373356',
    decimals: 18,
    address: '0xb4d930279552397bba2ee473229f89ec245bc365'
  },
  '0xdf2c7238198ad8b389666574f2d8bc411a4b7428': {
    id: 'mainframe',
    symbol: 'mft',
    name: 'Mainframe',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3293/large/Mainframe_MFT_icon.png?1605256332',
    decimals: 18,
    address: '0xdf2c7238198ad8b389666574f2d8bc411a4b7428'
  },
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2': {
    id: 'maker',
    symbol: 'mkr',
    name: 'Maker',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1364/large/Mark_Maker.png?1585191826',
    decimals: 18,
    address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2'
  },
  '0x5f0da599bb2cccfcf6fdfd7d81743b6020864350': {
    id: 'maker',
    symbol: 'mkr',
    name: 'Binance-Peg Maker',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/1364/large/Mark_Maker.png?1585191826',
    decimals: 18,
    address: '0x5f0da599bb2cccfcf6fdfd7d81743b6020864350'
  },
  '0x3593d125a4f7849a1b059e64f4517a86dd60c95d': {
    id: 'mantra-dao',
    symbol: 'om',
    name: 'MANTRA DAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12151/large/OM_3D_whtbg.png?1598332353',
    decimals: 18,
    address: '0x3593d125a4f7849a1b059e64f4517a86dd60c95d'
  },
  '0x630d98424efe0ea27fb1b3ab7741907dffeaad78': {
    id: 'marketpeak',
    symbol: 'peak',
    name: 'PEAKDEFI',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9626/large/PEAKDEFI_Logo_250x250.png?1603094772',
    decimals: 8,
    address: '0x630d98424efe0ea27fb1b3ab7741907dffeaad78'
  },
  '0x57b946008913b82e4df85f501cbaed910e58d26c': {
    id: 'marlin',
    symbol: 'pond',
    name: 'Marlin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8903/large/Marlin.png?1608584519',
    decimals: 18,
    address: '0x57b946008913b82e4df85f501cbaed910e58d26c'
  },
  '0x06f3c323f0238c72bf35011071f2b5b7f43a054c': {
    id: 'masq',
    symbol: 'masq',
    name: 'MASQ',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13699/large/MASQ_Logo_Gold.png?1611017625',
    decimals: 18,
    address: '0x06f3c323f0238c72bf35011071f2b5b7f43a054c'
  },
  '0x08d967bb0134f2d07f7cfb6e246680c53927dd30': {
    id: 'math',
    symbol: 'math',
    name: 'MATH',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11335/large/2020-05-19-token-200.png?1589940590',
    decimals: 18,
    address: '0x08d967bb0134f2d07f7cfb6e246680c53927dd30'
  },
  '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0': {
    id: 'matic-network',
    symbol: 'matic',
    name: 'Polygon',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4713/large/matic___polygon.jpg?1612939050',
    decimals: 18,
    address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0'
  },
  '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42': {
    id: 'mcdex',
    symbol: 'mcb',
    name: 'MCDEX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11796/large/mcb.png?1594355515',
    decimals: 18,
    address: '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42'
  },
  '0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26': {
    id: 'measurable-data-token',
    symbol: 'mdt',
    name: 'Measurable Data Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2441/large/mdt_logo.png?1569813574',
    decimals: 18,
    address: '0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26'
  },
  '0x66186008c1050627f979d464eabb258860563dbe': {
    id: 'medishares',
    symbol: 'mds',
    name: 'MediShares',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1343/large/medishares.png?1547978625',
    decimals: 18,
    address: '0x66186008c1050627f979d464eabb258860563dbe'
  },
  '0xec67005c4e498ec7f55e092bd1d35cbc47c91892': {
    id: 'melon',
    symbol: 'mln',
    name: 'Enzyme',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/605/large/Enzyme_Icon_Secondary.png?1611576629',
    decimals: 18,
    address: '0xec67005c4e498ec7f55e092bd1d35cbc47c91892'
  },
  '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2': {
    id: 'meta',
    symbol: 'MTA',
    name: 'Meta',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11846/large/mStable.png?1594950533',
    decimals: 18,
    address: '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2'
  },
  '0xf501dd45a1198c2e1b5aef5314a68b9006d842e0': {
    id: 'meta',
    symbol: 'MTA',
    name: 'Meta',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/11846/large/mStable.png?1594950533',
    decimals: 18,
    address: '0xf501dd45a1198c2e1b5aef5314a68b9006d842e0'
  },
  '0xf433089366899d83a9f26a773d59ec7ecf30355e': {
    id: 'metal',
    symbol: 'mtl',
    name: 'Metal',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/763/large/Metal.png?1592195010',
    decimals: 8,
    address: '0xf433089366899d83a9f26a773d59ec7ecf30355e'
  },
  '0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e': {
    id: 'metronome',
    symbol: 'met',
    name: 'Metronome',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3249/large/metronome.png?1548084800',
    decimals: 18,
    address: '0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e'
  },
  '0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14': {
    id: 'mettalex',
    symbol: 'mtlx',
    name: 'Mettalex',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12730/large/nrEqFTEW_400x400.jpg?1602063980',
    decimals: 18,
    address: '0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14'
  },
  '0x09a3ecafa817268f77be1283176b946c4ff2e608': {
    id: 'mirror-protocol',
    symbol: 'mir',
    name: 'Mirror Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13295/large/mirror_logo_transparent.png?1611554658',
    decimals: 18,
    address: '0x09a3ecafa817268f77be1283176b946c4ff2e608'
  },
  '0xd36932143f6ebdedd872d5fb0651f4b72fd15a84': {
    id: 'mirrored-apple',
    symbol: 'maapl',
    name: 'Mirrored Apple',
    chain: Chain.ETHEREUM,
    logo: 'https://whitelist.mirror.finance/images/AAPL.png',
    decimals: 18,
    address: '0xd36932143f6ebdedd872d5fb0651f4b72fd15a84'
  },
  '0x368b3a58b5f49392e5c9e4c998cb0bb966752e51': {
    id: 'mith-cash',
    symbol: 'mic',
    name: 'Mithril Cash',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13515/large/photo_2020-12-30_11-05-59.jpg?1609298399',
    decimals: 18,
    address: '0x368b3a58b5f49392e5c9e4c998cb0bb966752e51'
  },
  '0x3893b9422cd5d70a81edeffe3d5a1c6a978310bb': {
    id: 'mithril',
    symbol: 'mith',
    name: 'Mithril',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3484/large/mithril.png?1548085086',
    decimals: 18,
    address: '0x3893b9422cd5d70a81edeffe3d5a1c6a978310bb'
  },
  '0x4b4d2e899658fb59b1d518b68fe836b100ee8958': {
    id: 'mithril-share',
    symbol: 'mis',
    name: 'Mithril Share',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13516/large/MIS.png?1609306996',
    decimals: 18,
    address: '0x4b4d2e899658fb59b1d518b68fe836b100ee8958'
  },
  '0xa283aa7cfbb27ef0cfbcb2493dd9f4330e0fd304': {
    id: 'mm-token',
    symbol: 'mm',
    name: 'MM Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13302/large/MM.jpg?1607315862',
    decimals: 18,
    address: '0xa283aa7cfbb27ef0cfbcb2493dd9f4330e0fd304'
  },
  '0x51db5ad35c671a87207d88fc11d593ac0c8415bd': {
    id: 'moeda-loyalty-points',
    symbol: 'mda',
    name: 'Moeda Loyalty Points',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/895/large/moeda-loyalty-points.png?1547034720',
    decimals: 18,
    address: '0x51db5ad35c671a87207d88fc11d593ac0c8415bd'
  },
  '0xaf4dce16da2877f8c9e00544c93b62ac40631f16': {
    id: 'monetha',
    symbol: 'mth',
    name: 'Monetha',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/902/large/monetha.png?1547034730',
    decimals: 5,
    address: '0xaf4dce16da2877f8c9e00544c93b62ac40631f16'
  },
  '0xf552b656022c218c26dad43ad88881fc04116f76': {
    id: 'mork',
    symbol: 'mork',
    name: 'MORK',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12183/large/mork-logo.png?1597941710',
    decimals: 4,
    address: '0xf552b656022c218c26dad43ad88881fc04116f76'
  },
  '0x4a527d8fc13c5203ab24ba0944f4cb14658d1db6': {
    id: 'morpheus-labs',
    symbol: 'mitx',
    name: 'Morpheus Labs',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3164/large/mitx.png?1604888269',
    decimals: 18,
    address: '0x4a527d8fc13c5203ab24ba0944f4cb14658d1db6'
  },
  '0x7b0c06043468469967dba22d1af33d77d44056c8': {
    id: 'morpheus-network',
    symbol: 'mrph',
    name: 'Morpheus Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2379/large/MRPH_.png?1612252243',
    decimals: 4,
    address: '0x7b0c06043468469967dba22d1af33d77d44056c8'
  },
  '0x865ec58b06bf6305b886793aa20a2da31d034e68': {
    id: 'mossland',
    symbol: 'moc',
    name: 'Mossland',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2374/large/moc.png?1547036557',
    decimals: 18,
    address: '0x865ec58b06bf6305b886793aa20a2da31d034e68'
  },
  '0xb879da8b24c9b8685de8526cf492e954f165d74b': {
    id: 'moviebloc',
    symbol: 'mbl',
    name: 'MovieBloc',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8460/large/H5IOlyrzJPJn9v-DGVYMo9TRM7A-XmNFSyY57bLIhhoO0WVXskWYl7hvBvq6zl7S5Keip_jN9mQRyi4CccGi8vFpoARK1DFGiugOy_u6wqepC_StItYo-EWUEMyCJNYiQRCuNcsn04BAl1Pggp9Gwtbqle7_BZTkvxNVb8YTqgsHJAqCeCyF6O4uVEKUVPaWFZpvTx60TpEbIDhK2.jpg?1558690328',
    decimals: 18,
    address: '0xb879da8b24c9b8685de8526cf492e954f165d74b'
  },
  '0x8a6f3bf52a26a21531514e23016eeae8ba7e7018': {
    id: 'multiplier',
    symbol: 'mxx',
    name: 'Multiplier',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12119/large/MXXlogo.png?1597306184',
    decimals: 8,
    address: '0x8a6f3bf52a26a21531514e23016eeae8ba7e7018'
  },
  '0xe2f2a5c287993345a840db3b0845fbc70f5935a5': {
    id: 'musd',
    symbol: 'musd',
    name: 'mStable USD',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11576/large/mStable_USD.png?1595591803',
    decimals: 18,
    address: '0xe2f2a5c287993345a840db3b0845fbc70f5935a5'
  },
  '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f': {
    id: 'must',
    symbol: 'must',
    name: 'Must',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13688/large/must_logo.png?1610949645',
    decimals: 18,
    address: '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f'
  },
  '0x11eef04c884e24d9b7b4760e7476d06ddf797f36': {
    id: 'mx-token',
    symbol: 'mx',
    name: 'MX Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8545/large/TII1YIdv_400x400.png?1559180170',
    decimals: 18,
    address: '0x11eef04c884e24d9b7b4760e7476d06ddf797f36'
  },
  '0x5ca381bbfb58f0092df149bd3d243b08b9a8386e': {
    id: 'mxc',
    symbol: 'mxc',
    name: 'MXC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4604/large/MXC-app-icon10242x.png?1597628240',
    decimals: 18,
    address: '0x5ca381bbfb58f0092df149bd3d243b08b9a8386e'
  },
  '0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361': {
    id: 'mysterium',
    symbol: 'myst',
    name: 'Mysterium',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/757/large/mysterium.png?1547034503',
    decimals: 18,
    address: '0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361'
  },
  '0x72dd4b6bd852a3aa172be4d6c5a6dbec588cf131': {
    id: 'naga',
    symbol: 'ngc',
    name: 'NAGA',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1439/large/naga.jpg?1547035515',
    decimals: 18,
    address: '0x72dd4b6bd852a3aa172be4d6c5a6dbec588cf131'
  },
  '0x28b5e12cce51f15594b0b91d5b5adaa70f684a02': {
    id: 'napoleon-x',
    symbol: 'npx',
    name: 'Napoleon X',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1471/large/napoleon-x.jpg?1547035570',
    decimals: 2,
    address: '0x28b5e12cce51f15594b0b91d5b5adaa70f684a02'
  },
  '0x5d65d971895edc438f465c17db6992698a52318d': {
    id: 'nebulas',
    symbol: 'nas',
    name: 'Nebulas',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2431/large/193394331.png?1597976208',
    decimals: 18,
    address: '0x5d65d971895edc438f465c17db6992698a52318d'
  },
  '0xcc80c051057b774cd75067dc48f8987c4eb97a5e': {
    id: 'nectar-token',
    symbol: 'nec',
    name: 'Nectar',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3559/large/NecLogoLoad.png?1592795924',
    decimals: 18,
    address: '0xcc80c051057b774cd75067dc48f8987c4eb97a5e'
  },
  '0x04abeda201850ac0124161f037efd70c74ddc74c': {
    id: 'nest',
    symbol: 'nest',
    name: 'Nest Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11284/large/52954052.png?1589868539',
    decimals: 18,
    address: '0x04abeda201850ac0124161f037efd70c74ddc74c'
  },
  '0xa823e6722006afe99e91c30ff5295052fe6b8e32': {
    id: 'neumark',
    symbol: 'neu',
    name: 'Neumark',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2218/large/neumark.png?1547036501',
    decimals: 18,
    address: '0xa823e6722006afe99e91c30ff5295052fe6b8e32'
  },
  '0x674c6ad92fd080e4004b2312b45f796a192d27a0': {
    id: 'neutrino',
    symbol: 'usdn',
    name: 'Neutrino USD',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10117/large/78GWcZu.png?1600845716',
    decimals: 18,
    address: '0x674c6ad92fd080e4004b2312b45f796a192d27a0'
  },
  '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206': {
    id: 'nexo',
    symbol: 'nexo',
    name: 'NEXO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3695/large/nexo.png?1548086057',
    decimals: 18,
    address: '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206'
  },
  '0xcb8d1260f9c92a3a545d409466280ffdd7af7042': {
    id: 'nft-protocol',
    symbol: 'nft',
    name: 'NFT Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12174/large/nftprotocol_32.png?1597818115',
    decimals: 18,
    address: '0xcb8d1260f9c92a3a545d409466280ffdd7af7042'
  },
  '0x87d73e916d7057945c9bcd8cdd94e42a6f47f776': {
    id: 'nftx',
    symbol: 'nftx',
    name: 'NFTX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13574/large/nftx.png?1609880030',
    decimals: 18,
    address: '0x87d73e916d7057945c9bcd8cdd94e42a6f47f776'
  },
  '0x5cf04716ba20127f1e2297addcf4b5035000c9eb': {
    id: 'nkn',
    symbol: 'nkn',
    name: 'NKN',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3375/large/nkn.png?1548329212',
    decimals: 18,
    address: '0x5cf04716ba20127f1e2297addcf4b5035000c9eb'
  },
  '0xa8c8cfb141a3bb59fea1e2ea6b79b5ecbcd7b6ca': {
    id: 'noia-network',
    symbol: 'noia',
    name: 'Syntropy',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3269/large/Component_1.png?1608275724',
    decimals: 18,
    address: '0xa8c8cfb141a3bb59fea1e2ea6b79b5ecbcd7b6ca'
  },
  '0x6e9730ecffbed43fd876a264c982e254ef05a0de': {
    id: 'nord-finance',
    symbol: 'nord',
    name: 'Nord Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13630/large/nord.jpg?1610465136',
    decimals: 18,
    address: '0x6e9730ecffbed43fd876a264c982e254ef05a0de'
  },
  '0x20945ca1df56d237fd40036d47e866c7dccd2114': {
    id: 'nsure-network',
    symbol: 'nsure',
    name: 'Nsure Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12948/large/Nsure_token.png?1603778876',
    decimals: 18,
    address: '0x20945ca1df56d237fd40036d47e866c7dccd2114'
  },
  '0x809826cceab68c387726af962713b64cb5cb3cca': {
    id: 'nucleus-vision',
    symbol: 'ncash',
    name: 'Nucleus Vision',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3135/large/nv.jpg?1547037544',
    decimals: 18,
    address: '0x809826cceab68c387726af962713b64cb5cb3cca'
  },
  '0x4fe83213d56308330ec302a8bd641f1d0113a4cc': {
    id: 'nucypher',
    symbol: 'nu',
    name: 'NuCypher',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3318/large/photo1198982838879365035.jpg?1547037916',
    decimals: 18,
    address: '0x4fe83213d56308330ec302a8bd641f1d0113a4cc'
  },
  '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671': {
    id: 'numeraire',
    symbol: 'nmr',
    name: 'Numeraire',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/752/large/numeraire.png?1592538976',
    decimals: 18,
    address: '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671'
  },
  '0x57ab1ec28d129707052df4df418d58a2d46d5f51': {
    id: 'nusd',
    symbol: 'susd',
    name: 'sUSD',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5013/large/sUSD.png?1562212426',
    decimals: 18,
    address: '0x57ab1ec28d129707052df4df418d58a2d46d5f51'
  },
  '0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b': {
    id: 'nxm',
    symbol: 'nxm',
    name: 'Nexus Mutual',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11810/large/nexus-mutual.jpg?1594547726',
    decimals: 18,
    address: '0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b'
  },
  '0x967da4048cd07ab37855c090aaf366e4ce1b9f48': {
    id: 'ocean-protocol',
    symbol: 'ocean',
    name: 'Ocean Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg?1547038686',
    decimals: 18,
    address: '0x967da4048cd07ab37855c090aaf366e4ce1b9f48'
  },
  '0x7240ac91f01233baaf8b064248e80feaa5912ba3': {
    id: 'octofi',
    symbol: 'octo',
    name: 'OctoFi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12594/large/octofi-256x256-radius-22percent.png?1610679969',
    decimals: 18,
    address: '0x7240ac91f01233baaf8b064248e80feaa5912ba3'
  },
  '0xbf52f2ab39e26e0951d2a02b49b7702abe30406a': {
    id: 'odem',
    symbol: 'ode',
    name: 'ODEM',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3752/large/odem.png?1548329893',
    decimals: 18,
    address: '0xbf52f2ab39e26e0951d2a02b49b7702abe30406a'
  },
  '0x4092678e4e78230f46a1534c0fbc8fa39780892b': {
    id: 'odyssey',
    symbol: 'ocn',
    name: 'Odyssey',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2559/large/ocn.png?1547036683',
    decimals: 18,
    address: '0x4092678e4e78230f46a1534c0fbc8fa39780892b'
  },
  '0xabe580e7ee158da464b51ee1a83ac0289622e6be': {
    id: 'offshift',
    symbol: 'xft',
    name: 'Offshift',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11977/large/offshift_logo.png?1596597308',
    decimals: 18,
    address: '0xabe580e7ee158da464b51ee1a83ac0289622e6be'
  },
  '0x75231f58b43240c9718dd58b4967c5114342a86c': {
    id: 'okb',
    symbol: 'okb',
    name: 'OKB',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4463/large/okb_token.png?1548386209',
    decimals: 18,
    address: '0x75231f58b43240c9718dd58b4967c5114342a86c'
  },
  '0xd26114cd6ee289accf82350c8d8487fedb8a0c07': {
    id: 'omisego',
    symbol: 'omg',
    name: 'OMG Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/776/large/OMG_Network.jpg?1591167168',
    decimals: 18,
    address: '0xd26114cd6ee289accf82350c8d8487fedb8a0c07'
  },
  '0x0b342c51d1592c41068d5d4b4da4a68c0a04d5a4': {
    id: 'oneswap-dao-token',
    symbol: 'ones',
    name: 'OneSwap DAO Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12552/large/logo.png?1600682344',
    decimals: 18,
    address: '0x0b342c51d1592c41068d5d4b4da4a68c0a04d5a4'
  },
  '0xe0ad1806fd3e7edf6ff52fdb822432e847411033': {
    id: 'onx-finance',
    symbol: 'ONX',
    name: 'OnX Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13445/large/onxlogo-1.png?1608629659',
    decimals: 18,
    address: '0xe0ad1806fd3e7edf6ff52fdb822432e847411033'
  },
  '0x69e8b9528cabda89fe846c67675b5d73d463a916': {
    id: 'open-governance-token',
    symbol: 'open',
    name: 'OPEN Governance Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13233/large/opendao_logo.png?1606575207',
    decimals: 18,
    address: '0x69e8b9528cabda89fe846c67675b5d73d463a916'
  },
  '0x701c244b988a513c945973defa05de933b23fe1d': {
    id: 'openanx',
    symbol: 'oax',
    name: 'OAX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/853/large/OAXlogo.png?1598686792',
    decimals: 18,
    address: '0x701c244b988a513c945973defa05de933b23fe1d'
  },
  '0x888888888889c00c67689029d7856aac1065ec11': {
    id: 'opium',
    symbol: 'opium',
    name: 'Opium',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13758/large/opium-token-black_%281%29.png?1611767960',
    decimals: 18,
    address: '0x888888888889c00c67689029d7856aac1065ec11'
  },
  '0xad4f86a25bbc20ffb751f2fac312a0b4d8f88c64': {
    id: 'option-room',
    symbol: 'room',
    name: 'OptionRoom',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13889/large/option_room_logo.png?1612518313',
    decimals: 18,
    address: '0xad4f86a25bbc20ffb751f2fac312a0b4d8f88c64'
  },
  '0x4c11249814f11b9346808179cf06e71ac328c1b5': {
    id: 'oraichain-token',
    symbol: 'orai',
    name: 'Oraichain Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12931/large/2000x2000_azfsy0.png?1603696770',
    decimals: 18,
    address: '0x4c11249814f11b9346808179cf06e71ac328c1b5'
  },
  '0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa': {
    id: 'orbs',
    symbol: 'orbs',
    name: 'Orbs',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4630/large/Orbs.jpg?1547039896',
    decimals: 18,
    address: '0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa'
  },
  '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86': {
    id: 'origin-dollar',
    symbol: 'ousd',
    name: 'Origin Dollar',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12589/large/ousd-logo-200x200.png?1600943287',
    decimals: 18,
    address: '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86'
  },
  '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26': {
    id: 'origin-protocol',
    symbol: 'ogn',
    name: 'Origin Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3296/large/op.jpg?1547037878',
    decimals: 18,
    address: '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26'
  },
  '0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f': {
    id: 'origintrail',
    symbol: 'trac',
    name: 'OriginTrail',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1877/large/origintrail.jpg?1605694415',
    decimals: 18,
    address: '0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f'
  },
  '0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a': {
    id: 'orion-protocol',
    symbol: 'orn',
    name: 'Orion Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11841/large/orion_logo.png?1594943318',
    decimals: 8,
    address: '0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a'
  },
  '0x8c8687fc965593dfb2f0b4eaefd55e9d8df348df': {
    id: 'paid-network',
    symbol: 'paid',
    name: 'PAID Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13761/large/PAID.png?1612493556',
    decimals: 18,
    address: '0x8c8687fc965593dfb2f0b4eaefd55e9d8df348df'
  },
  '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82': {
    id: 'pancakeswap-token',
    symbol: 'CAKE',
    name: 'PancakeSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://i.postimg.cc/RFhy3fqg/CAKE-LOGO.png',
    decimals: 18,
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82'
  },
  '0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06': {
    id: 'parachute',
    symbol: 'par',
    name: 'Parachute',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7590/large/Parachute_Logo.png?1560918207',
    decimals: 18,
    address: '0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06'
  },
  '0x362bc847a3a9637d3af6624eec853618a43ed7d2': {
    id: 'parsiq',
    symbol: 'prq',
    name: 'PARSIQ',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11973/large/DsNgK0O.png?1596590280',
    decimals: 18,
    address: '0x362bc847a3a9637d3af6624eec853618a43ed7d2'
  },
  '0x45804880de22913dafe09f4980848ece6ecbaf78': {
    id: 'pax-gold',
    symbol: 'paxg',
    name: 'PAX Gold',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9519/large/paxg.PNG?1568542565',
    decimals: 18,
    address: '0x45804880de22913dafe09f4980848ece6ecbaf78'
  },
  '0x8e870d67f660d95d5be530380d0ec0bd388289e1': {
    id: 'paxos-standard',
    symbol: 'pax',
    name: 'Paxos Standard',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6013/large/paxos_standard.png?1548386291',
    decimals: 18,
    address: '0x8e870d67f660d95d5be530380d0ec0bd388289e1'
  },
  '0xbc396689893d065f41bc2c6ecbee5e0085233447': {
    id: 'perpetual-protocol',
    symbol: 'perp',
    name: 'Perpetual Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12381/large/perpetual_protocol_logo.jpg?1599469619',
    decimals: 18,
    address: '0xbc396689893d065f41bc2c6ecbee5e0085233447'
  },
  '0x6c5ba91642f10282b576d91922ae6448c9d52f4e': {
    id: 'pha',
    symbol: 'pha',
    name: 'Phala Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12451/large/phala.png?1600061318',
    decimals: 18,
    address: '0x6c5ba91642f10282b576d91922ae6448c9d52f4e'
  },
  '0x79c75e2e8720b39e258f41c37cc4f309e0b0ff80': {
    id: 'phantasma',
    symbol: 'soul',
    name: 'Phantasma',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4130/large/phantasma.png?1548331035',
    decimals: 8,
    address: '0x79c75e2e8720b39e258f41c37cc4f309e0b0ff80'
  },
  '0x429881672b9ae42b8eba0e26cd9c73711b891ca5': {
    id: 'pickle-finance',
    symbol: 'pickle',
    name: 'Pickle Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12435/large/pickle_finance_logo.jpg?1599817746',
    decimals: 18,
    address: '0x429881672b9ae42b8eba0e26cd9c73711b891ca5'
  },
  '0x78f225869c08d478c34e5f645d07a87d3fe8eb78': {
    id: 'piedao-defi-large-cap',
    symbol: 'defi+l',
    name: 'PieDAO DEFI Large Cap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12731/large/DeFi_L.png?1602070128',
    decimals: 18,
    address: '0x78f225869c08d478c34e5f645d07a87d3fe8eb78'
  },
  '0xad32a8e6220741182940c5abf610bde99e737b2d': {
    id: 'piedao-dough-v2',
    symbol: 'dough',
    name: 'PieDAO DOUGH v2',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12693/large/DOUGH2v.png?1602655308',
    decimals: 18,
    address: '0xad32a8e6220741182940c5abf610bde99e737b2d'
  },
  '0xe3818504c1b32bf1557b16c238b2e01fd3149c17': {
    id: 'pillar',
    symbol: 'plr',
    name: 'Pillar',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/809/large/PLR_Token.png?1595237935',
    decimals: 18,
    address: '0xe3818504c1b32bf1557b16c238b2e01fd3149c17'
  },
  '0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2': {
    id: 'plasma-finance',
    symbol: 'ppay',
    name: 'Plasma Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13340/large/Hi9sEGAD.png?1607586849',
    decimals: 18,
    address: '0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2'
  },
  '0x429d83bb0dcb8cdd5311e34680adc8b12070a07f': {
    id: 'platoncoin',
    symbol: 'pltc',
    name: 'PlatonCoin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7178/large/Platon.png?1587395719',
    decimals: 18,
    address: '0x429d83bb0dcb8cdd5311e34680adc8b12070a07f'
  },
  '0xd8912c10681d8b21fd3742244f44658dba12264e': {
    id: 'pluton',
    symbol: 'plu',
    name: 'Pluton',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1241/large/pluton.png?1548331624',
    decimals: 18,
    address: '0xd8912c10681d8b21fd3742244f44658dba12264e'
  },
  '0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed': {
    id: 'pnetwork',
    symbol: 'pnt',
    name: 'pNetwork',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11659/large/pNetwork.png?1592411134',
    decimals: 18,
    address: '0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed'
  },
  '0x0d6ae2a429df13e44a07cd2969e085e4833f64a0': {
    id: 'polkabridge',
    symbol: 'pbr',
    name: 'PolkaBridge',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13744/large/symbol-whitebg200x200.png?1611377553',
    decimals: 18,
    address: '0x0d6ae2a429df13e44a07cd2969e085e4833f64a0'
  },
  '0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa': {
    id: 'polkastarter',
    symbol: 'pols',
    name: 'Polkastarter',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12648/large/polkastarter.png?1609813702',
    decimals: 18,
    address: '0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa'
  },
  '0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec': {
    id: 'polymath-network',
    symbol: 'poly',
    name: 'Polymath Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2784/large/inKkF01.png?1605007034',
    decimals: 18,
    address: '0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec'
  },
  '0x9e46a38f5daabe8683e10793b06749eef7d733d1': {
    id: 'polyswarm',
    symbol: 'nct',
    name: 'PolySwarm',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2843/large/polyswarm.png?1548331600',
    decimals: 18,
    address: '0x9e46a38f5daabe8683e10793b06749eef7d733d1'
  },
  '0x69a95185ee2a045cdc4bcd1b1df10710395e4e23': {
    id: 'poolz-finance',
    symbol: 'poolz',
    name: 'Poolz Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13679/large/poolz_logo.png?1610806091',
    decimals: 18,
    address: '0x69a95185ee2a045cdc4bcd1b1df10710395e4e23'
  },
  '0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a': {
    id: 'populous',
    symbol: 'ppt',
    name: 'Populous',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/753/large/populous.png?1548331905',
    decimals: 8,
    address: '0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a'
  },
  '0x26607ac599266b21d13c7acf7942c7701a8b699c': {
    id: 'power-index-pool-token',
    symbol: 'pipt',
    name: 'Power Index Pool Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13281/large/pipt_logo.jpg?1606910642',
    decimals: 18,
    address: '0x26607ac599266b21d13c7acf7942c7701a8b699c'
  },
  '0x595832f8fc6bf59c85c527fec3740a1b7a361269': {
    id: 'power-ledger',
    symbol: 'powr',
    name: 'Power Ledger',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1104/large/power-ledger.png?1547035082',
    decimals: 6,
    address: '0x595832f8fc6bf59c85c527fec3740a1b7a361269'
  },
  '0xc57d533c50bc22247d49a368880fb49a1caa39f7': {
    id: 'powertrade-fuel',
    symbol: 'ptf',
    name: 'PowerTrade Fuel',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12590/large/powertrade_logo.jpg?1600944549',
    decimals: 18,
    address: '0xc57d533c50bc22247d49a368880fb49a1caa39f7'
  },
  '0xec213f83defb583af3a000b1c0ada660b1902a0f': {
    id: 'presearch',
    symbol: 'pre',
    name: 'Presearch',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1299/large/presearch.png?1548331942',
    decimals: 18,
    address: '0xec213f83defb583af3a000b1c0ada660b1902a0f'
  },
  '0xfc82bb4ba86045af6f327323a46e80412b91b27d': {
    id: 'prometeus',
    symbol: 'prom',
    name: 'Prometeus',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8825/large/G2LY-Dg_.png?1591698270',
    decimals: 18,
    address: '0xfc82bb4ba86045af6f327323a46e80412b91b27d'
  },
  '0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41': {
    id: 'props',
    symbol: 'props',
    name: 'Props Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6735/large/photo-2017-10-10-03-32-02.png?1595168186',
    decimals: 18,
    address: '0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41'
  },
  '0x226bb599a12c826476e3a771454697ea52e9e220': {
    id: 'propy',
    symbol: 'pro',
    name: 'Propy',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/869/large/propy.png?1548332100',
    decimals: 8,
    address: '0x226bb599a12c826476e3a771454697ea52e9e220'
  },
  '0x8642a849d0dcb7a15a974794668adcfbe4794b56': {
    id: 'prosper',
    symbol: 'pros',
    name: 'Prosper',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13668/large/heD6cg22l3sF5VgPh4G1xC6lnKEWXJif-jbaqUpv8CDP6jbWaqn9UjBdkXWNrw1CewaQOxb8zXRdNeNJWWiUDjfsEl_d7E3bPLg4cFoilQF5TGKHfWyJlnpm3UYc9ytvRvOjxOevMuiu8-lusnNoOcwgsJpMkYWHqe322GAxLt0_30kFMVAcjEDUrOlkK6hUYi0m9P433mvNlOm.jpg?1610671732',
    decimals: 18,
    address: '0x8642a849d0dcb7a15a974794668adcfbe4794b56'
  },
  '0x5228a22e72ccc52d415ecfd199f99d0665e7733b': {
    id: 'ptokens-btc',
    symbol: 'pbtc',
    name: 'pTokens BTC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10805/large/J51iIea.png?1583891599',
    decimals: 18,
    address: '0x5228a22e72ccc52d415ecfd199f99d0665e7733b'
  },
  '0x846c66cf71c43f80403b51fe3906b3599d63336f': {
    id: 'pumapay',
    symbol: 'pma',
    name: 'PumaPay',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2307/large/pumapay.png?1548607436',
    decimals: 18,
    address: '0x846c66cf71c43f80403b51fe3906b3599d63336f'
  },
  '0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3': {
    id: 'pundi-x',
    symbol: 'npxs',
    name: 'Pundi X',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2170/large/pundi-x.png?1548386366',
    decimals: 18,
    address: '0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3'
  },
  '0x618e75ac90b12c6049ba3b27f5d5f8651b0037f6': {
    id: 'qash',
    symbol: 'qash',
    name: 'QASH',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1220/large/qash.jpeg?1547035247',
    decimals: 6,
    address: '0x618e75ac90b12c6049ba3b27f5d5f8651b0037f6'
  },
  '0xc28e931814725bbeb9e670676fabbcb694fe7df2': {
    id: 'quadrant-protocol',
    symbol: 'equad',
    name: 'Quadrant Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4462/large/equad.png?1547039783',
    decimals: 18,
    address: '0xc28e931814725bbeb9e670676fabbcb694fe7df2'
  },
  '0x4a220e6096b25eadb88358cb44068a3248254675': {
    id: 'quant-network',
    symbol: 'qnt',
    name: 'Quant',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3370/large/5ZOu7brX_400x400.jpg?1612437252',
    decimals: 18,
    address: '0x4a220e6096b25eadb88358cb44068a3248254675'
  },
  '0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d': {
    id: 'quantstamp',
    symbol: 'qsp',
    name: 'Quantstamp',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1219/large/0_E0kZjb4dG4hUnoDD_.png?1604815917',
    decimals: 18,
    address: '0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d'
  },
  '0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664': {
    id: 'quark-chain',
    symbol: 'qkc',
    name: 'QuarkChain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3849/large/quarkchain.png?1548387524',
    decimals: 18,
    address: '0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664'
  },
  '0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6': {
    id: 'raiden-network',
    symbol: 'rdn',
    name: 'Raiden Network Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1132/large/raiden-logo.jpg?1547035131',
    decimals: 18,
    address: '0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6'
  },
  '0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b': {
    id: 'rally-2',
    symbol: 'rly',
    name: 'Rally',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12843/large/image.png?1611212077',
    decimals: 18,
    address: '0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b'
  },
  '0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f': {
    id: 'ramp',
    symbol: 'ramp',
    name: 'RAMP',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12837/large/RampdefiExternal200.png?1602897632',
    decimals: 18,
    address: '0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f'
  },
  '0xd291e7a03283640fdc51b121ac401383a46cc623': {
    id: 'rari-governance-token',
    symbol: 'rgt',
    name: 'Rari Governance Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12900/large/rgt_logo.png?1603340632',
    decimals: 18,
    address: '0xd291e7a03283640fdc51b121ac401383a46cc623'
  },
  '0xfca59cd816ab1ead66534d82bc21e7515ce441cf': {
    id: 'rarible',
    symbol: 'rari',
    name: 'Rarible',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11845/large/Rari.png?1594946953',
    decimals: 18,
    address: '0xfca59cd816ab1ead66534d82bc21e7515ce441cf'
  },
  '0xe8b251822d003a2b2466ee0e38391c2db2048739': {
    id: 'rbase-finance',
    symbol: 'rbase',
    name: 'rbase.finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13343/large/rbase_logo.jpg?1607619337',
    decimals: 9,
    address: '0xe8b251822d003a2b2466ee0e38391c2db2048739'
  },
  '0x1fc5ef0337aea85c5f9198853a6e3a579a7a6987': {
    id: 'reapchain',
    symbol: 'reap',
    name: 'ReapChain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13109/large/REAP.jpg?1605259422',
    decimals: 18,
    address: '0x1fc5ef0337aea85c5f9198853a6e3a579a7a6987'
  },
  '0xa1d6df714f91debf4e0802a542e13067f31b8262': {
    id: 'redfox-labs-2',
    symbol: 'rfox',
    name: 'RedFOX Labs',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12956/large/logo-200.png?1607620623',
    decimals: 18,
    address: '0xa1d6df714f91debf4e0802a542e13067f31b8262'
  },
  '0xd0929d411954c47438dc1d871dd6081f5c5e149c': {
    id: 'refereum',
    symbol: 'rfr',
    name: 'Refereum',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2102/large/refereum.png?1548608001',
    decimals: 4,
    address: '0xd0929d411954c47438dc1d871dd6081f5c5e149c'
  },
  '0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7': {
    id: 'reflect-finance',
    symbol: 'rfi',
    name: 'reflect.finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13189/large/reflect_finance_logo.png?1606098213',
    decimals: 9,
    address: '0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7'
  },
  '0x83984d6142934bb535793a82adb0a46ef0f66b6d': {
    id: 'remme',
    symbol: 'rem',
    name: 'Remme',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2152/large/semme.png?1561622861',
    decimals: 4,
    address: '0x83984d6142934bb535793a82adb0a46ef0f66b6d'
  },
  '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d': {
    id: 'renbtc',
    symbol: 'renBTC',
    name: 'renBTC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11370/large/renBTC.png?1589985711',
    decimals: 8,
    address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d'
  },
  '0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c': {
    id: 'renbtc',
    symbol: 'renBTC',
    name: 'renBTC',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11370/large/renBTC.png?1589985711',
    decimals: 8,
    address: '0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c'
  },
  '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24': {
    id: 'render-token',
    symbol: 'rndr',
    name: 'Render Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11636/large/uTDd98ZN_400x400.jpg?1592200150',
    decimals: 18,
    address: '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24'
  },
  '0x3832d2f059e55934220881f831be501d180671a7': {
    id: 'rendoge',
    symbol: 'rendoge',
    name: 'renDOGE',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13796/large/renDOGE.png?1611897869',
    decimals: 8,
    address: '0x3832d2f059e55934220881f831be501d180671a7'
  },
  '0x408e41876cccdc0f92210600ef50372656052a38': {
    id: 'republic-protocol',
    symbol: 'ren',
    name: 'REN',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3139/large/REN.png?1589985807',
    decimals: 18,
    address: '0x408e41876cccdc0f92210600ef50372656052a38'
  },
  '0x8f8221afbb33998d8584a2b05749ba73c37a938a': {
    id: 'request-network',
    symbol: 'req',
    name: 'Request',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1031/large/Request_icon.png?1550832088',
    decimals: 18,
    address: '0x8f8221afbb33998d8584a2b05749ba73c37a938a'
  },
  '0x8762db106b2c2a0bccb3a80d1ed41273552616e8': {
    id: 'reserve-rights-token',
    symbol: 'rsr',
    name: 'Reserve Rights Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8365/large/Reserve_Rights.png?1557737411',
    decimals: 18,
    address: '0x8762db106b2c2a0bccb3a80d1ed41273552616e8'
  },
  '0x2ef52ed7de8c5ce03a4ef0efbe9b7450f2d7edc9': {
    id: 'revain',
    symbol: 'rev',
    name: 'Revain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1107/large/256x256.png?1587018647',
    decimals: 6,
    address: '0x2ef52ed7de8c5ce03a4ef0efbe9b7450f2d7edc9'
  },
  '0x557b933a7c2c45672b610f8954a3deb39a51a8ca': {
    id: 'revv',
    symbol: 'revv',
    name: 'REVV',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12373/large/Nxy8QwOU.png?1599385982',
    decimals: 18,
    address: '0x557b933a7c2c45672b610f8954a3deb39a51a8ca'
  },
  '0xaf9f549774ecedbd0966c52f250acc548d3f36e5': {
    id: 'rio-defi',
    symbol: 'rfuel',
    name: 'RioDeFi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12623/large/RFUEL_SQR.png?1602481093',
    decimals: 18,
    address: '0xaf9f549774ecedbd0966c52f250acc548d3f36e5'
  },
  '0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6': {
    id: 'ripio-credit-network',
    symbol: 'rcn',
    name: 'Ripio Credit Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1057/large/ripio-credit-network.png?1548608361',
    decimals: 18,
    address: '0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6'
  },
  '0x7de91b204c1c737bcee6f000aaa6569cf7061cb7': {
    id: 'robonomics-network',
    symbol: 'xrt',
    name: 'Robonomics Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7024/large/Robonomics-Network-logo.png?1547043451',
    decimals: 9,
    address: '0x7de91b204c1c737bcee6f000aaa6569cf7061cb7'
  },
  '0xb4efd85c19999d84251304bda99e90b92300bd93': {
    id: 'rocket-pool',
    symbol: 'rpl',
    name: 'Rocket Pool',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2090/large/rocket.png?1563781948',
    decimals: 18,
    address: '0xb4efd85c19999d84251304bda99e90b92300bd93'
  },
  '0xfa5047c9c78b8877af97bdcb85db743fd7313d4a': {
    id: 'rook',
    symbol: 'rook',
    name: 'KeeperDAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13005/large/keeper_dao_logo.jpg?1604316506',
    decimals: 18,
    address: '0xfa5047c9c78b8877af97bdcb85db743fd7313d4a'
  },
  '0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e': {
    id: 'rootkit',
    symbol: 'root',
    name: 'Rootkit',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13151/large/rootkit_logo.png?1605712875',
    decimals: 18,
    address: '0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e'
  },
  '0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3': {
    id: 'rubic',
    symbol: 'rbc',
    name: 'Rubic',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12629/large/200x200.png?1607952509',
    decimals: 18,
    address: '0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3'
  },
  '0xf278c1ca969095ffddded020290cf8b5c424ace2': {
    id: 'ruff',
    symbol: 'ruff',
    name: 'Ruff',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2726/large/ruff.png?1548608556',
    decimals: 18,
    address: '0xf278c1ca969095ffddded020290cf8b5c424ace2'
  },
  '0x250a3500f48666561386832f1f1f1019b89a2699': {
    id: 'safe2',
    symbol: 'safe2',
    name: 'SAFE2',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12608/large/safe2.jpg?1601259102',
    decimals: 18,
    address: '0x250a3500f48666561386832f1f1f1019b89a2699'
  },
  '0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb': {
    id: 'safepal',
    symbol: 'sfp',
    name: 'SafePal',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13905/large/z_u91wou_400x400.jpg?1612798329',
    decimals: 18,
    address: '0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb'
  },
  '0xb753428af26e81097e7fd17f40c88aaa3e04902c': {
    id: 'saffron-finance',
    symbol: 'sfi',
    name: 'saffron.finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13117/large/sfi_red_250px.png?1606020144',
    decimals: 18,
    address: '0xb753428af26e81097e7fd17f40c88aaa3e04902c'
  },
  '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359': {
    id: 'sai',
    symbol: 'sai',
    name: 'Sai',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1442/large/dai.png?1547035520',
    decimals: 18,
    address: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359'
  },
  '0x066798d9ef0833ccc719076dab77199ecbd178b0': {
    id: 'sake-token',
    symbol: 'sake',
    name: 'SakeToken',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12428/large/sake.png?1599777402',
    decimals: 18,
    address: '0x066798d9ef0833ccc719076dab77199ecbd178b0'
  },
  '0x4156d3342d5c385a87d264f90653733592000581': {
    id: 'salt',
    symbol: 'salt',
    name: 'SALT',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/962/large/salt.png?1548608746',
    decimals: 8,
    address: '0x4156d3342d5c385a87d264f90653733592000581'
  },
  '0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098': {
    id: 'santiment-network-token',
    symbol: 'san',
    name: 'Santiment Network Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/797/large/santiment-network-token.png?1547034571',
    decimals: 18,
    address: '0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098'
  },
  '0xc28e27870558cf22add83540d2126da2e4b464c2': {
    id: 'sashimi',
    symbol: 'sashimi',
    name: 'Sashimi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12427/large/SashimiSwap-200x200.png?1601347413',
    decimals: 18,
    address: '0xc28e27870558cf22add83540d2126da2e4b464c2'
  },
  '0xdf49c9f599a0a9049d97cff34d0c30e468987389': {
    id: 'satt',
    symbol: 'satt',
    name: 'SaTT',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4070/large/satt.png?1547039223',
    decimals: 18,
    address: '0xdf49c9f599a0a9049d97cff34d0c30e468987389'
  },
  '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6': {
    id: 'sbtc',
    symbol: 'sbtc',
    name: 'sBTC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8838/large/XOZPLK3.png?1605006973',
    decimals: 18,
    address: '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6'
  },
  '0xe1afe1fd76fd88f78cbf599ea1846231b8ba3b6b': {
    id: 'sdefi',
    symbol: 'sdefi',
    name: 'sDEFI',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11832/large/sDEFI.png?1594787588',
    decimals: 18,
    address: '0xe1afe1fd76fd88f78cbf599ea1846231b8ba3b6b'
  },
  '0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d': {
    id: 'seigniorage-shares',
    symbol: 'share',
    name: 'Seigniorage Shares',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12306/large/logo_%281%29.png?1607658707',
    decimals: 9,
    address: '0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d'
  },
  '0x4cc19356f2d37338b9802aa8e8fc58b0373296e7': {
    id: 'selfkey',
    symbol: 'key',
    name: 'SelfKey',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2034/large/selfkey.png?1548608934',
    decimals: 18,
    address: '0x4cc19356f2d37338b9802aa8e8fc58b0373296e7'
  },
  '0xa44e5137293e855b1b7bc7e2c6f8cd796ffcb037': {
    id: 'sentinel-group',
    symbol: 'sent',
    name: 'Sentinel',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3625/large/download_%287%29.png?1547038545',
    decimals: 8,
    address: '0xa44e5137293e855b1b7bc7e2c6f8cd796ffcb037'
  },
  '0xc86d054809623432210c107af2e3f619dcfbf652': {
    id: 'sentinel-protocol',
    symbol: 'upp',
    name: 'Sentinel Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3369/large/Sentinel_Protocol.jpg?1547700074',
    decimals: 18,
    address: '0xc86d054809623432210c107af2e3f619dcfbf652'
  },
  '0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8': {
    id: 'sentivate',
    symbol: 'sntvt',
    name: 'Sentivate',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7383/large/2x9veCp.png?1598409975',
    decimals: 18,
    address: '0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8'
  },
  '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb': {
    id: 'seth',
    symbol: 'seth',
    name: 'sETH',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8843/large/9ZAujYk.png?1605006952',
    decimals: 18,
    address: '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb'
  },
  '0xd71ecff9342a5ced620049e616c5035f1db98620': {
    id: 'seur',
    symbol: 'seur',
    name: 'sEUR',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13637/large/sEUR.png?1610609277',
    decimals: 18,
    address: '0xd71ecff9342a5ced620049e616c5035f1db98620'
  },
  '0xd98f75b1a3261dab9eed4956c93f33749027a964': {
    id: 'sharering',
    symbol: 'shr',
    name: 'ShareToken',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3609/large/74586729_2443914875881351_2785018663453851648_n.png?1574898410',
    decimals: 2,
    address: '0xd98f75b1a3261dab9eed4956c93f33749027a964'
  },
  '0x9b02dd390a603add5c07f9fd9175b7dabe8d63b7': {
    id: 'shopping-io',
    symbol: 'spi',
    name: 'Shopping.io',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13537/large/vd4KuYIE_400x400.png?1609578616',
    decimals: 18,
    address: '0x9b02dd390a603add5c07f9fd9175b7dabe8d63b7'
  },
  '0xed0439eacf4c4965ae4613d77a5c2efe10e5f183': {
    id: 'shroom-finance',
    symbol: 'shroom',
    name: 'Shroom.Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12341/large/icon_%285%29.png?1599173559',
    decimals: 18,
    address: '0xed0439eacf4c4965ae4613d77a5c2efe10e5f183'
  },
  '0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca': {
    id: 'simple-token',
    symbol: 'ost',
    name: 'OST',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1367/large/ost.jpg?1547035393',
    decimals: 18,
    address: '0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca'
  },
  '0xaec2e87e0a235266d9c5adc9deb4b2e29b54d009': {
    id: 'singulardtv',
    symbol: 'sngls',
    name: 'SingularDTV',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/521/large/singulardtv.png?1547034199',
    decimals: 0,
    address: '0xaec2e87e0a235266d9c5adc9deb4b2e29b54d009'
  },
  '0x8eb24319393716668d768dcec29356ae9cffe285': {
    id: 'singularitynet',
    symbol: 'agi',
    name: 'SingularityNET',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2138/large/singularitynet.png?1548609559',
    decimals: 8,
    address: '0x8eb24319393716668d768dcec29356ae9cffe285'
  },
  '0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25': {
    id: 'sirin-labs-token',
    symbol: 'srn',
    name: 'Sirin Labs Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1872/large/sirin-labs-token.png?1548609584',
    decimals: 18,
    address: '0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25'
  },
  '0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7': {
    id: 'skale',
    symbol: 'skl',
    name: 'SKALE',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13245/large/SKALE_token_300x300.png?1606789574',
    decimals: 18,
    address: '0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7'
  },
  '0x048fe49be32adfc9ed68c37d32b5ec9df17b3603': {
    id: 'skrumble-network',
    symbol: 'skm',
    name: 'Skrumble Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3826/large/skrumble-network.png?1548609513',
    decimals: 18,
    address: '0x048fe49be32adfc9ed68c37d32b5ec9df17b3603'
  },
  '0x37236cd05b34cc79d3715af2383e96dd7443dcf1': {
    id: 'smooth-love-potion',
    symbol: 'SLP',
    name: 'Small Love Potion',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10366/large/SLP.png?1578640057',
    decimals: 0,
    address: '0x37236cd05b34cc79d3715af2383e96dd7443dcf1'
  },
  '0xa8754b9fa15fc18bb59458815510e40a12cd2014': {
    id: 'smooth-love-potion',
    symbol: 'SLP',
    name: 'Small Love Potion',
    chain: Chain.RONIN,
    logo: 'https://assets.coingecko.com/coins/images/10366/large/SLP.png?1578640057',
    decimals: 0,
    address: '0xa8754b9fa15fc18bb59458815510e40a12cd2014'
  },
  '0x297e4e5e59ad72b1b0a2fd446929e76117be0e0a': {
    id: 'smart-valor',
    symbol: 'valor',
    name: 'Smart Valor',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7231/large/smart_valor.png?1555925772',
    decimals: 18,
    address: '0x297e4e5e59ad72b1b0a2fd446929e76117be0e0a'
  },
  '0x72e9d9038ce484ee986fea183f8d8df93f9ada13': {
    id: 'smartcredit-token',
    symbol: 'smartcredit',
    name: 'SmartCredit Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13036/large/smartcredit_logo_02_white_a-1.png?1604545479',
    decimals: 18,
    address: '0x72e9d9038ce484ee986fea183f8d8df93f9ada13'
  },
  '0x06a01a4d579479dd5d884ebf61a31727a3d8d442': {
    id: 'smartkey',
    symbol: 'skey',
    name: 'SmartKey',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13425/large/smart-key_sign-256.png?1608531133',
    decimals: 8,
    address: '0x06a01a4d579479dd5d884ebf61a31727a3d8d442'
  },
  '0x21f15966e07a10554c364b988e91dab01d32794a': {
    id: 'smartmesh',
    symbol: 'smt',
    name: 'SmartMesh',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1346/large/smartmesh.png?1548609531',
    decimals: 18,
    address: '0x21f15966e07a10554c364b988e91dab01d32794a'
  },
  '0x8578530205cecbe5db83f7f29ecfeec860c297c2': {
    id: 'smartofgiving',
    symbol: 'aog',
    name: 'smARTOFGIVING',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6050/large/logo_%286%29.png?1547042007',
    decimals: 18,
    address: '0x8578530205cecbe5db83f7f29ecfeec860c297c2'
  },
  '0xfe9a29ab92522d14fc65880d817214261d8479ae': {
    id: 'snowswap',
    symbol: 'SNOW',
    name: 'Snowswap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12751/large/uQBJL3A.png?1602237225',
    decimals: 18,
    address: '0xfe9a29ab92522d14fc65880d817214261d8479ae'
  },
  '0x446c9033e7516d820cc9a2ce2d0b7328b579406f': {
    id: 'solve-care',
    symbol: 'solve',
    name: 'SOLVE',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1768/large/Solve.Token_logo_200_200_wiyhout_BG.png?1575869846',
    decimals: 8,
    address: '0x446c9033e7516d820cc9a2ce2d0b7328b579406f'
  },
  '0x983f6d60db79ea8ca4eb9968c6aff8cfa04b3c63': {
    id: 'sonm',
    symbol: 'snm',
    name: 'SONM',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/861/large/sonm.png?1548609871',
    decimals: 18,
    address: '0x983f6d60db79ea8ca4eb9968c6aff8cfa04b3c63'
  },
  '0x40fd72257597aa14c7231a7b1aaa29fce868f677': {
    id: 'sora',
    symbol: 'xor',
    name: 'Sora',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11093/large/sora_logo_cg_white.png?1588284194',
    decimals: 18,
    address: '0x40fd72257597aa14c7231a7b1aaa29fce868f677'
  },
  '0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4': {
    id: 'sora-validator-token',
    symbol: 'val',
    name: 'Sora Validator Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13299/large/val-gold-256.png?1607242927',
    decimals: 18,
    address: '0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4'
  },
  '0x86ed939b500e121c0c5f493f399084db596dad20': {
    id: 'spacechain-erc-20',
    symbol: 'spc',
    name: 'SpaceChain (ERC-20)',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6659/large/Spacechain.jpg?1547042861',
    decimals: 18,
    address: '0x86ed939b500e121c0c5f493f399084db596dad20'
  },
  '0x42d6622dece394b54999fbd73d108123806f6a18': {
    id: 'spankchain',
    symbol: 'spank',
    name: 'SpankChain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1226/large/spankchain.png?1548610811',
    decimals: 18,
    address: '0x42d6622dece394b54999fbd73d108123806f6a18'
  },
  '0xe4ae305ebe1abe663f261bc00534067c80ad677c': {
    id: 'spartan-protocol-token',
    symbol: 'sparta',
    name: 'Spartan Protocol Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12638/large/1*OWy3ohA24pht-tFypfs-Tg.png?1601364302',
    decimals: 18,
    address: '0xe4ae305ebe1abe663f261bc00534067c80ad677c'
  },
  '0xddd460bbd9f79847ea08681563e8a9696867210c': {
    id: 'spendcoin',
    symbol: 'spnd',
    name: 'Spendcoin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6076/large/spend.png?1561607507',
    decimals: 18,
    address: '0xddd460bbd9f79847ea08681563e8a9696867210c'
  },
  '0xbcd4b7de6fde81025f74426d43165a5b0d790fdd': {
    id: 'spiderdao',
    symbol: 'spdr',
    name: 'SpiderDAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13378/large/spiderdao_logo.png?1608029180',
    decimals: 18,
    address: '0xbcd4b7de6fde81025f74426d43165a5b0d790fdd'
  },
  '0xa806b3fed6891136940cf81c4085661500aa2709': {
    id: 'sport-and-leisure',
    symbol: 'snl',
    name: 'Sport and Leisure',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7762/large/745ba052-73b7-4735-a0bf-e75474def055.png?1550456328',
    decimals: 6,
    address: '0xa806b3fed6891136940cf81c4085661500aa2709'
  },
  '0x99fe3b1391503a1bc1788051347a1324bff41452': {
    id: 'sportx',
    symbol: 'sx',
    name: 'SportX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13779/large/sportx.png?1611725183',
    decimals: 18,
    address: '0x99fe3b1391503a1bc1788051347a1324bff41452'
  },
  '0xb987d48ed8f2c468d52d6405624eadba5e76d723': {
    id: 'stabilize',
    symbol: 'stbz',
    name: 'Stabilize',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12753/large/icon.png?1608771101',
    decimals: 18,
    address: '0xb987d48ed8f2c468d52d6405624eadba5e76d723'
  },
  '0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe': {
    id: 'stacktical',
    symbol: 'dsla',
    name: 'DSLA Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6694/large/dsla_logo-squared_200x200.png?1569571063',
    decimals: 18,
    address: '0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe'
  },
  '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f': {
    id: 'stake-dao',
    symbol: 'sdt',
    name: 'Stake DAO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13724/large/stakedao_logo.jpg?1611195011',
    decimals: 18,
    address: '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f'
  },
  '0xae7ab96520de3a18e5e111b5eaab095312d7fe84': {
    id: 'staked-ether',
    symbol: 'steth',
    name: 'Lido Staked Ether',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13442/large/steth_logo.png?1608607546',
    decimals: 18,
    address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84'
  },
  '0xdfe66b14d37c77f4e9b180ceb433d1b164f0281d': {
    id: 'stakehound-staked-ether',
    symbol: 'steth',
    name: 'StakeHound Staked Ether',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13752/large/926MHi5g_400x400.png?1611542247',
    decimals: 18,
    address: '0xdfe66b14d37c77f4e9b180ceb433d1b164f0281d'
  },
  '0xdb25f211ab05b1c97d595516f45794528a807ad8': {
    id: 'stasis-eurs',
    symbol: 'eurs',
    name: 'STASIS EURO',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5164/large/EURS_300x300.png?1550571779',
    decimals: 2,
    address: '0xdb25f211ab05b1c97d595516f45794528a807ad8'
  },
  '0xa7de087329bfcda5639247f96140f9dabe3deed1': {
    id: 'statera',
    symbol: 'sta',
    name: 'Statera',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11472/large/Statera.png?1590415353',
    decimals: 18,
    address: '0xa7de087329bfcda5639247f96140f9dabe3deed1'
  },
  '0x744d70fdbe2ba4cf95131626614a1763df805b9e': {
    id: 'status',
    symbol: 'SNT',
    name: 'Status',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/779/large/status.png?1548610778',
    decimals: 18,
    address: '0x744d70fdbe2ba4cf95131626614a1763df805b9e'
  },
  '0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac': {
    id: 'storj',
    symbol: 'storj',
    name: 'Storj',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/949/large/storj.png?1547034811',
    decimals: 8,
    address: '0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac'
  },
  '0xbe9375c6a420d2eeb258962efb95551a5b722803': {
    id: 'storm',
    symbol: 'stmx',
    name: 'StormX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1369/large/StormX.png?1603113002',
    decimals: 18,
    address: '0xbe9375c6a420d2eeb258962efb95551a5b722803'
  },
  '0xde7d85157d9714eadf595045cc12ca4a5f3e2adb': {
    id: 'stp-network',
    symbol: 'stpt',
    name: 'STP Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8713/large/STP.png?1560262664',
    decimals: 18,
    address: '0xde7d85157d9714eadf595045cc12ca4a5f3e2adb'
  },
  '0x0cf0ee63788a0849fe5297f3407f701e122cc023': {
    id: 'streamr-datacoin',
    symbol: 'data',
    name: 'Streamr DATAcoin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1115/large/streamr.png?1547035101',
    decimals: 18,
    address: '0x0cf0ee63788a0849fe5297f3407f701e122cc023'
  },
  '0x990f341946a3fdb507ae7e52d17851b87168017c': {
    id: 'strong',
    symbol: 'strong',
    name: 'Strong',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12092/large/STRONG-Token-256x256.png?1597823573',
    decimals: 18,
    address: '0x990f341946a3fdb507ae7e52d17851b87168017c'
  },
  '0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7': {
    id: 'suku',
    symbol: 'SUKU',
    name: 'SUKU',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11969/large/UmfW5S6f_400x400.jpg?1596602238',
    decimals: 18,
    address: '0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7'
  },
  '0xf4134146af2d511dd5ea8cdb1c4ac88c57d60404': {
    id: 'suncontract',
    symbol: 'snc',
    name: 'SunContract',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1166/large/suncontract.png?1548611174',
    decimals: 18,
    address: '0xf4134146af2d511dd5ea8cdb1c4ac88c57d60404'
  },
  '0xea319e87cf06203dae107dd8e5672175e3ee976c': {
    id: 'surf-finance',
    symbol: 'surf',
    name: 'Surf.Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12982/large/surf_200x200.png?1604050261',
    decimals: 18,
    address: '0xea319e87cf06203dae107dd8e5672175e3ee976c'
  },
  '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2': {
    id: 'sushi',
    symbol: 'SUSHI',
    name: 'Sushi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png?1606986688',
    decimals: 18,
    address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2'
  },
  '0x87edffde3e14c7a66c9b9724747a1c5696b742e6': {
    id: 'swag-finance',
    symbol: 'swag',
    name: 'SWAG Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12805/large/photo_2020-10-14_23.17.02.jpeg?1602688642',
    decimals: 18,
    address: '0x87edffde3e14c7a66c9b9724747a1c5696b742e6'
  },
  '0xb8baa0e4287890a5f79863ab62b7f175cecbd433': {
    id: 'swerve-dao',
    symbol: 'swrv',
    name: 'Swerve',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12361/large/swerve.png?1599278316',
    decimals: 18,
    address: '0xb8baa0e4287890a5f79863ab62b7f175cecbd433'
  },
  '0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e': {
    id: 'swftcoin',
    symbol: 'swftc',
    name: 'SWFT Blockchain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2346/large/QJB0PBHu_400x400.jpg?1565564254',
    decimals: 8,
    address: '0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e'
  },
  '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9': {
    id: 'swipe',
    symbol: 'sxp',
    name: 'Swipe',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9368/large/swipe.png?1566792311',
    decimals: 18,
    address: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9'
  },
  '0xba9d4199fab4f26efe3551d490e3821486f135ba': {
    id: 'swissborg',
    symbol: 'chsb',
    name: 'SwissBorg',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2117/large/YJUrRy7r_400x400.png?1589794215',
    decimals: 8,
    address: '0xba9d4199fab4f26efe3551d490e3821486f135ba'
  },
  '0x6710c63432a2de02954fc0f851db07146a6c0312': {
    id: 'syncfab',
    symbol: 'mfg',
    name: 'Smart MFG',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1842/large/mfg-logo.png?1608131645',
    decimals: 18,
    address: '0x6710c63432a2de02954fc0f851db07146a6c0312'
  },
  '0x1695936d6a953df699c38ca21c2140d497c08bd9': {
    id: 'synlev',
    symbol: 'syn',
    name: 'SynLev',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12841/large/synlev_logo.jpg?1602945400',
    decimals: 18,
    address: '0x1695936d6a953df699c38ca21c2140d497c08bd9'
  },
  '0x8daebade922df735c38c80c7ebd708af50815faa': {
    id: 'tbtc',
    symbol: 'tbtc',
    name: 'tBTC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11224/large/tBTC.png?1589620754',
    decimals: 18,
    address: '0x8daebade922df735c38c80c7ebd708af50815faa'
  },
  '0x467bccd9d29f223bce8043b84e8c8b282827790f': {
    id: 'telcoin',
    symbol: 'tel',
    name: 'Telcoin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1899/large/tel.png?1547036203',
    decimals: 2,
    address: '0x467bccd9d29f223bce8043b84e8c8b282827790f'
  },
  '0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5': {
    id: 'tellor',
    symbol: 'trb',
    name: 'Tellor',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/9644/large/Blk_icon_current.png?1584980686',
    decimals: 18,
    address: '0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5'
  },
  '0xb97048628db6b661d4c2aa833e95dbe1a905b280': {
    id: 'tenx',
    symbol: 'pay',
    name: 'TenX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/775/large/TenX-Icon-CircleBlack.png?1553766360',
    decimals: 18,
    address: '0xb97048628db6b661d4c2aa833e95dbe1a905b280'
  },
  '0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988': {
    id: 'terra-virtua-kolect',
    symbol: 'tvk',
    name: 'Terra Virtua Kolect',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13330/large/CoinGLogo.png?1607507042',
    decimals: 18,
    address: '0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988'
  },
  '0xa47c8bf37f92abed4a126bda807a7b7498661acd': {
    id: 'terrausd',
    symbol: 'UST',
    name: 'TerraUSD',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12681/large/UST.png?1601612407',
    decimals: 18,
    address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd'
  },
  '0x23396cf899ca06c4472205fc903bdb4de249d6fc': {
    id: 'terrausd',
    symbol: 'UST',
    name: 'TerraUSD',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12681/large/UST.png?1601612407',
    decimals: 18,
    address: '0x23396cf899ca06c4472205fc903bdb4de249d6fc'
  },
  '0xdac17f958d2ee523a2206206994597c13d831ec7': {
    id: 'tether',
    symbol: 'USDT',
    name: 'Tether',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707',
    decimals: 6,
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7'
  },
  '0x55d398326f99059ff775485246999027b3197955': {
    id: 'tether',
    symbol: 'USDT',
    name: 'Binance-Peg BUSD-T',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707',
    decimals: 18,
    address: '0x55d398326f99059ff775485246999027b3197955'
  },
  '0x4922a015c4407f87432b179bb209e125432e4a2a': {
    id: 'tether-gold',
    symbol: 'xaut',
    name: 'Tether Gold',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10481/large/tether-gold.png?1579946148',
    decimals: 6,
    address: '0x4922a015c4407f87432b179bb209e125432e4a2a'
  },
  '0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6': {
    id: 'the-abyss',
    symbol: 'abyss',
    name: 'Abyss',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2105/large/NrFmpxs.png?1600318377',
    decimals: 18,
    address: '0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6'
  },
  '0xc944e90c64b2c07662a292be6244bdf05cda44a7': {
    id: 'the-graph',
    symbol: 'GRT',
    name: 'The Graph',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13397/large/Graph_Token.png?1608145566',
    decimals: 18,
    address: '0xc944e90c64b2c07662a292be6244bdf05cda44a7'
  },
  '0x5fe2b58c013d7601147dcdd68c143a77499f5531': {
    id: 'the-graph',
    symbol: 'GRT',
    name: 'The Graph',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/13397/large/Graph_Token.png?1608145566',
    decimals: 18,
    address: '0x5fe2b58c013d7601147dcdd68c143a77499f5531'
  },
  '0x3845badade8e6dff049820680d1f14bd3903a5d0': {
    id: 'the-sandbox',
    symbol: 'sand',
    name: 'SAND',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12129/large/sandbox_logo.jpg?1597397942',
    decimals: 18,
    address: '0x3845badade8e6dff049820680d1f14bd3903a5d0'
  },
  '0x3155ba85d5f96b2d030a4966af206230e46849cb': {
    id: 'thorchain-erc20',
    symbol: 'rune',
    name: 'THORChain (ERC20)',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13677/large/IMG_20210123_132049_458.png?1612179252',
    decimals: 18,
    address: '0x3155ba85d5f96b2d030a4966af206230e46849cb'
  },
  '0xf7920b0768ecb20a123fac32311d07d193381d6f': {
    id: 'time-new-bank',
    symbol: 'tnb',
    name: 'Time New Bank',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1265/large/time-new-bank.png?1547035290',
    decimals: 18,
    address: '0xf7920b0768ecb20a123fac32311d07d193381d6f'
  },
  '0x3a8cccb969a61532d1e6005e2ce12c200caece87': {
    id: 'titanswap',
    symbol: 'titan',
    name: 'TitanSwap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12606/large/nqGlQzdz_400x400.png?1601019805',
    decimals: 18,
    address: '0x3a8cccb969a61532d1e6005e2ce12c200caece87'
  },
  '0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41': {
    id: 'tixl-new',
    symbol: 'txl',
    name: 'Tixl',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12432/large/Tixl-Logo-200-transparent.png?1610248504',
    decimals: 18,
    address: '0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41'
  },
  '0x2be5e8c109e2197d077d13a82daead6a9b3433c5': {
    id: 'tokamak-network',
    symbol: 'ton',
    name: 'Tokamak Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12260/large/D919x5-s_400x400.png?1598568068',
    decimals: 18,
    address: '0x2be5e8c109e2197d077d13a82daead6a9b3433c5'
  },
  '0xaaaf91d9b90df800df4f55c205fd6989c977e73a': {
    id: 'tokencard',
    symbol: 'tkn',
    name: 'Monolith',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/675/large/Monolith.png?1566296607',
    decimals: 8,
    address: '0xaaaf91d9b90df800df4f55c205fd6989c977e73a'
  },
  '0x4824a7b64e3966b0133f4f4ffb1b9d6beb75fff7': {
    id: 'tokenclub',
    symbol: 'tct',
    name: 'TokenClub',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2455/large/tokenclub.png?1558012103',
    decimals: 18,
    address: '0x4824a7b64e3966b0133f4f4ffb1b9d6beb75fff7'
  },
  '0x0000000000095413afc295d19edeb1ad7b71c952': {
    id: 'tokenlon',
    symbol: 'lon',
    name: 'Tokenlon',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13454/large/lon_logo.png?1608701720',
    decimals: 18,
    address: '0x0000000000095413afc295d19edeb1ad7b71c952'
  },
  '0xdd16ec0f66e54d453e6756713e533355989040e4': {
    id: 'tokenomy',
    symbol: 'ten',
    name: 'Tokenomy',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2286/large/logo_%281%29.png?1604543144',
    decimals: 18,
    address: '0xdd16ec0f66e54d453e6756713e533355989040e4'
  },
  '0xdcd85914b8ae28c1e62f1c488e1d968d5aaffe2b': {
    id: 'top-network',
    symbol: 'top',
    name: 'TOP Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7058/large/topnetwork-logo.png?1547043514',
    decimals: 18,
    address: '0xdcd85914b8ae28c1e62f1c488e1d968d5aaffe2b'
  },
  '0x77777feddddffc19ff86db637967013e6c6a116c': {
    id: 'tornado-cash',
    symbol: 'torn',
    name: 'Tornado Cash',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13496/large/ZINt8NSB_400x400.jpg?1609193407',
    decimals: 18,
    address: '0x77777feddddffc19ff86db637967013e6c6a116c'
  },
  '0x220b71671b649c03714da9c621285943f3cbcdc6': {
    id: 'tosdis',
    symbol: 'dis',
    name: 'TosDis',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13745/large/Tosdis-black.png?1611379744',
    decimals: 18,
    address: '0x220b71671b649c03714da9c621285943f3cbcdc6'
  },
  '0x0000000000085d4780b73119b644ae5ecd22b376': {
    id: 'true-usd',
    symbol: 'tusd',
    name: 'TrueUSD',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3449/large/TUSD.png?1559172762',
    decimals: 18,
    address: '0x0000000000085d4780b73119b644ae5ecd22b376'
  },
  '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784': {
    id: 'truefi',
    symbol: 'tru',
    name: 'TrueFi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13180/large/trust-token.png?1606009370',
    decimals: 8,
    address: '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784'
  },
  '0xcc4304a31d09258b0029ea7fe63d032f52e44efe': {
    id: 'trustswap',
    symbol: 'swap',
    name: 'Trustswap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11795/large/Trustswap.png?1594311216',
    decimals: 18,
    address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe'
  },
  '0xe884cc2795b9c45beeac0607da9539fd571ccf85': {
    id: 'ultiledger',
    symbol: 'ult',
    name: 'Ultiledger',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6747/large/Ultiledger.jpeg?1547042996',
    decimals: 18,
    address: '0xe884cc2795b9c45beeac0607da9539fd571ccf85'
  },
  '0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c': {
    id: 'ultra',
    symbol: 'uos',
    name: 'Ultra',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4480/large/Ultra.png?1563356418',
    decimals: 4,
    address: '0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c'
  },
  '0x8716fc5da009d3a208f0178b637a50f4ef42400f': {
    id: 'ultrain',
    symbol: 'ugas',
    name: 'Ultrain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4684/large/ultrain.png?1547039956',
    decimals: 18,
    address: '0x8716fc5da009d3a208f0178b637a50f4ef42400f'
  },
  '0x04fa0d235c4abf4bcf4787af4cf447de572ef828': {
    id: 'uma',
    symbol: 'uma',
    name: 'UMA',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10951/large/UMA.png?1586307916',
    decimals: 18,
    address: '0x04fa0d235c4abf4bcf4787af4cf447de572ef828'
  },
  '0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6': {
    id: 'unfederalreserve',
    symbol: 'ersdl',
    name: 'UnFederalReserve',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12975/large/unFederalReserve_LogoArtboard_1_copy_20-64.png?1610087806',
    decimals: 18,
    address: '0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6'
  },
  '0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e': {
    id: 'unibright',
    symbol: 'ubt',
    name: 'Unibright',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2707/large/UnibrightLogo_colorful_500x500_preview.png?1547036916',
    decimals: 8,
    address: '0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e'
  },
  '0xf29e46887ffae92f1ff87dfe39713875da541373': {
    id: 'unicrypt',
    symbol: 'unc',
    name: 'UniCrypt (Old)',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11782/large/200x200_%289%29.png?1593999474',
    decimals: 18,
    address: '0xf29e46887ffae92f1ff87dfe39713875da541373'
  },
  '0xadb2437e6f65682b85f814fbc12fec0508a7b1d0': {
    id: 'unicrypt-2',
    symbol: 'uncx',
    name: 'UniCrypt',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12871/large/unicrypt_logo.png?1603178739',
    decimals: 18,
    address: '0xadb2437e6f65682b85f814fbc12fec0508a7b1d0'
  },
  '0xa5959e9412d27041194c3c3bcbe855face2864f7': {
    id: 'unidexgas',
    symbol: 'undg',
    name: 'UniDexGas',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13740/large/undg_logo_200_.png?1611305562',
    decimals: 18,
    address: '0xa5959e9412d27041194c3c3bcbe855face2864f7'
  },
  '0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5': {
    id: 'unifi',
    symbol: 'unifi',
    name: 'Unifi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12258/large/Unifi.png?1598548933',
    decimals: 18,
    address: '0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5'
  },
  // '0x441761326490cacf7af299725b6292597ee822c2': {
  //   id: 'unifi-protocol-dao',
  //   symbol: 'unfi',
  //   name: 'Unifi Protocol DAO',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://assets.coingecko.com/coins/images/13152/large/logo-2.png?1605748967',
  //   decimals: 18,
  //   address: '0x441761326490cacf7af299725b6292597ee822c2'
  // },
  '0x0ff6ffcfda92c53f615a4a75d982f399c989366b': {
    id: 'unilayer',
    symbol: 'layer',
    name: 'UniLayer',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12164/large/Unilayer.jpg?1597779313',
    decimals: 18,
    address: '0x0ff6ffcfda92c53f615a4a75d982f399c989366b'
  },
  '0x10be9a8dae441d276a5027936c3aaded2d82bc15': {
    id: 'unimex-network',
    symbol: 'umx',
    name: 'UniMex Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13615/large/Unimex-Brandmark-RGB-Small_200x200.png?1612758290',
    decimals: 18,
    address: '0x10be9a8dae441d276a5027936c3aaded2d82bc15'
  },
  '0x226f7b842e0f0120b7e194d05432b3fd14773a9d': {
    id: 'union-protocol-governance-token',
    symbol: 'unn',
    name: 'UNION Protocol Governance Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13408/large/unn_finance.png?1608262290',
    decimals: 18,
    address: '0x226f7b842e0f0120b7e194d05432b3fd14773a9d'
  },
  '0x23b608675a2b2fb1890d3abbd85c5775c51691d5': {
    id: 'unisocks',
    symbol: 'socks',
    name: 'Unisocks',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10717/large/qFrcoiM.png?1582525244',
    decimals: 18,
    address: '0x23b608675a2b2fb1890d3abbd85c5775c51691d5'
  },
  '0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e': {
    id: 'unistake',
    symbol: 'unistake',
    name: 'Unistake',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12813/large/unistake.png?1612346684',
    decimals: 18,
    address: '0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e'
  },
  '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984': {
    id: 'uniswap',
    symbol: 'UNI',
    name: 'Uniswap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12504/large/uniswap-uni.png?1600306604',
    decimals: 18,
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984'
  },
  '0xbf5140a22578168fd562dccf235e5d43a02ce9b1': {
    id: 'uniswap',
    symbol: 'UNI',
    name: 'Binance-Peg Uniswap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12504/large/uniswap-uni.png?1600306604',
    decimals: 18,
    address: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1'
  },
  '0xc76fb75950536d98fa62ea968e1d6b45ffea2a55': {
    id: 'unit-protocol',
    symbol: 'col',
    name: 'Unit Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11862/large/Unit.png?1595580755',
    decimals: 18,
    address: '0xc76fb75950536d98fa62ea968e1d6b45ffea2a55'
  },
  '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5': {
    id: 'unit-protocol-duck',
    symbol: 'duck',
    name: 'Unit Protocol New',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13359/large/unit_telegram.png?1607878022',
    decimals: 18,
    address: '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5'
  },
  '0x6f87d756daf0503d08eb8993686c7fc01dc44fb1': {
    id: 'unitrade',
    symbol: 'trade',
    name: 'Unitrade',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11982/large/unitrade.PNG?1597009487',
    decimals: 18,
    address: '0x6f87d756daf0503d08eb8993686c7fc01dc44fb1'
  },
  '0x9e3319636e2126e3c0bc9e3134aec5e1508a46c7': {
    id: 'universa',
    symbol: 'utnp',
    name: 'Universa',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2981/large/universa.png?1547037236',
    decimals: 18,
    address: '0x9e3319636e2126e3c0bc9e3134aec5e1508a46c7'
  },
  '0x0202be363b8a4820f3f4de7faf5224ff05943ab1': {
    id: 'unlend-finance',
    symbol: 'uft',
    name: 'UniLend Finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12819/large/UniLend_Finance_logo_PNG.png?1602748658',
    decimals: 18,
    address: '0x0202be363b8a4820f3f4de7faf5224ff05943ab1'
  },
  '0x4290563c2d7c255b5eec87f2d3bd10389f991d68': {
    id: 'unlimitedip',
    symbol: 'uip',
    name: 'UnlimitedIP',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2642/large/unlimitedip.png?1547036818',
    decimals: 18,
    address: '0x4290563c2d7c255b5eec87f2d3bd10389f991d68'
  },
  '0x8564653879a18c560e7c0ea0e084c516c62f5653': {
    id: 'upbots',
    symbol: 'ubxt',
    name: 'UpBots',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12476/large/UBXT.png?1600132967',
    decimals: 18,
    address: '0x8564653879a18c560e7c0ea0e084c516c62f5653'
  },
  '0x8806926ab68eb5a7b909dcaf6fdbe5d93271d6e2': {
    id: 'uquid-coin',
    symbol: 'uqc',
    name: 'Uquid Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1341/large/uquid-coin.png?1548759712',
    decimals: 18,
    address: '0x8806926ab68eb5a7b909dcaf6fdbe5d93271d6e2'
  },
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': {
    id: 'usd-coin',
    symbol: 'USDC',
    name: 'USD Coin',
    chain: Chain.ETHEREUM,
    logo: '/images/coin/usdc.png',
    decimals: 6,
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
  },
  '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d': {
    id: 'usd-coin',
    symbol: 'USDC',
    name: 'Binance-Peg USD Coin',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/usdc.png',
    decimals: 18,
    address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'
  },
  '0x1c48f86ae57291f7686349f12601910bd8d470bb': {
    id: 'usdk',
    symbol: 'usdk',
    name: 'USDK',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/8824/large/usdk.png?1563418517',
    decimals: 18,
    address: '0x1c48f86ae57291f7686349f12601910bd8d470bb'
  },
  '0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c': {
    id: 'utrust',
    symbol: 'utk',
    name: 'UTRUST',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1824/large/300x300_logo.png?1570520533',
    decimals: 18,
    address: '0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c'
  },
  '0xa58a4f5c4bb043d2cc1e170613b74e767c94189b': {
    id: 'utu-coin',
    symbol: 'utu',
    name: 'UTU Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12831/large/Aa5nmbkJ_400x400.png?1602884636',
    decimals: 18,
    address: '0xa58a4f5c4bb043d2cc1e170613b74e767c94189b'
  },
  '0xfef4185594457050cc9c23980d301908fe057bb1': {
    id: 'v-id-blockchain',
    symbol: 'vidt',
    name: 'VIDT Datalink',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6115/large/V-ID_blockchain_-logo.png?1547042127',
    decimals: 18,
    address: '0xfef4185594457050cc9c23980d301908fe057bb1'
  },
  '0x4bd17003473389a42daf6a0a729f6fdb328bbbd7': {
    id: 'vai',
    symbol: 'vai',
    name: 'Vai',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13861/large/VAI_logo.png?1612413571',
    decimals: 18,
    address: '0x4bd17003473389a42daf6a0a729f6fdb328bbbd7'
  },
  '0x49e833337ece7afe375e44f4e3e8481029218e5c': {
    id: 'value-liquidity',
    symbol: 'value',
    name: 'Value Liquidity',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12525/large/value_logo_-_500x500.png?1601478115',
    decimals: 18,
    address: '0x49e833337ece7afe375e44f4e3e8481029218e5c'
  },
  '0xc5bddf9843308380375a611c18b50fb9341f502a': {
    id: 'vecrv-dao-yvault',
    symbol: 'yve-crvdao',
    name: 'veCRV-DAO yVault',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13065/large/yearn_veCRV.png?1612862859',
    decimals: 18,
    address: '0xc5bddf9843308380375a611c18b50fb9341f502a'
  },
  '0x7d29a64504629172a429e64183d6673b9dacbfce': {
    id: 'vectorspace',
    symbol: 'vxv',
    name: 'Vectorspace AI',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2063/large/vectorspace-ai-logo.jpeg?1547036362',
    decimals: 18,
    address: '0x7d29a64504629172a429e64183d6673b9dacbfce'
  },
  '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63': {
    id: 'venus',
    symbol: 'xvs',
    name: 'Venus',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/xvs.png',
    decimals: 18,
    address: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63'
  },
  '0xdf1d6405df92d981a2fb3ce68f6a03bac6c0e41f': {
    id: 'verasity',
    symbol: 'vra',
    name: 'Verasity',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3481/large/3mefBCd.png?1547038219',
    decimals: 18,
    address: '0xdf1d6405df92d981a2fb3ce68f6a03bac6c0e41f'
  },
  '0x8f3470a7388c05ee4e7af3d01d8c722b0ff52374': {
    id: 'veritaseum',
    symbol: 'veri',
    name: 'Veritaseum',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/695/large/veritaseum.png?1547034460',
    decimals: 18,
    address: '0x8f3470a7388c05ee4e7af3d01d8c722b0ff52374'
  },
  '0xabc430136a4de71c9998242de8c1b4b97d2b9045': {
    id: 'veros',
    symbol: 'vrs',
    name: 'Veros',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/539/large/vrs_logo.png?1583913100',
    decimals: 6,
    address: '0xabc430136a4de71c9998242de8c1b4b97d2b9045'
  },
  '0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724': {
    id: 'viberate',
    symbol: 'vib',
    name: 'Viberate',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/983/large/Viberate.png?1547034873',
    decimals: 18,
    address: '0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724'
  },
  '0x2c9023bbc572ff8dc1228c7858a280046ea8c9e5': {
    id: 'videocoin',
    symbol: 'vid',
    name: 'VideoCoin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/3376/large/Dark.png?1589435901',
    decimals: 18,
    address: '0x2c9023bbc572ff8dc1228c7858a280046ea8c9e5'
  },
  '0x286bda1413a2df81731d4930ce2f862a35a609fe': {
    id: 'wabi',
    symbol: 'wabi',
    name: 'Wabi',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1338/large/Tael.png?1547035364',
    decimals: 18,
    address: '0x286bda1413a2df81731d4930ce2f862a35a609fe'
  },
  '0xb7cb1c96db6b22b0d3d9536e0108d062bd488f74': {
    id: 'waltonchain',
    symbol: 'wtc',
    name: 'Waltonchain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1093/large/ggx6nnW.png?1604815811',
    decimals: 18,
    address: '0xb7cb1c96db6b22b0d3d9536e0108d062bd488f74'
  },
  '0x4cf488387f035ff08c371515562cba712f9015d4': {
    id: 'wepower',
    symbol: 'wpr',
    name: 'WePower',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/1951/large/wpr.png?1547036237',
    decimals: 18,
    address: '0x4cf488387f035ff08c371515562cba712f9015d4'
  },
  '0x9355372396e3f6daf13359b7b607a3374cc638e0': {
    id: 'whale',
    symbol: 'whale',
    name: 'WHALE',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11797/large/WHALE.png?1595004706',
    decimals: 4,
    address: '0x9355372396e3f6daf13359b7b607a3374cc638e0'
  },
  '0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44': {
    id: 'whiteheart',
    symbol: 'white',
    name: 'Whiteheart',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13484/large/whiteheart.png?1609076548',
    decimals: 18,
    address: '0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44'
  },
  '0xbd356a39bff2cada8e9248532dd879147221cf76': {
    id: 'wom-token',
    symbol: 'wom',
    name: 'WOM Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4559/large/wom_logo_small.png?1572098941',
    decimals: 18,
    address: '0xbd356a39bff2cada8e9248532dd879147221cf76'
  },
  '0x4691937a7508860f876c9c0a2a617e7d9e945d4b': {
    id: 'wootrade-network',
    symbol: 'woo',
    name: 'Wootrade Network',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12921/large/w2UiemF__400x400.jpg?1603670367',
    decimals: 18,
    address: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b'
  },
  '0x34950ff2b487d9e5282c5ab342d08a2f712eb79f': {
    id: 'wozx',
    symbol: 'wozx',
    name: 'Efforce',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13310/large/rZ6Oe3dm_400x400.jpg?1607331889',
    decimals: 18,
    address: '0x34950ff2b487d9e5282c5ab342d08a2f712eb79f'
  },
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599': {
    id: 'wrapped-bitcoin',
    symbol: 'wBTC',
    name: 'Wrapped Bitcoin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1548822744',
    decimals: 8,
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'
  },
  '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c': {
    id: 'wrapped-bitcoin',
    symbol: 'BTCB',
    name: 'Binance-Peg BTCB',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1548822744',
    decimals: 18,
    address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c'
  },
  '0xa0afaa285ce85974c3c881256cb7f225e3a1178a': {
    id: 'wrapped-crescofin',
    symbol: 'wcres',
    name: 'Wrapped CrescoFin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13254/large/cres_logo.png?1606728821',
    decimals: 18,
    address: '0xa0afaa285ce85974c3c881256cb7f225e3a1178a'
  },
  '0x0d438f3b5175bebc262bf23753c1e53d03432bde': {
    id: 'wrapped-nxm',
    symbol: 'wnxm',
    name: 'Wrapped NXM',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11890/large/wrapped-nexus-mutual.jpg?1595811559',
    decimals: 18,
    address: '0x0d438f3b5175bebc262bf23753c1e53d03432bde'
  },
  '0x0ae055097c6d159879521c384f1d2123d1f195e6': {
    id: 'xdai-stake',
    symbol: 'stake',
    name: 'xDAI Stake',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11061/large/xdai.png?1587714165',
    decimals: 18,
    address: '0x0ae055097c6d159879521c384f1d2123d1f195e6'
  },
  '0x0f7f961648ae6db43c75663ac7e5414eb79b5704': {
    id: 'xio',
    symbol: 'xio',
    name: 'Blockzero Labs',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10029/large/blockzero.jpg?1611110205',
    decimals: 18,
    address: '0x0f7f961648ae6db43c75663ac7e5414eb79b5704'
  },
  '0x0f8c45b896784a1e408526b9300519ef8660209c': {
    id: 'xmax',
    symbol: 'xmx',
    name: 'XMax',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4643/large/xmx.png?1574682262',
    decimals: 8,
    address: '0x0f8c45b896784a1e408526b9300519ef8660209c'
  },
  '0x4bae380b5d762d543d426331b8437926443ae9ec': {
    id: 'xvix',
    symbol: 'xvix',
    name: 'XVIX',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13364/large/xvix_logo.png?1607914655',
    decimals: 18,
    address: '0x4bae380b5d762d543d426331b8437926443ae9ec'
  },
  '0x0aacfbec6a24756c20d41914f2caba817c0d8521': {
    id: 'yam-2',
    symbol: 'yam',
    name: 'YAM',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12530/large/YAM-icon.png?1600495536',
    decimals: 18,
    address: '0x0aacfbec6a24756c20d41914f2caba817c0d8521'
  },
  '0xb1dc9124c395c1e97773ab855d66e879f053a289': {
    id: 'yaxis',
    symbol: 'yax',
    name: 'yAxis',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12620/large/Logo.png?1608310944',
    decimals: 18,
    address: '0xb1dc9124c395c1e97773ab855d66e879f053a289'
  },
  '0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d': {
    id: 'yearn-ecosystem-token-index',
    symbol: 'yeti',
    name: 'Yearn Ecosystem Token Index',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13332/large/yeti.png?1607563342',
    decimals: 18,
    address: '0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d'
  },
  '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': {
    id: 'yearn-finance',
    symbol: 'yfi',
    name: 'yearn.finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11849/large/yfi-192x192.png?1598325330',
    decimals: 18,
    address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e'
  },
  '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e': {
    id: 'yearn-finance',
    symbol: 'yfi',
    name: 'Binance-Peg yearn.finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11849/large/yfi-192x192.png?1598325330',
    decimals: 18,
    address: '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e'
  },
  '0x922105fad8153f516bcfb829f56dc097a0e1d705': {
    id: 'yee',
    symbol: 'yee',
    name: 'Yee',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2576/large/YeeCo-logo.png?1598519113',
    decimals: 18,
    address: '0x922105fad8153f516bcfb829f56dc097a0e1d705'
  },
  '0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577': {
    id: 'yfdai-finance',
    symbol: 'yf-dai',
    name: 'YfDAI.finance',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12385/large/LOGO_Coingechko.png?1600514429',
    decimals: 18,
    address: '0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577'
  },
  '0xa1d0e215a23d7030842fc67ce582a6afa3ccab83': {
    id: 'yfii-finance',
    symbol: 'yfii',
    name: 'DFI.money',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/11902/large/YFII-logo.78631676.png?1598677348',
    decimals: 18,
    address: '0xa1d0e215a23d7030842fc67ce582a6afa3ccab83'
  },
  '0x28cb7e841ee97947a86b06fa4090c8451f64c0be': {
    id: 'yflink',
    symbol: 'yfl',
    name: 'YF Link',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12081/large/YFLink.png?1596987945',
    decimals: 18,
    address: '0x28cb7e841ee97947a86b06fa4090c8451f64c0be'
  },
  '0xdcb01cc464238396e213a6fdd933e36796eaff9f': {
    id: 'yield',
    symbol: 'yld',
    name: 'Yield',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13336/large/yieldcredit-logo-1024.png?1607653444',
    decimals: 18,
    address: '0xdcb01cc464238396e213a6fdd933e36796eaff9f'
  },
  '0xf94b5c5651c888d928439ab6514b93944eee6f48': {
    id: 'yield-app',
    symbol: 'yld',
    name: 'YIELD App',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13365/large/Icon_Blue.png?1607917500',
    decimals: 18,
    address: '0xf94b5c5651c888d928439ab6514b93944eee6f48'
  },
  '0xae1eaae3f627aaca434127644371b67b18444051': {
    id: 'yield-optimization-platform',
    symbol: 'yop',
    name: 'Yield Optimization Chain & Protocol',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13678/large/J7zykPx.jpg?1610802162',
    decimals: 8,
    address: '0xae1eaae3f627aaca434127644371b67b18444051'
  },
  '0x34364bee11607b1963d66bca665fde93fca666a8': {
    id: 'you-chain',
    symbol: 'you',
    name: 'YOU Chain',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4649/large/youchain-logo.jpg?1547039915',
    decimals: 18,
    address: '0x34364bee11607b1963d66bca665fde93fca666a8'
  },
  '0x37e1160184f7dd29f00b78c050bf13224780b0b0': {
    id: 'yuan-chain-coin',
    symbol: 'ycc',
    name: 'Yuan Chain Coin',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5328/large/G5gCEo-J_400x400.jpg?1547040897',
    decimals: 8,
    address: '0x37e1160184f7dd29f00b78c050bf13224780b0b0'
  },
  '0x5ab793e36070f0fac928ea15826b0c1bc5365119': {
    id: 'yuki-coin',
    symbol: 'yuki',
    name: 'YUKI COIN',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/5464/large/KoNqMvi.png?1547041201',
    decimals: 8,
    address: '0x5ab793e36070f0fac928ea15826b0c1bc5365119'
  },
  '0x6781a0f84c7e9e846dcb84a9a5bd49333067b104': {
    id: 'zap',
    symbol: 'zap',
    name: 'Zap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/2180/large/zap.png?1547036476',
    decimals: 18,
    address: '0x6781a0f84c7e9e846dcb84a9a5bd49333067b104'
  },
  '0xbd0793332e9fb844a52a205a233ef27a5b34b927': {
    id: 'zb-token',
    symbol: 'zb',
    name: 'ZB Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/4773/large/zb-token-logo.png?1547040106',
    decimals: 18,
    address: '0xbd0793332e9fb844a52a205a233ef27a5b34b927'
  },
  '0xb1e9157c2fdcc5a856c8da8b2d89b6c32b3c1229': {
    id: 'zenfuse',
    symbol: 'zefu',
    name: 'Zenfuse',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12796/large/zenfuse.jpg?1602640333',
    decimals: 18,
    address: '0xb1e9157c2fdcc5a856c8da8b2d89b6c32b3c1229'
  },
  '0x9d1233cc46795e94029fda81aaadc1455d510f15': {
    id: 'zero-collateral-dai',
    symbol: 'zai',
    name: 'Zero Collateral Dai',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13569/large/zai_logo.png?1609844802',
    decimals: 18,
    address: '0x9d1233cc46795e94029fda81aaadc1455d510f15'
  },
  '0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5': {
    id: 'zeroswap',
    symbol: 'zee',
    name: 'ZeroSwap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/12861/large/zeroswap.jpg?1603111827',
    decimals: 18,
    address: '0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5'
  },
  '0x837010619aeb2ae24141605afc8f66577f6fb2e7': {
    id: 'zhegic',
    symbol: 'zhegic',
    name: 'zHEGIC',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13074/large/zhegic_logo.png?1604903561',
    decimals: 18,
    address: '0x837010619aeb2ae24141605afc8f66577f6fb2e7'
  },
  '0xaa602de53347579f86b996d2add74bb6f79462b2': {
    id: 'zipmex-token',
    symbol: 'zmt',
    name: 'Zipmex Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13866/large/zipmex.png?1612428520',
    decimals: 18,
    address: '0xaa602de53347579f86b996d2add74bb6f79462b2'
  },
  '0xe4815ae53b124e7263f08dcdbbb757d41ed658c6': {
    id: 'zkswap',
    symbol: 'zks',
    name: 'ZKSwap',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13585/large/hfmvDpha_400x400.jpg?1609922062',
    decimals: 18,
    address: '0xe4815ae53b124e7263f08dcdbbb757d41ed658c6'
  },
  '0xa8e7ad77c60ee6f30bac54e2e7c0617bd7b5a03e': {
    id: 'zlot',
    symbol: 'zlot',
    name: 'zLOT',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/13073/large/z-LOT-logo-transparent.png?1604900416',
    decimals: 18,
    address: '0xa8e7ad77c60ee6f30bac54e2e7c0617bd7b5a03e'
  },
  '0xfe39e6a32acd2af7955cb3d406ba2b55c901f247': {
    id: 'ztcoin',
    symbol: 'zt',
    name: 'ZBG Token',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/6566/large/ztcoin.jpg?1547042777',
    decimals: 18,
    address: '0xfe39e6a32acd2af7955cb3d406ba2b55c901f247'
  },
  terra15gwkyepfc6xgca5t5zefzwy42uts8l2m4g40k6: {
    id: 'mirror-protocol',
    symbol: 'mir',
    name: 'Mirror Protocol',
    chain: Chain.TERRA,
    logo: 'https://assets.coingecko.com/coins/images/13295/large/mirror_logo_transparent.png?1611554658',
    decimals: 6,
    address: 'terra15gwkyepfc6xgca5t5zefzwy42uts8l2m4g40k6'
  },
  terra1vxtwu4ehgzz77mnfwrntyrmgl64qjs75mpwqaz: {
    id: 'mirrored-apple',
    symbol: 'maapl',
    name: 'Mirrored Apple',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/AAPL.png',
    decimals: 6,
    address: 'terra1vxtwu4ehgzz77mnfwrntyrmgl64qjs75mpwqaz'
  },
  terra1h8arz2k547uvmpxctuwush3jzc8fun4s96qgwt: {
    id: 'mirrored-google',
    symbol: 'mgoogl',
    name: 'Mirrored Google',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/GOOGL.png',
    decimals: 6,
    address: 'terra1h8arz2k547uvmpxctuwush3jzc8fun4s96qgwt'
  },
  '0x62d71b23bf15218c7d2d7e48dbbd9e9c650b173f': {
    id: 'mirrored-google',
    symbol: 'mgoogl',
    name: 'Mirrored Google',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://whitelist.mirror.finance/images/GOOGL.png',
    decimals: 18,
    address: '0x62d71b23bf15218c7d2d7e48dbbd9e9c650b173f'
  },
  terra14y5affaarufk3uscy2vr6pe6w6zqf2wpjzn5sh: {
    id: 'mirrored-tesla',
    symbol: 'mtsla',
    name: 'Mirrored Tesla',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/TSLA.png',
    decimals: 6,
    address: 'terra14y5affaarufk3uscy2vr6pe6w6zqf2wpjzn5sh'
  },
  '0xf215a127a196e3988c09d052e16bcfd365cd7aa3': {
    id: 'mirrored-tesla',
    symbol: 'mtsla',
    name: 'Mirrored Tesla',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://whitelist.mirror.finance/images/TSLA.png',
    decimals: 18,
    address: '0xf215a127a196e3988c09d052e16bcfd365cd7aa3'
  },
  terra1jsxngqasf2zynj5kyh0tgq9mj3zksa5gk35j4k: {
    id: 'mirrored-netflix',
    symbol: 'mnflx',
    name: 'Mirrored Netflix',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/NFLX.png',
    decimals: 6,
    address: 'terra1jsxngqasf2zynj5kyh0tgq9mj3zksa5gk35j4k'
  },
  '0xa04f060077d90fe2647b61e4da4ad1f97d6649dc': {
    id: 'mirrored-netflix',
    symbol: 'mnflx',
    name: 'Mirrored Netflix',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://whitelist.mirror.finance/images/NFLX.png',
    decimals: 18,
    address: '0xa04f060077d90fe2647b61e4da4ad1f97d6649dc'
  },
  terra1csk6tc7pdmpr782w527hwhez6gfv632tyf72cp: {
    id: 'mirrored-invesco-qqq-trust',
    symbol: 'mqqq',
    name: 'Mirrored Invesco QQQ Trust',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/QQQ.png',
    decimals: 6,
    address: 'terra1csk6tc7pdmpr782w527hwhez6gfv632tyf72cp'
  },
  terra1cc3enj9qgchlrj34cnzhwuclc4vl2z3jl7tkqg: {
    id: 'mirrored-twitter',
    symbol: 'mtwtr',
    name: 'Mirrored Twitter',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/TWTR.png',
    decimals: 6,
    address: 'terra1cc3enj9qgchlrj34cnzhwuclc4vl2z3jl7tkqg'
  },
  terra1227ppwxxj3jxz8cfgq00jgnxqcny7ryenvkwj6: {
    id: 'mirrored-microsoft',
    symbol: 'mmsft',
    name: 'Mirrored Microsoft',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/MSFT.png',
    decimals: 6,
    address: 'terra1227ppwxxj3jxz8cfgq00jgnxqcny7ryenvkwj6'
  },
  terra165nd2qmrtszehcfrntlplzern7zl4ahtlhd5t2: {
    id: 'mirrored-amazon',
    symbol: 'mamzn',
    name: 'Mirrored Amazon',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/AMZN.png',
    decimals: 6,
    address: 'terra165nd2qmrtszehcfrntlplzern7zl4ahtlhd5t2'
  },
  '0x3947b992dc0147d2d89df0392213781b04b25075': {
    id: 'mirrored-amazon',
    symbol: 'mamzn',
    name: 'Mirrored Amazon',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://whitelist.mirror.finance/images/AMZN.png',
    decimals: 18,
    address: '0x3947b992dc0147d2d89df0392213781b04b25075'
  },
  terra1w7zgkcyt7y4zpct9dw8mw362ywvdlydnum2awa: {
    id: 'mirrored-alibaba',
    symbol: 'mbaba',
    name: 'Mirrored Alibaba',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/BABA.png',
    decimals: 6,
    address: 'terra1w7zgkcyt7y4zpct9dw8mw362ywvdlydnum2awa'
  },
  terra15hp9pr8y4qsvqvxf3m4xeptlk7l8h60634gqec: {
    id: 'mirrored-ishares-gold-trust',
    symbol: 'miau',
    name: 'Mirrored iShares Gold Trust',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/IAU.png',
    decimals: 6,
    address: 'terra15hp9pr8y4qsvqvxf3m4xeptlk7l8h60634gqec'
  },
  terra1kscs6uhrqwy6rx5kuw5lwpuqvm3t6j2d6uf2lp: {
    id: 'mirrored-ishares-silver-trust',
    symbol: 'mslv',
    name: 'Mirrored iShares Silver Trust',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/SLV.png',
    decimals: 6,
    address: 'terra1kscs6uhrqwy6rx5kuw5lwpuqvm3t6j2d6uf2lp'
  },
  terra1lvmx8fsagy70tv0fhmfzdw9h6s3sy4prz38ugf: {
    id: 'mirrored-united-states-oil-fund',
    symbol: 'muso',
    name: 'Mirrored United States Oil Fund',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/USO.png',
    decimals: 6,
    address: 'terra1lvmx8fsagy70tv0fhmfzdw9h6s3sy4prz38ugf'
  },
  terra1zp3a6q6q4953cz376906g5qfmxnlg77hx3te45: {
    id: 'mirrored-proshares-vix',
    symbol: 'mvixy',
    name: 'Mirrored ProShares VIX',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/VIXY.png',
    decimals: 6,
    address: 'terra1zp3a6q6q4953cz376906g5qfmxnlg77hx3te45'
  },
  terra1g4x2pzmkc9z3mseewxf758rllg08z3797xly0n: {
    id: 'mirrored-airbnb',
    symbol: 'mabnb',
    name: 'Mirrored Airbnb',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/ABNB.png',
    decimals: 6,
    address: 'terra1g4x2pzmkc9z3mseewxf758rllg08z3797xly0n'
  },
  terra1rhhvx8nzfrx5fufkuft06q5marfkucdqwq5sjw: {
    id: 'mirrored-bitcoin',
    symbol: 'mbtc',
    name: 'Mirrored Bitcoin',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/icon/BTC.png',
    decimals: 6,
    address: 'terra1rhhvx8nzfrx5fufkuft06q5marfkucdqwq5sjw'
  },
  terra1dk3g53js3034x4v5c3vavhj2738une880yu6kx: {
    id: 'mirrored-ether',
    symbol: 'meth',
    name: 'Mirrored Ether',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/icon/ETH.png',
    decimals: 6,
    address: 'terra1dk3g53js3034x4v5c3vavhj2738une880yu6kx'
  },
  terra1qelfthdanju7wavc5tq0k5r0rhsyzyyrsn09qy: {
    id: 'mirrored-amc-entertainment',
    symbol: 'mamc',
    name: 'Mirrored AMC Entertainment',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/icon/AMC.png',
    decimals: 6,
    address: 'terra1qelfthdanju7wavc5tq0k5r0rhsyzyyrsn09qy'
  },
  terra1m6j6j9gw728n82k78s0j9kq8l5p6ne0xcc820p: {
    id: 'mirrored-gamestop',
    symbol: 'mgme',
    name: 'Mirrored GameStop',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/images/GME.png',
    decimals: 6,
    address: 'terra1m6j6j9gw728n82k78s0j9kq8l5p6ne0xcc820p'
  },
  terra1mqsjugsugfprn3cvgxsrr8akkvdxv2pzc74us7: {
    id: 'mirrored-facebook',
    symbol: 'mfb',
    name: 'Mirrored Facebook',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/icon/FB.png',
    decimals: 6,
    address: 'terra1mqsjugsugfprn3cvgxsrr8akkvdxv2pzc74us7'
  },
  terra137drsu8gce5thf6jr5mxlfghw36rpljt3zj73v: {
    id: 'mirrored-goldman-sachs',
    symbol: 'mgs',
    name: 'Mirrored Goldman Sachs',
    chain: Chain.TERRA,
    logo: 'https://whitelist.mirror.finance/icon/GS.png',
    decimals: 6,
    address: 'terra137drsu8gce5thf6jr5mxlfghw36rpljt3zj73v'
  },
  // terra1aa00lpfexyycedfg5k2p60l9djcmw0ue5l8fhc: {
  //   id: 'mirrored-spy',
  //   symbol: 'mspy',
  //   name: 'Mirrored S7P 500',
  //   chain: Chain.TERRA,
  //   logo: 'https://whitelist.mirror.finance/icon/GS.png',
  //   decimals: 6,
  //   address: 'terra1aa00lpfexyycedfg5k2p60l9djcmw0ue5l8fhc'
  // },
  terra_ust: {
    id: 'terrausd',
    symbol: 'UST',
    name: 'TerraUSD',
    chain: Chain.TERRA,
    logo: 'https://assets.terra.money/icon/60/UST.png',
    decimals: 6,
    address: 'terra_ust'
  },
  uusd: {
    id: 'terrausd',
    symbol: 'UST',
    name: 'TerraUSD',
    chain: Chain.TERRA,
    logo: 'https://assets.terra.money/icon/60/UST.png',
    decimals: 6,
    address: 'uusd'
  },
  uluna: {
    id: 'terra-luna',
    symbol: 'LUNA',
    name: 'LUNA',
    chain: Chain.TERRA,
    logo: 'https://assets.terra.money/icon/60/Luna.png',
    decimals: 6,
    address: 'uluna'
  },
  bluna: {
    id: 'terra-luna',
    symbol: 'bluna',
    name: 'Bonded LUNA',
    chain: Chain.TERRA,
    logo: 'https://app.anchorprotocol.com/static/media/bluna.0f5e5a4f.svg',
    decimals: 6,
    address: 'uluna'
  },
  ukrw: {
    id: 'terra-krw',
    symbol: 'KRT',
    name: 'TerraKRT',
    chain: Chain.TERRA,
    logo: 'https://assets.terra.money/icon/60/KRT.png',
    decimals: 6,
    address: 'ukrw'
  },
  terra14z56l0fp2lsf86zy3hty2z47ezkhnthtr9yq76: {
    id: 'anchor-protocol',
    symbol: 'ANC',
    name: 'Anchor Protocol',
    chain: Chain.TERRA,
    logo: 'https://app.anchorprotocol.com/static/media/anc@160.d97267d8.gif',
    decimals: 6,
    address: 'terra14z56l0fp2lsf86zy3hty2z47ezkhnthtr9yq76'
  },
  '0x29f350b3822f51dc29619c583adbc9628646e315': {
    id: '7up',
    symbol: '7up',
    name: '7up',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12953/large/7up-token.png?1603790389',
    decimals: 18,
    address: '0x29f350b3822f51dc29619c583adbc9628646e315'
  },
  '0x6c5fe6e99d2484db7e4bf34f365aba42d0e4dc20': {
    id: 'absorber',
    symbol: 'abs',
    name: 'Absorber',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13405/large/absorber_protocol_logo.png?1608192726',
    decimals: 18,
    address: '0x6c5fe6e99d2484db7e4bf34f365aba42d0e4dc20'
  },
  '0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389': {
    id: 'acryptosi',
    symbol: 'acsi',
    name: 'ACryptoSI',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13307/large/favicon-200x200.png?1607327420',
    decimals: 18,
    address: '0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389'
  },
  '0x4247aeb8e759e575fe350921cd174c48df304f2a': {
    id: 'aedart-network',
    symbol: 'aedart',
    name: 'Aedart Network',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13103/large/Logo_200x200.png?1605232881',
    decimals: 18,
    address: '0x4247aeb8e759e575fe350921cd174c48df304f2a'
  },
  '0xac472d0eed2b8a2f57a6e304ea7ebd8e88d1d36f': {
    id: 'anime-token',
    symbol: 'ani',
    name: 'Anime Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13792/large/Anime_Token.png?1612160147',
    decimals: 18,
    address: '0xac472d0eed2b8a2f57a6e304ea7ebd8e88d1d36f'
  },
  '0xe02df9e3e622debdd69fb838bb799e3f168902c5': {
    id: 'bakerytoken',
    symbol: 'bake',
    name: 'BakeryToken',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12588/large/bakerytoken_logo.jpg?1600940032',
    decimals: 18,
    address: '0xe02df9e3e622debdd69fb838bb799e3f168902c5'
  },

  '0x190b589cf9fb8ddeabbfeae36a813ffb2a702454': {
    id: 'bdollar',
    symbol: 'bdo',
    name: 'bDollar',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13487/large/bdollar-yellow.png?1609291587',
    decimals: 18,
    address: '0x190b589cf9fb8ddeabbfeae36a813ffb2a702454'
  },
  '0x0d9319565be7f53cefe84ad201be3f40feae2740': {
    id: 'bdollar-share',
    symbol: 'sbdo',
    name: 'bDollar Share',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13511/large/bdollar-sbdo.png?1609281302',
    decimals: 18,
    address: '0x0d9319565be7f53cefe84ad201be3f40feae2740'
  },
  '0x81859801b01764d4f0fa5e64729f5a6c3b91435b': {
    id: 'bearn-fi',
    symbol: 'bfi',
    name: 'Bearn.fi',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13289/large/black.jpg?1608600768',
    decimals: 18,
    address: '0x81859801b01764d4f0fa5e64729f5a6c3b91435b'
  },
  '0x181de8c57c4f25eba9fd27757bbd11cc66a55d31': {
    id: 'belugaswap',
    symbol: 'beluga',
    name: 'Belugaswap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13790/large/bel_c.png?1611763431',
    decimals: 18,
    address: '0x181de8c57c4f25eba9fd27757bbd11cc66a55d31'
  },
  '0xf859bf77cbe8699013d6dbc7c2b926aaf307f830': {
    id: 'berry-data',
    symbol: 'bry',
    name: 'Berry Data',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13987/large/berry.jpg?1613476736',
    decimals: 18,
    address: '0xf859bf77cbe8699013d6dbc7c2b926aaf307f830'
  },
  '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4': {
    id: 'billionhappiness',
    symbol: 'bhc',
    name: 'BillionHappiness',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12803/large/BHC_BLACK_TRANSPARENT.png?1602665544',
    decimals: 18,
    address: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4'
  },
  '0xb913aa79a0365bc4d30c217ae2916e6e2c44c882': {
    id: 'binance-agile-set-dollar',
    symbol: 'basd',
    name: 'Binance Agile Set Dollar',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13705/large/50851049333_b61515faa6_o.png?1611043472',
    decimals: 18,
    address: '0xb913aa79a0365bc4d30c217ae2916e6e2c44c882'
  },
  '0x645748fa7e54a818310afdad898410bcb54fc4e0': {
    id: 'birthday-cake',
    symbol: 'bday',
    name: 'Birthday Cake',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13960/large/bday.png?1613355214',
    decimals: 18,
    address: '0x645748fa7e54a818310afdad898410bcb54fc4e0'
  },
  '0x403b56babc26a7ec4356056da8dca506dd82feac': {
    id: 'bittorro',
    symbol: 'torro',
    name: 'BitTorro',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13886/large/bitorro.png?1612507408',
    decimals: 18,
    address: '0x403b56babc26a7ec4356056da8dca506dd82feac'
  },
  '0x63870a18b6e42b01ef1ad8a2302ef50b7132054f': {
    id: 'blink',
    symbol: 'blink',
    name: 'BLink',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13210/large/exO5GLDpRGo.jpg?1606202654',
    decimals: 6,
    address: '0x63870a18b6e42b01ef1ad8a2302ef50b7132054f'
  },
  '0xc97fac34ceb0bb1b2bdaf3b59ada378b99111a22': {
    id: 'bliquid',
    symbol: 'bliq',
    name: 'Bliquid',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13155/large/5fb1d3513539883dfdff3555_Copy_of_Black_and_White_Framed_Kessey_Dj_Logo_%282%29.png?1605766749',
    decimals: 18,
    address: '0xc97fac34ceb0bb1b2bdaf3b59ada378b99111a22'
  },
  '0xe6df05ce8c8301223373cf5b969afcb1498c5528': {
    id: 'bnb48-club-token',
    symbol: 'koge',
    name: 'BNB48 Club Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13827/large/bnb48.png?1612154326',
    decimals: 18,
    address: '0xe6df05ce8c8301223373cf5b969afcb1498c5528'
  },
  '0x1921168344840d1ca5acd82e8815f24582d4ad27': {
    id: 'bollo-token',
    symbol: 'bolo',
    name: 'Bollo Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13430/large/bolo.png?1608521887',
    decimals: 18,
    address: '0x1921168344840d1ca5acd82e8815f24582d4ad27'
  },
  '0xa86d305a36cdb815af991834b46ad3d7fbb38523': {
    id: 'br34p',
    symbol: 'br34p',
    name: 'BR34P',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13975/large/br34p.png?1613434540',
    decimals: 8,
    address: '0xa86d305a36cdb815af991834b46ad3d7fbb38523'
  },
  '0x5ac52ee5b2a633895292ff6d8a89bb9190451587': {
    id: 'bscex',
    symbol: 'bscx',
    name: 'BSCEX',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13582/large/icon-bscex-200x200.png?1609917200',
    decimals: 18,
    address: '0x5ac52ee5b2a633895292ff6d8a89bb9190451587'
  },
  '0x17bc015607fdf93e7c949e9ca22f96907cfbef88': {
    id: 'bsc-farm',
    symbol: 'bsc',
    name: 'BSC Farm',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13147/large/bsc200x200.png?1605669566',
    decimals: 18,
    address: '0x17bc015607fdf93e7c949e9ca22f96907cfbef88'
  },
  '0xf388ee045cab30321db3fb69eab7dfb0c20f10ec': {
    id: 'bscswap',
    symbol: 'bswap',
    name: 'BSCswap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12787/large/bscswap_logo.png?1602564969',
    decimals: 18,
    address: '0xf388ee045cab30321db3fb69eab7dfb0c20f10ec'
  },
  '0xae9269f27437f0fcbc232d39ec814844a51d6b8f': {
    id: 'burger-swap',
    symbol: 'burger',
    name: 'Burger Swap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12563/large/burger.png?1600786553',
    decimals: 18,
    address: '0xae9269f27437f0fcbc232d39ec814844a51d6b8f'
  },
  '0xb1f198ff6e2bcfc42ddd9234974f7025463230f4': {
    id: 'cactus-finance',
    symbol: 'cas',
    name: 'Cactus Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13659/large/cactusfinance.png?1610599041',
    decimals: 9,
    address: '0xb1f198ff6e2bcfc42ddd9234974f7025463230f4'
  },
  '0x790be81c3ca0e53974be2688cdb954732c9862e1': {
    id: 'cafeswap-token',
    symbol: 'brew',
    name: 'CafeSwap Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13966/large/coingecko_listing.png?1613377423',
    decimals: 18,
    address: '0x790be81c3ca0e53974be2688cdb954732c9862e1'
  },
  '0x007ea5c0ea75a8df45d288a4debdd5bb633f9e56': {
    id: 'canyacoin',
    symbol: 'can',
    name: 'CanYaCoin',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/2027/large/canyacoin.png?1547564606',
    decimals: 18,
    address: '0x007ea5c0ea75a8df45d288a4debdd5bb633f9e56'
  },
  '0xadd8a06fd58761a5047426e160b2b88ad3b9d464': {
    id: 'cheeseswap',
    symbol: 'chs',
    name: 'CheeseSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13605/large/chs-200px.png?1610273230',
    decimals: 18,
    address: '0xadd8a06fd58761a5047426e160b2b88ad3b9d464'
  },
  '0x1ad8d89074afa789a027b9a31d0bd14e254711d0': {
    id: 'cross-finance',
    symbol: 'crp',
    name: 'Cross Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12801/large/200x200-crossfin.png?1602654793',
    decimals: 18,
    address: '0x1ad8d89074afa789a027b9a31d0bd14e254711d0'
  },
  '0xcc2e12a9b5b75360c6fbf23b584c275d52cddb0e': {
    id: 'crow-token',
    symbol: 'crow',
    name: 'Crow Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13823/large/QmcP76PoDNcygNU9Yn37QucYgTP9TbEUQvdG9cpJ628nnD_%281%29.png?1612151721',
    decimals: 18,
    address: '0xcc2e12a9b5b75360c6fbf23b584c275d52cddb0e'
  },
  '0x34681c1035f97e1edcccec5f142e02ff81a3a230': {
    id: 'cubiex',
    symbol: 'cbix',
    name: 'Cubiex',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/6161/large/Cubiex-ico.jpg?1547042188',
    decimals: 18,
    address: '0x34681c1035f97e1edcccec5f142e02ff81a3a230'
  },
  '0x0957c57c9eb7744850dcc95db5a06ed4a246236e': {
    id: 'dango',
    symbol: 'dango',
    name: 'Dango',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13017/large/Untitled-design-4-removebg-preview_%281%29.png?1604389487',
    decimals: 6,
    address: '0x0957c57c9eb7744850dcc95db5a06ed4a246236e'
  },
  '0x748ad98b14c814b28812eb42ad219c8672909879': {
    id: 'dice-finance',
    symbol: 'dice',
    name: 'DICE.FINANCE',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12233/large/dice2.png?1598344967',
    decimals: 18,
    address: '0x748ad98b14c814b28812eb42ad219c8672909879'
  },
  '0x233d91a0713155003fc4dce0afa871b508b3b715': {
    id: 'ditto',
    symbol: 'ditto',
    name: 'Ditto',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13463/large/ditto.png?1608766914',
    decimals: 9,
    address: '0x233d91a0713155003fc4dce0afa871b508b3b715'
  },
  '0x6306e883493824ccf606d90e25f68a28e47b98a3': {
    id: 'extend-finance',
    symbol: 'exf',
    name: 'Extend Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13986/large/extend_finance_logo.png?1613550188',
    decimals: 9,
    address: '0x6306e883493824ccf606d90e25f68a28e47b98a3'
  },
  '0x87ff96aba480f1813af5c780387d8de7cf7d8261': {
    id: 'falopa',
    symbol: 'flp',
    name: 'Falopa',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13945/large/flp-removebg-preview.png?1613172232',
    decimals: 18,
    address: '0x87ff96aba480f1813af5c780387d8de7cf7d8261'
  },
  '0xbdc7a3c000aee2c8a98d9fc7feb2394ea194681e': {
    id: 'fnaticx',
    symbol: 'fnax',
    name: 'FnaticX',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13532/large/20201231_204613.png?1609455697',
    decimals: 18,
    address: '0xbdc7a3c000aee2c8a98d9fc7feb2394ea194681e'
  },
  '0x7f4959528d4c9c7379b3437c7eea40376fd96b8a': {
    id: 'genta',
    symbol: 'gena',
    name: 'Genta',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13467/large/genta_gena_200x200_CoinGecko.png?1608794880',
    decimals: 18,
    address: '0x7f4959528d4c9c7379b3437c7eea40376fd96b8a'
  },
  '0xf952fc3ca7325cc27d15885d37117676d25bfda6': {
    id: 'goose-finance',
    symbol: 'egg',
    name: 'Goose Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13951/large/JNnBWAE4XEJD9Ty7x8A1RlJ8dvA7hcOe7C1LkQSWwuSlD6HcCz3V8fN44B6du2k4owhlLJZU3r6M6HcK0dkpMe53kkqYsESpfx9w8vU8t_PnykuyaGnLKXLJeZqEAP1C9Wi3G3sqfbTeHi32_msxtMMyUmIRgzBbDdp-adg6mIbst_8a7JknMgmdkORNydmX4Ptk8YUtZhoWewY.png?1613250907',
    decimals: 18,
    address: '0xf952fc3ca7325cc27d15885d37117676d25bfda6'
  },
  '0x179983892ec36287f1f342443b671ba549762241': {
    id: 'guns',
    symbol: 'guns',
    name: 'GUNS',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13113/large/oWzMbeg.jpg?1605317247',
    decimals: 18,
    address: '0x179983892ec36287f1f342443b671ba549762241'
  },
  '0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8': {
    id: 'helmet-insure',
    symbol: 'helmet',
    name: 'Helmet Insure',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13680/large/ZMdK-1J4_400x400.png?1610834469',
    decimals: 18,
    address: '0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8'
  },
  '0x3c037c4c2296f280bb318d725d0b454b76c199b9': {
    id: 'jntrb',
    symbol: 'jntr/b',
    name: 'JNTR/b',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12830/large/jntr_logo.jpg?1602837322',
    decimals: 18,
    address: '0x3c037c4c2296f280bb318d725d0b454b76c199b9'
  },
  '0x5f2caa99fc378248ac02cbbaac27e3fa155ed2c4': {
    id: 'jointer',
    symbol: 'jntr',
    name: 'Jointer',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12964/large/JNTR-coingecko.png?1603867872',
    decimals: 18,
    address: '0x5f2caa99fc378248ac02cbbaac27e3fa155ed2c4'
  },
  '0x5a41f637c3f7553dba6ddc2d3ca92641096577ea': {
    id: 'julswap',
    symbol: 'juld',
    name: 'JulSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13839/large/JULD3_%282%29.png?1612250301',
    decimals: 18,
    address: '0x5a41f637c3f7553dba6ddc2d3ca92641096577ea'
  },
  '0x5ea29eee799aa7cc379fde5cf370bc24f2ea7c81': {
    id: 'keep3r-bsc-network',
    symbol: 'kp3rb',
    name: 'Keep3r BSC Network',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13510/large/kp3rb-200px_%282%29.png?1609280690',
    decimals: 18,
    address: '0x5ea29eee799aa7cc379fde5cf370bc24f2ea7c81'
  },
  '0xa511768003d01eaa0f464feeea4b1cddc4254b4f': {
    id: 'koloop-basic',
    symbol: 'kpc',
    name: 'Koloop Basic',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13812/large/20210128_175745.png?1612048576',
    decimals: 18,
    address: '0xa511768003d01eaa0f464feeea4b1cddc4254b4f'
  },
  '0x35e869b7456462b81cdb5e6e42434bd27f3f788c': {
    id: 'midas-dollar',
    symbol: 'mdo',
    name: 'Midas Dollar',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13927/large/MDO_short_logo_%28on_black%29.jpg?1612860104',
    decimals: 18,
    address: '0x35e869b7456462b81cdb5e6e42434bd27f3f788c'
  },
  '0x242e46490397acca94ed930f2c4edf16250237fa': {
    id: 'midas-dollar-share',
    symbol: 'mds',
    name: 'Midas Dollar Share',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13979/large/MDS_token.png?1613447377',
    decimals: 18,
    address: '0x242e46490397acca94ed930f2c4edf16250237fa'
  },
  '0xacabd3f9b8f76ffd2724604185fa5afa5df25ac6': {
    id: 'monster-cash-share',
    symbol: 'mss',
    name: 'Monster Slayer Share',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13834/large/coin-share-Custom.png?1612175783',
    decimals: 18,
    address: '0xacabd3f9b8f76ffd2724604185fa5afa5df25ac6'
  },
  '0x8c784c49097dcc637b93232e15810d53871992bf': {
    id: 'monster-slayer-cash',
    symbol: 'msc',
    name: 'Monster Slayer Cash',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13832/large/coin-cash-Custom.png?1612169597',
    decimals: 18,
    address: '0x8c784c49097dcc637b93232e15810d53871992bf'
  },
  '0xa15083664eb19899885ccc2b4fd03977b26d3a2d': {
    id: 'nagaswap',
    symbol: 'bnw',
    name: 'NagaSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13828/large/1_dQKxZmGq8IWJAJHu6kDS5Q.png?1612157547',
    decimals: 18,
    address: '0xa15083664eb19899885ccc2b4fd03977b26d3a2d'
  },
  '0xa1303e6199b319a891b79685f0537d289af1fc83': {
    id: 'nar-token',
    symbol: 'nar',
    name: 'NAR Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13367/large/2XylnQL.png?1607923179',
    decimals: 18,
    address: '0xa1303e6199b319a891b79685f0537d289af1fc83'
  },
  '0xbfa0841f7a90c4ce6643f651756ee340991f99d5': {
    id: 'nyanswop-token',
    symbol: 'nya',
    name: 'Nyanswop Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13025/large/512x512_App_Icon.png?1604456222',
    decimals: 18,
    address: '0xbfa0841f7a90c4ce6643f651756ee340991f99d5'
  },
  '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51': {
    id: 'pancake-bunny',
    symbol: 'BUNNY',
    name: 'Pancake Bunny',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/bunny.svg',
    decimals: 18,
    address: '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51'
  },
  '0xd909840613fcb0fadc6ee7e5ecf30cdef4281a68': {
    id: 'panda-yield',
    symbol: 'bboo',
    name: 'Panda Yield',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13794/large/bamboo.png?1611803953',
    decimals: 18,
    address: '0xd909840613fcb0fadc6ee7e5ecf30cdef4281a68'
  },
  '0x9b44df3318972be845d83f961735609137c4c23c': {
    id: 'payrue',
    symbol: 'propel',
    name: 'Propel',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/8794/large/-RNl00DU_400x400.jpg?1601543259',
    decimals: 18,
    address: '0x9b44df3318972be845d83f961735609137c4c23c'
  },
  '0x2eae17386ea67f0fa13485fa90851c03bdc5a7a9': {
    id: 'pbs-chain',
    symbol: 'pbs',
    name: 'PBS Chain',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13135/large/logo2_%281%29.png?1605577213',
    decimals: 18,
    address: '0x2eae17386ea67f0fa13485fa90851c03bdc5a7a9'
  },
  '0xb9784c1633ef3b839563b988c323798634714368': {
    id: 'phoswap',
    symbol: 'pho',
    name: 'Phoswap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13836/large/200x200_%2810%29.png?1612182033',
    decimals: 8,
    address: '0xb9784c1633ef3b839563b988c323798634714368'
  },
  '0xcdad3683335a4a96d2c437dd4513b061e4aec4ff': {
    id: 'pokeball',
    symbol: 'poke',
    name: 'Pokeball',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13664/large/ppJtaxM.png?1610601636',
    decimals: 18,
    address: '0xcdad3683335a4a96d2c437dd4513b061e4aec4ff'
  },
  '0x8dbc995946ad745dd77186d1ac10019b8ea6694a': {
    id: 'psychic',
    symbol: 'psy',
    name: 'Psychic',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13780/large/Untitled-design-5-removebg-preview-1.png?1611727812',
    decimals: 18,
    address: '0x8dbc995946ad745dd77186d1ac10019b8ea6694a'
  },
  '0x8a8350f957e00692388c033467ed204d624ee0b4': {
    id: 'sharkyield',
    symbol: 'shark',
    name: 'SharkYield',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13813/large/200x.png?1612048894',
    decimals: 18,
    address: '0x8a8350f957e00692388c033467ed204d624ee0b4'
  },
  '0x60b3bc37593853c04410c4f07fe4d6748245bf77': {
    id: 'shield-protocol',
    symbol: 'shield',
    name: 'Shield Protocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13952/large/200X200_%2811%29.png?1613251312',
    decimals: 18,
    address: '0x60b3bc37593853c04410c4f07fe4d6748245bf77'
  },
  '0xe2e0f7b4a1116400e972c34996afaaabc4b8ada3': {
    id: 'shitcoin-token',
    symbol: 'shit',
    name: 'Shitcoin Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13733/large/20210121_231111.png?1611266794',
    decimals: 18,
    address: '0xe2e0f7b4a1116400e972c34996afaaabc4b8ada3'
  },
  '0x69eb9dda7776dfa2fef16b61d0ada70f4bc1a3ca': {
    id: 'smart-dollar',
    symbol: 'sd',
    name: 'Smart Dollar',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13723/large/77294044.png?1611188895',
    decimals: 18,
    address: '0x69eb9dda7776dfa2fef16b61d0ada70f4bc1a3ca'
  },
  '0x849233ff1aea15d80ef658b2871664c9ca994063': {
    id: 'soak-token',
    symbol: 'soak',
    name: 'Soak Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13891/large/soak_logo_200x200.png?1612559018',
    decimals: 18,
    address: '0x849233ff1aea15d80ef658b2871664c9ca994063'
  },
  '0x541e619858737031a1244a5d0cd47e5ef480342c': {
    id: 'soteria',
    symbol: 'wsote',
    name: 'Soteria',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13840/large/V9gQH1KZ_400x400.jpg?1612251776',
    decimals: 18,
    address: '0x541e619858737031a1244a5d0cd47e5ef480342c'
  },
  '0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5': {
    id: 'sowing-network',
    symbol: 'zseed',
    name: 'Sowing.Network',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13914/large/sowing.png?1612837300',
    decimals: 18,
    address: '0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5'
  },
  '0x37ac4d6140e54304d77437a5c11924f61a2d976f': {
    id: 'sparkpoint-fuel',
    symbol: 'sfuel',
    name: 'SparkPoint Fuel',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13564/large/XU1F0aFA_400x400.png?1609830897',
    decimals: 18,
    address: '0x37ac4d6140e54304d77437a5c11924f61a2d976f'
  },
  '0x3aabcf53a1930a42e18d938c019e83ebee50a849': {
    id: 'sponge',
    symbol: 'spg',
    name: 'Sponge',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13700/large/sponge_logo_200x200.png?1611021295',
    decimals: 9,
    address: '0x3aabcf53a1930a42e18d938c019e83ebee50a849'
  },

  '0x4ae2f11df681eec979bd93085dd1a05e9593c8c6': {
    id: 'supra-token',
    symbol: 'supra',
    name: 'Supra Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13978/large/supra.png?1613445387',
    decimals: 18,
    address: '0x4ae2f11df681eec979bd93085dd1a05e9593c8c6'
  },
  '0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb': {
    id: 'swirge',
    symbol: 'swg',
    name: 'Swirge',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12879/large/swirge_logo.png?1603250039',
    decimals: 18,
    address: '0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb'
  },
  '0xb08560ac3832f680d09b33cf3d8982bd78afcba8': {
    id: 'taxi',
    symbol: 'taxi',
    name: 'Taxi',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13427/large/TAXI_LOGO_OFFICIAL-07.png?1608517030',
    decimals: 18,
    address: '0xb08560ac3832f680d09b33cf3d8982bd78afcba8'
  },
  '0x7924afd2b21caf0ab09e53cc91cdec0761db99b4': {
    id: 'tetris',
    symbol: 'ttr',
    name: 'Tetris',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13388/large/76043636.png?1608087219',
    decimals: 18,
    address: '0x7924afd2b21caf0ab09e53cc91cdec0761db99b4'
  },
  '0xe10e9822a5de22f8761919310dda35cd997d63c0': {
    id: 'thugs-finance',
    symbol: 'thugs',
    name: 'Thugs Fi',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12786/large/thugs-logo-new.png?1605747400',
    decimals: 18,
    address: '0xe10e9822a5de22f8761919310dda35cd997d63c0'
  },
  '0x30c18c9cb3de61f2bd1974749a6f5725150208f3': {
    id: 'trinity-bsc',
    symbol: 'btri',
    name: 'Trinity (BSC)',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13618/large/5fd83136d1dc607bfc379606_tri200.png?1610328507',
    decimals: 9,
    address: '0x30c18c9cb3de61f2bd1974749a6f5725150208f3'
  },
  // '0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176': {
  //   id: 'venus-bch',
  //   symbol: 'vbch',
  //   name: 'Venus BCH',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://assets.coingecko.com/coins/images/13922/large/vbch.png?1612845455',
  //   decimals: 8,
  //   address: '0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176',
  // },
  '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf': {
    id: 'bitcoin-cash',
    symbol: 'bch',
    name: 'Binance-Peg BCH',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/780/small/bitcoin-cash-circle.png?1594689492',
    decimals: 18,
    address: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf'
  },

  '0x7083609fce4d1d8dc0c979aab8c869ea2c873402': {
    id: 'polkadot',
    symbol: 'dot',
    name: 'DOT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12171/large/aJGBjJFU_400x400.jpg?1597804776',
    decimals: 18,
    address: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402'
  },

  '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153': {
    id: 'filecoin',
    symbol: 'fil',
    name: 'Binance-Peg FIL',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12817/small/filecoin.png?1602753933',
    decimals: 18,
    address: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153'
  },

  '0x4338665cbb7b2485a8855a139b75d5e34ab0db94': {
    id: 'litecoin',
    symbol: 'litecoin',
    name: 'Binance-Peg LTC',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/2/small/litecoin.png?1547033580',
    decimals: 18,
    address: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94'
  },
  '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe': {
    id: 'ripple',
    symbol: 'xrp',
    name: 'Binance-Peg XRP',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/44/small/xrp-symbol-white-128.png?1605778731',
    decimals: 18,
    address: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe'
  },
  '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47': {
    id: 'cardano',
    symbol: 'ada',
    name: 'Binance-Peg ADA',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/975/small/cardano.png?1547034860',
    decimals: 18,
    address: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47'
  },
  '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6': {
    id: 'eos',
    symbol: 'eos',
    name: 'Binance-Peg EOS',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/738/small/eos-eos-logo.png?1547034481',
    decimals: 18,
    address: '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6'
  },

  '0xf9661bf16f7521102eaf412a29e68cfd791a2ca3': {
    id: 'water-token-2',
    symbol: 'wtr',
    name: 'Water Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13774/large/78027289.png?1611699805',
    decimals: 18,
    address: '0xf9661bf16f7521102eaf412a29e68cfd791a2ca3'
  },
  '0x45b07df4fe00202dd31f2d95f6c06f3a029625d5': {
    id: 'yieldnyan-token',
    symbol: 'nyan',
    name: 'YieldNyan Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13947/large/KakaoTalk_20210212_014442759.png?1613172981',
    decimals: 18,
    address: '0x45b07df4fe00202dd31f2d95f6c06f3a029625d5'
  },
  '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8': {
    id: 'compound-governance-token',
    symbol: 'comp',
    name: 'Compound',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/10775/large/COMP.png?1595348880',
    decimals: 18,
    address: '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8'
  },
  '0xc00e94cb662c3520282e6f5717214004a7f26888': {
    id: 'compound-governance-token',
    symbol: 'comp',
    name: 'Compound',
    chain: Chain.ETHEREUM,
    logo: 'https://assets.coingecko.com/coins/images/10775/large/COMP.png?1595348880',
    decimals: 18,
    address: '0xc00e94cb662c3520282e6f5717214004a7f26888'
  },
  '0x8f0528ce5ef7b51152a59745befdd91d97091d2f': {
    id: 'alpaca-finance',
    symbol: 'ALPACA',
    name: 'ALPACA',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14165/large/Logo200.png?1614748442',
    decimals: 18,
    address: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f'
  },
  '0x6f695bd5ffd25149176629f8491a5099426ce7a7': {
    id: 'alpaca-finance',
    symbol: 'sALPACA',
    name: 'salpaca',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://miro.medium.com/max/1152/1*me4C6h0KvPlc0k3YwejJRw.jpeg',
    decimals: 18,
    address: '0x6f695bd5ffd25149176629f8491a5099426ce7a7'
  },
  // '0xf1be8ecc990cbcb90e166b71e368299f0116d421': {
  //   id: 'alpaca-finance',
  //   symbol: 'ibALPACA',
  //   name: 'Interest Bearing ALPACA',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/icons/coins/ib-alpaca.svg',
  //   decimals: 18,
  //   address: '0xf1be8ecc990cbcb90e166b71e368299f0116d421'
  // },
  // '0xd7d069493685a581d27824fc46eda46b7efc0063': {
  //   id: 'binancecoin',
  //   symbol: 'ibBNB',
  //   name: 'Interest Bearing BNB',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/icons/coins/ib-bnb.svg',
  //   decimals: 18,
  //   address: '0xd7d069493685a581d27824fc46eda46b7efc0063'
  // },
  // '0x7c9e73d4c71dae564d41f78d56439bb4ba87592f': {
  //   id: 'binance-usd',
  //   symbol: 'ibBUSD',
  //   name: 'Interest Bearing BUSD',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/icons/coins/ib-busd.svg',
  //   decimals: 18,
  //   address: '0x7c9e73d4c71dae564d41f78d56439bb4ba87592f'
  // },
  // '0x158da805682bdc8ee32d52833ad41e74bb951e59': {
  //   id: 'usdt',
  //   symbol: 'ibUSDT',
  //   name: 'Interest Bearing USDT',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/icons/coins/ib-usdt.svg?v=2',
  //   decimals: 18,
  //   address: '0x158da805682bdc8ee32d52833ad41e74bb951e59'
  // },
  // '0x3282d2a151ca00bfe7ed17aa16e42880248cd3cd': {
  //   id: 'true-usd',
  //   symbol: 'ibTUSD',
  //   name: 'Interest Bearing TUSD',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/icons/coins/ib-tusd.svg',
  //   decimals: 18,
  //   address: '0x3282d2a151ca00bfe7ed17aa16e42880248cd3cd'
  // },
  // '0x08fc9ba2cac74742177e0afc3dc8aed6961c24e7': {
  //   id: 'wrapped-bitcoin',
  //   symbol: 'ibBTCB',
  //   name: 'Interest Bearing BTCB',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/icons/coins/ib-btcb.svg',
  //   decimals: 18,
  //   address: '0x08fc9ba2cac74742177e0afc3dc8aed6961c24e7'
  // },
  // '0xbff4a34a4644a113e8200d7f1d79b3555f723afe': {
  //   id: 'binance-eth',
  //   symbol: 'ibETH',
  //   name: 'Interest Bearing ETH',
  //   chain: Chain.BINANCE_SMART_CHAIN,
  //   logo: 'https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/icons/coins/ib-eth.svg',
  //   decimals: 18,
  //   address: '0xbff4a34a4644a113e8200d7f1d79b3555f723afe'
  // },
  '0x14016e85a25aeb13065688cafb43044c2ef86784': {
    id: 'true-usd',
    symbol: 'TUSD',
    name: 'TUSD',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/3449/small/tusd.png?1618395665',
    decimals: 18,
    address: '0x14016e85a25aeb13065688cafb43044c2ef86784'
  },

  '0x4b0f1812e5df2a09796481ff14017e6005508003': {
    id: 'trust-wallet-token',
    symbol: 'twt',
    name: 'Trust Wallet Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11085/large/Trust.png?1588062702',
    decimals: 18,
    address: '0x4b0f1812e5df2a09796481ff14017e6005508003'
  },
  '0x947950bcc74888a40ffa2593c5798f11fc9124c4': {
    id: 'sushi',
    symbol: 'SUSHI',
    name: 'Sushi',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12271/small/512x512_Logo_no_chop.png?1606986688',
    decimals: 18,
    address: '0x947950bcc74888a40ffa2593c5798f11fc9124c4'
  },
  '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2': {
    id: 'dodo',
    symbol: 'DODO',
    name: 'DODO',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12651/small/dodo_logo.png?1601433025',
    decimals: 18,
    address: '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2'
  },
  '0x67d012f731c23f0313cea1186d0121779c77fcfe': {
    id: 'apoyield',
    symbol: 'soul',
    name: 'apoyield',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14193/large/coingecko_soul.png?1614847338',
    decimals: 8,
    address: '0x67d012f731c23f0313cea1186d0121779c77fcfe'
  },
  '0x8519ea49c997f50ceffa444d240fb655e89248aa': {
    id: 'ramp',
    symbol: 'ramp',
    name: 'ramp',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12837/large/RampdefiExternal200.png?1602897632',
    decimals: 18,
    address: '0x8519ea49c997f50ceffa444d240fb655e89248aa'
  },
  '0xeeeeeeee82a747508b66431cfe9443ace4e30881': {
    id: 'shrimp-capital',
    symbol: 'shrmp',
    name: 'Shrimp Capital',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14137/large/shrimp_capital.jpg?1614639623',
    decimals: 18,
    address: '0xeeeeeeee82a747508b66431cfe9443ace4e30881'
  },
  '0x1311b352467d2b5c296881badea82850bcd8f886': {
    id: 'tools',
    symbol: 'tools',
    name: 'BSC.TOOLS',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://raw.githubusercontent.com/bsctools/bsctools-documentation/main/bsc-tools-logo.png',
    decimals: 18,
    address: '0x1311b352467d2b5c296881badea82850bcd8f886'
  },
  '0xa41497122bb9b9eb2eb1c5c872551e145bc3166b': {
    id: 'gg1',
    symbol: 'gg1',
    name: 'Golden Goose 1',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://www.goosedefi.com/images/incubator/gg96x96.png',
    decimals: 18,
    address: '0xa41497122bb9b9eb2eb1c5c872551e145bc3166b'
  },
  '0x522e4c72ae4b6d1b9a22bead644b38182dc54460': {
    id: 'gg2',
    symbol: 'gg2',
    name: 'Golden Goose 2',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://www.goosedefi.com/images/incubator/gg96x96.png',
    decimals: 18,
    address: '0x522e4c72ae4b6d1b9a22bead644b38182dc54460'
  },
  '0x8c36c27a5dca31e8f75867147daaf1087a34a802': {
    id: 'gg3',
    symbol: 'gg3',
    name: 'Golden Goose 3',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://www.goosedefi.com/images/incubator/gg96x96.png',
    decimals: 18,
    address: '0x8c36c27a5dca31e8f75867147daaf1087a34a802'
  },
  '0x9d422733607c01d36836bf20d2ded0885c1e14eb': {
    id: 'gg4',
    symbol: 'gg4',
    name: 'Golden Goose 4',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://www.goosedefi.com/images/incubator/gg96x96.png',
    decimals: 18,
    address: '0x9d422733607c01d36836bf20d2ded0885c1e14eb'
  },
  '0x2849b1ae7e04a3d9bc288673a92477cf63f28af4': {
    id: 'saltswap',
    symbol: 'salt',
    name: 'salt',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14272/small/A4E1Kdkt_400x400.png?1615236821',
    decimals: 18,
    address: '0x2849b1ae7e04a3d9bc288673a92477cf63f28af4'
  },
  '0x94babbe728d9411612ee41b20241a6fa251b26ce': {
    id: 'gforce',
    symbol: 'gfce',
    name: 'gforce',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14475/small/gforce_logo.png?1616407253',
    decimals: 9,
    address: '0x94babbe728d9411612ee41b20241a6fa251b26ce'
  },
  '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f': {
    id: 'belt',
    symbol: 'belt',
    name: 'belt',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14319/small/belt_logo.jpg?1615387083',
    decimals: 18,
    address: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f'
  },
  '0x039cb485212f996a9dbb85a9a75d898f94d38da6': {
    id: 'dexe',
    symbol: 'dexe',
    name: 'dexe',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12713/small/logo_%2814%29.png?1601952779',
    decimals: 18,
    address: '0x039cb485212f996a9dbb85a9a75d898f94d38da6'
  },
  '0x658a109c5900bc6d2357c87549b651670e5b0539': {
    id: 'force-protocol',
    symbol: 'for',
    name: 'fortube',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/8242/small/for.png?1606195375',
    decimals: 18,
    address: '0x658a109c5900bc6d2357c87549b651670e5b0539'
  },
  '0x05b339b0a346bf01f851dde47a5d485c34fe220c': {
    id: 'astronaut',
    symbol: 'naut',
    name: 'naut',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://apeswap.finance/images/tokens/NAUT.png',
    decimals: 8,
    address: '0x05b339b0a346bf01f851dde47a5d485c34fe220c'
  },
  '0x36c0556c2b15aed79f842675ff030782738ef9e8': {
    id: 'blue-swap',
    symbol: 'blue',
    name: 'blue',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://exchange.blueswap.finance/images/coins/BLUE.png',
    decimals: 18,
    address: '0x36c0556c2b15aed79f842675ff030782738ef9e8'
  },
  '0xa4fb1f591980e6e4eb4661a0d96df19a13d21aa7': {
    id: 'green',
    symbol: 'green',
    name: 'green',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://exchange.blueswap.finance/images/coins/GREEN.png',
    decimals: 18,
    address: '0xa4fb1f591980e6e4eb4661a0d96df19a13d21aa7'
  },
  '0x0feadcc3824e7f3c12f40e324a60c23ca51627fc': {
    id: 'warden',
    symbol: 'WAD',
    name: 'warden',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://firebasestorage.googleapis.com/v0/b/ape-board-prod.appspot.com/o/logo%2Fwarden.png?alt=media&token=d722d16b-c622-46d6-ab01-17f22a66402e',
    decimals: 18,
    address: '0x0feadcc3824e7f3c12f40e324a60c23ca51627fc'
  },
  '0xa7f552078dcc247c2684336020c03648500c6d9f': {
    id: 'ellipsis',
    symbol: 'eps',
    name: 'ellipsis',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14498/small/ellipsis.png?1616556354',
    decimals: 18,
    address: '0xa7f552078dcc247c2684336020c03648500c6d9f'
  },
  '0x86afa7ff694ab8c985b79733745662760e454169': {
    id: 'belt-fi',
    symbol: 'Belt.fi bDAI/bUSDC/bUSDT/bBUSD',
    name: 'Belt.fi bDAI/bUSDC/bUSDT/bBUSD',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://firebasestorage.googleapis.com/v0/b/ape-board-prod.appspot.com/o/logo%2FvPool.png?alt=media&token=8a6fe8c5-d2c9-411d-b71e-9ecdc1e4764e',
    decimals: 18,
    address: '0x86afa7ff694ab8c985b79733745662760e454169'
  },
  '0x5b6dcf557e2abe2323c48445e8cc948910d8c2c9': {
    id: 'mirror-protocol',
    symbol: 'MIR',
    name: 'Mirror Protocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13295/large/mirror_logo_transparent.png?1611554658',
    decimals: 18,
    address: '0x5b6dcf557e2abe2323c48445e8cc948910d8c2c9'
  },
  '0x0eb3a705fc54725037cc9e008bdede697f62f335': {
    id: 'cosmos',
    symbol: 'atom',
    name: 'Cosmos',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/1481/small/cosmos_hub.png?1555657960',
    decimals: 18,
    address: '0x0eb3a705fc54725037cc9e008bdede697f62f335'
  },
  '0x47bead2563dcbf3bf2c9407fea4dc236faba485a': {
    id: 'swipe',
    symbol: 'sxp',
    name: 'Swipe',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/9368/small/swipe.png?1566792311',
    decimals: 18,
    address: '0x47bead2563dcbf3bf2c9407fea4dc236faba485a'
  },
  '0x26a5dfab467d4f58fb266648cae769503cec9580': {
    id: 'xmark',
    symbol: 'xmark',
    name: 'xMark',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14188/small/omPQ9-nF_400x400.jpg?1614830988',
    decimals: 18,
    address: '0x26a5dfab467d4f58fb266648cae769503cec9580'
  },
  '0x9678e42cebeb63f23197d726b29b1cb20d0064e5': {
    id: 'iotex',
    symbol: 'iotx',
    name: 'IoTex',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/3334/small/iotex-logo.png?1547037941',
    decimals: 18,
    address: '0x9678e42cebeb63f23197d726b29b1cb20d0064e5'
  },
  '0x7af173f350d916358af3e218bdf2178494beb748': {
    id: 'unitrade',
    symbol: 'trade',
    name: 'Unitrade',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11982/small/unitrade.PNG?1597009487',
    decimals: 18,
    address: '0x7af173f350d916358af3e218bdf2178494beb748'
  },
  '0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c': {
    id: 'dusk-network',
    symbol: 'dusk',
    name: 'Dusk Network',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/5217/small/D_ticker_purple_on_circle_%282%29.png?1563781659',
    decimals: 18,
    address: '0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c'
  },
  '0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5': {
    id: 'tixl-new',
    symbol: 'txl',
    name: 'Tixl',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12432/small/Tixl-Logo-200-transparent.png?1610248504',
    decimals: 18,
    address: '0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5'
  },
  '0x96dd399f9c3afda1f194182f71600f1b65946501': {
    id: 'contentos',
    symbol: 'cos',
    name: 'Contentos',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/8379/small/4036.png?1561085375',
    decimals: 18,
    address: '0x96dd399f9c3afda1f194182f71600f1b65946501'
  },
  '0xac51066d7bec65dc4589368da368b212745d63e8': {
    id: 'my-neighbor-alice',
    symbol: 'alice',
    name: 'My Neighbor Alice',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14375/small/alice_logo.jpg?1615782968',
    decimals: 6,
    address: '0xac51066d7bec65dc4589368da368b212745d63e8'
  },
  '0x8cd6e29d3686d24d3c2018cee54621ea0f89313b': {
    id: 'nuls',
    symbol: 'nuls',
    name: 'Nuls',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/1053/small/Nuls.png?1556868153',
    decimals: 8,
    address: '0x8cd6e29d3686d24d3c2018cee54621ea0f89313b'
  },
  '0x8443f091997f06a61670b735ed92734f5628692f': {
    id: 'bella-protocol',
    symbol: 'bel',
    name: 'Bella Protocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12478/small/Bella.png?1602230054',
    decimals: 18,
    address: '0x8443f091997f06a61670b735ed92734f5628692f'
  },
  '0xeca41281c24451168a37211f0bc2b8645af45092': {
    id: 'token-pocket',
    symbol: 'tpt',
    name: 'Token Pocket',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/7603/small/_E6sbg1g_400x400.jpg?1548828066',
    decimals: 4,
    address: '0xeca41281c24451168a37211f0bc2b8645af45092'
  },
  '0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0': {
    id: 'yieldwatch',
    symbol: 'watch',
    name: 'Yieldwatch',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14186/small/WATCH1.png?1614825718',
    decimals: 18,
    address: '0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0'
  },
  '0x4131b87f74415190425ccd873048c708f8005823': {
    id: 'multiplier-bsc',
    symbol: 'bmxx',
    name: 'Multiplier (BSC)',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14235/small/FMG5eE0.png?1615176856',
    decimals: 18,
    address: '0x4131b87f74415190425ccd873048c708f8005823'
  },
  '0xf35262a9d427f96d2437379ef090db986eae5d42': {
    id: 'open-governance-token',
    symbol: 'bopen',
    name: 'OPEN Governanace Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13233/small/opendao_logo.png?1606575207',
    decimals: 18,
    address: '0xf35262a9d427f96d2437379ef090db986eae5d42'
  },
  '0x71de20e0c4616e7fcbfdd3f875d568492cbe4739': {
    id: 'swingby',
    symbol: 'swingby',
    name: 'Swingby',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11861/small/swingby.png?1595822302',
    decimals: 18,
    address: '0x71de20e0c4616e7fcbfdd3f875d568492cbe4739'
  },
  '0x250632378e573c6be1ac2f97fcdf00515d0aa91b': {
    id: 'binance-eth',
    symbol: 'beth',
    name: 'Binance ETH staking',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://exchange.pancakeswap.finance/images/coins/0x250632378e573c6be1ac2f97fcdf00515d0aa91b.png',
    decimals: 18,
    address: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b'
  },
  '0xaf4de8e872131ae328ce21d909c74705d3aaf452': {
    id: '3eps',
    symbol: '3EPS',
    name: 'Ellipsis.finance BUSD/USDC/USDT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://firebasestorage.googleapis.com/v0/b/ape-board-prod.appspot.com/o/logo%2F3pool.png?alt=media&token=0225d881-980d-4f25-9267-45ba79fee39f',
    decimals: 18,
    address: '0xaf4de8e872131ae328ce21d909c74705d3aaf452'
  },
  '0x57067a6bd75c0e95a6a5f158455926e43e79beb0': {
    id: 'blzd',
    symbol: 'BLZD',
    name: 'Blizzard Money',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://blizzard.money/images/blzd/logoRound.png',
    decimals: 18,
    address: '0x57067a6bd75c0e95a6a5f158455926e43e79beb0'
  },
  '0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd': {
    id: 'alloy',
    symbol: 'ALLOY',
    name: 'Hyper Alloy',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://bscscan.com/token/images/hyperalloy_32.png',
    decimals: 18,
    address: '0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd'
  },
  '0x844fa82f1e54824655470970f7004dd90546bb28': {
    id: 'dopple-finance',
    symbol: 'DOP',
    name: 'Dopple',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Flogo.svg&w=128&q=75',
    decimals: 18,
    address: '0x844fa82f1e54824655470970f7004dd90546bb28'
  },
  '0x802A183ac9F6b082716DFeE55432ed0C04ACB49a': {
    id: 'dopple-finance',
    symbol: 'DOPX',
    name: 'DoppleX',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Flogo.svg&w=128&q=75',
    decimals: 18,
    address: '0x802A183ac9F6b082716DFeE55432ed0C04ACB49a'
  },

  '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5': {
    id: 'dolly-bsc',
    symbol: 'DOLLY',
    name: 'Dolly Stable Coin',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/dolly.svg',
    decimals: 18,
    address: '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5'
  },
  '0x42f6f551ae042cbe50c739158b4f0cac0edb9096': {
    id: 'nerve-finance',
    symbol: 'NRV',
    name: 'Nerve Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14233/small/nerve_finance_logo.png?1615176392',
    decimals: 18,
    address: '0x42f6f551ae042cbe50c739158b4f0cac0edb9096'
  },

  '0x15b9462d4eb94222a7506bc7a25fb27a2359291e': {
    id: 'nerve-finance',
    symbol: 'xNRV',
    name: 'Nerve Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14233/small/nerve_finance_logo.png?1615176392',
    decimals: 18,
    address: '0x15b9462d4eb94222a7506bc7a25fb27a2359291e'
  },
  '0x4f0ed527e8a95ecaa132af214dfd41f30b361600': {
    id: 'vbswap',
    symbol: 'vBSWAP',
    name: 'vBSWAP',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14133/small/vbswap_22.png?1614601485',
    decimals: 18,
    address: '0x4f0ed527e8a95ecaa132af214dfd41f30b361600'
  },
  '0x7b0409a3a3f79baa284035d48e1dfd581d7d7654': {
    id: 'rupee',
    symbol: 'rupee',
    name: 'Rupee Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://app.hyruleswap.com/images/egg/9.png',
    decimals: 18,
    address: '0x7b0409a3a3f79baa284035d48e1dfd581d7d7654'
  },
  '0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d': {
    id: 'swampy',
    symbol: 'SWAMP',
    name: 'Swamp Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://swamp.finance/static/frontend/img/symbols/swamp.svg',
    decimals: 18,
    address: '0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d'
  },
  '0x7f70642d88cf1c4a3a7abb072b53b929b653eda5': {
    id: 'yfii-finance',
    symbol: 'YFII',
    name: 'DFI.money',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11902/small/YFII-logo.78631676.png?1598677348',
    decimals: 18,
    address: '0x7f70642d88cf1c4a3a7abb072b53b929b653eda5'
  },
  '0x16939ef78684453bfdfb47825f8a5f714f12623a': {
    id: 'tezos',
    symbol: 'XTZ',
    name: 'Tezos',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/976/small/Tezos-logo.png?1547034862',
    decimals: 18,
    address: '0x16939ef78684453bfdfb47825f8a5f714f12623a'
  },
  '0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929': {
    id: 'certik',
    symbol: 'CTK',
    name: 'CertiK',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12944/small/CertiK_Foundation_icon_96_96_-_round.png?1603772980',
    decimals: 6,
    address: '0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929'
  },
  '0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4': {
    id: 'stablexswap',
    symbol: 'stax',
    name: 'StableXSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12967/small/photo_2021-01-12_00.19.13.jpeg?1610381968',
    decimals: 18,
    address: '0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4'
  },
  '0xf79037f6f6be66832de4e7516be52826bc3cbcc4': {
    id: 'hard-protocol',
    symbol: 'hard',
    name: 'HARD Protocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13008/small/HARD_coin_256.png?1604733983',
    decimals: 6,
    address: '0xf79037f6f6be66832de4e7516be52826bc3cbcc4'
  },
  '0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe': {
    id: 'roobee',
    symbol: 'roobee',
    name: 'Roobee',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/8791/small/Group_11.png?1580344629',
    decimals: 18,
    address: '0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe'
  },
  '0x728c5bac3c3e370e372fc4671f9ef6916b814d8b': {
    id: 'unifi-protocol-dao',
    symbol: 'unfi',
    name: 'Unifi Protocol DAO',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13152/small/logo-2.png?1605748967',
    decimals: 18,
    address: '0x728c5bac3c3e370e372fc4671f9ef6916b814d8b'
  },
  '0xbc5609612b7c44bef426de600b5fd1379db2ecf1': {
    id: 'paris-saint-germain-fan-token',
    symbol: 'psg',
    name: 'Paris Saint-Germain Fan Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11620/small/psg.png?1592023100',
    decimals: 2,
    address: '0xbc5609612b7c44bef426de600b5fd1379db2ecf1'
  },
  '0xc40c9a843e1c6d01b7578284a9028854f6683b1b': {
    id: 'juventus-fan-token',
    symbol: 'juv',
    name: 'Juventus Fan Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/10060/small/Juve-10.png?1604737154',
    decimals: 2,
    address: '0xc40c9a843e1c6d01b7578284a9028854f6683b1b'
  },
  '0x80d5f92c2c8c682070c95495313ddb680b267320': {
    id: 'as-roma-fan-token',
    symbol: 'asr',
    name: 'AS Roma Fan Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11688/small/Roma-10.png?1604941843',
    decimals: 2,
    address: '0x80d5f92c2c8c682070c95495313ddb680b267320'
  },
  '0xf05e45ad22150677a017fbd94b84fbb63dc9b44c': {
    id: 'og-fan-token',
    symbol: 'og',
    name: 'OG Fan Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11663/small/og.jpg?1592838119',
    decimals: 2,
    address: '0xf05e45ad22150677a017fbd94b84fbb63dc9b44c'
  },
  '0x25e9d05365c867e59c1904e7463af9f312296f9e': {
    id: 'atletico-madrid',
    symbol: 'atm',
    name: 'Atletico Madrid Fan Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/11689/small/Atletico-10.png?1604941960',
    decimals: 2,
    address: '0x25e9d05365c867e59c1904e7463af9f312296f9e'
  },
  '0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e': {
    id: 'reef-finance',
    symbol: 'reef',
    name: 'Reef Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13504/small/Group_10572.png?1610534130',
    decimals: 18,
    address: '0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e'
  },
  '0x72faa679e1008ad8382959ff48e392042a8b06f7': {
    id: 'allianceblock',
    symbol: 'albt',
    name: 'AllianceBlock',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12392/small/alliance_block_logo.jpg?1599546617',
    decimals: 18,
    address: '0x72faa679e1008ad8382959ff48e392042a8b06f7'
  },
  '0x928e55dab735aa8260af3cedada18b5f70c72f1b': {
    id: 'frontier-token',
    symbol: 'front',
    name: 'Frontier',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12479/small/frontier_logo.png?1600145472',
    decimals: 18,
    address: '0x928e55dab735aa8260af3cedada18b5f70c72f1b'
  },
  '0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe': {
    id: 'elrond-erd-2',
    symbol: 'egld',
    name: 'Elrond',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12335/small/Elrond.png?1599132180',
    decimals: 18,
    address: '0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe'
  },
  '0xc7d8d35eba58a0935ff2d5a33df105dd9f071731': {
    id: 'hedget',
    symbol: 'hget',
    name: 'Hedget',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12453/small/Hedget.png?1599944809',
    decimals: 6,
    address: '0xc7d8d35eba58a0935ff2d5a33df105dd9f071731'
  },
  '0x762539b45a1dcce3d36d080f74d1aed37844b878': {
    id: 'linear',
    symbol: 'lina',
    name: 'Linear',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12509/small/linear.jpg?1606884470',
    decimals: 18,
    address: '0x762539b45a1dcce3d36d080f74d1aed37844b878'
  },
  '0x44754455564474a89358b2c2265883df993b12f0': {
    id: 'zeroswap',
    symbol: 'zee',
    name: 'ZeroSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12861/small/zeroswap.jpg?1603111827',
    decimals: 18,
    address: '0x44754455564474a89358b2c2265883df993b12f0'
  },
  '0x92d7756c60dcfd4c689290e8a9f4d263b3b32241': {
    id: 'boringdao',
    symbol: 'bor',
    name: 'BoringDAO',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12917/small/bor_logo.png?1603607502',
    decimals: 18,
    address: '0x92d7756c60dcfd4c689290e8a9f4d263b3b32241'
  },
  '0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd': {
    id: 'lto-network',
    symbol: 'lto',
    name: 'LTO Network',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/6068/small/LTO_Network.png?1567443682',
    decimals: 18,
    address: '0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd'
  },
  '0xbcf39f0edda668c58371e519af37ca705f2bfcbd': {
    id: 'crowns',
    symbol: 'cws',
    name: 'Crowns',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/13835/small/crowns_logo.png?1612176905',
    decimals: 18,
    address: '0xbcf39f0edda668c58371e519af37ca705f2bfcbd'
  },
  '0xb86abcb37c3a4b64f74f59301aff131a1becc787': {
    id: 'zilliqa',
    symbol: 'zil',
    name: 'Zilliqa',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/2687/small/Zilliqa-logo.png?1547036894',
    decimals: 12,
    address: '0xb86abcb37c3a4b64f74f59301aff131a1becc787'
  },
  '0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3': {
    id: 'lien',
    symbol: 'lien',
    name: 'Lien',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12224/small/Lien.png?1598262819',
    decimals: 8,
    address: '0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3'
  },
  '0x250b211ee44459dad5cd3bca803dd6a7ecb5d46c': {
    id: 'switcheo',
    symbol: 'swth',
    name: 'Switcheo',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/3645/small/02_SwitcheoSymbol_BGSquared_onAlternate.png?1591951537',
    decimals: 8,
    address: '0x250b211ee44459dad5cd3bca803dd6a7ecb5d46c'
  },
  '0x42712df5009c20fee340b245b510c0395896cf6e': {
    id: 'defiat',
    symbol: 'dft',
    name: 'DeFiat',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12303/small/defiat.jpg?1598985049',
    decimals: 18,
    address: '0x42712df5009c20fee340b245b510c0395896cf6e'
  },
  '0x0491648c910ad2c1afaab733faf71d30313df7fc': {
    id: 'jdi-token',
    symbol: 'JDI',
    name: 'JDI Yield',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/JDI.svg',
    decimals: 18,
    address: '0x0491648c910ad2c1afaab733faf71d30313df7fc'
  },
  '0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a': {
    id: 'jdi-token',
    symbol: 'JDI',
    name: 'JDI Yield',
    chain: Chain.POLYGON,
    logo: '/images/coin/JDI.svg',
    decimals: 18,
    address: '0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a'
  },
  '0x9a946c3cb16c08334b69ae249690c236ebd5583e': {
    id: 'blizzard',
    symbol: 'xBLZD',
    name: 'Blizzard',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15281/small/android-chrome-512x512_%281%29.png?1620351260',
    decimals: 18,
    address: '0x9a946c3cb16c08334b69ae249690c236ebd5583e'
  },
  '0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90': {
    id: 'waultswap',
    symbol: 'WEX',
    name: 'WaultSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/WEX.svg',
    decimals: 18,
    address: '0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90'
  },
  '0xb64e638e60d154b43f660a6bf8fd8a3b249a6a21': {
    id: 'wault',
    symbol: 'WAULTx',
    name: 'Wault',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/WAULTx.svg',
    decimals: 18,
    address: '0xb64e638e60d154b43f660a6bf8fd8a3b249a6a21'
  },
  '0x4c4bf319237d98a30a929a96112effa8da3510eb': {
    id: 'waultswap-polygon',
    symbol: 'WEXpoly',
    name: 'WEXpoly',
    chain: Chain.POLYGON,
    logo: '/images/coin/wexpoly.png',
    decimals: 18,
    address: '0x4c4bf319237d98a30a929a96112effa8da3510eb'
  },
  '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3': {
    id: 'safemoon',
    symbol: 'SAFEMOON',
    name: 'SAFEMOON',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14362/small/174x174-white.png?1617174846',
    decimals: 9,
    address: '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3'
  },
  '0x1f546ad641b56b86fd9dceac473d1c7a357276b7': {
    id: 'pantherswap',
    symbol: 'PANTHER',
    name: 'PantherSwap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://i.postimg.cc/rpq3KWDG/panther.png',
    decimals: 18,
    address: '0x1f546ad641b56b86fd9dceac473d1c7a357276b7'
  },
  '0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0': {
    id: 'eleven-finance',
    symbol: 'ELE',
    name: 'Eleven Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14541/large/eleven_finance_logo.png?1616895791',
    decimals: 18,
    address: '0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0'
  },
  '0xC0eFf7749b125444953ef89682201Fb8c6A917CD': {
    id: 'horizon-protocol',
    symbol: 'HZN',
    name: 'Horizon Protocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14795/small/horizon-logo-200-cg.png?1618475792',
    decimals: 18,
    address: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD'
  },
  '0xc0eff7749b125444953ef89682201fb8c6a917cd': {
    id: 'horizon-protocol',
    symbol: 'HZN',
    name: 'Horizon Protocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14795/small/horizon-logo-200-cg.png?1618475792',
    decimals: 18,
    address: '0xc0eff7749b125444953ef89682201fb8c6a917cd'
  },
  '0xa4f93159ce0a4b533b443c74b89967c60a5969f8': {
    id: 'bitfresh',
    symbol: 'BFT',
    name: 'Bitfresh',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15004/small/bft-token-bg-200x200.png?1619407975',
    decimals: 18,
    address: '0xa4f93159ce0a4b533b443c74b89967c60a5969f8'
  },
  '0xda360309c59cb8c434b28a91b823344a96444278': {
    id: 'merlin',
    symbol: 'MERL',
    name: 'Merlin Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15555/small/merlin-G-B_copy.png?1621203168',
    decimals: 18,
    address: '0xda360309c59cb8c434b28a91b823344a96444278'
  },
  '0xd15c444f1199ae72795eba15e8c1db44e47abf62': {
    id: 'ten',
    symbol: 'TENFI',
    name: 'TEN Finance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://i.postimg.cc/Mpw0gvsw/download-1.png',
    decimals: 18,
    address: '0xd15c444f1199ae72795eba15e8c1db44e47abf62'
  },
  '0xea90dc6f64d18771ca1dac8098526a9082265b42': {
    id: 'dinopark',
    symbol: 'DINOP',
    name: 'Dinopark',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/dinop.svg',
    decimals: 9,
    address: '0xea90dc6f64d18771ca1dac8098526a9082265b42'
  },
  '0xba26397cdff25f0d26e815d218ef3c77609ae7f1': {
    id: 'lyptus-token',
    symbol: 'LYPTUS',
    name: 'Lyptus Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/LYPTUS.png',
    decimals: 18,
    address: '0xba26397cdff25f0d26e815d218ef3c77609ae7f1'
  },
  '0xe486a69E432Fdc29622bF00315f6b34C99b45e80': {
    id: 'space-token',
    symbol: 'SPACE',
    name: 'Space Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/space.svg',
    decimals: 18,
    address: '0xe486a69E432Fdc29622bF00315f6b34C99b45e80'
  },
  '0x0314e5a39806c30d67b869ee1bcdabee7e08de74': {
    id: 'dbm',
    symbol: 'DBM',
    name: 'DoubleMoon',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0x0314e5a39806C30D67B869EE1bCDABee7e08dE74/logo.png',
    decimals: 9,
    address: '0x0314e5a39806c30d67b869ee1bcdabee7e08de74'
  },
  '0x768d221e81524de52841aed976370b2e4f990416': {
    id: 'moon-maker-protocol',
    symbol: 'MMP',
    name: 'MoonMakerProtocol',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0x768d221E81524De52841AeD976370b2e4F990416/logo.png',
    decimals: 18,
    address: '0x768d221e81524de52841aed976370b2e4f990416'
  },
  '0x30dfdefe4b5c2ef1bcb82fd1d6e62f9a8580a05a': {
    id: 'afi',
    symbol: 'AFI',
    name: 'Afi',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/WAFI.png',
    decimals: 18,
    address: '0x30dfdefe4b5c2ef1bcb82fd1d6e62f9a8580a05a'
  },
  '0xdff88a0a43271344b760b58a35076bf05524195c': {
    id: 'red-pulse',
    symbol: 'PHB',
    name: 'Red Pulse Phoenix Binance',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/1074/small/phoenix-logo.png?1547035031',
    decimals: 18,
    address: '0xdff88a0a43271344b760b58a35076bf05524195c'
  },
  '0x42d1b21eabe04d308148ea9ab90be674b64b4eef': {
    id: 'zfarm',
    symbol: 'ZFARM',
    name: 'ZFARM',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://app.zfarm.finance/images/tokens/ZFARM.png',
    decimals: 18,
    address: '0x42d1b21eabe04d308148ea9ab90be674b64b4eef'
  },
  '0xfcb8a4b1a0b645e08064e05b98e9cc6f48d2aa57': {
    id: 'zmine',
    symbol: 'ZMN',
    name: 'ZMINE Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/2103/small/zmn.png?1547036420',
    decimals: 18,
    address: '0xfcb8a4b1a0b645e08064e05b98e9cc6f48d2aa57'
  },
  '0x3806aae953a3a873d02595f76c7698a57d4c7a57': {
    id: 'twin',
    symbol: 'TWIN',
    name: 'TWIN Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/twindex.svg',
    decimals: 18,
    address: '0x3806aae953a3a873d02595f76c7698a57d4c7a57'
  },
  '0x40b34cc972908060d6d527276e17c105d224559d': {
    id: 'treedefi',
    symbol: 'SEED',
    name: 'TreeDefi Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0x40B34cC972908060D6d527276e17c105d224559d/logo.png',
    decimals: 18,
    address: '0x40b34cc972908060d6d527276e17c105d224559d'
  },
  '0xf0Fcd737fcE18F95621CC7841EbE0eA6EFCCf77e': {
    id: 'tree-defi',
    symbol: 'TREE',
    name: 'TreeDefi Token',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0x40B34cC972908060D6d527276e17c105d224559d/logo.png',
    decimals: 18,
    address: '0xf0Fcd737fcE18F95621CC7841EbE0eA6EFCCf77e'
  },
  '0xc10b2ce6a2bcfdfdc8100ba1602c1689997299d3': {
    id: 'aapl',
    symbol: 'AAPL',
    name: 'Dopple AAPL',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/twindex/AAPL.svg',
    decimals: 18,
    address: '0xc10b2ce6a2bcfdfdc8100ba1602c1689997299d3'
  },
  '0x17ace02e5c8814bf2ee9eaaff7902d52c15fb0f4': {
    id: 'dtsla',
    symbol: 'dTSLA',
    name: 'Dopple TSLA',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/twindex/TSLA.svg',
    decimals: 18,
    address: '0x17ace02e5c8814bf2ee9eaaff7902d52c15fb0f4'
  },
  '0x9c169647471c1c6a72773cffc50f6ba285684803': {
    id: 'dgoogl',
    symbol: 'dGOOGL',
    name: 'Dopple GOOGL',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/twindex/GOOGL.svg',
    decimals: 18,
    address: '0x9c169647471c1c6a72773cffc50f6ba285684803'
  },
  '0x1085b90544ff5c421d528aaf79cc65afc920ac79': {
    id: 'damzn',
    symbol: 'dAMZN',
    name: 'Dopple AMZN',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/twindex/AMZN.svg',
    decimals: 18,
    address: '0x1085b90544ff5c421d528aaf79cc65afc920ac79'
  },
  '0xa7bd090bc58e663388be63d5e8a7b683f5a3d4fc': {
    id: 'dmc',
    symbol: 'DMC',
    name: 'DoubleMoon Cat',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://exchange.doublemoon.finance/images/coins/dmc.png',
    decimals: 18,
    address: '0xa7bd090bc58e663388be63d5e8a7b683f5a3d4fc'
  },
  '0x56eab07247e3e6404ac90140f20bba61375d5c3c': {
    id: 'taps',
    symbol: 'TAPS',
    name: 'Tapswap',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://bscscan.com/token/images/tapswapmoney_32.png',
    decimals: 18,
    address: '0x56eab07247e3e6404ac90140f20bba61375d5c3c'
  },
  '0x9a2f5556e9a637e8fbce886d8e3cf8b316a1d8a2': {
    id: 'binanceidr',
    symbol: 'BIDR',
    name: 'BIDR',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/12121/small/bidr_mark_transparent.png',
    decimals: 18,
    address: '0x9a2f5556e9a637e8fbce886d8e3cf8b316a1d8a2'
  },
  '0x99ca242f20424a6565cc17a409e557fa95e25bd7': {
    id: 'ale',
    symbol: 'ALE',
    name: 'ALE',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/ale.svg',
    decimals: 18,
    address: '0x99ca242f20424a6565cc17a409e557fa95e25bd7'
  },
  '0xd86b5923f3ad7b585ed81b448170ae026c65ae9a': {
    id: 'iron-stablecoin',
    symbol: 'IRON',
    name: 'IRON',
    chain: Chain.POLYGON,
    logo: '/images/coin/iron.png',
    decimals: 18,
    address: '0xd86b5923f3ad7b585ed81b448170ae026c65ae9a'
  },
  '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a': {
    id: 'iron-titanium-token',
    symbol: 'TITAN',
    name: 'TITAN',
    chain: Chain.POLYGON,
    logo: '/images/coin/titan.png',
    decimals: 18,
    address: '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a'
  },
  '0x0000000000000000000000000000000000001010': {
    id: 'wmatic',
    symbol: 'MATIC',
    name: 'MATIC',
    chain: Chain.POLYGON,
    logo: '/images/coin/matic.png',
    decimals: 18,
    address: '0x0000000000000000000000000000000000001010'
  },
  '0x2791bca1f2de4661ed88a30c99a7a9449aa84174': {
    id: 'usd-coin',
    symbol: 'USDC',
    name: 'USDC',
    chain: Chain.POLYGON,
    logo: '/images/coin/usdc.png',
    decimals: 6,
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  },
  '0xc350caa89eb963d5d6b964324a0a7736d8d65533': {
    id: 'infinitee',
    symbol: 'INFTEE',
    name: 'InfiniteeToken',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://infinitee.finance/img/icon-inftee-black.77ef5c94.svg',
    decimals: 18,
    address: '0xc350caa89eb963d5d6b964324a0a7736d8d65533'
  },

  '0x965f527d9159dce6288a2219db51fc6eef120dd1': {
    id: 'biswap',
    symbol: 'BSW',
    name: 'BSW',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/bsw.svg',
    decimals: 18,
    address: '0x965f527d9159dce6288a2219db51fc6eef120dd1'
  },
  '0x7b65b489fe53fce1f6548db886c08ad73111ddd8': {
    id: 'iron-bsc',
    symbol: 'IRON',
    name: 'IRON',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/iron.png',
    decimals: 18,
    address: '0x7b65b489fe53fce1f6548db886c08ad73111ddd8'
  },
  '0x9001ee054f1692fef3a48330cb543b6fec6287eb': {
    id: 'steel',
    symbol: 'STEEL',
    name: 'STEEL',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/steel.png',
    decimals: 18,
    address: '0x9001ee054f1692fef3a48330cb543b6fec6287eb'
  },
  '0x39f1014a88c8ec087cedf1bfc7064d24f507b894': {
    id: 'scz',
    symbol: 'SCZ',
    name: 'SCZ',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/scz.png',
    decimals: 18,
    address: '0x39f1014a88c8ec087cedf1bfc7064d24f507b894'
  },
  '0xcd40f2670cf58720b694968698a5514e924f742d': {
    id: 'oddz',
    symbol: 'ODDZ',
    name: 'ODDZ',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14421/small/img_circle_200x200.png?1617345151',
    decimals: 18,
    address: '0xcd40f2670cf58720b694968698a5514e924f742d'
  },
  '0x831753dd7087cac61ab5644b308642cc1c33dc13': {
    id: 'quick',
    symbol: 'QUICK',
    name: 'QUICK',
    chain: Chain.POLYGON,
    logo: '/images/coin/quick.png',
    decimals: 18,
    address: '0x831753dd7087cac61ab5644b308642cc1c33dc13'
  },
  '0x4a3524936db5c310d852266033589d3f6f30ba5d': {
    id: 'jaguarswap',
    symbol: 'JAGUAR',
    name: 'JAGUAR',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15258/small/2_%286%29.png?1620269564',
    decimals: 18,
    address: '0x4a3524936db5c310d852266033589d3f6f30ba5d'
  },
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': {
    id: 'tether',
    symbol: 'USDT',
    name: 'USDT',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707',
    decimals: 6,
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
  },
  '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a': {
    id: 'sushi',
    symbol: 'SUSHI',
    name: 'SUSHI',
    chain: Chain.POLYGON,
    logo: '/images/coin/sushi.png',
    decimals: 18,
    address: '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a'
  },
  '0x0102bbfddffbd8d28d3a1b9c47017f62f42768f2': {
    id: '50cent',
    symbol: '50C',
    name: '50C',
    chain: Chain.POLYGON,
    logo: '/images/coin/50c.svg',
    decimals: 18,
    address: '0x0102bbfddffbd8d28d3a1b9c47017f62f42768f2'
  },
  '0xa656DC2b1061f80F4E847bbA2D9Bd52db4889836': {
    id: '50-keeper',
    symbol: '50K',
    name: '50K',
    chain: Chain.POLYGON,
    logo: '/images/coin/50k.svg',
    decimals: 18,
    address: '0xa656DC2b1061f80F4E847bbA2D9Bd52db4889836'
  },
  '0x1cbddf83de068464eba3a4e319bd3197a7eea12c': {
    id: 'seeder-finance',
    symbol: 'LEAF',
    name: 'LEAF',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/leaf.svg',
    decimals: 18,
    address: '0x1cbddf83de068464eba3a4e319bd3197a7eea12c'
  },
  '0xC51E41c88E1F7cCC12bc12748Bfd1CF3d0058908': {
    id: 'sdleaf',
    symbol: 'sdLEAF',
    name: 'sdLEAF',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/leaf.svg',
    decimals: 18,
    address: '0xC51E41c88E1F7cCC12bc12748Bfd1CF3d0058908'
  },
  '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270': {
    id: 'wmatic',
    name: 'WMATIC',
    symbol: 'WMATIC',
    chain: Chain.POLYGON,
    logo: '/images/coin/matic.png',
    decimals: 18,
    address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
  },
  '0x5d47bAbA0d66083C52009271faF3F50DCc01023C': {
    id: 'apeswap-finance',
    name: 'BANANA',
    symbol: 'BANANA',
    chain: Chain.POLYGON,
    logo: '/images/coin/banana.svg',
    decimals: 18,
    address: '0x5d47bAbA0d66083C52009271faF3F50DCc01023C'
  },
  '0x76bf0c28e604cc3fe9967c83b3c3f31c213cfe64': {
    id: 'polycrystal',
    name: 'CRYSTL',
    symbol: 'CRYSTL',
    chain: Chain.POLYGON,
    logo: '/images/coin/crystal.png',
    decimals: 18,
    address: '0x76bf0c28e604cc3fe9967c83b3c3f31c213cfe64'
  },
  '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619': {
    id: 'ethereum',
    name: 'WETH',
    symbol: 'WETH',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    decimals: 18,
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'
  },
  '0x3a3df212b7aa91aa0402b9035b098891d276572b': {
    id: 'polycat-finance',
    name: 'FISH',
    symbol: 'FISH',
    chain: Chain.POLYGON,
    logo: '/images/coin/fish.svg',
    decimals: 18,
    address: '0x3a3df212b7aa91aa0402b9035b098891d276572b'
  },
  '0xbb69e398c49A353D8359dEa4F750756566C9DFEa': {
    id: 'binancecoin',
    symbol: 'sdBNB',
    name: 'sdBNB',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png?1547034615',
    decimals: 18,
    address: '0xbb69e398c49A353D8359dEa4F750756566C9DFEa'
  },
  '0xd0f3121a190d85de0ab6131f2bcecdbfcfb38891': {
    id: 'polyyeld-token',
    symbol: 'YELD',
    name: 'YELD',
    chain: Chain.POLYGON,
    logo: '/images/coin/yeld.svg',
    decimals: 18,
    address: '0xd0f3121a190d85de0ab6131f2bcecdbfcfb38891'
  },
  '0x3bc5798416c1122bcfd7cb0e055d50061f23850d': {
    id: 'coinswap-space',
    symbol: 'CSS',
    name: 'CSS',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/css.png',
    decimals: 18,
    address: '0x3bc5798416c1122bcfd7cb0e055d50061f23850d'
  },
  '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd': {
    id: 'chainlink',
    symbol: 'LINK',
    name: 'LINK',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/link.png',
    decimals: 18,
    address: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd'
  },
  '0xdab0686d5f06deb4b721baa79da96e18cf19d029': {
    id: 'tree',
    symbol: 'TREE',
    name: 'TREE',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/tree.svg',
    decimals: 18,
    address: '0xdab0686d5f06deb4b721baa79da96e18cf19d029'
  },
  '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97': {
    id: 'dfyn-network',
    symbol: 'DFYN',
    name: 'DFYN',
    chain: Chain.POLYGON,
    logo: '/images/coin/dfyn.png',
    decimals: 18,
    address: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97'
  },
  '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063': {
    id: 'dai',
    symbol: 'DAI',
    name: 'DAI',
    chain: Chain.POLYGON,
    logo: '/images/coin/dai.png',
    decimals: 18,
    address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063'
  },
  '0x2f3391aebe27393aba0a790aa5e1577fea0361c2': {
    id: 'tako-token',
    symbol: 'TAKO',
    name: 'TAKO',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/TAKO.png',
    decimals: 18,
    address: '0x2f3391aebe27393aba0a790aa5e1577fea0361c2'
  },
  '0xb37cad62441ef8b866f3e36f12fd42062b6c0f33': {
    id: 'inku',
    symbol: 'INKU',
    name: 'INKU',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/INKU.png',
    decimals: 18,
    address: '0xb37cad62441ef8b866f3e36f12fd42062b6c0f33'
  },
  '0x6d2a71f4edf10ab1e821b9b373363e1e24e5df6b': {
    id: 'tako-token',
    symbol: 'TAKO',
    name: 'TAKO',
    chain: Chain.POLYGON,
    logo: '/images/coin/TAKO.png',
    decimals: 18,
    address: '0x6d2a71f4edf10ab1e821b9b373363e1e24e5df6b'
  },
  '0x1137d5836ef0e0ed9acc74aef8ffe2eaf81120b5': {
    id: 'never',
    symbol: 'NEVER',
    name: 'NEVER',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/never.svg',
    decimals: 18,
    address: '0x1137d5836ef0e0ed9acc74aef8ffe2eaf81120b5'
  },
  '0x48ea7cbabc983e4d0d67b8b2578b5ea665f40dfb': {
    id: 'ever',
    symbol: 'EVER',
    name: 'EVER',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/ever.png',
    decimals: 18,
    address: '0x48ea7cbabc983e4d0d67b8b2578b5ea665f40dfb'
  },
  '0xfaefe2e0d056243060a6f640d5735cae307001c4': {
    id: 'supermoon',
    symbol: 'OSM',
    name: 'OSM',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/osm.png',
    decimals: 9,
    address: '0xfaefe2e0d056243060a6f640d5735cae307001c4'
  },
  '0x5f84ce30dc3cf7909101c69086c50de191895883': {
    id: 'venus-reward-token',
    symbol: 'VRT',
    name: 'VRT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15734/small/vrt-venus-token.png?1621750113',
    decimals: 18,
    address: '0x5f84ce30dc3cf7909101c69086c50de191895883'
  },
  '0xd7730681b1dc8f6f969166b29d8a5ea8568616a3': {
    id: 'nafter',
    symbol: 'NAFT',
    name: 'NAFT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15493/small/Nafter_White_logo_1_200x200.png?1621047922',
    decimals: 18,
    address: '0xd7730681b1dc8f6f969166b29d8a5ea8568616a3'
  },
  '0x7e52a123ed6db6ac872a875552935fbbd2544c86': {
    id: 'xsl-labs',
    symbol: 'SYL',
    name: 'SYL',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14847/small/SYL_V01.png?1618650199',
    decimals: 6,
    address: '0x7e52a123ed6db6ac872a875552935fbbd2544c86'
  },
  '0x95a1199eba84ac5f19546519e287d43d2f0e1b41': {
    id: 'rabbit-finance',
    symbol: 'RABBIT',
    name: 'RABBIT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/rabbit.png',
    decimals: 18,
    address: '0x95a1199eba84ac5f19546519e287d43d2f0e1b41'
  },
  '0x4c28f48448720e9000907BC2611F73022fdcE1fA': {
    id: 'wmatic',
    symbol: 'WMATIC',
    name: 'WMATIC',
    chain: Chain.POLYGON,
    logo: '/images/coin/matic.png',
    decimals: 18,
    address: '0x4c28f48448720e9000907BC2611F73022fdcE1fA'
  },
  '0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef': {
    id: 'iron-finance',
    symbol: 'ICE',
    name: 'ICE',
    chain: Chain.POLYGON,
    logo: '/images/coin/ice.png',
    decimals: 18,
    address: '0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef'
  },

  // '0xD016cAAe879c42cB0D74BB1A265021bf980A7E96': {
  //   id: 'pspace',
  //   symbol: 'pSPACE',
  //   name: 'pSPACE',
  //   chain: Chain.POLYGON,
  //   logo: '/images/coin/space.svg',
  //   decimals: 18,
  //   address: '0xD016cAAe879c42cB0D74BB1A265021bf980A7E96'
  // },
  '0xb2ebaa0ad65e9c888008bf10646016f7fcdd73c3': {
    id: 'nails',
    symbol: 'NALIS',
    name: 'NALIS',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/NALIS.png',
    decimals: 18,
    address: '0xb2ebaa0ad65e9c888008bf10646016f7fcdd73c3'
  },
  '0x7f426f6dc648e50464a0392e60e1bb465a67e9cf': {
    id: 'auto',
    symbol: 'pAUTO',
    name: 'pAUTO',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/13751/large/autofarm_icon_200x200.png?1611494288',
    decimals: 18,
    address: '0x7f426f6dc648e50464a0392e60e1bb465a67e9cf'
  },
  '0x084bb94e93891d74579b54ab63ed24c4ef9cd5ef': {
    id: 'coupon',
    symbol: 'COUPON',
    name: 'COUPON',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/coupon.svg',
    decimals: 18,
    address: '0x084bb94e93891d74579b54ab63ed24c4ef9cd5ef'
  },
  '0x4ba0057f784858a48fe351445c672ff2a3d43515': {
    id: 'kalmar',
    symbol: 'KALM',
    name: 'KALM',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15849/small/kyljoFN.png?1622520569',
    decimals: 18,
    address: '0x4ba0057f784858a48fe351445c672ff2a3d43515'
  },
  '0xc748673057861a797275cd8a068abb95a902e8de': {
    id: 'baby-doge-coin',
    symbol: 'BabyDoge',
    name: 'BabyDoge',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/16125/small/Baby_Doge.png?1623044048',
    decimals: 9,
    address: '0xc748673057861a797275cd8a068abb95a902e8de'
  },
  '0xb09fe1613fe03e7361319d2a43edc17422f36b09': {
    id: 'bogged-finance',
    symbol: 'BOG',
    name: 'BOG',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15980/small/DNZDqtH2_400x400.png?1622530803',
    decimals: 18,
    address: '0xb09fe1613fe03e7361319d2a43edc17422f36b09'
  },
  '0x19ea6042ca81bcd1fec329004fd5967afdc6745e': {
    id: 'craft',
    symbol: 'CRAFT',
    name: 'CRAFT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/craft.png',
    decimals: 18,
    address: '0x19ea6042ca81bcd1fec329004fd5967afdc6745e'
  },
  '0xe363096e780fb8675966b3ffca88bedd62b838fa': {
    id: 'tuk',
    symbol: 'TUK',
    name: 'TUK',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/tuk.png',
    decimals: 18,
    address: '0xe363096e780fb8675966b3ffca88bedd62b838fa'
  },
  '0x13a5bcff72b024619df4d62093941c382b6d6348': {
    id: 'ttusd',
    symbol: 'ttUSD',
    name: 'ttUSD',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/ttusd.png',
    decimals: 18,
    address: '0x13a5bcff72b024619df4d62093941c382b6d6348'
  },
  '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6': {
    id: 'wrapped-bitcoin',
    symbol: 'WBTC',
    name: 'WBTC',
    chain: Chain.POLYGON,
    logo: '/images/coin/btcb.svg',
    decimals: 8,
    address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6'
  },
  '0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab': {
    id: 'cryptoblades',
    symbol: 'SKILL',
    name: 'SKILL',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/SKILL.svg',
    decimals: 18,
    address: '0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab'
  },
  '0x402bf56d4500f7bc86771390925ff8d059d29842': {
    id: 'cea',
    symbol: 'CEA',
    name: 'CEA',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/CEA.png',
    decimals: 18,
    address: '0x402bf56d4500f7bc86771390925ff8d059d29842'
  },
  '0x7039eb99215b345588b94bc8830e186c457a8fb3': {
    id: 'wafi',
    symbol: 'wAFI',
    name: 'wAFI',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/WAFI.png',
    decimals: 18,
    address: '0x7039eb99215b345588b94bc8830e186c457a8fb3'
  },
  '0x14c358b573a4ce45364a3dbd84bbb4dae87af034': {
    id: 'dungeonswap',
    symbol: 'DND',
    name: 'DND',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15207/small/logo-200x200.png?1620102338',
    decimals: 18,
    address: '0x14c358b573a4ce45364a3dbd84bbb4dae87af034'
  },
  '0x19a6da6e382b85f827088092a3dbe864d9ccba73': {
    id: 'bishares',
    symbol: 'BISON',
    name: 'BISON',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/17198/small/J0DEwgn.png?1628598234',
    decimals: 18,
    address: '0x19a6da6e382b85f827088092a3dbe864d9ccba73'
  },
  '0xd9025e25Bb6cF39f8c926A704039D2DD51088063': {
    id: 'cyt',
    symbol: 'CYT',
    name: 'CYT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://apeswap.finance/images/tokens/CYT.svg',
    decimals: 18,
    address: '0xd9025e25Bb6cF39f8c926A704039D2DD51088063'
  },
  '0xbd83010eb60f12112908774998f65761cf9f6f9a': {
    id: 'mogul-productions',
    symbol: 'STARS',
    name: 'STARS',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://apeswap.finance/images/tokens/STARS.svg',
    decimals: 18,
    address: '0xbd83010eb60f12112908774998f65761cf9f6f9a'
  },
  '0x82a479264b36104be4fdb91618a59a4fc0f50650': {
    id: 'birb',
    symbol: 'BIRD',
    name: 'BIRD',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://apeswap.finance/images/tokens/BIRB.svg',
    decimals: 18,
    address: '0x82a479264b36104be4fdb91618a59a4fc0f50650'
  },
  '0x215f51990a0ca5aa360a177181f014e2da376e5e': {
    id: 'hamaru',
    symbol: 'MARU',
    name: 'MARU',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/17434/small/IMG-20210730-105615-947.png?1627628183',
    decimals: 18,
    address: '0x215f51990a0ca5aa360a177181f014e2da376e5e'
  },
  '0xb9d8592e16a9c1a3ae6021cddb324eac1cbc70d6': {
    id: 'pera-finance',
    symbol: 'PERA',
    name: 'PERA',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/16185/small/Pera.jpg?1623238387',
    decimals: 18,
    address: '0xb9d8592e16a9c1a3ae6021cddb324eac1cbc70d6'
  },
  '0x9d986a3f147212327dd658f712d5264a73a1fdb0': {
    id: 'landshare',
    symbol: 'LAND',
    name: 'LAND',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/17507/small/Landshare.png?1628149620',
    decimals: 18,
    address: '0x9d986a3f147212327dd658f712d5264a73a1fdb0'
  },
  '0x1ba8c21c623c843cd4c60438d70e7ad50f363fbb': {
    id: 'srnartgallery',
    symbol: 'SACT',
    name: 'SACT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14538/small/SACTstake200x200.png?1616841629',
    decimals: 18,
    address: '0x1ba8c21c623c843cd4c60438d70e7ad50f363fbb'
  },
  '0x0ef0626736c2d484a792508e99949736d0af807e': {
    id: 'bitcrush',
    symbol: 'CRUSH',
    name: 'CRUSH',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/16061/small/BCA-Logo-Flat.png?1622711236',
    decimals: 18,
    address: '0x0ef0626736c2d484a792508e99949736d0af807e'
  },
  '0x0523215dcafbf4e4aa92117d13c6985a3bef27d7': {
    id: 'gmr-finance',
    symbol: 'GMR',
    name: 'GMR',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15301/small/fiy3DQh.png?1620369557',
    decimals: 18,
    address: '0x0523215dcafbf4e4aa92117d13c6985a3bef27d7'
  },
  '0x645695d8b5693d806af33dfc82eb15d4cc0a4a59': {
    id: 'hope',
    symbol: 'HOPE',
    name: 'HOPE',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: '/images/coin/firebird.svg',
    decimals: 18,
    address: '0x645695d8b5693d806af33dfc82eb15d4cc0a4a59'
  },
  '0xa3fa99a148fa48d14ed51d610c367c61876997f1': {
    id: 'mimatic',
    symbol: 'miMATIC',
    name: 'miMATIC',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/15264/small/mimatic-red.png?1620281018',
    decimals: 18,
    address: '0xa3fa99a148fa48d14ed51d610c367c61876997f1'
  },
  '0x580a84c73811e1839f75d86d75d88cca0c241ff4': {
    id: 'qi-dao',
    symbol: 'QI',
    name: 'Qi DAO',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/15329/small/qi.png?1620540969',
    decimals: 18,
    address: '0x580a84c73811e1839f75d86d75d88cca0c241ff4'
  },
  '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3': {
    id: 'balancer',
    symbol: 'BAL',
    name: 'Balancer',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1592792958',
    decimals: 18,
    address: '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3'
  },
  '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39': {
    id: 'chainlink',
    symbol: 'LINK',
    name: 'Chainlink',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700',
    decimals: 18,
    address: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39'
  },
  '0xd6df932a45c0f255f85145f286ea0b292b21c90b': {
    id: 'aave',
    symbol: 'AAVE',
    name: 'Aave',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/12645/large/AAVE.png?1601374110',
    decimals: 18,
    address: '0xd6df932a45c0f255f85145f286ea0b292b21c90b'
  },
  '0xd6c23852b94fede6ab571e4b4cfdb745b49dc9eb': {
    id: 'renbtc',
    symbol: 'renBTC',
    name: 'renBTC',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/11370/large/renBTC.png?1589985711',
    decimals: 8,
    address: '0xd6c23852b94fede6ab571e4b4cfdb745b49dc9eb'
  },
  '0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e': {
    id: 'augury-finance',
    symbol: 'OMEN',
    name: 'OMEN',
    chain: Chain.POLYGON,
    logo: '/images/coin/omen.png',
    decimals: 18,
    address: '0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e'
  },
  '0xec87e4ffc54da4dab82abb50595cd44306d20e03': {
    id: 'viva',
    symbol: 'VIVA',
    name: 'VIVAToken',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://i.postimg.cc/02hQF84m/vivainsure-dapp-defi-bsc-logo-166x166-432a71011782d5067f489195a33d4c51.png',
    decimals: 8,
    address: '0xec87e4ffc54da4dab82abb50595cd44306d20e03'
  },
  '0x3fcca8648651e5b974dd6d3e50f61567779772a8': {
    id: 'moonpot',
    symbol: 'POTS',
    name: 'POTSTOKEN',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/17517/small/moonpot.PNG?1628067384',
    decimals: 18,
    address: '0x3fcca8648651e5b974dd6d3e50f61567779772a8'
  },
  '0xd78c475133731cd54dadcb430f7aae4f03c1e660': {
    id: 'hope',
    symbol: 'HOPE',
    name: 'HOPE',
    chain: Chain.POLYGON,
    logo: '/images/coin/firebird.svg',
    decimals: 18,
    address: '0xd78c475133731cd54dadcb430f7aae4f03c1e660'
  },
  '0xf7844cb890f4c339c497aeab599abdc3c874b67a': {
    id: 'nft-art-finance',
    symbol: 'NFTART',
    name: 'NFTART',
    chain: Chain.POLYGON,
    logo: 'https://assets.coingecko.com/coins/images/15027/small/nft-art-coingecko-logo.png?1622443455',
    decimals: 9,
    address: '0xf7844cb890f4c339c497aeab599abdc3c874b67a'
  },
  '0xba2ae424d960c26247dd6c32edc70b295c744c43': {
    id: 'dogecoin',
    symbol: 'DOGE',
    name: 'DOGE',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/5/small/dogecoin.png?1547792256',
    decimals: 8,
    address: '0xba2ae424d960c26247dd6c32edc70b295c744c43'
  },
  '0x68e374f856bf25468d365e539b700b648bf94b67': {
    id: 'mist',
    symbol: 'MIST',
    name: 'MIST',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/14841/small/mist.jpg?1618629293',
    decimals: 18,
    address: '0x68e374f856bf25468d365e539b700b648bf94b67'
  },
  '0x3379a0bdf5a5cb566127c421782686ba0f80490a': {
    id: 'mvp-coin',
    symbol: 'MVP',
    name: 'MVP',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/17801/small/mvp.png?1629288557',
    decimals: 18,
    address: '0x3379a0bdf5a5cb566127c421782686ba0f80490a'
  },
  '0x940ff63e82d15fb47371bfe5a4ed7d7d183de1a5': {
    id: 'kusd',
    symbol: 'KUSD',
    name: 'KUSD',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://dopple.finance/_next/image?url=%2Fimages%2Ficons%2Flight-kusd.svg&w=32&q=75',
    decimals: 18,
    address: '0x940ff63e82d15fb47371bfe5a4ed7d7d183de1a5'
  },
  '0xe8176d414560cfe1bf82fd73b986823b89e4f545': {
    id: 'step-hero',
    symbol: 'HERO',
    name: 'HERO',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/17700/small/stephero.PNG?1629072670',
    decimals: 18,
    address: '0xe8176d414560cfe1bf82fd73b986823b89e4f545'
  },
  '0xd635b32688f36ee4a7fe117b4c91dd811277acb6': {
    id: 'copycat',
    symbol: 'COPYCAT',
    name: 'COPYCAT',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/18276/small/copycat.PNG?1631233811',
    decimals: 18,
    address: '0xd635b32688f36ee4a7fe117b4c91dd811277acb6'
  },
  '0x8d78c2ff1fb4fba08c7691dfeac7bb425a91c81a': {
    id: 'latte',
    symbol: 'LATTE',
    name: 'LATTE',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/15642/small/5842b803-3905-46ae-9dc3-df35bb12da4e.png?1621411379',
    decimals: 18,
    address: '0x8d78c2ff1fb4fba08c7691dfeac7bb425a91c81a'
  },
  '0xc3D912863152E1Afc935AD0D42d469e7C6B05B77': {
    id: 'lucky-lion',
    symbol: 'LUCKY',
    name: 'LUCKY',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/18712/small/lucky-token-200px.png?1633079262',
    decimals: 18,
    address: '0xc3D912863152E1Afc935AD0D42d469e7C6B05B77'
  },
  FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z: {
    id: 'avalanche-2',
    symbol: 'AVAX',
    name: 'Avalanche',
    chain: Chain.AVAX,
    logo: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818',
    decimals: 18,
    address: 'FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z'
  },
  '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7': {
    id: 'wavalanche',
    symbol: 'WAVAX',
    name: 'Avalanche',
    chain: Chain.AVAX,
    logo: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818',
    decimals: 18,
    address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
  },
  'penguin-finance': {
    id: 'penguin-finance',
    symbol: 'PEFI',
    name: 'Penguin Finance',
    chain: Chain.AVAX,
    logo: 'https://assets.coingecko.com/coins/images/14643/small/TcxLiQr.png?1617486828',
    decimals: 18,
    address: '0xe896cdeaac9615145c0ca09c8cd5c25bced6384c'
  },
  '0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084': {
    id: 'lydia-finance',
    symbol: 'LYD',
    name: 'Lydia Finance',
    chain: Chain.AVAX,
    logo: 'https://assets.coingecko.com/coins/images/15195/small/512_pure_logo.png?1620083279',
    decimals: 18,
    address: '0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084'
  },
  '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd': {
    id: 'joe',
    symbol: 'JOE',
    name: 'JOE',
    chain: Chain.AVAX,
    logo: 'https://assets.coingecko.com/coins/images/17569/small/joe_200x200.png?1628497750',
    decimals: 18,
    address: '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd'
  },
  '0x60781c2586d68229fde47564546784ab3faca982': {
    id: 'pangolin',
    symbol: 'PNG',
    name: 'PNG',
    chain: Chain.AVAX,
    logo: 'https://assets.coingecko.com/coins/images/14023/small/pangolin.jpg?1613743598',
    decimals: 18,
    address: '0x60781c2586d68229fde47564546784ab3faca982'
  },
  '0x9fd87aefe02441b123c3c32466cd9db4c578618f': {
    id: 'thetan-arena',
    symbol: 'THG',
    name: 'THG',
    chain: Chain.BINANCE_SMART_CHAIN,
    logo: 'https://assets.coingecko.com/coins/images/18414/small/c4N4n8k-_400x400.png?1631831447',
    decimals: 18,
    address: '0x9fd87aefe02441b123c3c32466cd9db4c578618f'
  },
  '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83': {
    id: 'fantom',
    symbol: 'FTM',
    name: 'Fantom',
    chain: Chain.FTM,
    logo: '/images/logo/ftm.png',
    decimals: 18,
    address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83'
  },
  '0x5cc61a78f164885776aa610fb0fe1257df78e59b': {
    id: 'spiritswap',
    symbol: 'SPIRIT',
    name: 'spiritswap',
    chain: Chain.FTM,
    logo: 'https://assets.coingecko.com/coins/images/15118/small/4mY2Biy9_400x400.jpg?1619753382',
    decimals: 18,
    address: '0x5cc61a78f164885776aa610fb0fe1257df78e59b'
  },
  '0x841fad6eae12c286d1fd18d1d525dffa75c7effe': {
    id: 'spookyswap',
    symbol: 'BOO',
    name: 'spiritswap',
    chain: Chain.FTM,
    logo: 'https://assets.coingecko.com/coins/images/15223/small/logo_200x200.png?1621992076',
    decimals: 18,
    address: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe'
  },
  '0x911da02C1232A3c3E1418B834A311921143B04d7': {
    id: 'vedao',
    symbol: 'WEVE',
    name: 'vedao',
    chain: Chain.FTM,
    logo: 'https://assets.coingecko.com/coins/images/22741/small/S0aENDh0_400x400.jpg?1642544703',
    decimals: 18,
    address: '0x911da02C1232A3c3E1418B834A311921143B04d7'
  },
  '0x82f0b8b456c1a451378467398982d4834b6829c1': {
    id: 'mim',
    symbol: 'MIM',
    name: 'magic internet Money',
    chain: Chain.FTM,
    logo: 'https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png?1624979612',
    decimals: 18,
    address: '0x82f0b8b456c1a451378467398982d4834b6829c1'
  },
  '0xc165d941481e68696f43ee6e99bfb2b23e0e3114': {
    id: '0xdao',
    symbol: 'OXD',
    name: 'OXD',
    chain: Chain.FTM,
    logo: 'https://assets.coingecko.com/coins/images/22888/small/rjks-OoT_400x400.jpg?1642827011',
    decimals: 18,
    address: '0xc165d941481e68696f43ee6e99bfb2b23e0e3114'
  }
}
//TODO will deprecated (blocks: allowance)
export const getTokenData = (address: string) => oldtokens[address]

export const noExistCoinGeckoTokens = {
  // DINOP: '0xea90dc6f64d18771ca1dac8098526a9082265b42',
  DBM: '0x0314e5a39806C30D67B869EE1bCDABee7e08dE74',
  AFI: '0x30dfdefe4b5c2ef1bcb82fd1d6e62f9a8580a05a',
  WAFI: '0x7039eb99215b345588b94bc8830e186c457a8fb3',
  CEA: '0x402bf56d4500f7bc86771390925ff8d059d29842',
  GNANA: '0xddb3bd8645775f59496c821e4f55a7ea6a6dc299',
  FINIX: '0x0f02b1f5af54e04fb6dd6550f009ac2429c4e30d',
  DOLLY: '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5',
  TWIN: '0x3806aae953a3a873d02595f76c7698a57d4c7a57',
  TAPS: '0x56eab07247e3e6404ac90140f20bba61375d5c3c',
  AAPL: '0xc10b2ce6a2bcfdfdc8100ba1602c1689997299d3',
  TSLA: '0x9c169647471c1c6a72773cffc50f6ba285684803',
  GOOGL: '0x9c169647471c1c6a72773cffc50f6ba285684803',
  AMZA: '0x1085b90544ff5c421d528aaf79cc65afc920ac79',
  DMC: '0xa7bd090bc58e663388be63d5e8a7b683f5a3d4fc',
  ALE: '0x99ca242f20424a6565cc17a409e557fa95e25bd7',
  INKU: '0xb37cad62441ef8b866f3e36f12fd42062b6c0f33',
  NALIS: '0xb2ebaa0ad65e9c888008bf10646016f7fcdd73c3',
  EVER: '0x48ea7cbabc983e4d0d67b8b2578b5ea665f40dfb',
  NEVER: '0x1137d5836ef0e0ed9acc74aef8ffe2eaf81120b5',
  COUPON: '0x084bb94e93891d74579b54ab63ed24c4ef9cd5ef',
  CRAFT: '0x19ea6042ca81bcd1fec329004fd5967afdc6745e',
  CYT: '0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
  'HOPE-P': '0x645695d8b5693d806af33dfc82eb15d4cc0a4a59',
  'HOPE-P-POLYGON': '0xd78c475133731cd54dadcb430f7aae4f03c1e660',
  KUSD: '0x940ff63e82d15fb47371bfe5a4ed7d7d183de1a5',
  COLYCAT: '0xd635b32688f36ee4a7fe117b4c91dd811277acb6',
  // JDI: '0x0491648c910ad2c1afaab733faf71d30313df7fc',
  LATTE: '0x8d78c2ff1fb4fba08c7691dfeac7bb425a91c81a'
  // AVAX: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'
}
