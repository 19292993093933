import { useRouter } from 'next/router';
import * as React from 'react';
export var useGA = function useGA() {
  var router = useRouter();
  React.useEffect(function () {
    if (window && router) {
      var handleRouteChange = function handleRouteChange(url) {
        pageview(url);
      }; //When the component is mounted, subscribe to router changes
      //and log those page views


      router.events.on('routeChangeComplete', handleRouteChange); // If the component is unmounted, unsubscribe
      // from the event with the `off` method

      return function () {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router]);
}; // log the pageview with their URL

var pageview = function pageview(url) {
  window['gtag']('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url
  });
}; // log specific events happening.


export var gaEvent = function gaEvent(_ref) {
  var action = _ref.action,
      params = _ref.params;
  window['gtag']('event', action, params);
};
export function reportWebVitals(_ref2) {
  var id = _ref2.id,
      name = _ref2.name,
      label = _ref2.label,
      value = _ref2.value;
  // Use `window.gtag` if you initialized Google Analytics as this example:
  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_document.js
  window['gtag']('event', name, {
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    // values must be integers
    event_label: id,
    // id unique to current page load
    non_interaction: true // avoids affecting bounce rate.

  });
}