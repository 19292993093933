interface ChainBridge {
  name: string
  image: string
}

export interface Bridge {
  chain: ChainBridge
  fee: string
  url: string
  estimateTime: string
  supports: string[]
}

export const bridges: Bridge[] = [
  {
    chain: {
      name: 'allbridge',
      image: 'Allbridge'
    },
    fee: '0.3% of the transfer amount or $0.5 fixed flat fee',
    url: 'https://allbridge.io/',
    estimateTime: '1-5 mins',
    supports: ['eth', 'bsc', 'matic', 'sol', 'fantom', 'avax', 'arbitrum']
  },
  {
    chain: {
      name: 'AnySwap Bridge',
      image: 'AnySwap'
    },
    fee: '$0',
    url: 'https://anyswap.exchange/dashboard#/bridge',
    estimateTime: '10-30 mins',
    supports: ['eth', 'bsc', 'fantom']
  },
  {
    chain: {
      name: 'Anyswap Router',
      image: 'AnySwap'
    },
    fee: '0.1% of the transfer amount',
    url: 'https://anyswap.exchange/dashboard#/router',
    estimateTime: '10-30 mins',
    supports: ['eth', 'bsc', 'matic', 'fantom', 'avax', 'arbitrum']
  },
  {
    chain: {
      name: 'Celer cBridge',
      image: 'Celer cBridge'
    },
    fee: '$0.5',
    url: 'https://cbridge.celer.network/#/transfer',
    estimateTime: '3-5 mins',
    supports: ['eth', 'bsc', 'matic', 'avax', 'arbitrum']
  },
  {
    chain: {
      name: 'Evodefi',
      image: 'EvoDefi'
    },
    fee: '0.5% of the transfer amount',
    url: 'https://bridge.evodefi.com/?token=USDC',
    estimateTime: '5-30 mins',
    supports: ['eth', 'bsc', 'matic', 'fantom']
  },
  {
    chain: {
      name: 'relaychain',
      image: 'Relaychain'
    },
    fee: '0.02 BNB',
    url: 'https://app.relaychain.com/#/cross-chain-bridge-transfer',
    estimateTime: '5-10 mins',
    supports: ['eth', 'bsc', 'matic', 'fantom', 'avax']
  },
  {
    chain: {
      name: 'Supra Finance',
      image: 'Supra Finance'
    },
    fee: '$3 (or 0.04 SUPRA)',
    url: 'https://app.supra.finance/bridge',
    estimateTime: '10-15 mins',
    supports: ['eth', 'bsc', 'matic', 'sol', 'fantom']
  },
  {
    chain: {
      name: 'Synapse',
      image: 'Synapse'
    },
    fee: '$1-80',
    url: 'https://synapseprotocol.com/?inputCurrency=USDC&outputCurrency=MIM&outputChain=42161',
    estimateTime: '1-3 mins',
    supports: ['eth', 'bsc', 'matic', 'avax', 'arbitrum']
  },
  {
    chain: {
      name: 'Zeroswap',
      image: 'Zeroswap'
    },
    fee: '$0',
    url: 'https://bridge.zeroswap.io/',
    estimateTime: '1-5 mins',
    supports: ['eth', 'bsc', 'matic']
  }
]
