import { Chain, JDIAuto } from '@jdiyield/interfaces'

export const DINO_PROFILE_NFT: string = process.env.NEXT_PUBLIC_DINO_PROFILE_NFT
  ? process.env.NEXT_PUBLIC_DINO_PROFILE_NFT
  : '0x406c0587258B365Cc33f822Ef9E1040167557e78'

export const DINO_PROFILE: string = process.env.NEXT_PUBLIC_DINO_PROFILE
  ? process.env.NEXT_PUBLIC_DINO_PROFILE
  : '0x621E35F56231262E854b11d54113f7082c4A9aD8'

export const DINOPARK_TOKEN: string = process.env.NEXT_PUBLIC_DINOPARK_TOKEN
  ? process.env.NEXT_PUBLIC_DINOPARK_TOKEN
  : '0x411724ec3925b0dc40cf34aa59be44f97190d5c2'

export const VOTE_CONTRACT: string = process.env.NEXT_PUBLIC_VOTE_CONTRACT
  ? process.env.NEXT_PUBLIC_VOTE_CONTRACT
  : '0xEc6367841F996A68CF48294c606B22e2f64d1D91'

export const JDI_TOKEN: string = process.env.NEXT_PUBLIC_JDI_TOKEN
  ? process.env.NEXT_PUBLIC_JDI_TOKEN
  : '0x0491648c910ad2c1afaab733faf71d30313df7fc'

export const JDI_PRO: string = process.env.NEXT_PUBLIC_JDI_PRO
  ? process.env.NEXT_PUBLIC_JDI_PRO
  : '0xdDD0c26Bea85A9D68Aa99bdC36b99dbd2F0E0CfD'

export enum PoolType {
  AUTO_COMPOUND = 'AUTO_COMPOUND',
  NORMAL = 'NORMAL'
}

export const jdiAutoPoolList: JDIAuto[] = [
  {
    name: 'PolyCrystal',
    logo: '/images/coin/crystal.png',
    link: 'https://polycrystal.finance/',
    chain: Chain.POLYGON,
    pool: [
      {
        id: '0',
        poolId: 5,
        poolIdStrat: 9,
        poolType: PoolType.AUTO_COMPOUND,
        name: 'JDI-MATIC LP',
        contractAddress: '0x7398cF51AF6Ca168295a6352F268E5d35FEDF770',
        farmContract: 'https://polygonscan.com/address/0xebcc84d2a73f0c9e23066089c6c24f4629ef1e6d',
        vaultContract: 'https://polygonscan.com/address/0x3A67CA7AaaE9a89022C1f5D03be6571f81B25cb5',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        lpAddress: '0xb01bAf15079eE93590A862Df37234e8f7C9825bF',
        depositFee: '0%',
        entranceFee: '< 0.1%',
        withdrawFee: '0.1%',
        token0: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        token1: '0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a',
        getLPLink: 'https://app.apeswap.finance/add/WMATIC/0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a',
        isActive: true,
        supportIL: false,
        supportROI: false,
        supportWithdrawAndRemoveLP: true,
        tokenDropdown: [
          {
            name: 'JDI-MATIC LP',
            address: '0xb01bAf15079eE93590A862Df37234e8f7C9825bF',
            routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
            approveToken: ['0xb01bAf15079eE93590A862Df37234e8f7C9825bF'],
            pathA: [],
            pathB: [],
            typeToken: 'LP',
            linkBuyToken: 'https://app.apeswap.finance/add/WMATIC/0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a'
          }
        ]
      },
      {
        id: '1',
        poolId: 3,
        poolIdStrat: 10,
        poolType: PoolType.AUTO_COMPOUND,
        name: 'USDT-USDC LP',
        contractAddress: '0x7398cF51AF6Ca168295a6352F268E5d35FEDF770',
        farmContract: 'https://polygonscan.com/address/0xebcc84d2a73f0c9e23066089c6c24f4629ef1e6d',
        vaultContract: 'https://polygonscan.com/address/0x470806Eb4e3CeDCFB48D44C44158B5c19A9F9E19',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        lpAddress: '0x9Ec257C1862F1BdF0603A6c20ED6f3d6Bae6DEB0',
        depositFee: '5%',
        entranceFee: '< 0.1%',
        withdrawFee: '0.1%',
        token0: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        token1: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        getLPLink:
          'https://app.apeswap.finance/add/0xc2132d05d31c914a87c6611c10748aeb04b58e8f/0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        isActive: true,
        supportIL: false,
        supportROI: false,
        supportWithdrawAndRemoveLP: true,
        tokenDropdown: [
          {
            name: 'USDT-USDC LP',
            address: '0x9Ec257C1862F1BdF0603A6c20ED6f3d6Bae6DEB0',
            routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
            pathA: [],
            pathB: [],
            approveToken: ['0x9Ec257C1862F1BdF0603A6c20ED6f3d6Bae6DEB0'],
            typeToken: 'LP',
            linkBuyToken:
              'https://app.apeswap.finance/add/0xc2132d05d31c914a87c6611c10748aeb04b58e8f/0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
          }
        ]
      },
      {
        id: '2',
        poolId: 2,
        poolIdStrat: 2,
        poolType: PoolType.AUTO_COMPOUND,
        name: 'MATIC-USDT LP',
        contractAddress: '0x7398cF51AF6Ca168295a6352F268E5d35FEDF770',
        farmContract: 'https://polygonscan.com/address/0xebcc84d2a73f0c9e23066089c6c24f4629ef1e6d',
        vaultContract: 'https://polygonscan.com/address/0x3C671065924C81c411fBB9492e764C0b7042295D',
        routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
        lpAddress: '0x65D43B64E3B31965Cd5EA367D4c2b94c03084797',
        depositFee: '2%',
        entranceFee: '< 0.1%',
        withdrawFee: '0.1%',
        token0: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        token1: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        getLPLink: 'https://app.apeswap.finance/add/ETH/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        isActive: true,
        supportIL: false,
        supportROI: false,
        supportWithdrawAndRemoveLP: true,
        tokenDropdown: [
          {
            name: 'MATIC-USDT LP',
            address: '0x65D43B64E3B31965Cd5EA367D4c2b94c03084797',
            routerAddress: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607',
            pathA: [],
            pathB: [],
            approveToken: ['0x65D43B64E3B31965Cd5EA367D4c2b94c03084797'],
            typeToken: 'LP',
            linkBuyToken: 'https://app.apeswap.finance/add/ETH/0xc2132d05d31c914a87c6611c10748aeb04b58e8f'
          }
        ]
      }
    ]
  },
  {
    name: 'Iron Finance',
    logo: '/images/coin/titan.png',
    link: 'https://app.iron.finance/',
    chain: Chain.POLYGON,
    pool: [
      {
        id: '0',
        poolId: 10,
        poolIdStrat: 0,
        poolType: PoolType.AUTO_COMPOUND,
        name: 'IS3USD LP',
        contractAddress: '0x7398cF51AF6Ca168295a6352F268E5d35FEDF770',
        farmContract: 'https://polygonscan.com/address/0x1fd1259fa8cdc60c6e8c86cfa592ca1b8403dfad',
        vaultContract: 'https://polygonscan.com/address/0x02310db0876Fa18baf827e712e2306DAf2305341',
        routerAddress: '0x837503e8A8753ae17fB8C8151B8e6f586defCb57',
        lpAddress: '0xb4d09ff3da7f9e9a2ba029cb0a81a989fd7b8f17',
        depositFee: '0%',
        entranceFee: '< 0.1%',
        withdrawFee: '0.1%',
        token0: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        token1: '0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a',
        getLPLink: 'https://app.iron.finance/swap/pools/is3usd/deposit',
        isActive: true,
        supportIL: false,
        supportROI: false,
        supportWithdrawAndRemoveLP: false,
        tokenDropdown: [
          {
            name: 'IS3USD LP',
            address: '0xb4d09ff3da7f9e9a2ba029cb0a81a989fd7b8f17',
            routerAddress: '0x837503e8A8753ae17fB8C8151B8e6f586defCb57',
            approveToken: ['0xb4d09ff3da7f9e9a2ba029cb0a81a989fd7b8f17'],
            pathA: [],
            pathB: [],
            typeToken: 'LP',
            linkBuyToken: 'https://app.iron.finance/swap/pools/is3usd/deposit'
          }
        ]
      }
    ]
  },
  {
    name: 'ApeSwap',
    link: 'https://apeswap.finance/',
    logo: 'https://i.postimg.cc/P5xZ5mQg/W36-DMB5-Ajkx-BWkw-Xi3x-SQm-A8-ADCRAPRr.png',
    chain: Chain.BINANCE_SMART_CHAIN,
    pool: [
      {
        id: '0',
        poolId: 3,
        poolIdStrat: 27,
        poolType: PoolType.AUTO_COMPOUND,
        name: 'JDI-BNB LP',
        contractAddress: '0x859f4e52fe4076d483A2EBCf53Ae69A35eBa7Aa5',
        farmContract: 'https://www.bscscan.com/address/0x5c8d727b265dbafaba67e050f2f739caeeb4a6f9',
        vaultContract: 'https://www.bscscan.com/address/0xdebf91f1Cff217b9B99B84Be904eFceEA58aAF63',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        lpAddress: '0xfb6063f29AF6dcd1fc03A8E221c6D91DEabbE764',
        depositFee: '0%',
        entranceFee: '< 0.1%',
        withdrawFee: '0.1%',
        token0: '0x0491648c910ad2c1afaab733faf71d30313df7fc',
        token1: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        getLPLink: 'https://app.apeswap.finance/add/0x0491648C910ad2c1aFaab733faF71D30313Df7FC/ETH',
        isActive: true,
        supportIL: true,
        supportROI: false,
        supportWithdrawAndRemoveLP: true,
        tokenDropdown: [
          {
            name: 'BNB',
            address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
            routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
            approveToken: ['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', '0x0491648c910ad2c1afaab733faf71d30313df7fc'],
            pathA: ['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', '0x0491648c910ad2c1afaab733faf71d30313df7fc'],
            pathB: [],
            typeToken: 'token',
            linkBuyToken: 'https://dex.apeswap.finance/#/swap?outputCurrency=ETH'
          },
          {
            name: 'JDI-BNB LP',
            address: '0xfb6063f29AF6dcd1fc03A8E221c6D91DEabbE764',
            routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
            approveToken: ['0xfb6063f29AF6dcd1fc03A8E221c6D91DEabbE764'],
            pathA: [],
            pathB: [],
            typeToken: 'LP',
            linkBuyToken: 'https://app.apeswap.finance/add/0x0491648C910ad2c1aFaab733faF71D30313Df7FC/ETH'
          }
        ]
      },
      {
        id: '1',
        poolId: 2,
        poolIdStrat: 3,
        poolType: PoolType.AUTO_COMPOUND,
        name: 'BNB-BUSD LP',
        contractAddress: '0x859f4e52fe4076d483A2EBCf53Ae69A35eBa7Aa5',
        farmContract: 'https://www.bscscan.com/address/0x5c8d727b265dbafaba67e050f2f739caeeb4a6f9',
        vaultContract: 'https://www.bscscan.com/address/0x74F52Ecc9c46BA25Ba9d102Eb90EA97cbae51E1B',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        lpAddress: '0x51e6D27FA57373d8d4C256231241053a70Cb1d93',
        depositFee: '0%',
        entranceFee: '< 0.1%',
        withdrawFee: '0.1%',
        token0: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        token1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        getLPLink: 'https://app.apeswap.finance/add/ETH/0xe9e7cea3dedca5984780bafc599bd69add087d56',
        isActive: true,
        supportIL: true,
        supportROI: false,
        supportWithdrawAndRemoveLP: true,
        tokenDropdown: [
          {
            name: 'BNB-BUSD LP',
            address: '0x51e6D27FA57373d8d4C256231241053a70Cb1d93',
            routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
            approveToken: ['0x51e6D27FA57373d8d4C256231241053a70Cb1d93'],
            pathA: [],
            pathB: [],
            typeToken: 'LP',
            linkBuyToken: 'https://app.apeswap.finance/add/ETH/0xe9e7cea3dedca5984780bafc599bd69add087d56'
          },
          {
            name: 'BNB',
            address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
            routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
            approveToken: ['0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', '0xe9e7cea3dedca5984780bafc599bd69add087d56'],
            pathA: [],
            pathB: ['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', '0xe9e7cea3dedca5984780bafc599bd69add087d56'],
            typeToken: 'token',
            linkBuyToken: 'https://dex.apeswap.finance/#/swap?outputCurrency=ETH'
          }
        ]
      },
      {
        id: '2',
        poolId: 1,
        poolIdStrat: 8,
        poolType: PoolType.AUTO_COMPOUND,
        name: 'BUSD-USDC LP',
        contractAddress: '0x859f4e52fe4076d483A2EBCf53Ae69A35eBa7Aa5',
        farmContract: 'https://www.bscscan.com/address/0x5c8d727b265dbafaba67e050f2f739caeeb4a6f9',
        vaultContract: 'https://www.bscscan.com/address/0x5efD59b0F0d95ffaaFE6814962D13A22Dc18a0C4',
        routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        lpAddress: '0xC087C78AbaC4A0E900a327444193dBF9BA69058E',
        depositFee: '0%',
        entranceFee: '< 0.1%',
        withdrawFee: '0.1%',
        token0: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        token1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        getLPLink:
          'https://app.apeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0xe9e7cea3dedca5984780bafc599bd69add087d56',
        isActive: true,
        supportIL: true,
        supportROI: false,
        supportWithdrawAndRemoveLP: true,
        tokenDropdown: [
          {
            name: 'BUSD-USDC LP',
            address: '0xC087C78AbaC4A0E900a327444193dBF9BA69058E',
            routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
            approveToken: ['0xC087C78AbaC4A0E900a327444193dBF9BA69058E'],
            pathA: [],
            pathB: [],
            typeToken: 'LP',
            linkBuyToken:
              'https://app.apeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0xe9e7cea3dedca5984780bafc599bd69add087d56'
          },
          {
            name: 'BNB',
            address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
            routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
            approveToken: ['0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', '0xe9e7cea3dedca5984780bafc599bd69add087d56'],
            pathA: ['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'],
            pathB: ['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', '0xe9e7cea3dedca5984780bafc599bd69add087d56'],
            typeToken: 'token',
            linkBuyToken: 'https://dex.apeswap.finance/#/swap?outputCurrency=ETH'
          }
        ]
      }
    ]
  }
]

export const jdiAutoListByChain = (chain: Chain): JDIAuto[] => jdiAutoPoolList.filter((i) => i.chain === chain)
