import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { SnackbarProvider, useSnackbar } from 'notistack';
import * as React from 'react';
import { NotiItem } from './components/NotiItem';
export var NotiProvider = function NotiProvider(_ref) {
  var children = _ref.children;
  return _jsx(SnackbarProvider, {
    children: children
  });
};
export var useNoti = function useNoti() {
  var _useSnackbar = useSnackbar(),
      enqueueSnackbar = _useSnackbar.enqueueSnackbar,
      closeSnackbar = _useSnackbar.closeSnackbar;

  var onClose = function onClose(key) {
    return function () {
      closeSnackbar(key);
    };
  };

  var pushSnackbar = function pushSnackbar(message, _ref2) {
    var contentButton = _ref2.contentButton,
        onButtonClick = _ref2.onButtonClick,
        options = _objectWithoutProperties(_ref2, ["contentButton", "onButtonClick"]);

    try {
      enqueueSnackbar(message, _objectSpread(_objectSpread({}, options), {}, {
        content: function content(key) {
          var _ref3 = options || {
            variant: undefined
          },
              variant = _ref3.variant;

          return _jsx("div", {
            children: _jsx(NotiItem, {
              message: message,
              variant: variant,
              onClose: onClose(key),
              contentButton: contentButton,
              onButtonClick: onButtonClick
            })
          }, key);
        }
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return pushSnackbar;
};