export var size = {
  xxs: 425,
  mobile: 768,
  desktop: 1368
};
export var breakpoint = {
  //   tablet: `only screen and (min-width: ${size.tablet})`,
  desktop: "only screen and (min-width: ".concat(size.desktop, "px)"),
  mobile: "only screen and (max-width: ".concat(size.mobile, "px)"),
  xxs: "only screen and (max-width: ".concat(size.xxs, "px)")
};