import { ChainID } from '@jdiyield/constants'
import BigNumber from 'bignumber.js'

import { Image } from './cms'

// import { User } from 'path/to/interfaces';
export type User = {
  id: number
  name: string
}

export enum Chain {
  ETHEREUM = 'ETHEREUM',
  BINANCE_SMART_CHAIN = 'BINANCE_SMART_CHAIN',
  BSC_TESTNET = 'BSC_TESTNET',
  TERRA = 'TERRA',
  POLYGON = 'POLYGON',
  RONIN = 'RONIN',
  AVAX = 'AVAX',
  FTM = 'FTM'
}

export type ConnectorId = 'bsc' | 'metamask' | 'safe-pal' | 'trust-wallet' | 'walletconnect' | 'none'

// eslint-disable-next-line @typescript-eslint/ban-types
export type TokenName = 'BUSD' | 'BNB' | 'JDI' | 'USDT' | '1INCH' | 'CAKE' | (string & {})

export type RemoveLiquidityTypes = 'NORMAL' | 'PERMIT'

export type JDIAuto = {
  name: string
  logo: string
  link: string
  chain: Chain
  pool: JDIPoolAuto[]
}

export interface JDIPoolAutoDropdownProps {
  name: string
  address: string
  routerAddress: string
  approveToken?: string[]
  linkBuyToken?: string
  pathA: string[]
  pathB: string[]
  typeToken: 'token' | 'LP'
}

export type JDIPoolAuto = {
  id: string
  poolId: number
  poolIdStrat: number
  poolType: string
  name: string
  contractAddress: string
  farmContract: string
  vaultContract: string
  routerAddress: string
  lpAddress: string
  depositFee: string
  entranceFee: string
  withdrawFee: string
  isActive: boolean
  token0: string
  token1: string
  getLPLink: string
  supportIL: boolean
  supportROI: boolean
  supportWithdrawAndRemoveLP: boolean
  tokenDropdown?: JDIPoolAutoDropdownProps[]
}

export type Contract = {
  contractAddress: string
  rewardAddress: string
  functionRewardPerBlockName?: string
  getBonusMultiplier?: string
  removeLiquidity: RemoveLiquidityTypes
  routerAddress: string
  pendingReward: string
  harvest: boolean
  stake: boolean
  unstake: boolean
  showReward: boolean
  supportROI: boolean
  supportIL: boolean
}

interface FactoryInfo {
  initCodeHash?: string
  factoryAddress?: string
}
type NFTConfig = {
  contractAddress: string
}

export type DeployerAndTimeLock = {
  contractAddress: string
}
export interface Platform {
  name: string
  link: string
  title: string
  chain: Chain
  isDollar: boolean
  logo?: string
  image?: Image
  new?: boolean
  symbol?: string
  contracts?: Contract[]
  specialCaseTokens?: Token[]
  factories?: FactoryInfo[]
  nftConfigs?: NFTConfig[]
  lendContracts?: LendContract[]
  deployer?: DeployerAndTimeLock[]
  timeLock?: DeployerAndTimeLock[]
}

export enum PlatformName {
  AXIE_INFINITY = 'axie infinity ETH',
  PANCAKE_SWAP = 'pancakeswap',
  APE_SWAP = 'apeswap',
  ALE_SWAP = 'aleswap',
  WARDEN_SWAP = 'wardenswap',
  DEFINIX = 'definix',
  DOPPLE = 'dopple',
  AUTO_FARM = 'autofarm',
  MDEX = 'mdex',
  JDI_POOL = 'JDI Pool',
  SWAMP = 'swamp',
  PANCAKE_BUNNY = 'PancakeBunny',
  SALT_SWAP = 'saltswap',
  KEBAB_FINANCE = 'kebabfinance',
  KOALA_DEFI = 'koaladefi',
  THUNDER_SWAP = 'thunderswap',
  MANY_SWAP = 'manyswap',
  GOOSE_DEFI = 'goosedefi',
  JAGUAR_SWAP = 'jaguarswap',
  WAULT = 'wault',
  VALUE_DEFI = 'valuedefi',
  TREE_DEFI = 'Treedefi',
  BLIZZARD = 'blizzard',
  BLUE_SWAP = 'blueswap',
  CAFE_SWAP = 'cafeswap',
  PANTHER_SWAP = 'PantherSwap',
  BEEFY = 'Beefy',
  BEEFY_POLYGON = 'Beefy Polygon',
  ALPACA_FINANCE = 'AlpacaFinance',
  HORIZON_PROTOCOL = 'Horizon Protocol',
  BFT_POOL = 'BFT Pool',
  ELLIPSIS = 'Ellipsis',
  MERLIN_LAB = 'Merlinlab',
  TEN_FINANCE = 'tenfinance',
  INFINITEE_FINANCE = 'infiniteefinance',
  APE_ROCKET = 'aperocket',
  ZFARM = 'zfarm',
  DINOP = 'DINOP',
  TWINDEX = 'Twindex',
  JDI = 'JDI Vaults BSC',
  KILL_SWITCH = 'KillSwitch',
  FIREBIRD = 'Firebird',
  MOONPOTS = 'Moonpot',
  VIVA_INSURE = 'VIVA Insure',
  SUSHISWAP_POLYGON = 'SushiSwap Polygon',
  QUICKSWAP_POLYGON = 'Quickswap Polygon',
  APE_SWAP_POLYGON = 'ApeSwap Polygon',
  DFYN_POLYGIN = 'Dfyn Polygon',
  JDI_POLYGON = 'JDI Vaults Polygon',
  APE_ROCKET_POLYGON = 'Aperocket Polygon',
  IRON_POLYGON = 'Iron Polygon',
  AUTO_FARM_POLYGON = 'Autofarm Polygon',
  SPACE_TUKTUK = 'Space TukTuk',
  BALANCER_ETH = 'Balancer ETH',
  BALANCER_POLYGON = 'Balancer Polygon',
  COPYCAT = 'Copycat',
  ALESWAP_VAULT = 'AleSwap Vault',
  LATTE_SWAP = 'LatteSwap',
  LUCKYLION = 'Lucky Lion',
  PENGUIN_FINANCE = 'Penguin Finance',
  LYDIA_FINANCE = 'Lydia Finance',
  JOE = 'JOE',
  PANGOLIN = 'Pangolin',
  SPIRITSWAP = 'SpiritSwap',
  SPOOKYSWAP = 'SpookySwap',
  VEDAO = 'vedao',
  OXDAO = 'OXD'
}

export type Stake = (Contract & Balance) | (Contract & BalanceLP)

export type LPToken = (Balance & BalanceLP) & {
  platformName: PlatformName
  chain: Chain
  removeLP?: boolean
}

export type LendContract = {
  id: string
  liquidity: string
  borrowed: string
  totalSupply: string

  underlying: string
  lendTokens: string[]
}

export type Lending = {
  collateralTokens: AnotherToken[]
  borrowTokens: AnotherToken[]
  supplyBalance: number
  borrowBalance: number
}

export interface PlatformAndValue extends Platform {
  stakes?: Stake[]
  lending?: Lending[]
  lpTokens?: LPToken[]
  nftResults?: NFTResult[]
  totalCollateral?: number
  totalReward?: number
  totalDebt?: number
  totalWorth?: number
  totalLPValue?: number
}
export interface WalletValue {
  address: string
  chainID: ChainID
  totalWallet?: number
  totalReward?: number
  totalWorth?: number
  totalDebt?: number
  totalCollateral?: number
  totalLPValue?: number
  assets: MyAsset[]
  platforms?: PlatformAndValue[]
  loading: boolean
}

export type TypeAccount = 'bsc' | 'watch' | 'terra' | 'polygon'

export type AirdropRound = {
  round: number
  date: string
  airdrop: number
}

export type ReceiveAirdrop = {
  totalAmount: number
  holderAmount: number
  eligibleAirdrop: number
}

export type AirdropRanking = {
  whale: [number, number]
  shark: [number, number]
  dolphin: [number, number]
  salmon: [number, number]
  nemo: [number, number]
}

export type Timeline = {
  _id: string
  price: number
  timestamp: number
}

export type TokenPriceHistory = {
  id?: string
  symbol?: string
  name?: string
  chain?: string
  logo?: string
  decimals?: number
  address?: string
  prices: Timeline[]
  balance?: number
}

export interface AbiItem {
  anonymous?: boolean
  constant?: boolean
  inputs?: AbiInput[]
  name?: string
  outputs?: AbiOutput[]
  payable?: boolean
  stateMutability?: StateMutabilityType
  type: AbiType
  gas?: number
}

type AbiType = 'function' | 'constructor' | 'event' | 'fallback'
type StateMutabilityType = 'pure' | 'view' | 'nonpayable' | 'payable'

interface AbiInput {
  name: string
  type: string
  indexed?: boolean
  components?: AbiInput[]
  internalType?: string
}

interface AbiOutput {
  name: string
  type: string
  components?: AbiOutput[]
  internalType?: string
}

export interface ChainDataNativeCurrency {
  name: string
  symbol: string
  decimals: number
}

export interface ChainData {
  chainID: number
  chainName: string
  dexguruID: string
  blockExplorerUrls: string[]
  nativeCurrency: ChainDataNativeCurrency
}

export interface Wallet {
  walletID: number
  chainID: ChainID
  address: string
  excludes?: string[]
}
export interface Profile {
  profileID: number
  name: string
  wallets: Wallet[]
}
export type Token = {
  chain: Chain
  id: string
  tokenId?: string
  address: string
  decimals?: number
  name: string
  symbol: string
  logo?: string
  image?: Image
  valueUSD?: number
}

export interface APR {
  dailyAPR: number
  weeklyAPR: number
  monthlyAPR: number
  yearlyAPR: number
  yearAPY: number
  rewardPrice?: number
  poolPrice?: number
  rewardAddress?: string
  rewardSymbol?: string
  stakedTvlUSD?: number
}

export interface BaseLPBalance {
  balance: string
  lpAddress: string
}

export interface BaseBalance {
  pool: number
  balance: string
  lpAddress: string
  availableBalanceOf?: string
  lpUSD?: number
  rewardAddress?: string
  poolName?: string
  reward?: number
  rewardSecondary?: number
  rewardSymbol?: string
  rewardSymbolSecondary?: string
  rewardUSD?: number
  apr?: APR
}

export interface AnotherToken {
  logo?: string
  symbol?: string
  name: string
  amount: BigNumber
  value: number
  decimals: number
  address?: string
  valueUSD?: number
  usdPerCoin?: number
}
export interface BalanceLP extends BaseBalance {
  tokenA: AnotherToken
  tokenB: AnotherToken
  worth: number
}
export interface Balance extends BaseBalance {
  logo?: string
  token: AnotherToken
  worth: number
}

export interface AssetCurrency {
  address: string
  name: string
  symbol: string
  decimals?: number
}

export interface MyAsset {
  currency: AssetCurrency
  logo: string
  value: number
  valueUSD?: number
  ratePriceUSD?: number
}

export interface MyAssetLP {
  token: AssetCurrency
  logo: string
  value: number
  balance: number
  chain: Chain
}

export type PriceList = {
  [key: string]: {
    usd: number
  }
}

export type NFTResult = {
  tokenID?: number
  thumbnail_url: string
  asset_url: string
  name: string
  asset_type: 'video' | 'image'
  link?: string
  creator?: string
  creatorLink?: string
}
export type Position = {
  owner: string
  id: number
}

export type ProPlan = {
  id: number
  title: string
  body: string
  pricing: number
  description: string
}

export interface ImpermanentLoss {
  token0: {
    deposit: number
    current: number
    change: number
    valueChange?: number
  }
  token1: {
    deposit: number
    current: number
    change: number
    valueChange?: number
  }
  totalDepositValue?: number
  totalCurrentValue?: number
  totalValueChange?: number
  totalValueChangeInPercent?: number
}
