import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import _styled from "@emotion/styled/base";
import { breakpoint, size } from '@frontend/styles/breakpoint';
import * as React from 'react';
import { Footer } from './components/Footer';
import { Header } from './components/Header';

var Container = _styled("div", true ? {
  target: "e10l7m0y0"
} : {
  target: "e10l7m0y0",
  label: "Container"
})("display:grid;grid-auto-rows:max-content;max-width:", size.desktop, "px;min-height:90vh;padding:0 1.25rem 2.5rem;margin:0 auto;@media ", breakpoint.desktop, "{min-height:70vh;padding-bottom:4.5rem;}" + (true ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvYXBwcy9mcm9udGVuZC9zcmMvY29tcG9uZW50cy9MYXlvdXQvaW5kZXgudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU80QiIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL2FwcHMvZnJvbnRlbmQvc3JjL2NvbXBvbmVudHMvTGF5b3V0L2luZGV4LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuaW1wb3J0IHsgYnJlYWtwb2ludCwgc2l6ZSB9IGZyb20gJ0Bmcm9udGVuZC9zdHlsZXMvYnJlYWtwb2ludCdcbmltcG9ydCAqIGFzIFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgeyBGb290ZXIgfSBmcm9tICcuL2NvbXBvbmVudHMvRm9vdGVyJ1xuaW1wb3J0IHsgSGVhZGVyIH0gZnJvbSAnLi9jb21wb25lbnRzL0hlYWRlcidcblxuY29uc3QgQ29udGFpbmVyID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZ3JpZDtcbiAgZ3JpZC1hdXRvLXJvd3M6IG1heC1jb250ZW50O1xuICBtYXgtd2lkdGg6ICR7c2l6ZS5kZXNrdG9wfXB4O1xuICBtaW4taGVpZ2h0OiA5MHZoO1xuICBwYWRkaW5nOiAwIDEuMjVyZW0gMi41cmVtO1xuICBtYXJnaW46IDAgYXV0bztcbiAgQG1lZGlhICR7YnJlYWtwb2ludC5kZXNrdG9wfSB7XG4gICAgbWluLWhlaWdodDogNzB2aDtcbiAgICBwYWRkaW5nLWJvdHRvbTogNC41cmVtO1xuICB9XG5gXG5cbmV4cG9ydCBjb25zdCBMYXlvdXQ6IFJlYWN0LkZDID0gKHsgY2hpbGRyZW4gfSkgPT4ge1xuICByZXR1cm4gKFxuICAgIDxkaXY+XG4gICAgICA8SGVhZGVyIC8+XG4gICAgICA8Q29udGFpbmVyPntjaGlsZHJlbn08L0NvbnRhaW5lcj5cbiAgICAgIDxGb290ZXIgLz5cbiAgICA8L2Rpdj5cbiAgKVxufVxuXG5leHBvcnQgZGVmYXVsdCBMYXlvdXRcbiJdfQ== */"));

export var Layout = function Layout(_ref) {
  var children = _ref.children;
  return _jsxs("div", {
    children: [_jsx(Header, {}), _jsx(Container, {
      children: children
    }), _jsx(Footer, {})]
  });
};
export default Layout;