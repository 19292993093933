import { Lending, LPToken, PriceList, Stake } from '@jdiyield/interfaces'

type Props = {
  address: string
  value: number
  coinList: PriceList
}
export const getTokenUSD = ({ address, value, coinList }: Props) => {
  let usdPerCoin = +coinList[address]?.usd ?? 0
  usdPerCoin = isNaN(usdPerCoin) ? 0 : usdPerCoin
  return { value: value * usdPerCoin, usdPerCoin }
}

export function summaryTotalStakes(stakes: Stake[], coinList: PriceList) {
  try {
    return stakes.reduce(
      (sum, stake) => {
        if (stake === null) return sum
        const { reward = 0, rewardAddress } = stake

        const rewardPrice = coinList[rewardAddress]?.usd ?? 0
        const lpPrice = 'token' in stake ? coinList[stake.token.address.toLowerCase()]?.usd ?? 0 : 0
        const worth = stake.worth || ('token' in stake ? stake.token.value * lpPrice : 0)
        const rewardUSD = stake.rewardUSD || reward * rewardPrice

        const totalReward = sum.totalReward + rewardUSD
        const totalWorth = sum.totalWorth + worth
        const noEmptyStakes: Stake[] =
          reward + worth !== 0 ? [...sum.noEmptyStakes, { ...stake, rewardUSD }] : sum.noEmptyStakes

        return { totalReward, totalWorth, noEmptyStakes }
      },
      { totalReward: 0, totalWorth: 0, noEmptyStakes: [] }
    )
  } catch (error) {
    console.log(stakes, error)
    return { totalReward: 0, totalWorth: 0, noEmptyStakes: [] }
  }
}

export function summaryTotalLP(lpTokens: LPToken[]) {
  if (!lpTokens) return { totalLPValue: 0 }

  try {
    return lpTokens.reduce(
      (acc, cur) => {
        const totalLPValue = acc.totalLPValue + (cur?.worth ?? 0)

        return { totalLPValue }
      },
      { totalLPValue: 0 }
    )
  } catch (error) {
    console.log(lpTokens, error)
    return { totalLPValue: 0 }
  }
}

export function summaryTotalLending(lending: Lending[]) {
  if (!lending || lending.length === 0) return { totalDebt: 0, totalCollateral: 0 }

  const result = lending.reduce(
    (sum, curr) => {
      return {
        totalDebt: sum.totalDebt + curr.borrowBalance,
        totalCollateral: sum.totalCollateral + curr.supplyBalance
      }
    },
    { totalDebt: 0, totalCollateral: 0 }
  )
  return result
}
