import { PlatformName } from '@jdiyield/interfaces'

import { oldplatforms as platforms } from './platforms'

export const PANCAKE_ROUTER = '0x05ff2b0db69458a0750badebc4f9e13add608c7f'
export const BNB = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
export const UST = '0x23396cf899ca06c4472205fc903bdb4de249d6fc'.toLowerCase()
export const BUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56'
export const USDT = '0x55d398326f99059ff775485246999027b3197955'
export const AUTO_CC = '0x763a05bdb9f8946d8c3fa72d1e0d3f5e68647e5c'
export const AUTO = '0x0895196562C7868C5Be92459FaE7f877ED450452'
export const AUTO_POLYGON = '0x89d065572136814230A55DdEeDDEC9DF34EB0B76'
export const SWAMP = '0x33adbf5f1ec364a4ea3a5ca8f310b597b8afdee3'
export const JDI_JDI_POOL = '0xa3283c497710cbbe31d35a5cf56c315c621d6e10'
export const JDI_JDIBNB_POOL = '0x7336b60ac64a5bf04eec760bd135ea105994387c'
export const JDI_BANANA_POOL = '0x72c8750af0744282e065f0df3f73460a3764c624'
export const SUSHI_POOL = '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F'
export const POLYYELD_AUTO_POOL = '0xc49bc7118a73ca6cb36bfa454fd40ecae079a463'
export const POLYCRYSTAL_AUTO_POOL = '0x5BaDd6C71fFD0Da6E4C7D425797f130684D057dd'

export const POLYCRYSTAL_PARTNER_POOLS = [
  '0x4e9e19b2943c74a2a6f801be0421ed3c563b83e9',
  '0x80163ae2ed3f0f572f580e9749a57a4d999e3f5b',
  '0xb51620eabfb567ce383272b922e6ec663e86bdb0',
  '0xde771a3445d60ab734e14f2e4aa1aaac6d724403',
  '0x5badd6c71ffd0da6e4c7d425797f130684d057dd',
  '0x03a6c95be4d1cefd51511c0a7ce6be66f0aa4957'
]

export const POLYCAT_POOL = '0xBdA1f897E851c7EF22CD490D2Cf2DAce4645A904'

export const QUICK_POOLS = [
  '0x251d9837a13F38F3Fe629ce2304fa00710176222',
  '0x7Ca29F0DB5Db8b88B332Aa1d67a2e89DfeC85E7E',
  '0x4A73218eF2e820987c59F838906A82455F42D98b',
  '0x070D182EB7E9C3972664C959CE58C5fC6219A7ad',
  '0xB26bfcD52D997211C13aE4C35E82ced65AF32A02',
  '0x785AaCd49c1Aa3ca573F2a32Bb90030A205b8147',
  '0x573bb5CCC26222d8108EdaCFcC7F7cb9e388Af10',
  '0x97D69E23DF7BBB01F9eA78b5651cb6ad125D6d9a'
]

export const DFYN_POOLS = [
  //Ecosystem
  '0x250fF0EE7a02d0DaeC193A0Ea76f879B26818732',
  '0x6719BD4E5Aed0e25b4cE5FB01d763353103e7258',
  '0xa3d7c12a474a6806df5e0260019eD613bF970c54',
  '0xfBCE866aF59bEa3b3880330F7DE3b08d7bc26676',
  '0xf4822f5e1B01Dc101914C888d88E6d295c6A7FCA',
  '0x4cAE3C18b058eBF1EC439f01457348a9dD9CcC53',
  //Popular
  '0xeee84F55F493c6ea89b655FFF09F2a2f9C2D1Dd8',
  '0x17e8732E2f0f6c35a794e9db4e63AeaDa9ce927C',
  '0xA51aF13F630489eE77B4489844041031e4e36824',
  '0xa55D1729eF64755D44640C67feA6D18A44EE9326',
  '0xf01adB0eF728D4544E9a1E3543FC98F0C1CAE7FD',
  '0xbd7BD38EC111A1789158b25240B5DaAE043113aE',
  '0x12286A4a13FCAFB77f08c48A00e6963A0ca6d917',
  '0x39a7733e5F418a5F4c11A2212f085F0a776Ac7D3',
  '0xCf32aF39BC10BAd0E193630E4E49b0Fa44867E7B',
  '0xC79FC48EC33038e80531B14b1efE0C8cAb50747A',
  '0xbE6D6BA111E459610646FD4234005331af49179F',
  '0xC9091079b96fc51df933720b5071c0B0d18EF785',
  '0xf8f7F41BC59f37cfC19794CB41Ec37073fc98E5f',
  '0x9b0E341661E8A993BBe3dd4b1d2484f100A55BB4',
  '0xE504196B11dE48Da00872697f4683F5596dc8E8E',
  '0xF4B0Dfe49aa35463D40d2FFAe47006990Ae10465',
  '0x6aa7f7cD7185905948951ab10E5FAE65d4Ab883D'
]
export const BALANCER_LPs = [
  /** BPSP*/ '0x06df3b2bbb68adc8b0e302443692037ed9f91b42',
  /** BPTC*/ '0x03cd191f589d12b0582a99808cf19851e468e6b5',
  /** B-POLYBASE*/ '0x0297e37f1873d2dab4487aa67cd56b58e2f27875',
  /** WBTC+renBTC*/ '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56',
  /** BAL+WETH*/ '0x7eb878107af0440f9e776f999ce053d277c8aca8',
  /** LINK+WETH+BAL+AAVE*/ '0xce66904b68f1f070332cbc631de7ee98b650b499',
  /** WMATIC+USDC+QI+BAL+miMATIC*/ '0xf461f2240b66d55dcf9059e26c022160c06863bf',
  /** SNX-GRT-USDC-WETH (BPSG)*/ '0x4e7f40cd37cee710f5e87ad72959d30ef8a01a5d',
  /** WMATIC+MTA+WETH*/ '0x614b5038611729ed49e0ded154d8a5d3af9d1d9e',
  //ETH
  '0x8339e311265a025fd5792db800daa8eda4264e2c',
  '0x45910faff3cbf990fdb204682e93055506682d17',
  '0xfa22ec1c02f121083bf04fbbcaad019f490d7a30',
  '0xa660ba113f9aabaeb4bcd28a4a1705f4997d5432',
  '0xea8886a24b6e01fba88a9e98d794e8d1f29ed863',
  '0x0b09dea16768f0799065c475be02919503cb2a35',
  '0xde148e6cc3f6047eed6e97238d341a2b8589e19e',
  '0x5d563ca1e2daaae3402c36097b934630ab53702c',
  '0x9f1f16b025f703ee985b58ced48daf93dad2f7ef',
  '0x3ebf48cd7586d7a4521ce59e53d9a907ebf1480f',
  '0xefaa1604e82e1b3af8430b90192c1b9e8197e377',
  '0x9c08c7a7a89cfd671c79eacdc6f07c1996277ed5',
  '0xe99481dc77691d8e2456e5f3f61c1810adfc1503',
  '0xec60a5fef79a92c741cb74fdd6bfc340c0279b01',
  '0x231e687c9961d3a27e6e266ac5c433ce4f8253e4',
  '0x80be0c303d8ad2a280878b50a39b1ee8e54dbd22',
  '0x5aa90c7362ea46b3cbfbd7f01ea5ca69c98fef1c',
  '0xa6f548df93de924d73be7d25dc02554c6bd66db5',
  '0x96646936b91d6b9d7d0c47c496afbf3d6ec7b6f8',
  '0x186084ff790c65088ba694df11758fae4943ee9e',
  '0x4626d81b3a1711beb79f4cecff2413886d461677',
  '0x072f14b85add63488ddad88f855fda4a99d6ac9b',
  '0xaac98ee71d4f8a156b6abaa6844cdb7789d086ce',
  '0x7eb878107af0440f9e776f999ce053d277c8aca8',
  '0x16faf9f73748013155b7bc116a3008b57332d1e6',
  '0x6ae82385f76e3742f89cb46343b169f6ee49de1b',
  '0x8a92c3afabab59101b4e2426c82a7ddbb66b5450',
  '0x5c6ee304399dbdb9c8ef030ab642b10820db8f56',
  '0xf4c0dd9b82da36c07605df83c8a416f11724d88b',
  '0x3e5fa9518ea95c3e533eb377c001702a9aacaa32',
  '0xf8a0623ab66f985effc1c69d05f1af4badb01b00',
  '0x021c343c6180f03ce9e48fae3ff432309b9af199',
  '0x14462305d211c12a736986f4e8216e28c5ea7ab4',
  '0xf5aaf7ee8c39b651cebf5f1f50c10631e78e0ef9',
  '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56',
  '0x06df3b2bbb68adc8b0e302443692037ed9f91b42'
]

export const miMATIC = '0xa3fa99a148fa48d14ed51d610c367c61876997f1'

export const IRON_V2 = '0x1fd1259fa8cdc60c6e8c86cfa592ca1b8403dfad'

export const IRON_LPs = ['0x985d40fedaa3208dabacdfdca00cbeaac9543949', '0xb4d09ff3da7f9e9a2ba029cb0a81a989fd7b8f17']
export const BUNNY = '0x1b6e3d394f1d809769407dea84711cf57e507b99'
export const ALPHA = '0x3bb5f6285c312fc7e1877244103036ebbeda193d'
export const BEEFY = '0xCa3F508B8e4Dd382eE878A314789373D80A5190A'
export const AUTO_CAKE_POOL = '0xa80240eb5d7e05d3f250cf000eec0891d00b51cc'
export const BAKERYSWAP = '0x61890bd5AF37A44f15a697f9db9BfcCeAbF4e322'
//? Alpaca
export const ibALPACA = '0xf1be8ecc990cbcb90e166b71e368299f0116d421'
export const ibBNB = '0xd7d069493685a581d27824fc46eda46b7efc0063'
export const ibBUSD = '0x7c9e73d4c71dae564d41f78d56439bb4ba87592f'
export const ibUSDT = '0x158da805682bdc8ee32d52833ad41e74bb951e59'
export const ibTUSD = '0x3282d2a151ca00bfe7ed17aa16e42880248cd3cd'
export const ibBTCB = '0x08fc9ba2cac74742177e0afc3dc8aed6961c24e7'
export const ibETH = '0xbff4a34a4644a113e8200d7f1d79b3555f723afe'
export const alpacaDebtTokens = [ibALPACA, ibBNB, ibBUSD, ibUSDT, ibTUSD, ibBTCB, ibETH]

export const mebBUSD = '0x7e0c621ea9f7afd5b86a50b0942eaee68b04a61c'
export const EPS_3P = '0xaf4de8e872131ae328ce21d909c74705d3aaf452'
export const WEX = '0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90'
export const DOP = '0x844fa82f1e54824655470970f7004dd90546bb28'
export const mebBNB = '0x639f18c72b6a017bdd209c161d1617c9481a1e4d'
export const ALPACA = '0xa625ab01b08ce023b2a342dbb12a16f2c8489a8f'
export const HORIZON = '0xC0eFf7749b125444953ef89682201Fb8c6A917CD'
export const BISON = '0x5a10311f7d03a6e7ab464da54bdd535f3d4ce512'
export const SACT = '0xade70b187429e3cf4a8dd476ecba8ce9002621ec'
export const BHC = '0x189ae88b8df159bdb28260e86e87d770f2fc384d'
export const CRUSH = '0xac4409cc7709783c7cceb6c6e69fe42fad192623'
export const GMR = '0xfd9c606d3ff6ac1b044c4f5c0a9fa934634bd2e8'
export const BANANA_GNANA = '0x8f97b2e6559084cfaba140e2ab4da9aaf23fe7f8'
export const BFT = '0x49bbb345c432b9b66b407f1525a81a84f4509b8b'
export const CYT = '0x64fc4bf63c4315156f56b953a42bf2459b227a68'
export const STARS = '0x2555190c9f515877ac88defc2242a4e91aa49021'
export const BIRD = '0xaffd843b548f70cc586cdecb2dd0e85be5349e96'
export const DINOP_GNANA = '0x82ee644a04573aebbfbf3d7fa98f20922a5c606a'
export const MARU = '0x51c58fb9a61c00e3e4cd09b499726ba1cd3dd261'
export const PERA = '0xe6c5dafc5baeb927bc56b3a4154edecfdbb937d6'
export const LAND = '0x44b7e5593dfc65208a2b68c3f6c1cffd881ef6fb'
export const MERLINLAB = '0x0c5d20266babc524eedf8719321629e5dfa1a4d9'
export const TENFINANCE = '0x264a1b3f6db28de4d3dd4ed23ab31a468b0c1a96'
export const DOPPLE = '0xda0a175960007b0919dbf11a38e6ec52896bddbe'
export const DOPPLE_UST_LP = '0x7edcdc8cd062948ce9a9bc38c477e6aa244dd545'
export const DOP_2P_LP = '0x124166103814e5a033869c88e0f40c61700fca17'
export const DOPPLE_3P = '0xaa5509ce0ecea324bff504a46fc61eb75cb68b0c'
export const DOP_LP = '0x9116f04092828390799514bac9986529d70c3791'

export const DOP_LPS = [
  DOPPLE_UST_LP,
  DOPPLE_3P,
  DOP_LP,
  DOP_2P_LP,
  '0x2bf718e5fa2106dc1998d3f964c1baea8bda36e1',
  '0x07fe67a1cfc285e14e72fb5bb03a6ccfa6224aa8',
  '0x367189b09340a6e157e5fd31cb0924e91b71710c',
  '0x7755816b41f04ab62cec2334263987e7cd78a4fe',
  '0x01222c8b6cb44e47c19e61c6ddac769e6d9fe629'
]

export const DOLLY = '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5'
export const DAI = '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3'
export const TWINDEX = '0xe6be78800f25ffae4d1db7ca6d3485629bd200ed'

export const SEEDER_POOL = '0x1aF28E7b1A03fA107961897a28449F4F9768ac75'

export const APEROCKET =
  platforms
    .find((platform) => platform.name === PlatformName.APE_ROCKET)
    ?.contracts.map((contract) => contract.contractAddress) || []

export const APEROCKET_POLYGON =
  platforms
    .find((platform) => platform.name === PlatformName.APE_ROCKET_POLYGON)
    ?.contracts.map((contract) => contract.contractAddress) || []

export const DINOPARK_LV1 = [
  '0x5261729982b07176853cC399bf7fBfD6055511f7', //PoolJDI
  '0x9c20f9a419dCa69059AAc7C4b944f9fB4a52Bc0e', // PoolDINOP
  '0x9CEADda8Dd25E6427Ed3619bf9ebEaCA0D851cbb', //PoolBANANA
  '0x47Bf068069bE9e233b91447eeDdD9A6263e7618F' //PoolDBM
]

export const GNANA_POOL = [CYT, STARS, BIRD, DINOP_GNANA, MARU, PERA, LAND, BISON, SACT]

export const APEZONE_POOLS = [
  JDI_BANANA_POOL,
  JDI_JDIBNB_POOL,
  JDI_JDI_POOL,
  BFT,
  BANANA_GNANA,
  BHC,
  CRUSH,
  GMR,
  ...GNANA_POOL
]

export const FIFTYCENT_POOLS = [
  '0x3F8868c5A75Ae03498100774F73B10F80eBF44d4',
  '0x975601b1f21d3688EC853f25821Ce9DAd62c5E1d'
]

export const KILLSWITCH_VAULT = '0xd02ad47cc8d52b66f245a09df3bec188ed26cff3'

export const FOODCOURT_POOL = '0xe43b7c5c4c2df51306cceb7cbc4b2fcc038874f1'

export const SYRUP_POOLS = ['0xb9ff4da0954b300542e722097671ead8cf337c17']

export const masterChefPresets = [
  {
    name: '-',
    address: ''
  },
  {
    name: 'PancakeSwap',
    address: '0x73feaa1ee314f8c655e354234017be2193c9e24e'
  },
  {
    name: 'ApeSwap',
    address: '0x5c8D727b265DBAfaba67E050f2f739cAeEB4A6F9'
  },
  {
    name: 'Goose',
    address: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57'
  },
  {
    name: 'CafeSwap',
    address: '0xc772955c33088a97D56d0BBf473d05267bC4feBB'
  },
  {
    name: 'Kebab',
    address: '0x76FCeffFcf5325c6156cA89639b17464ea833ECd'
  },
  {
    name: 'Teamrocket',
    address: '0x2b56ae44435825cebdb3cbcb15e2458345da5247'
  },
  {
    name: 'Auto',
    address: AUTO
  },
  {
    name: 'Ramen',
    address: '0x97dd424b4628c8d3bd7fcf3a4e974cebba011651'
  },
  {
    name: 'KickStart',
    address: '0x2ea36cd78f24ca535fb66e020d7963e7731dd371'
  },
  {
    name: 'DonutDefi',
    address: '0x93070Bd094Abdf8815Fadd40048812Ad815A3ec0'
  },
  {
    name: 'RoseSwap',
    address: '0x5059acdce47c783442a353fcd2a7d226155f0135'
  },
  {
    name: 'SaltSwap',
    address: '0xB4405445fFAcF2B86BC2bD7D1C874AC739265658'
  },
  {
    name: 'Icecream',
    address: '0xc26316b19117495e89c187339ddb6e86f1e39f0c'
  },
  {
    name: 'Viking',
    address: '0xEf6e807fD2c0Ef5883A03Ed1b962333E8C9b725f'
  },
  {
    name: 'UFO',
    address: '0x95d9a00087f0db9e72b4014017842336480a153b'
  },
  {
    name: 'TACO',
    address: '0x36f44a1C8e973739D0034FF1B9B9f6c4c7085625'
  },
  {
    name: 'Alpaca',
    address: '0xA625AB01B08ce023B2a342Dbb12a16f2C8489A8F'
  },
  {
    name: 'Sashimi',
    address: '0x34E483600e9c73390C7fBB4C9985a057156a611F'
  }
]

export const routerPresets = [
  {
    name: 'PancakeSwap',
    address: '0x05ff2b0db69458a0750badebc4f9e13add608c7f'
  },
  {
    name: 'ApeSwap',
    address: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607'
  },
  {
    name: 'Icecream',
    address: '0x6728f3c8241c44cc741c9553ff7824ba9e932a4a'
  },
  {
    name: 'Sashimi',
    address: '0x24cEFA86fC1826FD31b4cb911034907735F8085A'
  }
]

export const FIREBIRD_FARM_HOPE = '0xd67caed2cedd964dfc7e91543099960db044a6fe'
export const FIREBIRD_FARM_HOPE_POLYGON = '0xe9a8b6ea3e7431e6befca51258cb472df2dd21d4'

export const MOON_POTS_MAIN = '0x35ebb629b6e65cb7705b5e0695982d206898f195'
export const MOON_POTS_CALE = '0x30d55ce74e2dcd1b0ff37214a6978fcfc06aa499'
export const MOON_POTS = [MOON_POTS_MAIN, MOON_POTS_CALE]
export const PANGOLIN = ['0x574d3245e36Cf8C9dc86430EaDb0fDB2F385F829']
export const JOE = ['0x188bED1968b795d5c9022F6a0bb5931Ac4c18F00']

export const COPYCAT_LP = '0x9a1b69f216ea3b9d7c4f938edaea0dae7e758c17'
export const LATTE_SWAP = '0xbcee0d15a4402c9cc894d52cc5e9982f60c463d6'
