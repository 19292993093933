import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 3 * 60 * 1000
})

// Create `axios` instance passing the newly created `cache.adapter`
export const api = axios.create({
  adapter: cache.adapter
})
